import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Messaging, onMessage } from 'firebase/messaging';
import {
    AcceptInvite,
    Home,
    RestorePassword,
    SetPassword,
    SignIn,
    SignUp,
} from './screens';
import { PrivateRoute } from './screens/Auth';
// eslint-disable-next-line import/extensions
import './scss/style.scss';
import { fetchChain } from './services/ChainsService';

import { fetchCircle } from './services/CirclesService';
import { messagingPromise } from './services/firebase';
import { fetchReport } from './services/ReportsService';
import { fetchTransaction } from './services/TransactionsService';
import { getToken } from './state/token';
import { getMyUserId } from './state/user';
import {
    fetchCurrentOrganizationStatistics,
    fetchFactoringClient,
    fetchFactoringOrganization,
} from './services/OrganizationsService';
import { fetchNotifications } from './services/NotificationsService';
import { getMyCurrentOrganizationId } from './state/statistics';
import { store } from './state/store';

export default function App() {
    const token = useSelector(getToken);
    const userId = useSelector(getMyUserId);
    const [messaging, setMessaging] = useState<Messaging | undefined>();

    useEffect(() => {
        (async () => {
            const firebaseMessaging = await messagingPromise();
            setMessaging(firebaseMessaging);
        })();
    }, []);

    useEffect(() => {
        let unsubscribe;

        if (messaging) {
            unsubscribe = onMessage(messaging, async remoteMessage => {
                // const unsubscribe = messaging?.onMessage(async remoteMessage => {
                console.log('remoteMessage', remoteMessage);

                const type = remoteMessage?.data?.notificatable_type;
                const id = remoteMessage?.data?.notificatable_id
                    ? Number(remoteMessage?.data?.notificatable_id)
                    : 0;

                const currentOrganizationId = getMyCurrentOrganizationId(
                    store.getState(),
                );
                const organizationId = remoteMessage?.data?.organization_id;

                if (
                    !!token &&
                    !!userId &&
                    !!type &&
                    (currentOrganizationId?.toString() === organizationId ||
                        !currentOrganizationId ||
                        !organizationId)
                ) {
                    await fetchCurrentOrganizationStatistics();
                    await fetchNotifications();
                    switch (type) {
                        case 'transaction':
                            await fetchTransaction(id);
                            break;
                        case 'factor_request_transaction':
                            const transactionId = remoteMessage?.data
                                ?.transaction_id
                                ? Number(remoteMessage?.data?.transaction_id)
                                : undefined;
                            if (transactionId) {
                                await fetchTransaction(transactionId);
                            }
                            break;
                        case 'report':
                            await fetchReport(id);
                            break;
                        case 'circle':
                            await fetchCircle(id);
                            break;
                        case 'chain':
                            await fetchChain(id);
                            break;
                        case 'factor_request_connection':
                            const action = remoteMessage?.data?.action; // status_change | status_change_organization
                            if (action === 'status_change') {
                                await fetchFactoringClient(id);
                            } else if (
                                action === 'status_change_organization'
                            ) {
                                const orgId = remoteMessage?.data
                                    ?.current_organization_id
                                    ? Number(
                                          remoteMessage?.data
                                              ?.current_organization_id,
                                      )
                                    : undefined;
                                if (orgId) {
                                    await fetchFactoringOrganization(orgId);
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
            });
        }

        return unsubscribe;
    }, [messaging, token, userId]);

    return (
        <Router>
            <Switch>
                <Route path="/sign-in">
                    <SignIn />
                </Route>
                <Route path="/sign-up">
                    <SignUp />
                </Route>
                <Route path="/restore-info">
                    <RestorePassword />
                </Route>
                <Route path="/go-back">
                    <></>
                </Route>
                <Route path="/set-password">
                    <SetPassword />
                </Route>
                <Route path="/accept-invite">
                    <AcceptInvite />
                </Route>
                <PrivateRoute>
                    <Route path="/">
                        <Home />
                    </Route>
                </PrivateRoute>
            </Switch>
        </Router>
    );
}
