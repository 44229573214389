import React, { memo, useCallback, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import {
    Column,
    DeleteAccountCardContainer,
    DeleteCardSubtitle,
    DeleteCardTitle,
    DeleteFormTitle,
    DeleteIcon,
    SpinnerWrapper,
} from './styles';
import deleteIcon from './delete-icon.svg';
import { FormError } from '../../../components';
import { signOut } from '../../../services/SignOutService';
import { deleteAccount } from '../../../services/UsersService';

type DeleteAccountFormProps = {
    setDeleteAccountFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteAccountForm = memo(
    ({ setDeleteAccountFormVisible }: DeleteAccountFormProps) => {
        const [isLoading, setLoading] = useState(false);

        const requiredFields = useMemo(() => ['password'], []);

        const allFields = useMemo(() => requiredFields, [requiredFields]);

        const [errors, setErrors] = useState<{ [key: string]: any }>({});

        const [credentials, setCredentials] = useState<
            Partial<{ password: string }>
        >({ password: '' });

        const checkEmptyValues = useCallback(() => {
            let allFieldsFilled = true;
            requiredFields.forEach(item => {
                if (
                    !errors.hasOwnProperty(item) &&
                    (!credentials.hasOwnProperty(item) ||
                        credentials[item] === '')
                ) {
                    allFieldsFilled = false;
                    const errorMessage = `The ${item} is required`;
                    setErrors(prevState => {
                        return {
                            [item]: [errorMessage],
                            ...prevState,
                        };
                    });
                }
            });
            return allFieldsFilled;
        }, [credentials, errors, requiredFields]);

        const onDelete = useCallback(async () => {
            if (!Object.keys(errors).length) {
                const allFieldsFilled = checkEmptyValues();
                if (allFieldsFilled) {
                    setLoading(true);
                    const response = await deleteAccount(credentials);
                    setLoading(false);

                    if (response?.status === 200 || response?.status === 201) {
                        setDeleteAccountFormVisible(false);
                        await signOut();
                    }
                    if (
                        response?.response?.status === 422 &&
                        response?.response?.data?.errors
                    ) {
                        const allErrors = response?.response?.data?.errors;
                        const filteredErrors = Object.keys(allErrors)
                            .filter(key => allFields.includes(key))
                            .reduce((obj, key) => {
                                return {
                                    ...obj,
                                    [key]: allErrors[key],
                                };
                            }, {});
                        const otherFilteredErrors = Object.keys(allErrors)
                            .filter(key => !allFields.includes(key))
                            .reduce((obj, key) => {
                                return {
                                    ...obj,
                                    [key]: allErrors[key],
                                };
                            }, {});
                        setErrors(filteredErrors);

                        if (Object.keys(otherFilteredErrors).length) {
                            console.log('other errors:', otherFilteredErrors);
                        }
                    }
                }
            }
        }, [
            allFields,
            checkEmptyValues,
            credentials,
            errors,
            setDeleteAccountFormVisible,
        ]);

        const credentialsChanged = useCallback(
            (key: string, value: string) => {
                setCredentials(prevState => ({
                    ...prevState,
                    [key]: value,
                }));
                if (key === 'password' && !value) {
                    const errorMessage = `The ${key} is required`;
                    if (!errors.hasOwnProperty(key))
                        setErrors(prevState => {
                            return { ...prevState, [key]: [errorMessage] };
                        });
                } else {
                    setErrors(prevState => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { [key]: _, ...res } = prevState;
                        return res;
                    });
                }
            },
            [errors],
        );

        return (
            <div className="form">
                <DeleteAccountCardContainer>
                    <DeleteIcon src={deleteIcon} />
                    <Column>
                        <DeleteCardTitle>
                            Are you sure you want to{' '}
                            <DeleteCardTitle danger>
                                delete your account?
                            </DeleteCardTitle>
                            <DeleteCardSubtitle>
                                Deleting the account is irreversible and all
                                your information, including business profile,
                                invoices and tours will be deleted. You will NOT
                                be able to restore this information.
                            </DeleteCardSubtitle>
                        </DeleteCardTitle>
                    </Column>
                    {isLoading && (
                        <SpinnerWrapper>
                            <Loader
                                type="Watch"
                                color="#f37749"
                                visible
                                height={30}
                                width={30}
                            />
                        </SpinnerWrapper>
                    )}
                </DeleteAccountCardContainer>
                <DeleteFormTitle>
                    Submit the form to confirm the deletion of your account
                </DeleteFormTitle>

                <div className="form-group" style={{ maxWidth: 291 }}>
                    {/* //col-lg-6 col-sm-6 col-xs-6"> */}
                    <input
                        className="form-control"
                        type="password"
                        placeholder="Enter your password"
                        value={credentials?.password}
                        onChange={e =>
                            credentialsChanged('password', e.target.value)
                        }
                    />
                    <FormError errors={errors?.password} />
                </div>

                <div className="form-actions" style={{ maxWidth: 589 }}>
                    <div className="button-group">
                        <button
                            type="button"
                            className="button button-primary button-sm"
                            disabled={isLoading}
                            onClick={onDelete}
                        >
                            <em className="mdi mdi-check start" />
                            Submit
                        </button>
                        <button
                            type="button"
                            className="button button-default button-primary button-sm"
                            disabled={isLoading}
                            onClick={() => setDeleteAccountFormVisible(false)}
                        >
                            <em className="mdi mdi-close start" />
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    },
);
