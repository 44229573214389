import { DateTime } from 'luxon';
import { TdValue } from './styles';

type Props = {
    date?: string;
    onClick?: () => void;
};

export const Td2 = ({ date, onClick }: Props) => {
    return (
        <td>
            <button type="button" onClick={onClick}>
                <TdValue>
                    {date
                        ? DateTime.fromSQL(date).toUTC().toFormat('dd.MM.yyyy')
                        : '-'}
                </TdValue>
            </button>
        </td>
    );
};
