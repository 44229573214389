import { AxiosResponse } from 'axios';
import api from './axiosConfig';
import { fetchCurrentOrganizationStatistics } from './OrganizationsService';

export const createMerchant: Promise<AxiosResponse<any>> | any = async () => {
    const response = await api.post('/merchants');
    if (response.status === 201 || response.status === 200) {
        await fetchCurrentOrganizationStatistics();
    }
    return response;
};
