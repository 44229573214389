import { useCallback, useState } from 'react';
import { TD6Container } from './styles';
import { TGroup } from '../../types/types';
import {
    groupInviteApprove,
    groupInviteReject,
} from '../../services/OrganizationsService';

type Props = {
    group: TGroup;

    onEdit?: (id: number) => void;
    onChange?: (groupId: number) => Promise<void>;
};

export const Td6 = ({ group, onEdit, onChange }: Props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const onGroupChange = useCallback(async () => {
        setButtonDisabled(true);
        await onChange?.(group?.group_id);
        setButtonDisabled(false);
    }, [group?.group_id, onChange]);
    const onAccept = useCallback(async () => {
        setButtonDisabled(true);
        await groupInviteApprove(group?.id);
        setButtonDisabled(false);
    }, [group?.id]);

    const onReject = useCallback(async () => {
        setButtonDisabled(true);
        await groupInviteReject(group?.id);
        setButtonDisabled(false);
    }, [group?.id]);
    return (
        <td>
            <TD6Container className="block">
                {!!group?.can_edit && (
                    <button
                        type="button"
                        className="button button-default button-external button-sm"
                        onClick={() => onEdit?.(group?.id)}
                        onKeyDown={() => onEdit?.(group?.id)}
                        disabled={isButtonDisabled}
                        style={{ maxWidth: 100 }}
                    >
                        <em className="mdi mdi-pencil start" />
                        Edit
                    </button>
                )}
                {(!!group?.can_edit || !!group?.is_default) &&
                    !group?.is_current && (
                        <button
                            type="button"
                            className="button button-primary button-external button-sm"
                            onClick={onGroupChange}
                            onKeyDown={onGroupChange}
                            disabled={isButtonDisabled}
                            style={{ maxWidth: 130 }}
                        >
                            <em className="mdi white-color mdi-check start" />
                            Set as current
                        </button>
                    )}
                {!!group?.can_accept_or_reject && (
                    <button
                        type="button"
                        className="button button-primary button-external button-sm"
                        onClick={onAccept}
                        onKeyDown={onAccept}
                        disabled={isButtonDisabled}
                        style={{ maxWidth: 130 }}
                    >
                        <em className="mdi white-color mdi-check start" />
                        Accept
                    </button>
                )}
                {!!group?.can_accept_or_reject && (
                    <button
                        type="button"
                        className="button button-default button-external button-sm"
                        onClick={onReject}
                        onKeyDown={onReject}
                        disabled={isButtonDisabled}
                        style={{ maxWidth: 100 }}
                    >
                        Reject
                    </button>
                )}
            </TD6Container>
        </td>
    );
};
