import ContentLoader from 'react-content-loader';
import { FactorRequestTransaction } from '../../types/types';

type Props = {
    id?: number;
    status?: FactorRequestTransaction['status'];
    onClick: (id?: number) => void;
};

export const TdRequestStatus = ({ id, status, onClick }: Props) => {
    return (
        <td>
            <button type="button" onClick={() => onClick(id)}>
                {status && !!id ? (
                    <div
                        className={`label ${
                            status === 'Accepted'
                                ? 'label-success'
                                : status === 'Pending'
                                ? 'label-warning-light'
                                : status === 'Rejected'
                                ? 'label-danger'
                                : status === 'Canceled'
                                ? 'label-danger-light'
                                : ''
                        }`}
                        style={{
                            position: 'relative',
                        }}
                    >
                        {status}
                    </div>
                ) : id ? (
                    ''
                ) : (
                    <div
                        style={{
                            width: 60,
                            height: 15,
                        }}
                    >
                        <ContentLoader viewBox="0 0 60 15">
                            <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="60"
                                height="15"
                            />
                        </ContentLoader>
                    </div>
                )}
            </button>
        </td>
    );
};
