import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { GroupHeader } from './GroupHeader';
import { Container } from './styles';
import { EmptyInvitedOrganizations } from './EmptyInvitedOrganizations';
import { GroupMembersTable } from './GroupMembersTable';
import {
    AppHeader,
    BlockTitle,
    ChangeBillingPlanModal,
    CurrenciesFooter,
    GroupPaymentHistoryModal,
    InviteGroupsMembersModal,
} from '../../components';

import {
    fetchCurrentOrganizationStatistics,
    fetchGroup,
    fetchGroupMembers,
} from '../../services/OrganizationsService';
import { getAbilityEditCurrentOrganizationSettings } from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import {
    selectAvailableBillingPlans,
    selectBillingPlanById,
} from '../../state/billingPlans';
import { EditGroupModal } from '../MyGroups/EditGroupModal';
import { selectGroupById } from '../../state/groups';
import { CurrentPlan } from '../BillingDetails/CurrentPlan';
import { CurrentPlanCards } from '../BillingDetails/CurrentPlanCards';
import { TGroup, TGroupMember, TMeta } from '../../types/types';
import { useDelayDebounceQuery } from '../../hooks';

export type SortField = 'company_name' | 'created_at' | 'email' | 'status';

export const MyGroup = () => {
    const { id } = useParams<{ id: string }>();
    const group = useSelector(selectGroupById(Number(id)));
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const history = useHistory();

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        (async () => {
            if (id && currentOrganizationId) {
                setButtonDisabled(true);
                const response = await fetchGroup(id);
                setButtonDisabled(false);
                if (response?.status !== 200) {
                    history.goBack();
                }
            } else {
                history.goBack();
            }
        })();
    }, [currentOrganizationId, history, id]);

    const [editGroupModalId, setEditGroupModalId] = useState<
        number | undefined
    >();

    // const showEditGroupModal = useCallback(() => {
    //     setEditGroupModalId(Number(id));
    // }, [id]);

    const closeEditGroupModal = useCallback(() => {
        setEditGroupModalId(undefined);
    }, []);

    useEffect(() => {
        (async () => {
            await fetchCurrentOrganizationStatistics();
        })();
    }, []);

    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );

    useEffect(() => {
        if (!canChangeOrganizationSettings && !!currentOrganizationId && !!id) {
            history.push(`/`);
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history, id]);

    const currentBillingPlan = useSelector(
        selectBillingPlanById(group?.plan_id),
    );

    const [
        isChangeBillingPlanModalVisible,
        setChangeBillingPlanModalVisible,
    ] = useState(false);

    const openChangeBillingPlanModal = useCallback(() => {
        setChangeBillingPlanModalVisible(true);
    }, []);

    const availableBillingPlans = useSelector(selectAvailableBillingPlans);

    const [
        isInviteGroupsMembersModalVisible,
        setInviteGroupsMembersModalVisible,
    ] = useState(false);

    const openInviteGroupsMembersModal = () => {
        setInviteGroupsMembersModalVisible(true);
    };

    const closeInviteGroupsMembersModal = () => {
        setInviteGroupsMembersModalVisible(false);
    };

    const [groupMembers, setGroupMembers] = useState<TGroupMember[]>([]);
    const [meta, setMeta] = useState<TMeta>({
        current_page: 1,
        from: null,
        last_page: 1,
        links: null,
        path: '',
        per_page: 10,
        to: null,
        total: 0,
    });

    const [isMembersLoading, setMembersLoading] = useState(false);

    const { query, delayQuery, setDelayQuery } = useDelayDebounceQuery();

    const [status, setStatus] = useState<TGroup['status'][]>([]);

    const [sortField, setSortField] = useState<SortField>('created_at');
    const [sortOrder, setSortOrder] = useState<{
        [key: string]: 'ASC' | 'DESC';
    }>({
        company_name: 'DESC',
        created_at: 'DESC',
        email: 'DESC',
        status: 'DESC',
    });

    const setOrderMethod = useCallback(
        (value: SortField) => {
            setSortField(prevState => {
                if (prevState === value) {
                    if (sortOrder[value] === 'ASC') {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'DESC',
                        }));
                    } else {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'ASC',
                        }));
                    }
                }
                return value;
            });
        },
        [sortOrder],
    );

    const fetchGroupMembersMethod = useCallback(
        async (page = 1, update?: boolean) => {
            if (group?.id) {
                setMembersLoading(true);
                const response = await fetchGroupMembers(group?.id, {
                    page,
                    q: query,
                    status: status?.length ? status?.toString() : '',
                    sortField,
                    sortOrder: sortOrder[sortField],
                });

                setMembersLoading(false);
                if (response?.status !== 200) {
                    history.goBack();
                }
                if (response?.data?.data) {
                    if (update) {
                        setGroupMembers(prevState => [
                            ...prevState,
                            ...response?.data?.data,
                        ]);
                    } else {
                        setGroupMembers(response?.data?.data);
                    }
                }
                if (response?.data?.meta) {
                    setMeta(response?.data?.meta);
                }
            }
        },
        [group?.id, history, query, sortField, sortOrder, status],
    );

    useEffect(() => {
        (async () => {
            if (group?.id && currentOrganizationId) {
                await fetchGroupMembersMethod();
            } else {
                history.goBack();
            }
        })();
    }, [currentOrganizationId, fetchGroupMembersMethod, group?.id, history]);

    const onPressNext = useCallback(async () => {
        await fetchGroupMembersMethod(meta.current_page + 1);
    }, [fetchGroupMembersMethod, meta.current_page]);

    const onPressPrev = useCallback(async () => {
        await fetchGroupMembersMethod(meta.current_page - 1);
    }, [fetchGroupMembersMethod, meta.current_page]);

    const onPressMore = useCallback(async () => {
        await fetchGroupMembersMethod(meta.current_page + 1, true);
    }, [fetchGroupMembersMethod, meta.current_page]);

    const onPressFirst = useCallback(async () => {
        await fetchGroupMembersMethod(1);
    }, [fetchGroupMembersMethod]);

    const onPressLast = useCallback(async () => {
        if (meta.last_page) await fetchGroupMembersMethod(meta.last_page);
    }, [fetchGroupMembersMethod, meta.last_page]);

    const onPressPage = useCallback(
        async (page: number) => {
            await fetchGroupMembersMethod(page);
        },
        [fetchGroupMembersMethod],
    );

    const [
        isGroupPaymentHistoryModalVisible,
        setGroupPaymentHistoryModalVisible,
    ] = useState(false);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                isLoading={!!isButtonDisabled}
                title="Group details"
                headerSubtitles={[
                    {
                        subtitle: 'My groups',
                        subtitleLink: `/organization${id}/my-groups`,
                    },
                ]}
                singleButtonText={group?.can_edit ? 'Send invite' : undefined}
                singleButtonMdi="account-multiple-outline"
                onSingleButtonPress={openInviteGroupsMembersModal}
            />

            <div className="app-content">
                <div className="app-body">
                    <Container>
                        <BlockTitle
                            title="Group details"
                            style={{ margin: 0 }}
                        />
                        <GroupHeader
                            id={group?.id}
                            name={group?.name}
                            isEditable={!!group?.can_edit}
                        />
                        <BlockTitle
                            title="Current plan"
                            buttons={
                                group?.can_edit
                                    ? [
                                          {
                                              title: 'Edit billing details',
                                              className:
                                                  'button button-default button-sm',
                                              iconClassName:
                                                  'mdi mdi-pencil start',
                                          },
                                          {
                                              title: 'Upgrade',
                                              className:
                                                  'button button-primary button-sm',
                                              iconClassName:
                                                  'mdi mdi-cog start',
                                              onPress: openChangeBillingPlanModal,
                                          },
                                      ]
                                    : undefined
                            }
                            style={{ margin: 0 }}
                        />
                        <CurrentPlan
                            plan={currentBillingPlan}
                            usageAmount={group?.usage_amount}
                            openChangeBillingPlanModal={
                                group?.can_edit
                                    ? openChangeBillingPlanModal
                                    : undefined
                            }
                        />
                        <CurrentPlanCards
                            currentPlanName={currentBillingPlan?.title}
                            nextPaymentAt={group?.next_payment_at}
                            viewPaymentHistory={group?.view_payment_history}
                            openChangeBillingPlanModal={
                                group?.can_edit
                                    ? openChangeBillingPlanModal
                                    : undefined
                            }
                            openGroupPaymentHistory={() =>
                                setGroupPaymentHistoryModalVisible(true)
                            }
                        />
                        {(!!group?.can_edit || group?.status === 'owner') && (
                            <>
                                <BlockTitle
                                    title={`Members (${meta?.total || 0})`}
                                    style={{ margin: 0 }}
                                />
                                {groupMembers?.length ? (
                                    <GroupMembersTable
                                        planId={group?.plan_id}
                                        members={groupMembers}
                                        meta={meta}
                                        isLoading={isMembersLoading}
                                        sortField={sortField}
                                        sortOrder={sortOrder}
                                        setOrderMethod={setOrderMethod}
                                        delayQuery={delayQuery}
                                        status={status}
                                        onPressPrev={onPressPrev}
                                        onPressNext={onPressNext}
                                        onPressMore={onPressMore}
                                        onPressFirst={onPressFirst}
                                        onPressLast={onPressLast}
                                        onPressPage={onPressPage}
                                        onQueryChange={setDelayQuery}
                                        onStatusChange={setStatus}
                                    />
                                ) : (
                                    <EmptyInvitedOrganizations
                                        onPress={openInviteGroupsMembersModal}
                                    />
                                )}
                            </>
                        )}
                    </Container>
                </div>
            </div>
            <CurrenciesFooter />
            {!!isChangeBillingPlanModalVisible && (
                <ChangeBillingPlanModal
                    isOpen={isChangeBillingPlanModalVisible}
                    currentBillingPlan={currentBillingPlan}
                    availableBillingPlans={availableBillingPlans}
                    onClose={() => {
                        setChangeBillingPlanModalVisible(false);
                    }}
                />
            )}
            {!!editGroupModalId && (
                <EditGroupModal
                    id={editGroupModalId}
                    onRequestClose={closeEditGroupModal}
                />
            )}

            <InviteGroupsMembersModal
                groupId={group?.group_id}
                isModalOpen={isInviteGroupsMembersModalVisible}
                onRequestClose={closeInviteGroupsMembersModal}
            />

            {!!isGroupPaymentHistoryModalVisible && !!group?.id && (
                <GroupPaymentHistoryModal
                    groupId={group?.id}
                    isModalOpen={isGroupPaymentHistoryModalVisible}
                    onRequestClose={() =>
                        setGroupPaymentHistoryModalVisible(false)
                    }
                />
            )}
        </div>
    );
};
