import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api from './axiosConfig';
import {
    countTransactionsAllSet,
    countTransactionsArchivedSet,
    countTransactionsFactoringRequestsSet,
    countTransactionsPayablesSet,
    countTransactionsReceivablesSet,
} from '../state/collectionsCount';
import { store } from '../state/store';
import {
    transactionAdd,
    transactionsSet,
    transactionsUpdate,
    transactionUpdate,
} from '../state/transactions';
import { CreateTransaction } from '../types/types';

export const createTransaction: Promise<AxiosResponse<any>> | any = async (
    transaction: CreateTransaction,
) => {
    const response = await api.post('/transactions', transaction);
    if (response.status === 201 && response?.data?.data) {
        store.dispatch(transactionAdd(response.data.data));
    }
    return response;
};

export const fetchTransactions = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api('/transactions', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 10,
            ...params,
        },
    });

    if (response?.status === 200 && !!response?.data?.data) {
        if (!update) {
            store.dispatch(transactionsSet(response.data?.data));
        } else {
            store.dispatch(transactionsUpdate(response.data?.data));
        }
        if (!params?.type) {
            store.dispatch(countTransactionsAllSet(response.data?.meta?.total));
        } else if (params?.type === 'payable') {
            store.dispatch(
                countTransactionsPayablesSet(response.data?.meta?.total),
            );
        } else if (params?.type === 'receivable') {
            store.dispatch(
                countTransactionsReceivablesSet(response.data?.meta?.total),
            );
        } else if (params?.type === 'factor-request') {
            store.dispatch(
                countTransactionsFactoringRequestsSet(
                    response.data?.meta?.total,
                ),
            );
        } else if (params?.type === 'archived') {
            store.dispatch(
                countTransactionsArchivedSet(response.data?.meta?.total),
            );
        }
    }
    return response;
};

export const fetchTransaction = async (id: number) => {
    const response = await api(`/transactions/${id}`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(transactionUpdate(response.data?.data));
    }
    return response;
};

export const archiveTransaction = async (id: number) => {
    const response = await api.patch(`/transactions/${id}/archive`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(transactionUpdate(response.data?.data));
    }
    return response;
};

export const archiveTransactions = async (ids: string[]) =>
    await api.post(`/transactions/archive-selected`, {
        id: ids,
    });

export const acceptTransaction = async (id: number) => {
    const response = await api.patch(`/transactions/${id}/accept`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(transactionUpdate(response.data?.data));
    }
    return response;
};

export const rejectTransaction = async (id: number) => {
    const response = await api.patch(`/transactions/${id}/reject`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(transactionUpdate(response.data?.data));
    }
    return response;
};

export const factorTransactions = async (ids: string[], id?: number) =>
    await api.post(`/transactions/factoring`, {
        id: ids,
        from_organization_id: id,
    });

export const sendToFactorTransactions = async (ids: string[], id?: number) =>
    await api.post(`/factor-request-transactions`, {
        transaction_id: ids,
        factor_organization_id: id,
    });

export const cancelFactoringRequest = async (id: number) => {
    const response = await api.post(
        `/factor-request-transactions/cancel/${id}`,
    );
    if (
        (response.status === 200 || response.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(transactionUpdate(response.data?.data));
    }
    return response;
};

export const acceptFactoringRequest = async (id: number) => {
    const response = await api.post(
        `/factor-request-transactions/accept/${id}`,
    );
    if (
        (response.status === 200 || response.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(transactionUpdate(response.data?.data));
    }
    return response;
};

export const rejectFactoringRequest = async (id: number) => {
    const response = await api.post(
        `/factor-request-transactions/reject/${id}`,
    );
    if (
        (response.status === 200 || response.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(transactionUpdate(response.data?.data));
    }
    return response;
};
