import React from 'react';
import { useSelector } from 'react-redux';
import briefcaseIcon from '../../assets/img/icons/icon-briefcase.svg';
import chainIcon from '../../assets/img/icons/icon-chain.svg';
import circleIcon from '../../assets/img/icons/icon-circle.svg';
import handshakeIcon from '../../assets/img/icons/icon-handshake.svg';
import { amountOptions } from '../../services/helpers';
import { getStatistics } from '../../state/statistics';
import { getMyUser } from '../../state/user';

export const TotalAmounts = () => {
    const user = useSelector(getMyUser);
    const statistics = useSelector(getStatistics);
    return (
        <div className="block block-overview_cards">
            {(!!statistics?.total_payables ||
                statistics?.total_payables === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={handshakeIcon} alt="handshake icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Total payables
                            </div>
                        </div>
                        <div className="overview_card-value overview_card-value-danger">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.total_payables > 0 ? '-' : ''}
                                {statistics?.total_payables?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}
            {(!!statistics?.total_receivables ||
                statistics?.total_receivables === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={briefcaseIcon} alt="briefcase icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Total receivables
                            </div>
                        </div>
                        <div className="overview_card-value">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.total_receivables?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}
            {(!!statistics?.circular_netted ||
                statistics?.circular_netted === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={circleIcon} alt="circle-icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Circular netted
                            </div>
                        </div>
                        <div className="overview_card-value overview_card-value-success">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.circular_netted?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}
            {(!!statistics?.chain_netted || statistics?.chain_netted === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={chainIcon} alt="chain icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Chain netted
                            </div>
                        </div>
                        <div className="overview_card-value overview_card-value-success">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.chain_netted?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
};
