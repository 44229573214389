import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CurrencyItem } from './CurrencyItem';
import { getCurrencies } from '../../../state/currencies';
import { Currency } from '../../../types/types';
import { fetchCurrencies } from '../../../services/CurrenciesService';

export const CurrenciesFooter = () => {
    const currencies = useSelector(getCurrencies);
    useEffect(() => {
        if (!currencies?.length) {
            (async () => {
                await fetchCurrencies();
            })();
        }
    }, [currencies?.length]);
    const currenciesObject = useMemo(() => {
        const co: { [code: string]: Currency } = {};

        currencies.forEach(currency => (co[currency?.code] = currency));
        return co;
    }, [currencies]);

    return (
        <div className="app-currencies-footer">
            <div className="app-currencies-footer-wrapper">
                <CurrencyItem currency={currenciesObject?.USD} />
                <CurrencyItem currency={currenciesObject?.EUR} />
                <CurrencyItem currency={currenciesObject?.GBP} />
                <CurrencyItem currency={currenciesObject?.AUD} />
                <CurrencyItem currency={currenciesObject?.NZD} />
                <CurrencyItem currency={currenciesObject?.CHF} />
                <CurrencyItem currency={currenciesObject?.JPY} />
                <CurrencyItem currency={currenciesObject?.CNY} last />
            </div>
        </div>
    );
};
