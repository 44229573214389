import styled from 'styled-components';
import { OrganizationRequestConnection } from '../../types/types';

export const LogoWrapper = styled.div`
    width: 36px;
    height: 36px;

    background: #ffffff;

    border: 1px solid #eaecf0;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 12px;
`;

export const Logo = styled.img`
    width: 36px;
    height: 36px;

    border-radius: 6px;
`;

export const Initials = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    color: #f37749;
`;

export const Name = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #080808;
`;

export const Email = styled.div`
    margin-top: 6px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;

    color: #555555;
    align-self: flex-start;
`;

export const Status = styled.div<{
    active?: boolean;
    status?: OrganizationRequestConnection['status'];
}>`
    background: ${({ status, active }) =>
        status === 'Pending'
            ? '#f5f79b'
            : status === 'Rejected'
            ? '#ffa500'
            : active
            ? '#1B9477'
            : '#ffffff'};

    border: 1px solid
        ${({ status, active }) =>
            status === 'Pending'
                ? '#f5f79b'
                : status === 'Rejected'
                ? '#ffa500'
                : active
                ? '#1B9477'
                : '#080808'};

    border-radius: 3px;
    margin: 0px 0px;
    padding: 3px 5px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: ${({ status, active }) =>
        status === 'Rejected' || active ? 'bold' : 'normal'};
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;

    color: ${({ status, active }) =>
        status === 'Rejected' || active ? '#fff' : '#080808'};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

export const TableRow = styled.tr<{ last?: boolean }>`
    position: relative;
    &:hover ${Name} {
        color: #f37749;
    }
    &:hover td {
        background-color: #f2f2f2;
    }
    td {
        background-color: #fff;
        padding-top: 18px;
        padding-bottom: 18px;
        box-shadow: ${({ last }) =>
            last ? 'none' : 'inset 0px -1px 0px #e1e1e1'};
        cell {
            vertical-align: middle;
        }
    }
`;

export const TdValue = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;

    color: #202531;
`;

export const CurrencyIcon = styled.img`
    width: 17px;
    height: 17px;
    margin-right: 5px;
`;

export const Amount = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: -3px;
`;
