type Props = {
    active: boolean;
    title: string;
    down: boolean;
    onPress: () => void;
};

export const TableHeader = ({ active, title, down, onPress }: Props) => {
    return (
        <th className={active ? 'active' : ''}>
            <div
                role="button"
                tabIndex={0}
                className="table-th-order-by"
                onClick={onPress}
                onKeyDown={onPress}
            >
                {title}
                {active && (
                    <em className={`mdi mdi-menu-${down ? 'down' : 'up'}`} />
                )}
            </div>
        </th>
    );
};
