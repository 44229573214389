/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Transition } from 'react-transition-group';
import Loader from 'react-loader-spinner';
import {
    NettedAmount,
    TimeBigDot,
    TimeDot,
    TimeLine,
    TimelineLastText,
    TimelineRow,
    TimelineText,
} from './styles';
import { Success } from './Success';
import {
    AppHeader,
    CircleCard,
    CurrenciesFooter,
    PaymentCard,
    ReceiptCard,
    ReportCard,
} from '../../components';

import { getTransactionById } from '../../state/transactions';
import { amountOptions } from '../../services/helpers';
import {
    ChainShort,
    Circle,
    Payment,
    Receipt,
    Report,
    Timeline,
} from '../../types/types';
import {
    acceptFactoringRequest,
    acceptTransaction,
    archiveTransaction,
    cancelFactoringRequest,
    factorTransactions,
    fetchTransaction,
    rejectFactoringRequest,
    rejectTransaction,
    sendToFactorTransactions,
} from '../../services/TransactionsService';

import {
    fetchConnectedFactoringOrganizations,
    fetchCurrentOrganizationStatistics,
} from '../../services/OrganizationsService';
import { getConnectedFactoringOrganizations } from '../../state/connectedFactoringOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import {
    getAbilityToManageTransactions,
    getFactoringAbility,
    getMyFactoringOrganizations,
} from '../../state/myOrganizations';

const transitionStyles = {
    entering: { opacity: 0.8, transition: 'opacity 1s ease-in-out' },
    entered: { opacity: 1, transition: 'opacity 1s ease-in-out' },
    exiting: { opacity: 0.5, transition: 'opacity 1s ease-in-out' },
    exited: { opacity: 0, transition: 'opacity 1s ease-in-out' },
};

export const Transaction = () => {
    const { id, newOne } = useParams<{
        id: string;
        newOne?: string;
    }>();
    const transaction = useSelector(getTransactionById(Number(id)));
    const myFactoringOrganizations = useSelector(getMyFactoringOrganizations);
    const connectedFactoringOrganizations = useSelector(
        getConnectedFactoringOrganizations,
    );
    const abilityToManageTransactions = useSelector(
        getAbilityToManageTransactions,
    );

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const factoringAbility = useSelector(getFactoringAbility);

    const onCancelFactoringRequest = useCallback(async () => {
        if (transaction?.factor_request_transaction?.id) {
            setLoading(true);
            await cancelFactoringRequest(
                transaction?.factor_request_transaction?.id,
            );
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [transaction?.factor_request_transaction?.id]);

    const onAcceptFactoringRequest = useCallback(async () => {
        if (transaction?.factor_request_transaction?.id) {
            setLoading(true);
            await acceptFactoringRequest(
                transaction?.factor_request_transaction?.id,
            );
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [transaction?.factor_request_transaction?.id]);

    const onRejectFactoringRequest = useCallback(async () => {
        if (transaction?.factor_request_transaction?.id) {
            setLoading(true);
            await rejectFactoringRequest(
                transaction?.factor_request_transaction?.id,
            );
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [transaction?.factor_request_transaction?.id]);

    const onSendToFactorOrganization = useCallback(
        async (factId?: number) => {
            setLoading(true);
            const response = await sendToFactorTransactions(
                [transaction?.id?.toString()],
                factId,
            );
            setLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                setLoading(true);
                await fetchTransaction(Number(id));
                setLoading(false);
                await fetchCurrentOrganizationStatistics();
            }
        },
        [id, transaction?.id],
    );

    // const [isDetailsVisible, setDetailsVisible] = useState(true);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                setLoading(true);
                const transactionResponse = await fetchTransaction(Number(id));
                setLoading(false);
                await fetchConnectedFactoringOrganizations();
                if (transactionResponse.status !== 200) {
                    history.push('/transactions/payables');
                }
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, history, id]);

    // const onClickDetails = useCallback(() => {
    //     setDetailsVisible(prevState => !prevState);
    // }, []);

    const [isLoading, setLoading] = useState(false);

    const accept = useCallback(async () => {
        setLoading(true);
        await acceptTransaction(transaction?.id);
        setLoading(false);
        await fetchCurrentOrganizationStatistics();
    }, [transaction?.id]);
    const reject = useCallback(async () => {
        setLoading(true);
        await rejectTransaction(transaction?.id);
        setLoading(false);
        await fetchCurrentOrganizationStatistics();
    }, [transaction?.id]);
    const archive = useCallback(async () => {
        setLoading(true);
        await archiveTransaction(transaction?.id);
        setLoading(false);
        await fetchCurrentOrganizationStatistics();
    }, [transaction?.id]);

    const openCircle = useCallback(
        (circleId: number) => {
            history.push(`/circles/circle/${circleId}`);
        },
        [history],
    );

    const openChain = useCallback(
        (chainId: number) => {
            history.push(`/chains/chain/${chainId}`);
        },
        [history],
    );

    const openReport = useCallback(
        (reportId: number) => {
            history.push(`/reports/report/${reportId}`);
        },
        [history],
    );

    const openReceipt = useCallback(
        (receiptId: number) => {
            history.push(`/transactions/receipt/${receiptId}`);
        },
        [history],
    );

    const [success, setSuccess] = useState<string | undefined>(newOne);

    useEffect(() => {
        let successTimer: any;
        if (success) {
            successTimer = setTimeout(() => {
                setSuccess(undefined);
            }, 7500);
        }

        return () => {
            if (successTimer) {
                clearTimeout(successTimer);
            }
        };
    }, [success]);

    const transitionRef = useRef(null);

    const onFactoring = useCallback(
        async (factId?: number) => {
            setLoading(true);
            const response = await factorTransactions(
                [transaction?.id?.toString()],
                factId,
            );
            setLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                setLoading(true);
                await fetchTransaction(Number(id));
                setLoading(false);
                await fetchCurrentOrganizationStatistics();
            }
        },
        [id, transaction?.id],
    );

    const currentFactoringOrganizations = useMemo(
        () =>
            myFactoringOrganizations?.filter(
                org => org?.id !== transaction?.title?.id,
            ),
        [myFactoringOrganizations, transaction?.title?.id],
    );

    const currentConnectedFactoringOrganizations = useMemo(
        () =>
            connectedFactoringOrganizations?.filter(
                org => org?.id !== transaction?.title?.id,
            ),
        [connectedFactoringOrganizations, transaction?.title?.id],
    );

    const factoringButton = useMemo(
        () =>
            factoringAbility &&
            transaction?.status === 'open' &&
            transaction?.factor_request_transaction?.status !== 'Pending' &&
            !transaction?.is_payer,
        [
            factoringAbility,
            transaction?.factor_request_transaction?.status,
            transaction?.is_payer,
            transaction?.status,
        ],
    );

    const isFactoringRequestActionsAvailable = useMemo(
        () => abilityToManageTransactions && transaction?.status === 'open',
        [abilityToManageTransactions, transaction?.status],
    );

    const sendToFactorButton = useMemo(
        () =>
            isFactoringRequestActionsAvailable &&
            !transaction?.is_payer &&
            (!transaction?.factor_request_transaction?.factor_organization_id ||
                transaction?.factor_request_transaction?.status ===
                    'Canceled' ||
                transaction?.factor_request_transaction?.status ===
                    'Rejected') &&
            !!currentConnectedFactoringOrganizations?.length,
        [
            currentConnectedFactoringOrganizations?.length,
            isFactoringRequestActionsAvailable,
            transaction?.factor_request_transaction?.factor_organization_id,
            transaction?.factor_request_transaction?.status,
            transaction?.is_payer,
        ],
    );

    const bigDotTextRef = useRef<any>(null);

    const getBigDotTextHeight = useCallback(
        () =>
            typeof bigDotTextRef?.current?.offsetHeight === 'number' &&
            bigDotTextRef?.current?.offsetHeight > 20
                ? bigDotTextRef?.current?.offsetHeight - 20
                : 0,
        [],
    );

    // useEffect(() => {
    //     if (transaction?.can_weavr_pay) {
    //         (async () => {
    //             const response = await checkIfOrganizationIsAuthUser(
    //                 transaction?.title?.id,
    //             );
    //             console.log('checkIfOrganizationIsAuthUser', response);
    //         })();
    //     }
    // }, [transaction?.can_weavr_pay, transaction?.title?.id]);

    return (
        <div className="app-container">
            <AppHeader
                title={`Transaction #${id || ''}`}
                isButtonDisabled={isLoading}
                isLoading={isLoading}
                acceptButton={!!transaction?.can_accept_or_reject && !isLoading}
                rejectButton={!!transaction?.can_accept_or_reject && !isLoading}
                archiveButton={!!transaction?.can_archive && !isLoading}
                downloadButton={!!transaction?.attachment?.url_public}
                downloadPath={transaction?.attachment?.url_public}
                factoringButton={factoringButton}
                factoringOrganizations={currentFactoringOrganizations}
                sendToFactorButton={sendToFactorButton}
                currentConnectedFactoringOrganizations={
                    currentConnectedFactoringOrganizations
                }
                canFactorCancel={!!transaction?.can_factor_cancel}
                canAcceptOrRejectFactorRequest={
                    !!transaction?.can_factor_action
                }
                onCancelFactoringRequest={onCancelFactoringRequest}
                onAccept={accept}
                onReject={reject}
                onArchive={archive}
                onFactoring={onFactoring}
                onSendToFactorOrganization={onSendToFactorOrganization}
                onAcceptFactoringRequest={onAcceptFactoringRequest}
                onRejectFactoringRequest={onRejectFactoringRequest}
            />
            <div className="app-content">
                <div className="app-body">
                    {newOne === 'new' && (
                        <Transition
                            nodeRef={transitionRef}
                            in={!!success}
                            timeout={2000}
                            appear
                            mountOnEnter
                            unmountOnExit
                        >
                            {state => {
                                return (
                                    <div
                                        className="block block-payment"
                                        style={{
                                            ...transitionStyles[state],
                                        }}
                                        ref={transitionRef}
                                    >
                                        <Success
                                            type={transaction?.type}
                                            companyName={
                                                transaction?.title?.user
                                            }
                                        />
                                    </div>
                                );
                            }}
                        </Transition>
                    )}
                    <div
                        className={`block block-payment ${
                            transaction?.is_payer
                                ? 'block-payment-out'
                                : 'block-payment-in'
                        }`}
                    >
                        <div className="payment-overview">
                            {/* <Row>
                                <div className="payment-title">
                                    {`${transaction?.title?.text || ''} `}&nbsp;
                                    <strong>
                                        {transaction?.title?.user || ''}
                                    </strong>
                                </div>
                                {!!transaction?.title2?.text &&
                                    !!transaction?.title2?.user && (
                                        <div className="payment-title">
                                            {`, ${
                                                transaction?.title2?.text || ''
                                            } `}
                                            &nbsp;
                                            <strong>
                                                {transaction?.title2?.user ||
                                                    ''}
                                            </strong>
                                        </div>
                                    )}
                            </Row> */}
                            <div className="payment-value">
                                <div className="payment-currency">
                                    {!!transaction?.currency?.icon?.png && (
                                        <img
                                            src={
                                                transaction?.currency?.icon?.png
                                            }
                                            alt="icon currency"
                                        />
                                    )}
                                </div>
                                <div className="payment-amount">
                                    {transaction?.is_payer &&
                                    !!transaction?.amount_due
                                        ? (-transaction?.amount_due)?.toLocaleString(
                                              'en',
                                              amountOptions,
                                          )
                                        : transaction?.amount_due?.toLocaleString(
                                              'en',
                                              amountOptions,
                                          )}{' '}
                                    <small>
                                        {transaction?.amount &&
                                        transaction?.amount !==
                                            transaction?.amount_due
                                            ? transaction?.is_payer &&
                                              !!transaction?.amount_due
                                                ? (-transaction?.amount)?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : transaction?.amount?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                            : undefined}
                                    </small>
                                </div>
                            </div>
                            <div className="payment-overview-icon">
                                <div
                                    className={`mdi mdi-arrow-${
                                        transaction?.is_payer
                                            ? 'top-right'
                                            : 'bottom-left'
                                    }`}
                                />
                            </div>
                        </div>
                        <div className="payment-details">
                            {/* <div className="payment-details-heading">
                                <div className="payment-details-title">
                                    Transaction details
                                </div>
                                <button
                                    type="button"
                                    style={{
                                        borderWidth: 0,
                                        background: 'transparent',
                                    }}
                                    className="payment-details-toggle"
                                    onClick={onClickDetails}
                                >
                                    Hide details
                                    <em className="mdi mdi-chevron-down" />
                                </button>
                            </div> */}
                            {/* {!!isDetailsVisible && ( */}
                            <div className="payment-details-list">
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        {transaction?.title?.text === 'Send to'
                                            ? 'Seller'
                                            : transaction?.title?.text ===
                                              'Receive from'
                                            ? 'Buyer'
                                            : ''}
                                    </div>
                                    <div className="payment-details-value">
                                        {transaction?.title?.user || ''}
                                    </div>
                                </div>
                                {!!transaction?.title2?.text &&
                                    !!transaction?.title2?.user && (
                                        <div className="payment-details-item">
                                            <div className="payment-details-key">
                                                {transaction?.title2?.text ===
                                                'Send to'
                                                    ? 'Seller'
                                                    : transaction?.title2
                                                          ?.text ===
                                                      'Receive from'
                                                    ? 'Buyer'
                                                    : ''}
                                            </div>
                                            <div className="payment-details-value">
                                                {transaction?.title2?.user ||
                                                    ''}
                                            </div>
                                        </div>
                                    )}
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Sent as
                                    </div>
                                    <div className="payment-details-value">
                                        {transaction?.type_name || ''}
                                    </div>
                                </div>
                                {!!transaction?.created_at && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Created date
                                        </div>
                                        <div className="payment-details-value">
                                            {DateTime.fromSQL(
                                                transaction?.created_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMMM yyyy, HH:mm 'GMT'",
                                                )}
                                        </div>
                                    </div>
                                )}
                                {!!transaction?.accepted_date && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Accepted on
                                        </div>
                                        {transaction?.accepted_date && (
                                            <div className="payment-details-value">
                                                {DateTime.fromSQL(
                                                    transaction?.accepted_date,
                                                )
                                                    .toUTC()
                                                    .toFormat(
                                                        "dd MMMM yyyy, HH:mm 'GMT'",
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {(!!transaction?.netted_amount ||
                                    transaction?.netted_amount === 0) && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Netted amount in Troc Circle
                                        </div>
                                        <div className="payment-details-value">
                                            {`${transaction?.netted_amount?.toLocaleString(
                                                'en',
                                                amountOptions,
                                            )} ${
                                                transaction
                                                    ?.netted_amount_currency
                                                    ?.code
                                            }`}
                                        </div>
                                    </div>
                                )}
                                {(!!transaction?.payment_total ||
                                    transaction?.payment_total === 0) && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Paid externally of Troc Circle
                                        </div>
                                        <div className="payment-details-value">
                                            {`${transaction?.payment_total?.toLocaleString(
                                                'en',
                                                amountOptions,
                                            )} ${transaction?.currency?.code}`}
                                        </div>
                                    </div>
                                )}
                                {!!transaction?.transaction_number && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Transaction number
                                        </div>
                                        <div className="payment-details-value">
                                            {transaction?.transaction_number}
                                        </div>
                                    </div>
                                )}
                                {!!transaction?.description && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Description
                                        </div>
                                        <div className="payment-details-value">
                                            {transaction?.description}
                                        </div>
                                    </div>
                                )}
                                {(!!transaction?.weavr_payment_amount ||
                                    transaction?.weavr_payment_amount ===
                                        0) && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Paid via CaaS
                                        </div>
                                        <div className="payment-details-value">
                                            {`${transaction?.weavr_payment_amount?.toLocaleString(
                                                'en',
                                                amountOptions,
                                            )} ${transaction?.currency?.code}`}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                    {!!transaction?.circle?.length &&
                        transaction?.factor_request_transaction?.status !==
                            'Accepted' && (
                            <div className="block block-users">
                                <div
                                    className="block block-title"
                                    style={{ marginTop: 15 }}
                                >
                                    Circles
                                </div>
                                {transaction?.circle?.map((circle: Circle) => (
                                    <CircleCard
                                        key={circle?.id}
                                        circle={circle}
                                        withoutShadow
                                        openCircle={() =>
                                            openCircle(circle?.id)
                                        }
                                    />
                                ))}
                            </div>
                        )}

                    {!!transaction?.chain?.length && (
                        <div className="block block-users">
                            <div
                                className="block block-title"
                                style={{ marginTop: 15 }}
                            >
                                Chains
                            </div>
                            {transaction?.chain?.map((chain: ChainShort) => (
                                <CircleCard
                                    key={chain?.id}
                                    reportableType="chain"
                                    circle={chain}
                                    withoutShadow
                                    openCircle={() => openChain(chain?.id)}
                                />
                            ))}
                        </div>
                    )}
                    {!!transaction?.report?.length && (
                        <div className="block block-users">
                            <div
                                className="block block-title"
                                style={{ marginTop: 15 }}
                            >
                                Reports
                            </div>
                            {transaction?.report?.map((report: Report) => (
                                <ReportCard
                                    key={report?.id.toString()}
                                    report={report}
                                    withoutShadow
                                    openReport={() => openReport(report?.id)}
                                />
                            ))}
                        </div>
                    )}

                    {!!transaction?.receipts?.length && (
                        <div className="block block-users">
                            <div
                                className="block block-title"
                                style={{ marginTop: 15 }}
                            >
                                Receipts
                            </div>

                            {transaction?.receipts?.map((receipt: Receipt) => (
                                <ReceiptCard
                                    key={receipt?.id}
                                    receipt={receipt}
                                    withoutShadow
                                    openReceipt={() => openReceipt(receipt?.id)}
                                />
                            ))}
                        </div>
                    )}

                    {/* <PaymentCard
                        payment={
                            {
                                source: 'Oddo',
                                created_at: '2012-12-23',
                                amount: 12333.333,
                                currency: {
                                    icon: {
                                        png:
                                            'https://api.dev.troccircle.com/assets/images/currencies/png/eur.png',
                                        svg:
                                            'https://api.dev.troccircle.com/assets/images/currencies/svg/eur.svg',
                                    },
                                },
                            } as Payment
                        }
                    /> */}

                    {transaction?.payments?.map((payment: Payment) => (
                        <PaymentCard key={payment?.id} payment={payment} />
                    ))}
                </div>
                <div className="app-aside app-aside-wide">
                    <div dir="ltr">
                        {transaction?.timelines ? (
                            transaction?.timelines.map(
                                (timeline: Timeline, index) =>
                                    transaction?.timelines?.length &&
                                    transaction?.timelines?.length > 1 &&
                                    index ===
                                        transaction?.timelines?.length - 1 ? (
                                        <NettedAmount key={timeline?.id}>
                                            <TimelineRow>
                                                <TimelineText right>
                                                    {DateTime.fromSQL(
                                                        timeline?.created_at,
                                                    )
                                                        .toUTC()
                                                        .toFormat('dd MMM')}
                                                </TimelineText>
                                                <TimeBigDot />
                                                <TimelineLastText
                                                    ref={bigDotTextRef}
                                                >
                                                    {timeline?.text}
                                                </TimelineLastText>
                                            </TimelineRow>
                                        </NettedAmount>
                                    ) : (
                                        <TimelineRow
                                            key={timeline?.id}
                                            style={{
                                                flexDirection: 'row-reverse',
                                            }}
                                        >
                                            <TimelineText right>
                                                {DateTime.fromSQL(
                                                    timeline?.created_at,
                                                )
                                                    .toUTC()
                                                    .toFormat('dd MMM')}
                                            </TimelineText>
                                            <TimeDot>
                                                {transaction?.timelines
                                                    ?.length &&
                                                    index <
                                                        transaction?.timelines
                                                            ?.length -
                                                            1 && (
                                                        <TimeLine
                                                            extraHeight={getBigDotTextHeight()}
                                                            last={
                                                                index ===
                                                                transaction
                                                                    ?.timelines
                                                                    ?.length -
                                                                    2
                                                            }
                                                        />
                                                    )}
                                            </TimeDot>
                                            <TimelineText>
                                                {timeline?.text}
                                            </TimelineText>
                                        </TimelineRow>
                                    ),
                            )
                        ) : isLoading ? (
                            <Loader
                                type="Audio"
                                color="#f37749"
                                visible
                                height={20}
                                width={50}
                            />
                        ) : null}
                    </div>
                </div>
            </div>

            <CurrenciesFooter />
        </div>
    );
};
