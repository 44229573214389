import iconCircle from '../../../assets/img/pane-icons/icon-circle.svg';
import iconChain from '../../../assets/img/pane-icons/icon-chain.svg';
import { ChainShort, Circle, Report, Reportable } from '../../../types/types';

type Props = {
    reportableType?: Report['reportable_type'];
    circle: Circle | Reportable | ChainShort;
    withoutShadow?: boolean;
    openCircle: () => void;
};

export const CircleCard = ({
    reportableType,
    circle,
    withoutShadow,
    openCircle,
}: Props) => {
    return (
        <div
            role="button"
            tabIndex={0}
            className={`block block-circle-pane ${
                withoutShadow && 'without-shadow'
            }`}
            onClick={openCircle}
            onKeyDown={openCircle}
        >
            <div className="circle-media">
                <img
                    src={reportableType === 'chain' ? iconChain : iconCircle}
                    alt=""
                />
            </div>
            <div className="circle-details">
                <div className="circle-title">
                    <div className="flex flex-grow">
                        {reportableType !== 'chain' ? 'Circle' : 'Chain'}&nbsp;
                        <strong>{circle?.name || ''}</strong>
                    </div>
                    {!!circle?.status && (
                        <div
                            className={`label ${
                                circle?.status === 'completed'
                                    ? 'label-success-light'
                                    : circle?.status === 'cancelled' ||
                                      circle?.status === 'waiting for payment'
                                    ? 'label-warning-light'
                                    : 'label-default'
                            } label-md`}
                        >
                            {circle?.status}
                        </div>
                    )}
                </div>
                <div className="circle-members">
                    <em className="mdi mdi-account-outline" />
                    <div className="circle-members-label">
                        {' '}
                        {`${circle?.users_count || ''} ${
                            circle?.users_count === 1 ? 'member' : 'members'
                        }`}
                    </div>
                </div>
            </div>
        </div>
    );
};
