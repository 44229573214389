import styled from 'styled-components';

export const Container = styled.div<{ marginTop?: number }>`
    margin-top: ${({ marginTop }) => (marginTop ? marginTop - 35 / 2 : 0)}px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    align-items: center;
    justify-content: center;
    align-self: center;
    z-index: 9999;
`;

export const RelativeContainer = styled.div`
    padding: 20px;

    display: flex;
    flex: 1;
    width: 100%;
    background: yellow;
    align-items: center;
    justify-content: center;
    align-self: center;
`;
