import { Currency } from '../../../types/types';

type Props = {
    currency?: Currency;
    last?: boolean;
};

export const CurrencyItem = ({ currency, last }: Props) => {
    return currency ? (
        <>
            <div className="currency-item">
                <div className="currency-item-label">{currency?.code}</div>
                <div className="currency-item-label value">
                    {currency?.code === 'USD'
                        ? '1.000'
                        : currency?.exchange_rate}
                </div>
                {!!currency?.prev_rate && currency?.prev_rate !== '0' && (
                    <em
                        className={`mdi mdi-menu-${
                            Number(currency.prev_rate) > 0 ? 'up' : 'down'
                        } ${
                            Number(currency.prev_rate) > 0
                                ? 'increase'
                                : 'decrease'
                        }`}
                    />
                )}
            </div>
            {!last && <div className="dot" />}
        </>
    ) : null;
};
