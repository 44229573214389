import { DateTime } from 'luxon';
import React from 'react';
import iconReceipt from '../../../assets/img/pane-icons/icon-receipt.svg';
import { Receipt } from '../../../types/types';
import { getAmountDecimal, getAmountInteger } from '../../../services/helpers';

type Props = {
    receipt: Receipt;
    withoutShadow?: boolean;
    style?: React.CSSProperties;
    openReceipt?: () => void;
};

export const ReceiptCard = ({
    receipt,
    withoutShadow,
    style,
    openReceipt,
}: Props) => {
    return (
        <div
            role="button"
            tabIndex={0}
            className={`block block-receipt-pane ${
                withoutShadow && 'without-shadow'
            }`}
            onClick={openReceipt}
            onKeyDown={openReceipt}
            style={style}
        >
            <div className="receipt-overview">
                <div className="receipt-media">
                    <img src={iconReceipt} alt="icon receipt" />
                </div>
                <div className="receipt-info">
                    <div className="receipt-title">Receipt</div>
                    <div className="receipt-info-list">
                        <div className="receipt-info-item">
                            <em className="mdi mdi-clock-outline" />
                            {receipt?.created_at
                                ? DateTime.fromSQL(receipt?.created_at)
                                      .toUTC()
                                      .toFormat("dd MMMM yyyy, HH:mm 'GMT'")
                                : ''}
                        </div>
                        <div className="receipt-info-item">
                            <em className="mdi primary mdi-receipt" />
                            {receipt?.user?.company_name || ''}
                        </div>
                    </div>
                </div>
                <div className="receipt-value">
                    <div className="receipt-amount">
                        {!!receipt?.currency?.icon?.png && (
                            <img
                                src={receipt?.currency?.icon?.png}
                                alt="icon currency"
                            />
                        )}
                        {receipt?.netted_amount || receipt?.netted_amount === 0
                            ? getAmountInteger(receipt?.netted_amount)
                            : ''}
                        <sup>
                            {receipt?.netted_amount ||
                            receipt?.netted_amount === 0
                                ? getAmountDecimal(receipt?.netted_amount)
                                : ''}
                        </sup>
                    </div>
                    <div className="receipt-label">Netted amount</div>
                </div>
            </div>
            <div className="receipt-details">
                <div className="receipt-value">
                    <div className="receipt-amount">
                        {!!receipt?.currency?.icon?.png && (
                            <img
                                src={receipt?.currency?.icon?.png}
                                alt="icon currency"
                            />
                        )}
                        {receipt?.entry_amount || receipt?.entry_amount === 0
                            ? getAmountInteger(receipt?.entry_amount)
                            : ''}
                        <sup>
                            {receipt?.entry_amount ||
                            receipt?.entry_amount === 0
                                ? getAmountDecimal(receipt?.entry_amount)
                                : ''}
                        </sup>
                    </div>
                    <div className="receipt-label">Entry amount</div>
                    <div className="receipt-arrow">
                        <div className="mdi mdi-arrow-right" />
                    </div>
                </div>
                <div className="receipt-value">
                    <div className="receipt-amount">
                        {!!receipt?.currency?.icon?.png && (
                            <img
                                src={receipt?.currency?.icon?.png}
                                alt="icon currency"
                            />
                        )}
                        {receipt?.amount_due || receipt?.amount_due === 0
                            ? getAmountInteger(receipt?.amount_due)
                            : ''}
                        <sup>
                            {receipt?.amount_due || receipt?.amount_due === 0
                                ? getAmountDecimal(receipt?.amount_due)
                                : ''}
                        </sup>
                    </div>
                    <div className="receipt-label">Exit amount</div>
                </div>
            </div>
        </div>
    );
};
