import { useState } from 'react';
import {
    SuccessColumn,
    SuccessIcon,
    SuccessRow,
    SuccessSubtitle,
    SuccessTitle,
} from './styles';
import successIcon from '../../assets/img/icons/success.svg';

type Props = {
    type: 'invoice' | 'ptp';
    companyName: string;
};

export const Success = ({ type, companyName }: Props) => {
    const [stateType] = useState(type);
    const [stateCompanyName] = useState(companyName);
    return (
        <SuccessRow>
            <SuccessIcon src={successIcon} alt="success" />
            <SuccessColumn>
                <SuccessTitle>{`${
                    stateType === 'invoice' ? 'Invoice' : 'Promise to pay'
                } sent successfully`}</SuccessTitle>
                <SuccessSubtitle>
                    {`We sent your ${
                        stateType === 'invoice' ? 'invoice' : 'promise to pay'
                    } to ${stateCompanyName}. The ${
                        stateType === 'invoice'
                            ? 'recipient will need to approve the invoice before it is'
                            : 'PTP is directly'
                    } available for netting.`}
                </SuccessSubtitle>
            </SuccessColumn>
        </SuccessRow>
    );
};
