import * as countriesList from 'countries-list';
import { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { Content } from './styles';
import { SelectComponent } from '..';
import {
    CloseButton,
    CloseButtonText,
    Header,
    HeaderSubtitle,
    HeaderTitle,
} from '../../../screens/OrganizationMembers/styles';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type Props = {
    countryCode?: string;
    isCountrySelectorOpen: boolean;
    updatePhonePrefix: (countryCode: string, phonePrefix: string) => void;
    onCloseCountrySelector: () => void;
};

export const CountryPhoneSelector = ({
    countryCode,
    isCountrySelectorOpen,
    updatePhonePrefix,
    onCloseCountrySelector,
}: Props) => {
    const onClose = useCallback(() => {
        onCloseCountrySelector();
    }, [onCloseCountrySelector]);

    const value = useMemo(() => {
        if (countryCode) {
            const country = countriesList?.countries[countryCode];
            return {
                value: countryCode?.toUpperCase(),
                label: `${country?.name}${
                    !!country?.native && country?.native !== country?.name
                        ? ` (${country?.native})`
                        : ''
                } +${country?.phone}`,
            };
        }
        return undefined;
    }, [countryCode]);

    const options = useMemo(
        () =>
            Object.entries(countriesList?.countries).map(item => ({
                value: item[0]?.toUpperCase(),
                label: `${item[1]?.name}${
                    !!item[1]?.native && item[1]?.native !== item[1]?.name
                        ? ` (${item[1]?.native})`
                        : ''
                } +${item[1]?.phone}`,
            })),
        [],
    );
    const onCurrentOptionChange = useCallback(
        (item: { value: string; label: string }) => {
            updatePhonePrefix(
                item?.value?.toLocaleUpperCase(),
                countriesList?.countries[item?.value?.toLocaleUpperCase()]
                    ?.phone,
            );
            if (item?.value) {
                setTimeout(() => {
                    onClose();
                }, 400);
            }
        },
        [onClose, updatePhonePrefix],
    );

    return (
        <Modal
            isOpen={isCountrySelectorOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Select country"
        >
            <Header>
                <div>
                    <HeaderTitle>Select country</HeaderTitle>
                    <HeaderSubtitle>
                        Select country to get phone prefix.
                    </HeaderSubtitle>
                </div>
                <CloseButton onClick={onClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>
            <Content>
                <div className="form form-control-wrapper">
                    <div className="form-group">
                        <SelectComponent
                            isClearable
                            placeholder="select country..."
                            menuIsOpen
                            value={value}
                            onChange={onCurrentOptionChange}
                            options={options}
                        />
                    </div>
                </div>
            </Content>
        </Modal>
    );
};
