import { PlanName } from './styles';

type Props = {
    planName: string;
    onClick?: () => void;
};

export const TdPlanName = ({ planName, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PlanName>{planName}</PlanName>
            </button>
        </td>
    );
};
