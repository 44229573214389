/* eslint-disable jsx-a11y/anchor-is-valid */
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { BlankNotificationsText, Triangle } from './styles';
import transactionIcon from '../../assets/img/notifications/transaction.svg';
import reportIcon from '../../assets/img/notifications/report.svg';
import receiptIcon from '../../assets/img/notifications/receipt.svg';
import circleIcon from '../../assets/img/notifications/circle.svg';
import chainIcon from '../../assets/img/notifications/chain.svg';
import { desc } from '../../services/helpers';
import { fetchNotifications } from '../../services/NotificationsService';
import { getNotifications } from '../../state/notifications';
import { INotification, NotificationsState } from '../../types/types';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import {
    getMyCurrentOrganizationId,
    getSumNotifications,
} from '../../state/statistics';

const customStyles = {
    overlay: {
        backgroundColor: 'transparent',
        zIndex: 1000,
        padding: 0,
    },
    content: {
        left: 'auto',
        right: '20px',
        // bottom: '70px',
        bottom: 'auto',
        top: '70px',
        padding: 0,
        overflow: 'none',
        border: 0,
        boxShadow: '1px 2px 25px 2px rgba(0,0,0,.15)',
        borderRadius: 5,
        // color: 'transparent',
        font: '700 18px/25px "Azo Sans", "Roboto", arial, sans-serif',
    },
};

type Props = {
    isOpen: boolean;
    triangleRight?: number;
    closeNotificationsPopup: () => void;
};

const getDateStringSmall = (date: string) => {
    return DateTime.fromSQL(date).toUTC().toFormat('dd MMMM yyyy');
};

const sort = (notifications: NotificationsState): INotification[] => {
    const notificationsArray: INotification[] = Object.values(notifications);
    notificationsArray.sort((a, b) =>
        desc(
            DateTime.fromSQL(a.created_at).valueOf(),
            DateTime.fromSQL(b.created_at).valueOf(),
        ),
    );
    return notificationsArray;
};

export const Notifications = ({
    isOpen,
    triangleRight,
    closeNotificationsPopup,
}: Props) => {
    const newNotificationsCount = useSelector(getSumNotifications);
    const notificationsState = useSelector(getNotifications);
    const notifications = useMemo(() => sort(notificationsState), [
        notificationsState,
    ]);

    const [meta, setMeta] = useState({
        current_page: 0,
        last_page: 1,
    });

    const [isLoading, setLoading] = useState(false);

    const onClose = useCallback(() => {
        setMeta({
            current_page: 0,
            last_page: 1,
        });
        closeNotificationsPopup();
    }, [closeNotificationsPopup]);

    const fetchUserNotifications = useCallback(
        async (currentPage = 0, lastPage = 1) => {
            const page = currentPage + 1;
            if (currentPage < lastPage) {
                setLoading(true);
                const response = await fetchNotifications({ page });
                setLoading(false);
                if (response?.data?.meta) {
                    setMeta(response?.data?.meta);
                }
            }
        },
        [],
    );

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        if (currentOrganizationId && !!isOpen) {
            (async () => {
                setMeta({
                    current_page: 0,
                    last_page: 1,
                });
                await fetchUserNotifications();
                await fetchCurrentOrganizationStatistics();
            })();
        }
    }, [currentOrganizationId, fetchUserNotifications, isOpen]);

    const loadMoreNotifications = useCallback(async () => {
        await fetchUserNotifications(meta.current_page, meta.last_page);
    }, [fetchUserNotifications, meta.current_page, meta.last_page]);

    const handleScroll = useCallback(
        async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            const bottom =
                e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 20 <
                e.currentTarget.clientHeight;

            if (bottom && !isLoading) {
                await loadMoreNotifications();
            }
        },
        [isLoading, loadMoreNotifications],
    );

    const history = useHistory();

    const onPress = useCallback(
        async (notification: INotification) => {
            if (notification?.notificatable_type === 'transaction') {
                history.push(
                    `/transactions/transaction/${notification?.notificatable_id}`,
                );
            } else if (notification?.notificatable_type === 'report') {
                history.push(
                    `/reports/report/${notification?.notificatable_id}`,
                );
            } else if (notification?.notificatable_type === 'circle') {
                history.push(
                    `/circles/circle/${notification?.notificatable_id}`,
                );
            } else if (notification?.notificatable_type === 'chain') {
                history.push(`/chains/chain/${notification?.notificatable_id}`);
            } else if (
                notification?.notificatable_type === 'organization' &&
                !!notification?.from_organization_id
            ) {
                history.push(
                    `/auth-user-set-password/${currentOrganizationId}/${notification?.from_organization_id}/${notification?.authorised_access_weavr_id}`,
                );
            }
            onClose();
        },
        [currentOrganizationId, history, onClose],
    );

    return (
        <Modal
            isOpen={isOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Notifications"
        >
            <Triangle right={triangleRight} />

            <div
                className="block block-notifications-popup"
                style={{ margin: 0 }}
            >
                <div className="notifications-header">
                    <div className="notifications-header-title">
                        {newNotificationsCount
                            ? `${newNotificationsCount} new ${
                                  newNotificationsCount === 1
                                      ? 'notification'
                                      : 'notifications'
                              }`
                            : 'Notifications'}
                    </div>
                    <button
                        type="button"
                        className="notifications-header-link"
                        style={{
                            borderWidth: 0,
                            background: 'transparent',
                        }}
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
                <div className="notifications-body" onScroll={handleScroll}>
                    {notifications?.length ? (
                        notifications?.map(notification => (
                            <div
                                key={notification?.id?.toString()}
                                role="button"
                                tabIndex={0}
                                className="notification-item"
                                onClick={() => onPress(notification)}
                                onKeyDown={() => onPress(notification)}
                            >
                                <div className="notification-item-details">
                                    <div
                                        className={`notification-item-title ${
                                            !notification?.was_read
                                                ? 'bold'
                                                : ''
                                        }`}
                                    >
                                        {notification?.description}
                                    </div>
                                    <div className="notification-item-date">
                                        {' '}
                                        <em className="mdi mdi-clock-outline" />
                                        {getDateStringSmall(
                                            notification?.created_at,
                                        )}
                                    </div>
                                </div>
                                <div className="notification-item-media">
                                    <img
                                        src={
                                            notification?.notificatable_type ===
                                            'transaction'
                                                ? transactionIcon
                                                : notification?.notificatable_type ===
                                                  'report'
                                                ? reportIcon
                                                : notification?.notificatable_type ===
                                                  'circle'
                                                ? circleIcon
                                                : notification?.notificatable_type ===
                                                  'chain'
                                                ? chainIcon
                                                : receiptIcon
                                        }
                                        alt="notification icon"
                                    />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div
                            className="notification-item-details"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <BlankNotificationsText>
                                No notifications
                            </BlankNotificationsText>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
