import React from 'react';

type Props = {
    errors?: string[];
    style?: React.CSSProperties | undefined;
};

export const FormError = ({ errors, style }: Props) => {
    return (
        <div style={style}>
            {Array.isArray(errors) &&
                errors.map((error, index) => {
                    return (
                        <div className="form-error" key={index.toString()}>
                            {error}
                        </div>
                    );
                })}
        </div>
    );
};
