import React from 'react';

type Props = {
    disabled?: boolean;
    inviteForm?: boolean;
    invitationDisabled?: boolean;
    chainPaymentForm?: boolean;
    submitText?: string;
    onSubmit?: () => void;
    onCancel?: () => void;
};

export const FooterButtons = ({
    disabled,
    inviteForm,
    invitationDisabled,
    chainPaymentForm,
    submitText,
    onSubmit,
    onCancel,
}: Props) => {
    return (
        <div className="form-actions">
            <div className="button-group">
                {!inviteForm && (
                    <button
                        type="button"
                        className="button button-primary button-sm"
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        <em className="mdi mdi-check start" />
                        {submitText || (chainPaymentForm ? 'Submit' : 'Create')}
                    </button>
                )}
                <button
                    type="button"
                    className="button button-default button-primary button-sm"
                    onClick={onCancel}
                >
                    <em className="mdi mdi-close start" />
                    Cancel
                </button>
                {inviteForm && (
                    <button
                        type="button"
                        disabled={invitationDisabled}
                        className="button button-primary button-sm"
                        onClick={onSubmit}
                    >
                        <em className="mdi mdi-send-outline start" />
                        Send invitation
                    </button>
                )}
            </div>
        </div>
    );
};
