import { useSelector } from 'react-redux';
import { Plan } from './styles';
import { TBillingPlan } from '../../types/types';
import { selectBillingPlanById } from '../../state/billingPlans';

type Props = {
    planId?: TBillingPlan['id'];
    onClick?: () => void;
};

export const Td3 = ({ planId, onClick }: Props) => {
    const plan = useSelector(selectBillingPlanById(planId));
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                }}
            >
                {!!plan?.id && <Plan planId={plan?.id}>{plan?.title}</Plan>}
            </button>
        </td>
    );
};
