import styled from 'styled-components';

export const NettedAmount = styled.div`
    margin-top: 10px;
`;
export const TimelineRow = styled.div`
    flex-direction: row;
    align-items: center;
    min-height: 33px;
    max-width: 900px;
    position: relative;
`;

export const TimelineText = styled.div<{ right?: boolean }>`
    display: inline-block;
    min-width: 55px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* display: flex; */
    align-items: center;
    text-align: ${({ right }) => (right ? 'right' : 'left')};

    color: #464646;
    /* max-width: 70%; */
`;

export const TimeDot = styled.div`
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    width: 6px;
    height: 6px;

    background: #f37749;
    border-radius: 6px;
    align-items: center;
`;

export const TimeLine = styled.div<{ last?: boolean; extraHeight?: number }>`
    margin-left: 2px;
    width: 2px;
    height: ${({ last, extraHeight = 0 }) => (last ? 44 + extraHeight : 33)}px;

    background: #f37749;
`;

export const TimeBigDot = styled.div`
    display: inline-block;
    margin-horizontal: 17px;
    margin-left: 17px;
    margin-right: 17px;
    width: 12px;
    height: 12px;

    background: #f37749;
    border-radius: 20px;
    align-items: center;
`;

export const TimelineLastText = styled.div`
    display: inline-block;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    align-items: center;

    color: #f37749;
    max-width: 70%;
`;

export const SuccessIcon = styled.img`
    width: 72px;
    height: 72px;
`;

export const SuccessRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
`;

export const SuccessColumn = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: column;
`;

export const SuccessTitle = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;

    display: flex;
    align-items: center;

    color: #000000;
`;

export const SuccessSubtitle = styled.div`
    margin-top: 5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    color: #464646;
`;
