import styled from 'styled-components';

export const WithoutBlock = styled.div`
    padding: 15px 0px;
`;

export const CountResult = styled.div<{ withoutMargin?: boolean }>`
    margin-left: ${({ withoutMargin }) => (withoutMargin ? 0 : 15)}px;
`;

export const PaginationButtonsEmpty = styled.div`
    min-width: 120px;
`;
