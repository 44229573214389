import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import iconCheck from '../../assets/img/icons/icon-check.svg';
import iconMerchant from '../../assets/img/icons/icon-merchant.svg';
import iconPending from '../../assets/img/icons/icon-pending.svg';
import iconRejected from '../../assets/img/icons/icon-rejected.svg';
import iconMerchantPane from '../../assets/img/pane-icons/icon-merchant.svg';
import { getAbilityEditCurrentOrganizationSettings } from '../../state/myOrganizations';
import { Merchant } from '../../types/types';

type Props = {
    merchant?: Merchant;
    companyName?: string;
    createNewMerchant?: () => void;
};

export const MerchantCard = ({
    merchant,
    companyName,
    createNewMerchant,
}: Props) => {
    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );

    const merchantActivatedDateTime = useMemo(
        () =>
            merchant?.activated_at
                ? DateTime.fromSQL(merchant?.activated_at).toUTC()
                : merchant?.created_at
                ? DateTime.fromSQL(merchant?.created_at).toUTC()
                : undefined,
        [merchant?.activated_at, merchant?.created_at],
    );

    const isCanToSubmitNewRequest = useMemo(
        () =>
            merchantActivatedDateTime
                ? DateTime.now()
                      .toUTC()
                      .diff(merchantActivatedDateTime, 'hours').hours > 5
                : false,
        [merchantActivatedDateTime],
    );

    const merchantActivatedDate = useMemo(
        () => merchantActivatedDateTime?.toFormat('dd.MM.yyyy'),
        [merchantActivatedDateTime],
    );

    return (
        <>
            {!merchant && (
                <div className="block block-merchant">
                    <div className="merchant-create">
                        <div className="merchant-create-icon">
                            <img alt="" src={iconMerchant} />
                        </div>
                        <div className="merchant-create-title">
                            Register as a merchant
                        </div>
                        {canChangeOrganizationSettings && (
                            <button
                                type="button"
                                className="button button-primary"
                                onClick={createNewMerchant}
                            >
                                Register now
                            </button>
                        )}
                    </div>
                </div>
            )}
            {merchant?.status === 'APPROVED' && (
                <div className="block block-merchant">
                    <div className="merchant-overview">
                        <div className="merchant-icon">
                            <img alt="" src={iconMerchantPane} />
                        </div>
                        <div className="merchant-details">
                            <div className="merchant-title">
                                {companyName}{' '}
                                <div className="label label-success-light">
                                    Approved
                                </div>
                            </div>
                            <div className="merchant-subtitle">
                                {merchantActivatedDate
                                    ? `Active as a merchant since ${merchantActivatedDate}`
                                    : 'Active as a merchant'}
                            </div>
                        </div>
                        {/* {canChangeOrganizationSettings && (
                            <div
                                role="button"
                                tabIndex={0}
                                className="button button-primary"
                                onClick={onMerchantEdit}
                                onKeyDown={onMerchantEdit}
                            >
                                <div className="mdi mdi-pen start" />
                                Edit
                            </div>
                        )} */}
                    </div>
                    <div className="merchant-note">
                        <img
                            alt=""
                            className="merchant-note-icon"
                            src={iconCheck}
                        />
                        <div className="merchant-note-value">
                            {merchant?.modified_at
                                ? `Your application as a Merchant had been resubmitted on ${DateTime.fromSQL(
                                      merchant?.modified_at,
                                  )
                                      .toUTC()
                                      .toFormat('dd.MM.yyyy hh:ss')}`
                                : `Your application as a
                    Merchant had been
                    approved.`}
                        </div>
                    </div>
                </div>
            )}
            {(merchant?.status === 'PENDING' ||
                merchant?.status === 'PROVISIONING') && (
                <div className="block block-merchant">
                    <div className="merchant-overview">
                        <div className="merchant-icon">
                            <img alt="" src={iconMerchantPane} />
                        </div>
                        <div className="merchant-details">
                            <div className="merchant-title">
                                {companyName}{' '}
                                <div className="label label-warning-light">
                                    Pending
                                </div>
                            </div>
                            <div className="merchant-subtitle">
                                Application submitted on{' '}
                                {DateTime.fromSQL(merchant?.updated_at)
                                    .toUTC()
                                    .toFormat('dd.MM.yyyy')}
                            </div>
                        </div>
                        {isCanToSubmitNewRequest && (
                            <button
                                type="button"
                                className="button button-primary"
                                onClick={createNewMerchant}
                            >
                                Submit new request
                            </button>
                        )}
                    </div>
                    <div className="merchant-note">
                        <img
                            alt=""
                            className="merchant-note-icon"
                            src={iconPending}
                        />
                        <div className="merchant-note-value">
                            Your application is submitted. Approving can take up
                            to 48 hours
                        </div>
                    </div>
                </div>
            )}
            {merchant?.status === 'REJECTED' && (
                <div className="block block-merchant">
                    <div className="merchant-overview">
                        <div className="merchant-icon">
                            <img alt="" src={iconMerchantPane} />
                        </div>
                        <div className="merchant-details">
                            <div className="merchant-title">
                                {companyName}{' '}
                                <div className="label label-danger-light">
                                    Rejected
                                </div>
                            </div>
                            <div className="merchant-subtitle">
                                Rejected on{' '}
                                {DateTime.fromSQL(merchant?.updated_at)
                                    .toUTC()
                                    .toFormat('dd.MM.yyyy')}
                            </div>
                        </div>
                        <button
                            type="button"
                            className="button button-primary"
                            onClick={createNewMerchant}
                        >
                            Submit new request
                        </button>
                    </div>
                    <div className="merchant-note">
                        <img
                            alt=""
                            className="merchant-note-icon"
                            src={iconRejected}
                        />
                        <div className="merchant-note-value">
                            Your application is rejected.
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
