import styled from 'styled-components';
import { OrganizationRequestConnection } from '../../types/types';

export const LogoWrapper = styled.div`
    width: 70px;
    height: 70px;

    background: #ffffff;

    border: 1px solid #eaecf0;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 17px;
`;

export const Logo = styled.img`
    width: 70px;
    height: 70px;

    border-radius: 6px;
`;

export const Initials = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;

    color: #f37749;
`;
export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Name = styled.div<{ marginTop?: number }>`
    margin-top: ${({ marginTop = 0 }) => marginTop}px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #000000;
`;

export const Status = styled.div<{
    active?: boolean;
    status?: OrganizationRequestConnection['status'];
}>`
    background: ${({ status, active }) =>
        status === 'Pending'
            ? '#f5f79b'
            : status === 'Rejected'
            ? '#ffa500'
            : active
            ? '#1B9477'
            : '#f4f4f1'};
    border-radius: 3px;

    margin-top: 8px;

    padding: 3px 5px;

    font-family: Azo Sans;
    font-weight: ${({ status, active }) =>
        status === 'Rejected' || active ? 'bold' : 'normal'};
    font-size: 12px;
    line-height: 12px;

    align-items: center;

    color: ${({ status, active }) =>
        status === 'Rejected' || active ? '#fff' : '#080808'};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

export const TotalIcon = styled.img`
    width: 16px;
    height: 16px;
`;

export const TotalHeader = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    color: #202531;

    margin: 0px 6px;
`;

export const TotalValue = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;

    /* identical to box height */
    display: flex;
    align-items: center;

    color: #202531;

    margin-bottom: -4px;
`;

export const DefaultCurrencyIcon = styled.img`
    margin-right: 8px;
    width: 22px;
    height: 22px;
`;

export const SignatureWrapper = styled.div`
    position: relative;
    border: 1px solid #eaecf0;
    border-radius: 6px;
    cursor: grabbing;
`;

export const ClearButtonContainer = styled.div`
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    border-radius: 10px;
    background: black;
`;
