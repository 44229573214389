type Props = {
    name?: string;
    logo?: string;
    active?: boolean;
    connected?: boolean;
    pending?: boolean;
    openConnection?: () => void;
};

export const ExternalConnectionCard = ({
    name,
    logo,
    active,
    connected,
    pending,

    openConnection,
}: Props) => {
    return (
        <div
            role="button"
            tabIndex={0}
            onClick={openConnection}
            onKeyDown={openConnection}
            className={`external-connection  ${
                !!active && 'external-connection-active'
            }`}
        >
            <div className="external-connection-container">
                {!!connected && (
                    <div className="external-connection-check">
                        <div className="mdi mdi-check" />
                    </div>
                )}
                {!!pending && <div className="external-connection-pending" />}
                {!!name && !!logo && (
                    <div className="external-connection-logo">
                        <img
                            className="external-connection-logo-img"
                            src={logo}
                            alt={name}
                        />
                    </div>
                )}
                {!!name && (
                    <div className="external-connection-name">{name}</div>
                )}
                {/* <div className="external-connection-button">
                    {disabled ? (
                        <div className="button button-external">
                            <Loader
                                type="Oval"
                                color="#f37749"
                                visible
                                height={18}
                                width={18}
                            />
                        </div>
                    ) : !active ? (
                        <div
                            role="button"
                            tabIndex={0}
                            className="button button-external"
                            onClick={connectPress}
                            onKeyDown={connectPress}
                        >
                            <em className="mdi mdi-plus start" />
                            Connect
                        </div>
                    ) : (
                        <>
                            <div className="external-connection-button-left">
                                <div
                                    role="button"
                                    tabIndex={-1}
                                    className="button button-external"
                                    onClick={disconnectPress}
                                    onKeyDown={disconnectPress}
                                >
                                    <em className="mdi mdi-close start gray-color" />
                                    Disconnect
                                </div>
                            </div>
                            <div className="external-connection-button-right">
                                <div
                                    role="button"
                                    tabIndex={-2}
                                    className="button button-external"
                                    onClick={editPress}
                                    onKeyDown={editPress}
                                >
                                    <em className="mdi mdi-pencil start" />
                                    Edit
                                </div>
                            </div>
                        </>
                    )}
                </div> */}
            </div>
        </div>
    );
};
