import { Report } from '../../../types/types';

type Props = {
    report: Report;
    withoutShadow?: boolean;
    openReport?: () => void;
};

export const ReportCard = ({ report, withoutShadow, openReport }: Props) => {
    return (
        <div
            role="button"
            tabIndex={0}
            key={report?.id.toString()}
            className={`block block-report-pane ${
                withoutShadow && 'without-shadow'
            }`}
            onClick={openReport}
            onKeyDown={openReport}
        >
            <div className="report-title">
                <div className="flex flex-grow">View report</div>
                <div
                    className={`label ${
                        report?.status === 'accepted'
                            ? 'label-success-light'
                            : report?.status === 'rejected'
                            ? 'label-warning-light'
                            : 'label-primary-light'
                    } label-md`}
                >
                    {report?.status}
                </div>
            </div>
            <div className="report-members">
                <em className="mdi mdi-account-outline" />
                <div className="report-members-label">
                    {`${report?.users_count} ${
                        report?.users_count === 1 ? 'member' : 'members'
                    }`}
                </div>
            </div>
        </div>
    );
};
