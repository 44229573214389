import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    ButtonWrapper,
    Container,
    FlexOne,
    PayMethodContainerWrapper,
    SalePaymentDate,
    Status,
} from './styles';

import { TrocTransaction } from './TrocTransaction';
import {
    AppHeader,
    CircleCard,
    CurrenciesFooter,
    PayMethodButtons,
    PayModalAch,
} from '../../components';
import { amountOptions } from '../../services/helpers';
import {
    acceptReport,
    fetchReport,
    rejectReport,
} from '../../services/ReportsService';
import { getReportById } from '../../state/reports';
import { Reportable, ReportTransaction } from '../../types/types';
import { useComponentVisible } from '../../hooks';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { getAbilityToPerformPayments } from '../../state/myOrganizations';
import { payChain } from '../../services/ChainsService';

export const Report = () => {
    const { id } = useParams<{ id: string }>();
    const report = useSelector(getReportById(Number(id)));
    const abilityToPerformPayments = useSelector(getAbilityToPerformPayments);
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const [isLoading, setLoading] = useState(false);
    // const [isDetailsVisible, setDetailsVisible] = useState(true);

    // const onClickDetails = useCallback(() => {
    //     setDetailsVisible(prevState => !prevState);
    // }, []);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                setLoading(true);
                const reportResponse = await fetchReport(Number(id));
                setLoading(false);
                if (reportResponse.status !== 200) {
                    history.push('/reports/all');
                }
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, history, id]);

    const accept = useCallback(async () => {
        if (!isLoading) {
            setLoading(true);
            await acceptReport(Number(id));
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [id, isLoading]);

    const reject = useCallback(async () => {
        if (!isLoading) {
            setLoading(true);
            await rejectReport(Number(id));
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [id, isLoading]);

    const openReportable = useCallback(() => {
        if (report?.reportable_type === 'chain') {
            history.push(`/chains/chain/${report?.reportable_id}`);
        } else if (report?.reportable_type === 'circle') {
            history.push(`/circles/circle/${report?.reportable_id}`);
        }
    }, [history, report?.reportable_id, report?.reportable_type]);

    const openTransaction = useCallback(
        (transactionId: number) => {
            history.push(`/transactions/transaction/${transactionId}`);
        },
        [history],
    );

    const {
        ref,
        buttonRef,
        isComponentVisible,
        setIsComponentVisible,
    } = useComponentVisible(false);

    const [isAchModalOpen, setAchModalOpen] = useState(false);

    const showAchModal = useCallback(() => {
        setAchModalOpen(true);
        setIsComponentVisible(false);
    }, [setIsComponentVisible]);

    const closeAchModal = useCallback(() => {
        setAchModalOpen(false);
    }, []);

    const [paymentStatus, setPaymentStatus] = useState<
        'success' | 'fail' | undefined
    >();

    const onSuccess = useCallback(() => {
        setPaymentStatus('success');
        (async () => {
            await fetchCurrentOrganizationStatistics();
        })();
    }, []);

    const onFail = useCallback(() => setPaymentStatus('fail'), []);
    const resetStatus = useCallback(() => setPaymentStatus(undefined), []);

    const newTransactionsCount = useMemo(() => {
        let count = report?.new_transactions?.length || 0;
        if (report?.reportable_type === 'chain') {
            if (
                report?.reportable?.organizations[
                    report?.reportable?.organizations?.length - 1
                ]?.id === currentOrganizationId ||
                report?.reportable?.organizations[0]?.id ===
                    currentOrganizationId
            ) {
                count += 1;
            }
        }
        return count;
    }, [
        currentOrganizationId,
        report?.new_transactions?.length,
        report?.reportable?.organizations,
        report?.reportable_type,
    ]);

    const nettedAmount = useMemo(
        () => (report?.netted_amount || 0)?.toLocaleString('en', amountOptions),
        [report?.netted_amount],
    );

    const paymentAmount = useMemo(
        () =>
            report?.sale?.amount
                ? Number(report?.sale?.amount)?.toLocaleString(
                      'en',
                      amountOptions,
                  )
                : nettedAmount,
        [nettedAmount, report?.sale?.amount],
    );

    const isCanPayChain = abilityToPerformPayments && !!report?.can_pay;

    // const onPayNowPress = () => {
    //     setIsComponentVisible(prevState => !prevState);
    // };

    const payByStripe = async () => {
        if (!isLoading) {
            setIsComponentVisible(false);
            setLoading(true);
            const response = await payChain(report?.reportable_id);
            setLoading(false);
            if (response?.data?.url) {
                const win = window.open(response?.data?.url, '_blank');
                win?.focus();
            }
        }
    };

    return (
        <div className="app-container">
            <AppHeader
                title={`Report id ${id}`}
                headerSubtitles={[{ subtitle: 'Reports' }]}
                acceptButton={report?.can_accept_or_reject}
                rejectButton={report?.can_accept_or_reject}
                isLoading={isLoading}
                isButtonDisabled={isLoading}
                onAccept={accept}
                onReject={reject}
                message={paymentStatus}
                resetStatus={resetStatus}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-title">
                        {/* // Generated on 08 Jan 2021, at 11:52 */}
                        {`Generated on ${
                            report?.created_at
                                ? DateTime.fromSQL(report?.created_at as string)
                                      .toUTC()
                                      .toFormat("dd MMMM yyyy, HH:mm 'GMT'")
                                : ''
                        }`}
                    </div>

                    <CircleCard
                        reportableType={report?.reportable_type}
                        circle={report?.reportable as Reportable}
                        openCircle={openReportable}
                    />

                    <div className="block block-payment block-payment-in">
                        <div className="payment-overview">
                            <div className="payment-title">Netted amount</div>
                            <div className="payment-value">
                                <div className="payment-currency">
                                    {!!report?.netted_amount_currency?.icon
                                        ?.png && (
                                        <img
                                            src={
                                                report?.netted_amount_currency
                                                    ?.icon?.png
                                            }
                                            alt="netted currency"
                                        />
                                    )}
                                </div>
                                <div className="payment-amount">
                                    {nettedAmount}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block block-payment">
                        <div className="payment-details">
                            {/* <div className="payment-details-heading">
                                <div className="payment-details-title">
                                    Report details
                                </div>
                                <button
                                    type="button"
                                    style={{
                                        borderWidth: 0,
                                        background: 'transparent',
                                    }}
                                    className="payment-details-toggle"
                                    onClick={onClickDetails}
                                >
                                    Hide details
                                    <em className="mdi mdi-chevron-down" />
                                </button>
                            </div> */}
                            {/* {!!isDetailsVisible && ( */}
                            <div className="payment-details-list">
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Generated on
                                    </div>
                                    <div className="payment-details-value">
                                        {report?.created_at
                                            ? DateTime.fromSQL(
                                                  report?.created_at as string,
                                              )
                                                  .toUTC()
                                                  .toFormat(
                                                      "dd MMMM yyyy, HH:mm 'GMT'",
                                                  )
                                            : ''}
                                    </div>
                                </div>
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Accepted
                                    </div>
                                    {!!report?.status && (
                                        <div className="payment-details-value">
                                            {report?.status === 'accepted'
                                                ? 'Yes'
                                                : 'No'}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                    {(!!report?.can_pay || !!report?.sale?.id) && (
                        <div className="block block-payment block-payment-in">
                            <div
                                className="payment-overview"
                                style={{
                                    background:
                                        report?.sale?.state === 'SUCCEEDED'
                                            ? '#cffacf'
                                            : '#FFFFFF',
                                    borderRadius: 5,
                                }}
                            >
                                {!!report?.can_pay && (
                                    <div className="payment-title">
                                        In order to close this chain - you need
                                        to pay this amount. Save on the bank
                                        fees by paying online via Troc Circle.
                                    </div>
                                )}
                                <div className="payment-value payment-value-justify">
                                    <Container>
                                        <div className="payment-currency">
                                            <img
                                                src={
                                                    report
                                                        ?.netted_amount_currency
                                                        ?.icon?.png
                                                }
                                                alt="netted currency"
                                            />
                                        </div>
                                        <div className="payment-amount">
                                            {paymentAmount}
                                        </div>
                                        <Status>
                                            <div className="circle-title">
                                                <div
                                                    className={`label ${
                                                        report?.sale?.state ===
                                                        'SUCCEEDED'
                                                            ? 'label-success-payment'
                                                            : report?.sale
                                                                  ?.state ===
                                                              'PENDING'
                                                            ? 'label-warning-light'
                                                            : report?.sale
                                                                  ?.state ===
                                                                  'CANCELED' ||
                                                              report?.sale
                                                                  ?.state ===
                                                                  'FAILED'
                                                            ? 'label-warning'
                                                            : 'label-primary-light'
                                                    } label-md`}
                                                >
                                                    {!report?.sale?.state
                                                        ? 'not paid'
                                                        : report?.sale
                                                              ?.state ===
                                                          'PENDING'
                                                        ? 'processing payment'
                                                        : report?.sale
                                                              ?.state ===
                                                          'FAILED'
                                                        ? 'paid failed'
                                                        : report?.sale
                                                              ?.state ===
                                                          'CANCELED'
                                                        ? 'paid canceled'
                                                        : 'paid'}
                                                </div>
                                            </div>
                                        </Status>
                                    </Container>
                                    {isCanPayChain && (
                                        <ButtonWrapper>
                                            <button
                                                ref={buttonRef}
                                                type="button"
                                                className="button button-primary button-sm"
                                                onClick={payByStripe}
                                            >
                                                Pay now
                                                {/* <em
                                                    className={`mdi mdi-menu-${
                                                        isComponentVisible
                                                            ? 'up'
                                                            : 'down'
                                                    } end`}
                                                /> */}
                                            </button>
                                            <PayMethodContainerWrapper
                                                ref={ref}
                                            >
                                                {isComponentVisible && (
                                                    <PayMethodButtons
                                                        payByAch={showAchModal}
                                                        payByStripe={
                                                            payByStripe
                                                        }
                                                    />
                                                )}
                                            </PayMethodContainerWrapper>
                                        </ButtonWrapper>
                                    )}
                                    {report?.sale?.state === 'SUCCEEDED' && (
                                        <SalePaymentDate>
                                            Payment received on{' '}
                                            {DateTime.fromSQL(
                                                report?.sale?.created_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMM yyyy 'at' HH:mm 'GMT'",
                                                )}
                                        </SalePaymentDate>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="block block-payment-graph block-payment-graph-connect">
                        {!!report?.old_transactions?.length && (
                            <FlexOne>
                                <div className="graph-col">
                                    <div className="graph-header">
                                        {`Old ${
                                            report?.old_transactions?.length ===
                                            1
                                                ? 'transaction'
                                                : 'transactions'
                                        } (${
                                            report?.old_transactions?.length
                                        })`}
                                    </div>
                                    {report?.old_transactions?.map(
                                        (transaction: ReportTransaction) => (
                                            <div
                                                key={transaction?.id?.toString()}
                                                role="button"
                                                tabIndex={0}
                                                className={`graph-item ${
                                                    transaction?.is_payer
                                                        ? 'graph-item-out'
                                                        : 'graph-item-in'
                                                }`}
                                                onClick={() =>
                                                    openTransaction(
                                                        transaction?.id,
                                                    )
                                                }
                                                onKeyDown={() =>
                                                    openTransaction(
                                                        transaction?.id,
                                                    )
                                                }
                                            >
                                                <div className="graph-item-details">
                                                    <div className="graph-item-title">
                                                        {
                                                            transaction?.title
                                                                ?.text
                                                        }{' '}
                                                        <strong>
                                                            {
                                                                transaction
                                                                    ?.title
                                                                    ?.user
                                                            }
                                                        </strong>
                                                    </div>
                                                    <div className="graph-value">
                                                        <div className="graph-currency">
                                                            <img
                                                                src={
                                                                    transaction
                                                                        ?.currency
                                                                        ?.icon
                                                                        ?.png
                                                                }
                                                                alt="transaction currency icon"
                                                            />
                                                        </div>
                                                        <div className="graph-amount">
                                                            {transaction?.amount_due?.toLocaleString(
                                                                'en',
                                                                amountOptions,
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="graph-item-icon">
                                                    <em
                                                        className={`mdi ${
                                                            transaction?.is_payer
                                                                ? 'mdi-arrow-top-right'
                                                                : 'mdi-arrow-bottom-left'
                                                        } `}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    )}
                                </div>
                            </FlexOne>
                        )}
                        {!!report?.new_transactions?.length && (
                            <div className="graph-arrow">
                                <div className="mdi mdi-arrow-right" />
                            </div>
                        )}
                        {!!report?.new_transactions?.length && (
                            <FlexOne>
                                <div className="graph-col">
                                    <div className="graph-header">
                                        {`Proposed deal (${newTransactionsCount} ${
                                            newTransactionsCount === 1
                                                ? 'transaction'
                                                : 'transactions'
                                        })`}
                                    </div>
                                    {report?.new_transactions?.map(
                                        (transaction: ReportTransaction) => (
                                            <div
                                                key={transaction?.id?.toString()}
                                                role="button"
                                                tabIndex={0}
                                                className={`graph-item ${
                                                    transaction?.is_payer
                                                        ? 'graph-item-out'
                                                        : 'graph-item-in'
                                                }`}
                                                onClick={() =>
                                                    openTransaction(
                                                        transaction?.id,
                                                    )
                                                }
                                                onKeyDown={() =>
                                                    openTransaction(
                                                        transaction?.id,
                                                    )
                                                }
                                            >
                                                <div className="graph-item-details">
                                                    <div className="graph-item-title">
                                                        {
                                                            transaction?.title
                                                                ?.text
                                                        }{' '}
                                                        <strong>
                                                            {
                                                                transaction
                                                                    ?.title
                                                                    ?.user
                                                            }
                                                        </strong>
                                                    </div>
                                                    <div className="graph-value">
                                                        <div className="graph-currency">
                                                            <img
                                                                src={
                                                                    transaction
                                                                        ?.currency
                                                                        ?.icon
                                                                        ?.png
                                                                }
                                                                alt="transaction currency icon"
                                                            />
                                                        </div>
                                                        <div className="graph-amount">
                                                            {transaction?.amount_due?.toLocaleString(
                                                                'en',
                                                                amountOptions,
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="graph-item-icon">
                                                    <em
                                                        className={`mdi ${
                                                            transaction?.is_payer
                                                                ? 'mdi-arrow-top-right'
                                                                : 'mdi-arrow-bottom-left'
                                                        } `}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    )}

                                    {report?.reportable_type === 'chain' &&
                                        report?.reportable?.organizations[0]
                                            ?.id === currentOrganizationId && (
                                            <TrocTransaction
                                                amount={report?.netted_amount}
                                                currencyIcon={
                                                    report
                                                        ?.netted_amount_currency
                                                        ?.icon?.svg
                                                }
                                            />
                                        )}
                                    {report?.reportable_type === 'chain' &&
                                        report?.reportable?.organizations
                                            ?.length > 1 &&
                                        report?.reportable?.organizations[
                                            report?.reportable?.organizations
                                                ?.length - 1
                                        ]?.id === currentOrganizationId && (
                                            <TrocTransaction
                                                income
                                                amount={report?.netted_amount}
                                                currencyIcon={
                                                    report
                                                        ?.netted_amount_currency
                                                        ?.icon?.svg
                                                }
                                            />
                                        )}
                                </div>
                            </FlexOne>
                        )}
                    </div>
                </div>
                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
            {isAchModalOpen && (
                <PayModalAch
                    id={Number(report?.reportable_id)}
                    isModalOpen={isAchModalOpen}
                    amount={paymentAmount}
                    currencyIcon={report?.netted_amount_currency?.icon?.png}
                    onRequestClose={closeAchModal}
                    onSuccess={onSuccess}
                    onFail={onFail}
                />
            )}
        </div>
    );
};
