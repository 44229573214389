import * as countries from 'countries-list';
import parsePhoneNumberFromString, {
    parsePhoneNumber,
} from 'libphonenumber-js';
import { useCallback, useMemo, useState } from 'react';
import {
    CountryPhoneSelector,
    FormComponent,
    FormError,
    SelectComponent,
} from '../../components';
import {
    getPhoneString,
    getRegions,
    getSomeDigitLength,
    isEmail,
    removeSnake,
    setPercentageLimit,
} from '../../services/helpers';
import {
    ClientOrganizationOwner,
    ConnectOrganizationProps,
} from '../../types/types';

const requiredFields = [
    'first_name',
    'last_name',
    'phone',
    'email',
    'address',
    'postal_code',
    'country_code',
    'city',
    'state',
    'social_security_number',
    'title',
    'ownership',
];

type Props = {
    ownerIndex: number;
    organization?: Partial<ConnectOrganizationProps>;
    countriesOptions: {
        value: string;
        label: string;
        regions: string;
    }[];
    errors: {
        [key: string]: any;
    };
    setErrors: React.Dispatch<
        React.SetStateAction<{
            [key: string]: any;
        }>
    >;
    ownerChanged: (
        key: keyof ClientOrganizationOwner,
        value: any,
        index: number,
    ) => void;
};

export const Owner = ({
    ownerIndex,
    organization,
    countriesOptions,
    errors,
    setErrors,
    ownerChanged,
}: Props) => {
    const ownerChangedMethod = useCallback(
        (key: keyof ClientOrganizationOwner, value: any) => {
            ownerChanged(key, value, ownerIndex);
            if (
                (!value && value !== 0 && requiredFields?.includes(key)) ||
                (key === 'email' && !isEmail(value as string) && !!value) ||
                (key === 'phone' &&
                    !!value &&
                    !parsePhoneNumberFromString(value as string)?.isPossible())
            ) {
                const errorMessage =
                    key === 'email'
                        ? 'The email must be a valid email address'
                        : key === 'phone'
                        ? 'The phone must be a valid and possible phone number'
                        : `The ${removeSnake(key)} field is required`;
                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return {
                            ...prevState,
                            [`owners.${ownerIndex}.${key}`]: [errorMessage],
                        };
                    });
            } else {
                setErrors(prevState => {
                    const {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        [`owners.${ownerIndex}.${key}`]: _,
                        ...res
                    } = prevState;
                    return res;
                });
            }
        },
        [errors, ownerChanged, ownerIndex, setErrors],
    );

    const [countryOption, setCountryOption] = useState<{
        value: string;
        label: string;
        regions: string;
    } | null>(null);

    const regionsOptions = useMemo(
        () =>
            countryOption?.regions
                ? getRegions(countryOption?.regions)?.map(
                      (item: string, index: number) => ({
                          value: index.toString(),
                          label: item,
                      }),
                  )
                : undefined,
        [countryOption?.regions],
    );

    const [regionOption, setRegionOption] = useState<{
        value: string;
        label: string;
    } | null>(null);

    const onCountryChange = useCallback(
        item => {
            setCountryOption(item);
            ownerChangedMethod('country_code', item?.value);
            setRegionOption(null);
            ownerChangedMethod('state', '');
        },
        [ownerChangedMethod],
    );
    const onRegionChange = useCallback(
        item => {
            setRegionOption(item);
            ownerChangedMethod('state', item?.label);
        },
        [ownerChangedMethod],
    );
    const [phoneCountryCode, setPhoneCountryCode] = useState<
        string | undefined
    >();

    const emoji = useMemo(
        () =>
            phoneCountryCode ? countries?.getEmojiFlag(phoneCountryCode) : '',
        [phoneCountryCode],
    );

    const [isCountrySelectorOpen, setCountrySelectorOpen] = useState(false);

    const onOpenCountrySelector = useCallback(() => {
        setCountrySelectorOpen(true);
    }, []);

    const onCloseCountrySelector = useCallback(() => {
        setCountrySelectorOpen(false);
    }, []);

    const updatePhonePrefix = useCallback(
        (countryCode: string, phonePrefix: string) => {
            setPhoneCountryCode(countryCode);
            ownerChangedMethod('phone', getPhoneString(phonePrefix));
        },
        [ownerChangedMethod],
    );

    const phoneChanged = useCallback(
        (value: string) => {
            if (
                !!value &&
                value?.length > 6 &&
                !!parsePhoneNumberFromString(value)
            ) {
                if (parsePhoneNumber(value)?.country) {
                    setPhoneCountryCode(parsePhoneNumber(value)?.country);
                }
            }
            ownerChangedMethod('phone', getPhoneString(value));
        },
        [ownerChangedMethod],
    );
    return (
        <>
            <div className="row">
                <FormComponent
                    className="form-group col-lg-6"
                    placeholder="First name"
                    label2={`Owner/Officer ${ownerIndex + 1}`}
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.first_name) ||
                        ''
                    }
                    onChange={e =>
                        ownerChangedMethod('first_name', e.target.value)
                    }
                    errors={errors[`owners.${ownerIndex}.first_name`]}
                />
                <FormComponent
                    className="form-group col-lg-6"
                    placeholder="Last name"
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.last_name) ||
                        ''
                    }
                    onChange={e =>
                        ownerChangedMethod('last_name', e.target.value)
                    }
                    errors={errors[`owners.${ownerIndex}.last_name`]}
                />
            </div>
            <div className="row">
                <FormComponent
                    className="form-group col-lg-6"
                    placeholder="Phone"
                    emoji={emoji}
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.phone) ||
                        ''
                    }
                    onOpenCountrySelector={onOpenCountrySelector}
                    onChange={e => phoneChanged(e.target.value)}
                    errors={errors[`owners.${ownerIndex}.phone`]}
                />
                <FormComponent
                    className="form-group col-lg-6"
                    placeholder="Email"
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.email) ||
                        ''
                    }
                    onChange={e => ownerChangedMethod('email', e.target.value)}
                    errors={errors[`owners.${ownerIndex}.email`]}
                />
            </div>
            <div className="row">
                <FormComponent
                    className="form-group col-lg-12"
                    placeholder="Address (street, house number and addition)"
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.address) ||
                        ''
                    }
                    onChange={e =>
                        ownerChangedMethod('address', e.target.value)
                    }
                    errors={errors[`owners.${ownerIndex}.address`]}
                />
            </div>
            <div className="row">
                <FormComponent
                    className="form-group col-lg-6"
                    placeholder="City"
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.city) ||
                        ''
                    }
                    onChange={e => ownerChangedMethod('city', e.target.value)}
                    errors={errors[`owners.${ownerIndex}.city`]}
                />
                <FormComponent
                    className="form-group col-lg-6"
                    placeholder="Zip or Postal code"
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.postal_code) ||
                        ''
                    }
                    onChange={e =>
                        ownerChangedMethod('postal_code', e.target.value)
                    }
                    errors={errors[`owners.${ownerIndex}.postal_code`]}
                />
            </div>
            <div className="row">
                <div className="form-group col-lg-6">
                    <div className="form-label">Country</div>
                    <SelectComponent
                        isClearable
                        placeholder="Country"
                        value={countryOption}
                        onChange={onCountryChange}
                        options={countriesOptions as any}
                    />
                    <FormError
                        errors={errors[`owners.${ownerIndex}.country_code`]}
                    />
                </div>
                <div className="form-group col-lg-6">
                    <div className="form-label">State (region)</div>
                    <SelectComponent
                        isClearable
                        placeholder="State (region)"
                        value={regionOption}
                        onChange={onRegionChange}
                        options={regionsOptions as any}
                    />
                    <FormError errors={errors[`owners.${ownerIndex}.state`]} />
                </div>
            </div>
            <div className="row">
                <FormComponent
                    className="form-group col-lg-4"
                    placeholder="Social Security Number"
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]
                                ?.social_security_number) ||
                        ''
                    }
                    onChange={e =>
                        ownerChangedMethod(
                            'social_security_number',
                            getSomeDigitLength(e.target.value, 9),
                        )
                    }
                    errors={
                        errors[`owners.${ownerIndex}.social_security_number`]
                    }
                />
                <FormComponent
                    className="form-group col-lg-5"
                    placeholder="Title"
                    value={
                        (!!organization?.owners?.length &&
                            organization?.owners[ownerIndex]?.title) ||
                        ''
                    }
                    onChange={e => ownerChangedMethod('title', e.target.value)}
                    errors={errors[`owners.${ownerIndex}.title`]}
                />
                <FormComponent
                    className="form-group col-lg-3"
                    placeholder="Ownership %"
                    value={
                        !!organization?.owners?.length &&
                        (!!organization?.owners[ownerIndex]?.ownership ||
                            organization?.owners[ownerIndex]?.ownership === 0)
                            ? organization?.owners[ownerIndex]?.ownership
                            : ''
                    }
                    onChange={e =>
                        ownerChangedMethod(
                            'ownership',
                            setPercentageLimit(e.target.value),
                        )
                    }
                    errors={errors[`owners.${ownerIndex}.ownership`]}
                />
            </div>
            <CountryPhoneSelector
                isCountrySelectorOpen={isCountrySelectorOpen}
                onCloseCountrySelector={onCloseCountrySelector}
                countryCode={phoneCountryCode}
                updatePhonePrefix={updatePhonePrefix}
            />
        </>
    );
};
