import { useCallback, useState } from 'react';
import { CountryRegionData } from 'react-country-region-selector';
import { InvitationFormContainer } from './styles';
import { InvitationProps } from '../../types/types';
import { FormError, SelectComponent } from '../../components';

const countriesOptions = CountryRegionData.map(item => ({
    value: item[1],
    label: item[0],
}));

type Props = {
    invitationFields: Partial<InvitationProps>;
    invitationErrors: {
        [key: string]: any;
    };
    invitationFieldsChanged: (
        key: keyof InvitationProps,
        value: string,
    ) => void;
    search: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InvitationForm = ({
    invitationFields,
    invitationErrors,
    invitationFieldsChanged,
    search,
}: Props) => {
    const [countryOption, setCountryOption] = useState<{
        value: string;
        label: string;
    } | null>(null);

    const onCountryChange = useCallback(
        item => {
            setCountryOption(item);
            invitationFieldsChanged('country_code', item?.value);
        },
        [invitationFieldsChanged],
    );

    return (
        <InvitationFormContainer className="col-lg-12 form">
            <div className="row">
                <div className="form-group col-lg-6 col-xs-12">
                    <div className="form-label">First name</div>
                    <div className="form-control-wrapper">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="First name"
                            value={invitationFields?.first_name || ''}
                            onChange={e =>
                                invitationFieldsChanged(
                                    'first_name',
                                    e.target.value,
                                )
                            }
                        />
                    </div>
                    <FormError errors={invitationErrors?.first_name} />
                </div>
                <div className="form-group col-lg-6 col-xs-12">
                    <div className="form-label">Last name</div>
                    <div className="form-control-wrapper">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Last name)"
                            value={invitationFields?.last_name || ''}
                            onChange={e =>
                                invitationFieldsChanged(
                                    'last_name',
                                    e.target.value,
                                )
                            }
                        />
                    </div>
                    <FormError errors={invitationErrors?.last_name} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-lg-12">
                    <div className="form-label">Company name</div>
                    <div className="form-control-wrapper">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Company name"
                            value={invitationFields?.company_name || ''}
                            onChange={e =>
                                invitationFieldsChanged(
                                    'company_name',
                                    e.target.value,
                                )
                            }
                        />
                    </div>
                    <FormError errors={invitationErrors?.company_name} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-lg-6 col-xs-12">
                    <div className="form-label">Email</div>
                    <div className="form-control-wrapper">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Email"
                            value={invitationFields?.email || ''}
                            onChange={search}
                        />
                    </div>
                    <FormError errors={invitationErrors?.email} />
                </div>
                <div className="form-group col-lg-6 col-xs-12">
                    <div className="form-label">Country</div>
                    <div className="form-control-wrapper">
                        <SelectComponent
                            isClearable
                            placeholder="Country"
                            value={countryOption}
                            onChange={onCountryChange}
                            options={countriesOptions as any}
                        />
                    </div>
                    <FormError errors={invitationErrors?.country_code} />
                </div>
            </div>
        </InvitationFormContainer>
    );
};
