import { DateTime } from 'luxon';
import { CreatedAt } from './styles';
import { TGroup } from '../../types/types';

type Props = {
    createdAt: TGroup['created_at'];
    onClick?: () => void;
};

export const Td2 = ({ createdAt, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                }}
            >
                <CreatedAt>
                    {createdAt
                        ? DateTime.fromSQL(createdAt)
                              .toUTC()
                              .toFormat('dd.MM.yyyy')
                        : '-'}
                </CreatedAt>
            </button>
        </td>
    );
};
