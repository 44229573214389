import ContentLoader from 'react-content-loader';
import { RedLine } from '../../components';
import { Transaction } from '../../types/types';

type Props = {
    id?: number;
    status?: Transaction['status'];
    redLine?: boolean;
    onClick: (id?: number) => void;
};

export const TdStatus = ({ id, status, redLine, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={() => onClick(id)}
                style={{
                    position: 'relative',
                }}
            >
                {redLine && <RedLine top={-8} />}
                {status ? (
                    <div
                        className={`label ${
                            status === 'open'
                                ? 'label-success-light'
                                : status === 'in payment'
                                ? 'label-warning-light'
                                : status === 'rejected'
                                ? 'label-danger-light'
                                : status === 'pending'
                                ? ''
                                : status === 'archived'
                                ? 'label-default'
                                : status === 'paid'
                                ? 'label-success'
                                : status === 'processing'
                                ? 'label-warning'
                                : 'label-default-dashed'
                        }`}
                    >
                        {status}
                    </div>
                ) : !id ? (
                    <div
                        style={{
                            width: 60,
                            height: 15,
                        }}
                    >
                        <ContentLoader viewBox="0 0 60 15">
                            <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="60"
                                height="15"
                            />
                        </ContentLoader>
                    </div>
                ) : (
                    ''
                )}
            </button>
        </td>
    );
};
