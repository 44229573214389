import { OrganizationRequestConnection } from '../../types/types';

type Props = {
    connection: OrganizationRequestConnection;
};

export const ClientBlocks = ({ connection }: Props) => {
    return (
        <div className="window-header">
            <div className="row">
                <div className="col-lg-6 col-sm-12 form">
                    <div className="block block-title">
                        Business information
                    </div>
                    <div className="block block-overview-list">
                        <div className="row">
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Legal name of the business
                                </div>
                                <div className="overview-item-value">
                                    {connection?.company_name || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Trade Name (DBA)
                                </div>
                                <div className="overview-item-value">
                                    {connection?.trade_name || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Tax ID
                                </div>
                                <div className="overview-item-value">
                                    {connection?.tax_id || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Industry
                                </div>
                                <div className="overview-item-value">
                                    {connection?.industry || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Address (street, house number and addition)
                                </div>
                                <div className="overview-item-value">
                                    {connection?.primary_address || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">City</div>
                                <div className="overview-item-value">
                                    {connection?.primary_city || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Zip or Postal code
                                </div>
                                <div className="overview-item-value">
                                    {connection?.primary_postal_code || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Country
                                </div>
                                <div className="overview-item-value">
                                    {connection?.primary_country_code || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    State (region)
                                </div>
                                <div className="overview-item-value">
                                    {connection?.primary_state || '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 form">
                    <div className="block block-title">Primary contact</div>
                    <div className="block block-overview-list">
                        <div className="row">
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    First name
                                </div>
                                <div className="overview-item-value">
                                    {connection?.primary_first_name || '-'}
                                </div>
                            </div>

                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">
                                    Last name
                                </div>
                                <div className="overview-item-value">
                                    {connection?.primary_last_name || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">Phone</div>
                                <div className="overview-item-value">
                                    {connection?.primary_phone || '-'}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 overview-item">
                                <div className="overview-item-label">Email</div>
                                <div className="overview-item-value">
                                    {connection?.primary_email || '-'}
                                </div>
                            </div>
                            {!!connection?.signature?.sizes?.thumbnail && (
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Signature
                                    </div>
                                    <img
                                        src={
                                            connection?.signature?.sizes
                                                ?.thumbnail
                                        }
                                        alt="signature"
                                    />
                                </div>
                            )}
                            {!!connection?.date &&
                                !!connection?.signature?.sizes?.thumbnail && (
                                    <div className="col-lg-6 col-xs-12 overview-item">
                                        <div className="overview-item-label">
                                            Date
                                        </div>
                                        <div className="overview-item-value">
                                            {connection?.date}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {connection?.owners?.map((owner, ownerIndex) => (
                    <div className="col-lg-6 col-sm-12 form">
                        <div className="block block-title">
                            {`Owner/Officer ${ownerIndex + 1} information`}
                        </div>
                        <div className="block block-overview-list">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        First name
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.first_name || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Last name
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.last_name || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Phone
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.phone || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Email
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.email || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Address (street, house number and
                                        addition)
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.address || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        City
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.city || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Zip or Postal code
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.postal_code || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Country
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.country_code || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        State (region)
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.state || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Social Security Number
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.social_security_number || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Title
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.title || '-'}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 overview-item">
                                    <div className="overview-item-label">
                                        Ownership %
                                    </div>
                                    <div className="overview-item-value">
                                        {owner?.ownership || '-'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="col-lg-6 col-sm-12 form">
                    <div className="block block-title">Attachments</div>
                    <div className="block block-overview-list">
                        <div className="col-lg-12 overview-item">
                            <div className="overview-item-label">
                                Current Balance Sheet
                            </div>
                            {connection?.current_balance_sheet_files?.length ? (
                                connection?.current_balance_sheet_files?.map(
                                    item => (
                                        <div
                                            className="overview-item-value"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {item?.original_name || '-'}
                                            {!!item?.url_public && (
                                                <a
                                                    href={item?.url_public}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="mdi mdi-download end" />
                                                </a>
                                            )}
                                        </div>
                                    ),
                                )
                            ) : (
                                <div className="overview-item-value">-</div>
                            )}
                        </div>
                        <div className="col-lg-12 overview-item">
                            <div className="overview-item-label">
                                Current income statement
                            </div>
                            {connection?.current_income_statement_files
                                ?.length ? (
                                connection?.current_income_statement_files?.map(
                                    item => (
                                        <div
                                            className="overview-item-value"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {item?.original_name || '-'}
                                            {!!item?.url_public && (
                                                <a
                                                    href={item?.url_public}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="mdi mdi-download end" />
                                                </a>
                                            )}
                                        </div>
                                    ),
                                )
                            ) : (
                                <div className="overview-item-value">-</div>
                            )}
                        </div>
                        <div className="col-lg-12 overview-item">
                            <div className="overview-item-label">
                                Current AR Aging
                            </div>
                            {connection?.current_ar_aging_files?.length ? (
                                connection?.current_ar_aging_files?.map(
                                    item => (
                                        <div
                                            className="overview-item-value"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {item?.original_name || '-'}
                                            {!!item?.url_public && (
                                                <a
                                                    href={item?.url_public}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="mdi mdi-download end" />
                                                </a>
                                            )}
                                        </div>
                                    ),
                                )
                            ) : (
                                <div className="overview-item-value">-</div>
                            )}
                        </div>
                        <div className="col-lg-12 overview-item">
                            <div className="overview-item-label">
                                Current AP Aging
                            </div>
                            {connection?.current_ap_aging_files?.length ? (
                                connection?.current_ap_aging_files?.map(
                                    item => (
                                        <div
                                            className="overview-item-value"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {item?.original_name || '-'}
                                            {!!item?.url_public && (
                                                <a
                                                    href={item?.url_public}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="mdi mdi-download end" />
                                                </a>
                                            )}
                                        </div>
                                    ),
                                )
                            ) : (
                                <div className="overview-item-value">-</div>
                            )}
                        </div>
                        <div className="col-lg-12 overview-item">
                            <div className="overview-item-label">
                                Guarantor’s ID Copy
                            </div>
                            {connection?.guarantors_id_copy_files?.length ? (
                                connection?.guarantors_id_copy_files?.map(
                                    item => (
                                        <div
                                            className="overview-item-value"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {item?.original_name || '-'}
                                            {!!item?.url_public && (
                                                <a
                                                    href={item?.url_public}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="mdi mdi-download end" />
                                                </a>
                                            )}
                                        </div>
                                    ),
                                )
                            ) : (
                                <div className="overview-item-value">-</div>
                            )}
                        </div>
                        {!!connection?.other_items_files?.length && (
                            <div className="col-lg-12 overview-item">
                                <div className="overview-item-label">
                                    Other Items
                                </div>
                                {connection?.other_items_files?.map(item => (
                                    <div
                                        className="overview-item-value"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {item?.original_name || '-'}
                                        {!!item?.url_public && (
                                            <a
                                                href={item?.url_public}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <em className="mdi mdi-download end" />
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
