import { TdAmount } from './TdAmount';
import { TdCheckBox } from './TdCheckBox';
import { TdDate } from './TdDate';
import { TdPaymentStatus } from './TdPaymentStatus';
import { TdRecipient } from './TdRecipient';
import { TdRequestStatus } from './TdRequestStatus';
import { TdStatus } from './TdStatus';
import { Transaction } from '../../types/types';

type Props = {
    abilityToManageTransactions: boolean;
    canAcceptOrRejectFactorRequest: boolean;
    canFactor: boolean;
    canFactorRequest: boolean;
    checked: boolean;
    factoringAbility: boolean;
    isActionCheckBoxVisible: boolean;
    transaction?: Transaction;
    type: 'receivable' | 'archived' | 'factor-request' | 'payable';
    onActionFilterChange: (event: any) => void;
    onClick: (id?: number | undefined) => void;
};

export const Tr = ({
    abilityToManageTransactions,
    canAcceptOrRejectFactorRequest,
    canFactor,
    canFactorRequest,
    checked,
    factoringAbility,
    isActionCheckBoxVisible,
    transaction,
    type,
    onActionFilterChange,
    onClick,
}: Props) => {
    return (
        <tr className="tr-compact">
            {isActionCheckBoxVisible && (
                <TdCheckBox
                    id={transaction?.id}
                    disabled={
                        type === 'receivable'
                            ? !transaction?.can_archive &&
                              !factoringAbility &&
                              !abilityToManageTransactions
                            : type === 'factor-request'
                            ? transaction?.factor_request_transaction
                                  ?.status === 'Accepted' &&
                              !transaction?.can_archive
                            : !transaction?.can_archive
                    }
                    checked={checked}
                    redLine={
                        transaction?.can_accept_or_reject ||
                        transaction?.can_factor_action
                    }
                    onChange={onActionFilterChange}
                />
            )}
            <TdStatus
                id={transaction?.id}
                status={transaction?.status}
                redLine={
                    transaction?.can_accept_or_reject &&
                    !isActionCheckBoxVisible
                }
                onClick={onClick}
            />

            {(canFactor ||
                canFactorRequest ||
                canAcceptOrRejectFactorRequest) && (
                <TdRequestStatus
                    id={transaction?.id}
                    status={transaction?.factor_request_transaction?.status}
                    onClick={onClick}
                />
            )}
            <TdDate
                id={transaction?.id}
                date={transaction?.created_at}
                onClick={onClick}
            />
            <TdRecipient
                id={transaction?.id}
                recipient={transaction?.title?.user}
                onClick={onClick}
            />

            {canAcceptOrRejectFactorRequest && (
                <TdRecipient
                    id={transaction?.id}
                    recipient={transaction?.title2?.user}
                    onClick={onClick}
                />
            )}
            <TdPaymentStatus
                id={transaction?.id}
                status={transaction?.payment_status}
                onClick={onClick}
            />
            <TdAmount
                id={transaction?.id}
                icon={transaction?.currency?.icon?.svg}
                isPayer={transaction?.is_payer}
                amount={transaction?.amount}
                onClick={onClick}
            />
            <TdAmount
                id={transaction?.id}
                icon={transaction?.currency?.icon?.svg}
                isPayer={transaction?.is_payer}
                amount={transaction?.amount_due}
                onClick={onClick}
            />
        </tr>
    );
};
