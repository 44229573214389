import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import iconBank from '../../assets/img/pane-icons/icon-bank.svg';
import {
    AppHeader,
    CurrenciesFooter,
    FormError,
    Spinner,
} from '../../components';
import { getInitials } from '../../services/helpers';
import {
    deleteMember,
    fetchMember,
    updateMemberPermissions,
} from '../../services/MembersService';
import { fetchPermissions } from '../../services/PermissionsService';
import { getMemberById } from '../../state/members';
import { getMyOrganizationById } from '../../state/myOrganizations';
import { getPermissions } from '../../state/permissions';
import { getMyCurrentOrganizationId } from '../../state/statistics';

export const OrganizationMember = () => {
    const { id, memberId } = useParams<{ id: string; memberId: string }>();

    const storedOrganization = useSelector(getMyOrganizationById(Number(id)));
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    const member = useSelector(getMemberById(Number(memberId)));

    const history = useHistory();
    useEffect(() => {
        if (
            !!id &&
            !!currentOrganizationId &&
            Number(id) !== currentOrganizationId
        ) {
            history.goBack();
        }
    }, [currentOrganizationId, history, id]);

    useEffect(() => {
        (async () => {
            const response = await fetchMember(Number(memberId));
            if (response?.status === 200 && response?.data?.data) {
                setMemberPermissions(response?.data?.data?.permissions);
            }
        })();
    }, [memberId]);

    const permissions = useSelector(getPermissions);

    useEffect(() => {
        (async () => {
            await fetchPermissions();
        })();
    }, []);

    const [memberPermissions, setMemberPermissions] = useState(
        member?.permissions,
    );

    const [isButtonsVisible, setButtonsVisible] = useState(false);

    const onPermissionsChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setButtonsVisible(true);
            const { target } = e;
            const value =
                target.type === 'checkbox' ? target.checked : target.value;
            const { name } = target;
            setMemberPermissions(prevState => {
                if (value) {
                    if (prevState?.length) {
                        if (prevState?.includes(Number(name))) {
                            return prevState;
                        }
                        return [...prevState, Number(name)];
                    }
                    return [Number(name)];
                }
                if (prevState?.length) {
                    let filtered = [...prevState];
                    filtered = filtered.filter(item => item !== Number(name));
                    return filtered;
                }
                return prevState;
            });
        },
        [],
    );
    // const [isTipVisible, setTipVisible] = useState(true);

    const onCancel = useCallback(() => {
        history.goBack();
    }, [history]);

    const [isLoading, setLoading] = useState(false);

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const onSubmit = useCallback(async () => {
        setLoading(true);
        const response = await updateMemberPermissions(
            Number(memberId),
            memberPermissions as number[],
        );
        setLoading(false);
        if (response?.status === 200) {
            history.goBack();
        } else if (response?.response?.data?.errors) {
            const permissionsErrors =
                response?.response?.data?.errors?.permissions;
            if (permissionsErrors) {
                setErrors(prevState => ({
                    ...prevState,
                    permissions: permissionsErrors,
                }));
            } else {
                alert("Can't set permissions");
            }
        }
    }, [history, memberId, memberPermissions]);

    const removeMember = useCallback(async () => {
        setLoading(true);
        const response = await deleteMember(Number(memberId));
        setLoading(false);
        if (response?.status === 200) {
            history.goBack();
        } else if (response?.status === 422) {
            alert("Can't delete");
        }
    }, [history, memberId]);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                title={
                    !!member?.first_name && !!member?.last_name
                        ? `${member?.first_name} ${member?.last_name}`
                        : ''
                }
                headerSubtitles={[
                    {
                        subtitle: storedOrganization?.company_name,
                        subtitleLink: `/organization/${id}`,
                    },
                    {
                        subtitle: 'Members',
                        subtitleLink: `/organization${id}/members`,
                    },
                ]}
            />

            <div className="app-content">
                <div className="app-body">
                    <div className="block block-title">
                        <div className="block-title-wrapper">
                            Edit permissions for{' '}
                            {`${!!member?.first_name && member?.first_name} ${
                                !!member?.last_name && member?.last_name
                            }`}
                            <div className="block-title-subtitle">
                                {`Manage who has access to ${storedOrganization?.company_name} or add new
                                members to.`}
                            </div>
                        </div>
                        {/* <div className="block-title-actions">
                            <a
                                href="/"
                                className="button button-primary button-primary-outline button-sm"
                            >
                                <em className="mdi mdi-account-multiple-outline start" />
                                Edit
                            </a>
                        </div> */}
                    </div>
                    <div className="block block-users">
                        <div className="user">
                            <div className="user-media">
                                {member?.avatar?.sizes?.thumbnail ? (
                                    <img
                                        className="user-media-image"
                                        src={member?.avatar?.sizes?.thumbnail}
                                        alt=""
                                    />
                                ) : (
                                    !!member?.first_name &&
                                    !!member?.last_name && (
                                        <div className="user-media-abbr">
                                            {getInitials(
                                                `${member?.first_name} ${member?.last_name}`,
                                            )}
                                        </div>
                                    )
                                )}
                            </div>

                            <div className="user-details">
                                <div className="user-name">
                                    {' '}
                                    {`${
                                        !!member?.first_name &&
                                        member?.first_name
                                    } ${
                                        !!member?.last_name && member?.last_name
                                    }`}
                                    <div
                                        className={`label ${
                                            !member?.invintation_accepted
                                                ? 'label-default-outline'
                                                : 'label-primary-outline'
                                        }`}
                                    >
                                        {!member?.invintation_accepted
                                            ? 'Invitation sent'
                                            : member?.is_owner
                                            ? 'Owner'
                                            : 'User'}
                                    </div>
                                </div>
                                <div className="user-email">
                                    {member?.email}
                                </div>
                            </div>
                            <div className="user-actions">
                                <div className="button-group">
                                    <div
                                        className="button button-default"
                                        role="button"
                                        tabIndex={0}
                                        onClick={removeMember}
                                        onKeyDown={removeMember}
                                    >
                                        <div className="mdi mdi-trash-can-outline start" />
                                        Delete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block block-permissions">
                        <div className="permissions-overview">
                            <div className="permissions-media">
                                <img alt="" src={iconBank} />
                            </div>
                            <div className="permissions-details">
                                <div className="permissions-title">
                                    Available permissions
                                </div>
                                <div className="permissions-subtitle">
                                    To protect your sensitive information or
                                    restrict the actions they can perform,
                                    permissions limit new member access.
                                </div>
                            </div>
                        </div>
                        <div className="permissions-list">
                            {permissions?.map(permission => (
                                <label
                                    key={permission?.id?.toString()}
                                    className="permissions-item"
                                    htmlFor={permission?.slug}
                                >
                                    <div className="permissions-item-details">
                                        <div className="permissions-item-title">
                                            {permission?.name}
                                        </div>
                                        <div className="permissions-item-subtitle">
                                            {permission?.description}
                                        </div>
                                    </div>
                                    <div className="permissions-item-actions">
                                        <div className="form">
                                            <div className="form-checkbox checkbox-narrow">
                                                <input
                                                    type="checkbox"
                                                    id={permission?.id?.toString()}
                                                    name={permission?.id?.toString()}
                                                    onChange={
                                                        onPermissionsChange
                                                    }
                                                    checked={
                                                        memberPermissions?.includes(
                                                            permission?.id,
                                                        ) || false
                                                    }
                                                />
                                                <label
                                                    className="checkbox-label"
                                                    htmlFor={permission?.id?.toString()}
                                                >
                                                    <div className="checkbox-label-box">
                                                        <div className="mdi mdi-check" />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            ))}
                        </div>
                        <FormError errors={errors?.permissions} />
                    </div>
                    {/* {isTipVisible && <Tip setTipVisible={setTipVisible} />} */}
                    {isButtonsVisible && (
                        <div className="form">
                            <div className="form-actions">
                                <div className="button-group">
                                    <div
                                        className="button button-primary button-sm"
                                        role="button"
                                        tabIndex={-1}
                                        onClick={onSubmit}
                                        onKeyDown={onSubmit}
                                    >
                                        {' '}
                                        <em className="mdi mdi-check start" />
                                        Submit
                                    </div>
                                    <div
                                        className="button button-default button-primary button-sm"
                                        role="button"
                                        tabIndex={-2}
                                        onClick={onCancel}
                                        onKeyDown={onCancel}
                                    >
                                        {' '}
                                        <em className="mdi mdi-close start" />
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
            {!!isLoading && <Spinner />}
        </div>
    );
};
