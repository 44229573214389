/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import authLogo from '../../assets/img/auth-logo.svg';
import { FormError } from '../../components';
import { login } from '../../services/AuthService';
import { isEmail, removeSnake } from '../../services/helpers';
import { Credentials } from '../../types/types';

export const SignIn = () => {
    const [credentials, setCredentials] = useState<Partial<Credentials>>({});

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const credentialsChanged = useCallback(
        (key: string, value: string) => {
            setCredentials(prevState => ({
                ...prevState,
                [key]: value,
            }));
            if (
                (key === 'email' && !isEmail(value)) ||
                (key === 'password' && !value)
            ) {
                const errorMessage =
                    key === 'email'
                        ? 'The email must be a valid email address'
                        : 'The password field is required.';
                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors],
    );

    const history = useHistory();

    const requiredFields = useMemo(() => ['email', 'password'], []);

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                !credentials.hasOwnProperty(item)
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [credentials, errors, requiredFields]);

    const signIn = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await login(credentials as Credentials);
                setButtonDisabled(false);
                if (response?.status === 200) {
                    history.push('/');
                }
                if (response?.response?.data?.errors) {
                    const {
                        email,
                        password,
                        ...other
                    } = response?.response?.data?.errors;
                    if (email) setErrors({ email });
                    if (password) setErrors({ password });
                    if (email && password) setErrors({ email, password });
                    if (Object.keys(other).length)
                        console.log('other errors:', other);
                }
            }
        }
    }, [checkEmptyValues, credentials, errors, history]);

    const register = useCallback(() => {
        history.push('/sign-up');
    }, [history]);

    return (
        <div className="block block-auth">
            <div className="auth-content" style={{ position: 'relative' }}>
                <form className="auth-wrapper" action="">
                    <div className="auth-icon">
                        <img
                            src={authLogo}
                            alt="auth logo"
                            style={{
                                width: 120,
                                height: 60,
                                marginBottom: 20,
                            }}
                        />
                    </div>
                    <div className="auth-title">Login to your account</div>
                    <div className="auth-description">
                        {/* Show your interest by subscribing to our newsletter */}
                    </div>
                    <div className="form">
                        <div className="form-group">
                            <div className="form-label">Email</div>
                            <input
                                className="form-control form-control-lg"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="YOUR EMAIL"
                                value={credentials?.email || ''}
                                onChange={e =>
                                    credentialsChanged('email', e.target.value)
                                }
                            />
                            <FormError errors={errors?.email} />
                        </div>
                        <div className="form-group">
                            <div className="form-label">Password</div>
                            <input
                                className="form-control form-control-lg"
                                id="password"
                                name="password"
                                type="password"
                                placeholder="PASSWORD"
                                value={credentials?.password || ''}
                                onChange={e =>
                                    credentialsChanged(
                                        'password',
                                        e.target.value,
                                    )
                                }
                            />
                            <FormError errors={errors?.password} />
                        </div>
                        <div className="form-group">
                            <div className="flex-row">
                                <div className="flex-col">
                                    <div className="auth-remember">
                                        <div className="form-checkbox checkbox-narrow">
                                            <input
                                                type="checkbox"
                                                id="remember"
                                                defaultChecked
                                            />
                                            <label
                                                className="checkbox-label"
                                                htmlFor="remember"
                                            >
                                                <div className="checkbox-label-box">
                                                    <div className="mdi mdi-check" />
                                                </div>
                                                <div className="checkbox-label-text">
                                                    KEEP ME LOGGED IN
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-col flex-center">
                                    <a
                                        className="auth-recover"
                                        href="/restore-info"
                                    >
                                        <div className="auth-recover-icon">
                                            <em className="mdi mdi-help-circle-outline" />
                                        </div>
                                        <div className="auth-recover-label">
                                            RECOVER PASSWORD
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                type="button"
                                className={`button button-lg button-fill button-dark ${
                                    !!isButtonDisabled && 'button-disabled'
                                }`}
                                onClick={signIn}
                                disabled={isButtonDisabled}
                            >
                                LOG IN TO YOUR ACCOUNT
                            </button>
                            <div className="button button-lg button-fill button-text button-disabled button-disabled-text">
                                Don’t have an account yet?
                            </div>
                            <button
                                type="button"
                                className={`button button-lg button-fill button-dark button-dark-outline ${
                                    !!isButtonDisabled && 'button-disabled'
                                }`}
                                disabled={isButtonDisabled}
                                onClick={register}
                            >
                                REGISTER A NEW ACCOUNT
                            </button>
                        </div>
                    </div>
                </form>
                {!!isButtonDisabled && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Loader
                            type="MutatingDots"
                            color="#f37749"
                            visible
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            </div>
            <div className="auth-aside" />
        </div>
    );
};
