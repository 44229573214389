import styled from 'styled-components';
import { BASE_FONT } from '../../../theme/fonts';

export const DeleteAccountButtonContainer = styled.div`
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
`;
export const DeleteIcon = styled.img`
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;

export const DeleteAccountTitle = styled(BASE_FONT)`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
`;

export const DeleteButton = styled.div`
    display: flex;
    padding: 8px 10px;
    align-items: center;
    justify-content: center;

    border-radius: 5px;
    background: var(--d-13-b-3-b, #d13b3b);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
`;

export const DeleteButtonText = styled(BASE_FONT)`
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
`;
