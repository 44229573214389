import styled from 'styled-components';
import { BASE_FONT } from '../../theme/fonts';
import { TBillingPlan, TGroup } from '../../types/types';

export const Name = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 500;
    font-size: 17px;

    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #080808;
    margin-right: 6px;
`;

export const TableRow = styled.tr<{ last?: boolean }>`
    position: relative;
    &:hover ${Name} {
        color: #f37749;
    }
    &:hover td {
        background-color: #f2f2f2;
    }
    td {
        background-color: #fff;
        padding-top: 18px;
        padding-bottom: 18px;
        box-shadow: ${({ last }) =>
            last ? 'none' : 'inset 0px -1px 0px #e1e1e1'};
        cell {
            vertical-align: middle;
        }
    }
`;

export const Status = styled(BASE_FONT)<{
    active?: boolean;
    status?: TGroup['status'];
}>`
    background: ${({ status }) =>
        status?.toLowerCase() === 'owner'
            ? '#1B9477'
            : status === 'active'
            ? '#CFFACF'
            : status === 'invited'
            ? '#f5f79b'
            : status === 'rejected'
            ? '#ffa500'
            : '#ffffff'};

    border: 1px solid
        ${({ status }) =>
            status?.toLowerCase() === 'owner'
                ? '#1B9477'
                : status === 'default'
                ? 'gray'
                : status === 'active'
                ? '#CFFACF'
                : status === 'invited'
                ? '#f5f79b'
                : status === 'rejected'
                ? '#ffa500'
                : '#080808'};

    border-radius: 3px;
    margin: 0px 0px;
    padding: 3px 5px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: ${({ status }) => (status === 'rejected' ? 'bold' : 'normal')};
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;

    color: ${({ status }) =>
        status?.toLowerCase() === 'owner'
            ? '#fff'
            : status === 'active'
            ? '#17AA51'
            : status === 'rejected'
            ? '#fff'
            : '#080808'};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const tagColorsArray = ['#FDA611', '#6933D8', '#FC3B44', '#66CD77'];

const getTagColor = (id: number, tagColors: string[]) => {
    if (Number.isInteger(id / 7)) {
        return tagColors[3];
    }
    if (Number.isInteger(id / 6)) {
        return tagColors[2];
    }
    if (Number.isInteger(id / 3)) {
        return tagColors[1];
    }
    if (Number.isInteger(id / 2)) {
        return tagColors[0];
    }
    return tagColors[0];
};

export const Plan = styled(BASE_FONT)<{
    planId: TBillingPlan['id'];
}>`
    border: 1px solid
        ${({ planId }) => getTagColor(planId || 1, tagColorsArray)};

    border-radius: 3px;
    margin: 0px 0px;
    padding: 3px 5px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;

    color: ${({ planId }) => getTagColor(planId || 1, tagColorsArray)};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const GroupId = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;

    color: #555555;
`;

export const CreatedAt = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;

    /* identical to box height, or 175% */

    color: #202531;
`;

export const PlanUsageTitle = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;

    display: flex;
    align-items: center;

    color: #202531;
`;

export const PlanUsageWrapper = styled.div`
    margin-top: 13px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const PlanUsageContainer = styled.div`
    width: 100%;
    height: 3px;

    /* #F4F4F1 */
    background: #f4f4f1;
    border-radius: 40px;
`;

export const PlanUsage = styled.div<{ value?: number }>`
    width: ${({ value = 0 }) => value}%;
    height: 3px;
    border-radius: 40px 0px 0px 40px;

    background: #f37749;
`;

export const PlanUsagePercentages = styled(BASE_FONT)`
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    text-align: right;

    color: #202531;
`;

export const TD6Container = styled.div`
    display: flex;
    gap: 10px;

    align-items: center;
    justify-content: flex-start;
`;

export const CheckIcon = styled.div`
    width: 16px;
    height: 16px;

    background: #1b9477;
    border-radius: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 6px;
`;

export const MemberContainer = styled.div<{ index: number }>`
    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ index }) =>
        index === 0 ? '#ed8627' : index === 1 ? '#19956B' : '#EB5757'};

    border: 2px solid #ffffff;
    border-radius: 13px;
    margin-left: ${({ index }) => (index > 0 ? -4 : 0)}px;
`;

export const CompanyName = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    /* identical to box height */

    color: #ffffff;
`;

export const ExtraCount = styled(BASE_FONT)`
    margin-left: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    /* identical to box height, or 133% */
    display: flex;
    align-items: center;

    /* Dirty Blue */
    color: #0c161d;
`;
