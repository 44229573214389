import ContentLoader from 'react-content-loader';
import { Transaction } from '../../types/types';

type Props = {
    id?: number;
    status?: Transaction['payment_status'];
    onClick: (id?: number) => void;
};

export const TdPaymentStatus = ({ id, status, onClick }: Props) => {
    return (
        <td>
            <button type="button" onClick={() => onClick(id)}>
                {status && id ? (
                    <div
                        className={`label ${
                            status === 'not paid'
                                ? ''
                                : status === 'partially paid'
                                ? 'label-warning-light'
                                : 'label-success'
                        }`}
                    >
                        {status || ''}
                    </div>
                ) : !id ? (
                    <div
                        style={{
                            width: 40,
                            height: 15,
                        }}
                    >
                        <ContentLoader viewBox="0 0 40 15">
                            <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="40"
                                height="15"
                            />
                        </ContentLoader>
                    </div>
                ) : (
                    ''
                )}
            </button>
        </td>
    );
};
