import ContentLoader from 'react-content-loader';
import { DateTime } from 'luxon';

type Props = {
    id?: number;
    date?: string;
    onClick: (id?: number) => void;
};

export const TdDate = ({ id, date, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={() => onClick(id)}
                style={{ minWidth: 180 }}
            >
                {date && id ? (
                    DateTime.fromSQL(date)
                        .toUTC()
                        .toFormat("dd MMMM yyyy, HH:mm 'GMT'")
                ) : !id ? (
                    <>
                        <div
                            style={{
                                width: 170,
                                height: 14,
                            }}
                        >
                            <ContentLoader viewBox="0 0 170 14">
                                <rect
                                    x="0"
                                    y="1"
                                    rx="2"
                                    ry="2"
                                    width="15"
                                    height="13"
                                />
                                <rect
                                    x="20"
                                    y="1"
                                    rx="2"
                                    ry="2"
                                    width="50"
                                    height="13"
                                />
                                <rect
                                    x="77"
                                    y="1"
                                    rx="2"
                                    ry="2"
                                    width="28"
                                    height="13"
                                />
                                <rect
                                    x="112"
                                    y="1"
                                    rx="2"
                                    ry="2"
                                    width="28"
                                    height="13"
                                />
                                <rect
                                    x="145"
                                    y="0"
                                    rx="2"
                                    ry="2"
                                    width="25"
                                    height="14"
                                />
                            </ContentLoader>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </button>
        </td>
    );
};
