import { ClearButtonContainer } from './styles';

type Props = {
    onPress: () => void;
};

export const ClearButton = ({ onPress }: Props) => {
    return (
        <ClearButtonContainer
            role="button"
            tabIndex={0}
            onClick={onPress}
            onKeyDown={onPress}
        >
            <em className="mdi mdi-close" style={{ color: 'white' }} />
        </ClearButtonContainer>
    );
};
