import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import authLogo from '../../assets/img/auth-logo.svg';
import { FormError } from '../../components';
import { config } from '../../config';
import { setPassword } from '../../services/AuthService';
import { checkPassword } from '../../services/helpers';
import { signOut } from '../../services/SignOutService';
import { getToken } from '../../state/token';
import { RegisterProps, SetPasswordProps } from '../../types/types';

const transitionStyles = {
    entering: { opacity: 0.5, transition: 'opacity 1s ease-in-out' },
    entered: { opacity: 1, transition: 'opacity 1s ease-in-out' },
    exiting: { opacity: 0.5, transition: 'opacity 1s ease-in-out' },
    exited: { opacity: 0, transition: 'opacity 1s ease-in-out' },
};

export const SetPassword = () => {
    const history = useHistory();
    const location = useLocation();

    const tempToken = useMemo(
        () =>
            location?.search?.substring(
                location?.search?.indexOf('?token=') + 7,
            ),
        [location?.search],
    );

    useEffect(() => {
        if (!tempToken) {
            history.push('/');
        }
    }, [history, tempToken]);

    const currentAuthToken = useSelector(getToken);

    const [currentAuthTokenWasReset, resetCurrentAuthToken] = useState(false);

    useEffect(() => {
        if (currentAuthToken && !currentAuthTokenWasReset) {
            (async () => {
                resetCurrentAuthToken(true);
                await signOut();
            })();
        }
    }, [currentAuthToken, currentAuthTokenWasReset]);

    const requiredFields = useMemo(() => {
        return ['password', 'password_confirmation'];
    }, []);

    const [passwordProps, setPasswordProps] = useState<
        Partial<SetPasswordProps>
    >({ token: tempToken });

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const userChanged = useCallback(
        (key: string, value: string) => {
            setTokenErrors(undefined);
            setPasswordProps(prevState => ({
                ...prevState,
                [key]: value,
            }));
            if (
                (key === 'password' && !checkPassword(value)) ||
                (key === 'password_confirmation' &&
                    value !== passwordProps?.password)
            ) {
                const errorMessage =
                    key === 'password'
                        ? 'The password must be 6–30 characters, and include a number and a letter'
                        : 'The password confirmation does not match';

                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors, passwordProps.password],
    );

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const onTermsClick = useCallback(() => {
        setTokenErrors(undefined);
        setTermsAccepted(prevState => {
            if (prevState) {
                setErrors(prevStateErrors => {
                    return {
                        ...prevStateErrors,
                        terms: ['Please accept terms and conditions'],
                    };
                });
            } else {
                setErrors(prevStateErrors => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { terms: _, ...res } = prevStateErrors;
                    return res;
                });
            }
            return !prevState;
        });
    }, []);

    const [tokenError, setTokenError] = useState(false);

    const [tokenErrors, setTokenErrors] = useState<string[] | undefined>();

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!passwordProps?.hasOwnProperty(item) ||
                    passwordProps[item] === undefined)
            ) {
                allFieldsFilled = false;
                const errorMessage =
                    item === 'password_confirmation'
                        ? 'The password confirmation field is required'
                        : `The ${item} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        if (!termsAccepted) {
            allFieldsFilled = false;
            setErrors(prevStateErrors => {
                return {
                    ...prevStateErrors,
                    terms: ['Please accept terms and conditions'],
                };
            });
        }
        return allFieldsFilled;
    }, [requiredFields, termsAccepted, errors, passwordProps]);

    const onSubmit = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await setPassword(
                    passwordProps as RegisterProps,
                );
                setButtonDisabled(false);
                if (response?.status === 200) {
                    history.push('/');
                }
                if (response?.response?.data?.errors) {
                    const {
                        token,
                        password,
                        password_confirmation,
                        ...other
                    } = response?.response?.data?.errors;
                    if (token) {
                        setTokenError(true);
                        setTokenErrors([
                            ...token,
                            'Please ask for a new invitation or register',
                        ]);
                    }
                    if (password)
                        setErrors(prevState => ({ ...prevState, password }));
                    if (password_confirmation)
                        setErrors(prevState => ({
                            ...prevState,
                            password_confirmation,
                        }));

                    if (Object.keys(other).length)
                        console.log('other errors:', other);
                }
            }
        }
    }, [errors, checkEmptyValues, passwordProps, history]);

    const transitionRef = useRef(null);

    const register = useCallback(() => {
        history.push('/sign-up');
    }, [history]);

    return (
        <div className="block block-auth">
            <form
                className="auth-content"
                action=""
                style={{ position: 'relative' }}
            >
                <div className="auth-container">
                    <div className="auth-wrapper auth-wrapper-lg">
                        <div className="auth-icon">
                            <img
                                src={authLogo}
                                alt="auth logo"
                                style={{
                                    width: 120,
                                    height: 60,
                                    marginBottom: 20,
                                }}
                            />
                        </div>
                        <div className="auth-title">Set password</div>
                        <div className="auth-description">
                            {tokenErrors?.length
                                ? tokenErrors?.map((error: any) => `${error}\n`)
                                : 'You are invited to the platform'}
                        </div>
                        {!tokenError && (
                            <div className="form">
                                <div className="row">
                                    <div className="col-xs-12 col-lg-6 form-group">
                                        <input
                                            className="form-control form-control-lg"
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="PASSWORD"
                                            value={
                                                passwordProps?.password || ''
                                            }
                                            onChange={e =>
                                                userChanged(
                                                    'password',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <FormError errors={errors?.password} />
                                    </div>
                                    <div className="col-xs-12 col-lg-6 form-group">
                                        <input
                                            className="form-control form-control-lg"
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            type="password"
                                            placeholder="CONFIRM PASSWORD"
                                            value={
                                                passwordProps.password_confirmation ||
                                                ''
                                            }
                                            onChange={e =>
                                                userChanged(
                                                    'password_confirmation',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <FormError
                                            errors={
                                                errors?.password_confirmation
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-lg-12 form-group">
                                        <div className="form-checkbox checkbox-narrow">
                                            <input
                                                type="checkbox"
                                                id="remember"
                                                checked={termsAccepted}
                                                onChange={onTermsClick}
                                            />
                                            <label
                                                className="checkbox-label"
                                                htmlFor="remember"
                                            >
                                                <div className="checkbox-label-box">
                                                    <div className="mdi mdi-check" />
                                                </div>
                                                <div className="checkbox-label-text">
                                                    I accept{' '}
                                                    <a
                                                        className="checkbox-label-link"
                                                        href={
                                                            config.privacyPolicy
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        the privacy policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        className="checkbox-label-link"
                                                        href={
                                                            config.termsAndConditionsUrl
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        terms and conditions{' '}
                                                    </a>
                                                    of Troc Circle
                                                </div>
                                            </label>
                                        </div>
                                        <FormError errors={errors?.terms} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!tokenError && (
                        <div className="auth-wrapper">
                            <button
                                type="button"
                                className="button button-lg button-fill button-dark"
                                onClick={onSubmit}
                            >
                                FINISH REGISTRATION
                            </button>
                        </div>
                    )}

                    <Transition
                        nodeRef={transitionRef}
                        in={!!tokenError}
                        timeout={2000}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {state => {
                            return (
                                <div
                                    className="auth-wrapper auth-wrapper-lg"
                                    style={{
                                        ...transitionStyles[state],
                                    }}
                                    ref={transitionRef}
                                >
                                    <button
                                        type="button"
                                        className={`button button-lg button-fill button-dark button-dark-outline ${
                                            !!isButtonDisabled &&
                                            'button-disabled'
                                        }`}
                                        disabled={isButtonDisabled}
                                        onClick={register}
                                    >
                                        REGISTER A NEW ACCOUNT
                                    </button>
                                </div>
                            );
                        }}
                    </Transition>
                </div>
                {!!isButtonDisabled && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Loader
                            type="MutatingDots"
                            color="#f37749"
                            visible
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            </form>

            <div className="auth-aside auth-aside-sign_up" />
        </div>
    );
};
