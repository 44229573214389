import React, { memo } from 'react';
import {
    CreateOrganizationProps,
    EditOrganizationBlock,
} from '../../types/types';
import { Emoji, FormError } from '../../components';

type ContactInformationBlockProps = {
    isContactInformationEditable: boolean;
    id?: string | null;
    canChangeOrganizationSettings: boolean;
    organization: Partial<CreateOrganizationProps>;
    emoji: string;
    errors: {
        [key: string]: any;
    };
    editMethodModal: (block: EditOrganizationBlock) => void;
    organizationChanged: (
        key: keyof CreateOrganizationProps,
        value: string | number,
    ) => void;
    onOpenCountrySelector: () => void;
};

export const ContactInformationBlock = memo(
    ({
        isContactInformationEditable,
        id,
        canChangeOrganizationSettings,
        organization,
        emoji,
        errors,
        editMethodModal,
        organizationChanged,
        onOpenCountrySelector,
    }: ContactInformationBlockProps) => {
        return !isContactInformationEditable && !!id ? (
            <>
                <div className="block block-title">
                    Contact information
                    {canChangeOrganizationSettings && (
                        <div className="block-title-actions">
                            <button
                                type="button"
                                className="button button-default button-sm"
                                onClick={() => editMethodModal('contacts')}
                            >
                                <em className="mdi mdi-pencil start primary" />
                                Edit
                            </button>
                        </div>
                    )}
                </div>
                <div className="block block-overview-list">
                    <div className="row">
                        <div className="col-lg-6 col-xs-12 overview-item">
                            <div className="overview-item-label">
                                Phone number
                            </div>
                            <div className="overview-item-value">
                                {organization?.phone || '-'}
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12 overview-item">
                            <div className="overview-item-label">Website</div>
                            <div className="overview-item-value">
                                {organization?.website || '-'}
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12 overview-item">
                            <div className="overview-item-label">E-mail</div>
                            <div className="overview-item-value">
                                {organization?.email || '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : id ? (
            <>
                <div className="form-title">Contact information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-xs-12">
                        <div className="form-label">Phone</div>

                        <div
                            className="form-control-wrapper"
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Phone"
                                value={organization?.phone || ''}
                                onChange={e =>
                                    organizationChanged('phone', e.target.value)
                                }
                                style={{
                                    paddingLeft: 55,
                                }}
                            />
                            <div className="form-control-info">
                                <em className="mdi mdi-information form-control-info-icon" />
                                <div className="form-control-info-container form-control-info-container-dim">
                                    Phone
                                </div>
                            </div>
                            <Emoji
                                onPress={onOpenCountrySelector}
                                symbol={emoji}
                            />
                        </div>

                        <FormError errors={errors?.phone} />
                    </div>
                    <div className="form-group col-lg-6 col-xs-12">
                        <div className="form-label">E-mail</div>
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="email"
                                placeholder="E-mail"
                                value={organization?.email || ''}
                                onChange={e =>
                                    organizationChanged('email', e.target.value)
                                }
                            />
                            <div className="form-control-info">
                                <em className="mdi mdi-information form-control-info-icon" />
                                <div className="form-control-info-container form-control-info-container-dim">
                                    E-mail
                                </div>
                            </div>
                        </div>
                        <FormError errors={errors?.email} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-xs-12">
                        <div className="form-label">Website</div>
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Website"
                                value={organization?.website || ''}
                                onChange={e =>
                                    organizationChanged(
                                        'website',
                                        e.target.value,
                                    )
                                }
                            />
                            <div className="form-control-info">
                                <em className="mdi mdi-information form-control-info-icon" />
                                <div className="form-control-info-container form-control-info-container-dim">
                                    Website
                                </div>
                            </div>
                        </div>
                        <FormError errors={errors?.website} />
                    </div>
                </div>
            </>
        ) : null;
    },
);
