import { Email, Initials, Logo, LogoWrapper, Name } from './styles';
import { getInitials } from '../../services/helpers';
import { Column, Row } from '../../components';

type Props = {
    name: string;
    email?: string;
    logo?: string;
    onClick?: () => void;
};

export const Td1 = ({ name, email, logo, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{ alignItems: 'flex-start' }}
            >
                <Row>
                    <LogoWrapper>
                        {logo ? (
                            <Logo src={logo} />
                        ) : (
                            <Initials>{getInitials(name)}</Initials>
                        )}
                    </LogoWrapper>
                    <Column justifyContent="center">
                        <Row>
                            <Name>{name}</Name>
                        </Row>
                        {!!email && <Email>{email}</Email>}
                    </Column>
                </Row>
            </button>
        </td>
    );
};
