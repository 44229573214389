import { CompanyName, ExtraCount, MemberContainer } from './styles';
import { TGroup } from '../../types/types';
import { getInitial } from '../../services/helpers';

type Props = {
    members: TGroup['members'];
    onClick?: () => void;
};

export const Td5 = ({ members, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {members?.slice(0, 3)?.map((member, index) => (
                    <MemberContainer key={index?.toString()} index={index}>
                        <CompanyName>
                            {getInitial(member?.company_name)}
                        </CompanyName>
                    </MemberContainer>
                ))}
                {members?.length > 3 && (
                    <ExtraCount>{`+${members?.length - 3}`}</ExtraCount>
                )}
            </button>
        </td>
    );
};
