import styled from 'styled-components';

export const MerchantStatus = styled.div<{
    status: 'APPROVED' | 'PROVISIONING' | 'REJECTED' | 'PENDING';
}>`
    position: absolute;
    bottom: -2px;
    right: -2px;

    width: 8px;
    height: 8px;
    border-radius: 999px;
    border: 2px solid #1f2126;
    background: ${({ status }) =>
        status === 'APPROVED'
            ? '#20de40'
            : status === 'REJECTED'
            ? '#ED383C'
            : '#ffa500'};
`;
