import { memo } from 'react';
import {
    CheckIcon,
    CurrentPlanName,
    CurrentPlanTitle,
    Period,
    PlanContainer,
    Price,
} from './styles';
import { Row } from '../../common';
import { getAmountDecimal, getAmountInteger } from '../../../services/helpers';

type CurrentPlanProps = {
    name?: string;
    price?: number;
};

export const CurrentPlan = memo(({ name, price }: CurrentPlanProps) => {
    return (
        <PlanContainer>
            <div>
                <Row>
                    <CheckIcon>
                        <div
                            className="mdi mdi-check"
                            style={{ color: 'white', fontSize: 14 }}
                        />
                    </CheckIcon>
                    <CurrentPlanTitle>Current plan</CurrentPlanTitle>
                </Row>
                <CurrentPlanName>{name}</CurrentPlanName>
            </div>
            {(!!price || price === 0) && (
                <div>
                    <Price>
                        ${getAmountInteger(price)}
                        <sup>{getAmountDecimal(price)}</sup>
                    </Price>
                    <Period>month</Period>
                </div>
            )}
        </PlanContainer>
    );
});
