type Props = {
    title: string;
    field?: string;
    sortField?: string;
    sortOrder?: 'ASC' | 'DESC';
    style?: React.CSSProperties | undefined;
    onPress?: () => void;
};

export const Th = ({
    title,
    field,
    sortField,
    sortOrder,
    style,
    onPress,
}: Props) => {
    return (
        <th
            className={
                !!sortField && !!field && sortField === field ? 'active' : ''
            }
            style={style}
        >
            <div
                role="button"
                tabIndex={0}
                className="table-th-order-by"
                style={{ minWidth: 50 }}
                onClick={onPress}
                onKeyDown={onPress}
            >
                {title}
                {!!sortField && !!field && sortField === field && (
                    <em
                        className={`mdi mdi-menu-${
                            sortOrder === 'DESC' ? 'down' : 'up'
                        }`}
                    />
                )}
            </div>
        </th>
    );
};
