import styled from 'styled-components';
import { BASE_FONT } from '../../theme/fonts';
import { TPaymentHistory } from '../../types/types';

export const CloseButton = styled.button`
    border: 0;
    padding: 0;
    background: white;
    cursor: pointer;
`;

export const CloseButtonText = styled.em`
    font-size: 24px;
    &:hover {
        color: gray;
    }
`;

export const Content = styled.div`
    background: #fbfbfb;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
`;

export const Header = styled.div`
    width: 100%;
    height: 61px;
    left: 0px;
    top: 0px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #eaecf0;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
`;

export const HeaderTitle = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #000000;
`;

export const PlanName = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: #000000;
`;

export const TableRow = styled.tr<{ last?: boolean }>`
    position: relative;
    &:hover ${PlanName} {
        color: #f37749;
    }
    &:hover td {
        background-color: #f2f2f2;
    }
    td {
        background-color: #fff;
        padding-top: 18px;
        padding-bottom: 18px;
        box-shadow: ${({ last }) =>
            last ? 'none' : 'inset 0px -1px 0px #e1e1e1'};
        cell {
            vertical-align: middle;
        }
    }
`;

export const PaymentDate = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;

    color: #202531;
`;

export const State = styled(BASE_FONT)<{
    state?: TPaymentHistory['state'];
}>`
    background: ${({ state }) => (state === 'active' ? '#CFFACF' : '#f5f79b')};

    border: 1px solid
        ${({ state }) => (state === 'active' ? '#CFFACF' : '#f5f79b')};

    border-radius: 3px;
    margin: 0px 0px;
    padding: 3px 5px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;

    color: ${({ state }) => (state === 'active' ? '#17AA51' : '#080808')};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const CurrencyIcon = styled.img`
    width: 17px;
    height: 17px;
    margin-right: 5px;
`;

export const Amount = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: -3px;
`;
