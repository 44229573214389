import React, { memo, useCallback, useState } from 'react';
import { GroupHeaderColumn, GroupHeaderContainer, GroupName } from './styles';
import iconGroup from './icon-group.svg';
import { FormError, Row } from '../../../components';
import { removeSnake } from '../../../services/helpers';
import { editGroup } from '../../../services/OrganizationsService';

type GroupHeaderProps = {
    id?: number;
    name?: string;
    isEditable?: boolean;
};

const allFields = ['name'];
const requiredFields = allFields;

export const GroupHeader = memo(
    ({ id, name, isEditable }: GroupHeaderProps) => {
        const [isEditInputVisible, setEditInputVisible] = useState(false);
        const [errors, setErrors] = useState<{ [key: string]: any }>({});
        const [otherErrors, setOtherErrors] = useState<{ [key: string]: any }>(
            {},
        );
        const [isButtonDisabled, setButtonDisabled] = useState(false);
        const [fields, setFields] = useState<{ id?: number; name: string }>({
            id,
            name: name || '',
        });
        const fieldsChanged = useCallback(
            (key: string, value: string | boolean) => {
                setOtherErrors({});
                // setFooterButtonsVisible(true);
                setFields(prevState => ({
                    ...prevState,
                    [key]: value,
                }));
                if (typeof value !== 'boolean' && !value) {
                    const errorMessage = `The ${removeSnake(
                        key,
                    )} field is required`;

                    if (!errors.hasOwnProperty(key))
                        setErrors(prevState => {
                            return { ...prevState, [key]: [errorMessage] };
                        });
                } else {
                    setErrors(prevState => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { [key]: _, ...res } = prevState;
                        return res;
                    });
                }
            },
            [errors],
        );

        const checkEmptyValues = useCallback(() => {
            let allFieldsFilled = true;
            requiredFields.forEach(item => {
                if (
                    !errors.hasOwnProperty(item) &&
                    (!fields?.hasOwnProperty(item) || fields[item] === '')
                ) {
                    allFieldsFilled = false;
                    const errorMessage = `The ${removeSnake(
                        item,
                    )} field is required`;
                    setErrors(prevState => {
                        return {
                            [item]: [errorMessage],
                            ...prevState,
                        };
                    });
                }
            });
            return allFieldsFilled;
        }, [errors, fields]);

        const onSubmit = useCallback(async () => {
            if (!Object.keys(errors).length) {
                const allFieldsFilled = checkEmptyValues();
                if (allFieldsFilled) {
                    setButtonDisabled(true);
                    const response = await editGroup(fields);
                    setButtonDisabled(false);
                    if (response?.status === 200 || response?.status === 201) {
                        setEditInputVisible(false);
                    }
                    if (
                        response?.response?.status === 422 &&
                        response?.response?.data?.errors
                    ) {
                        const allErrors = response?.response?.data?.errors;
                        const filteredErrors = Object.keys(allErrors)
                            .filter(key => allFields.includes(key))
                            .reduce((obj, key) => {
                                return {
                                    ...obj,
                                    [key]: allErrors[key],
                                };
                            }, {});
                        const otherFilteredErrors = Object.keys(allErrors)
                            .filter(key => !allFields.includes(key))
                            .reduce((obj, key) => {
                                return {
                                    ...obj,
                                    [key]: allErrors[key],
                                };
                            }, {});
                        setErrors(filteredErrors);
                        setOtherErrors(otherFilteredErrors);
                    }
                }
            }
        }, [checkEmptyValues, errors, fields]);

        return (
            <GroupHeaderContainer>
                <GroupHeaderColumn>
                    <Row>
                        <img src={iconGroup} alt="icon group" />
                        <GroupName>{name || ''}</GroupName>
                    </Row>
                    {isEditable &&
                        (!isEditInputVisible ? (
                            <button
                                type="button"
                                className="button button-default button-external button-sm"
                                style={{ width: 66 }}
                                onClick={() => setEditInputVisible(true)}
                            >
                                <em
                                    className="mdi mdi-pencil start"
                                    style={{ color: '#202531' }}
                                />
                                Edit
                            </button>
                        ) : (
                            <div className="form-actions">
                                <div className="button-group">
                                    <button
                                        type="button"
                                        className="button button-default button-primary button-sm"
                                        disabled={isButtonDisabled}
                                        onClick={() => {
                                            setEditInputVisible(false);
                                        }}
                                    >
                                        {' '}
                                        <em className="mdi mdi-close start" />
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className={`button button-primary button-sm ${
                                            isButtonDisabled
                                                ? 'button-disabled'
                                                : ''
                                        }`}
                                        disabled={isButtonDisabled}
                                        onClick={onSubmit}
                                    >
                                        {' '}
                                        <em className="mdi mdi-check start" />
                                        Submit
                                    </button>
                                </div>
                            </div>
                        ))}
                </GroupHeaderColumn>
                {isEditInputVisible && (
                    <GroupHeaderColumn bordered>
                        <div className="form" style={{ width: '100%' }}>
                            <div className="form-control-wrapper">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Group Name"
                                    value={fields?.name || ''}
                                    onChange={e =>
                                        fieldsChanged('name', e.target.value)
                                    }
                                />
                            </div>
                            <FormError errors={errors?.name} />
                        </div>
                        {!!Object.keys(otherErrors)?.length && (
                            <>
                                <div className="form-title">
                                    {`Other error${
                                        Object.keys(otherErrors)?.length > 1
                                            ? 's:'
                                            : ':'
                                    }`}
                                </div>
                                {Object.keys(otherErrors)?.map(
                                    otherErrorKey => (
                                        <FormError
                                            key={otherErrorKey}
                                            errors={otherErrors[otherErrorKey]}
                                        />
                                    ),
                                )}
                            </>
                        )}
                    </GroupHeaderColumn>
                )}
            </GroupHeaderContainer>
        );
    },
);
