import React, { memo } from 'react';
import { Progress } from './styles';
import { TBillingPlan } from '../../../types/types';
import { amountOptions } from '../../../services/helpers';

type CurrentPlanProps = {
    plan?: TBillingPlan;
    usageAmount?: number;
    openChangeBillingPlanModal?: () => void;
};

export const CurrentPlan = memo(
    ({
        plan,

        usageAmount,
        openChangeBillingPlanModal,
    }: CurrentPlanProps) => {
        return plan ? (
            <div className="block block-current-plan">
                <div className="block-current-plan-header">
                    <div className="block-current-plan-name">{plan?.title}</div>
                    {!!openChangeBillingPlanModal && (
                        <div>
                            <div className="block-current-plan-price">
                                {`$${plan.price?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}/month`}
                            </div>
                            <div
                                className="block-current-plane-change"
                                role="button"
                                tabIndex={0}
                                onClick={openChangeBillingPlanModal}
                                onKeyDown={openChangeBillingPlanModal}
                            >
                                Change plan
                            </div>
                        </div>
                    )}
                </div>
                <div className="block-current-plan-total">
                    <div className="block-current-plan-total-title">
                        Total netted amount
                    </div>
                    <div className="block-current-plan-total-payment">
                        You have netted out{' '}
                        <strong>{`$${(usageAmount || 0)?.toLocaleString(
                            'en',
                            amountOptions,
                        )}`}</strong>{' '}
                        out of{' '}
                        <strong>{`$${plan.limit_to?.toLocaleString(
                            'en',
                            amountOptions,
                        )}`}</strong>
                    </div>
                </div>
                <div className="block-current-progress-wrapper">
                    <Progress
                        value={
                            ((usageAmount || 0) / (plan.limit_to || 1)) * 100
                        }
                    />
                </div>
                <div className="block-current-plan-footer">
                    <div className="footer-item">{plan?.description}</div>
                </div>
            </div>
        ) : null;
    },
);
