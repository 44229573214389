import styled from 'styled-components';
import { BASE_FONT } from '../../../theme/fonts';

export const GroupHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;

    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    width: 100%;
`;

export const GroupHeaderColumn = styled.div<{ bordered?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    border-top: ${({ bordered }) => (bordered ? 1 : 0)}px solid #e1e1e1;

    width: 100%;
`;

export const GroupName = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #000000;

    margin-left: 12px;
`;

export const Separator = styled.div``;
