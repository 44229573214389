import { Email } from './styles';
import userIcon from './user.svg';

type Props = {
    email?: string | null;
    onClick?: () => void;
};

export const Td5 = ({ email, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                    minWidth: 130,
                }}
            >
                {!!email && (
                    <>
                        <img src={userIcon} alt="user" />
                        <Email>{email.replace('preeternal', '')}</Email>
                    </>
                )}
            </button>
        </td>
    );
};
