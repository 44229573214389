import styled from 'styled-components';

export const Content = styled.div`
    background: #fbfbfb;
    padding-top: 35px;
    padding-bottom: 35px;
    position: relative;
    padding-left: 28.5px;
    padding-right: 28.5px;
    height: 400px;

    /* overflow-y: scroll; */
`;
