import styled from 'styled-components';

export const Chevron = styled.button`
    margin-top: 5.5px;
    color: #6f747e;
    font-size: 24px;
    line-height: 11px;
    border: 0;
    padding: 0;
    background: transparent;
`;

export const CloseButton = styled.button`
    border: 0;
    padding: 0;
    background: white;
    cursor: pointer;
`;

export const CloseButtonText = styled.em`
    font-size: 24px;
    &:hover {
        color: gray;
    }
`;

export const Content = styled.div`
    background: #fbfbfb;
    padding-bottom: 10px;
    position: relative;
`;

export const Header = styled.div`
    width: 100%;
    height: 61px;
    left: 0px;
    top: 0px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #eaecf0;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
`;

export const HeaderTitle = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #000000;
`;

export const Icon = styled.em`
    color: #6f747e;
`;

export const IconWrapper = styled.button<{ active?: boolean }>`
    width: 40px;
    height: 40px;

    background: ${({ active }) => (active ? '#f37749' : ' #e6e7ec')};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ItemAvatarContainer = styled.div<{ large?: boolean }>`
    min-width: ${({ large }) => (large ? 38 : 24)}px;
    height: ${({ large }) => (large ? 38 : 24)}px;

    background: ${({ large }) => (large ? '#519AFF' : 'white')};
    border: ${({ large }) => (large ? 0 : 1)}px solid #e6edfb;
    border-radius: 38px;

    display: flex;

    justify-content: center;

    margin-right: ${({ large }) => (large ? 0 : 12)}px;
`;

export const ItemAvatarTitle = styled.div<{ large?: boolean }>`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: ${({ large }) => (large ? 14 : 12)}px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.408px;

    color: ${({ large }) => (large ? 'white' : '#f37749')};
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    /* width: 110px; */
`;

export const LeftRef = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ListWrapper = styled.div`
    position: absolute;
    left: 45px;
    bottom: 7.5px;
    z-index: 999;
`;

export const PersonWrapper = styled.div<{ margin?: boolean }>`
    margin-left: ${({ margin }) => (margin ? -19.5 : 8)}px;
`;

export const RowElement = styled.div`
    flex-direction: row;
    justify-content: space-between;

    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    position: relative;
`;

export const SentTo = styled.div`
    margin-bottom: 5.5px;
    width: 95px;
    height: 11px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;

    /* identical to box height */
    text-align: center;
    text-transform: uppercase;

    color: #000000;
`;

export const SmallCard = styled.div<{ active?: boolean }>`
    position: relative;

    width: 360px;
    height: 100px;

    background: ${({ active }) => (active ? 'white' : ' #fbfbfb')};
    border: 1px solid #eaecf0;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7.5px;

    &:hover {
        background-color: white;
    }

    &:hover ${IconWrapper} {
        background: #f37749;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 5px 8px rgba(113, 119, 129, 0.122);
    }

    ${IconWrapper} {
        background: ${({ active }) => (active ? '#f37749' : ' #e6e7ec')};
        border: ${({ active }) => (active ? 2 : 0)}px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px ${({ active }) => (active ? 5 : 0)}px
            ${({ active }) => (active ? 8 : 0)}px rgba(113, 119, 129, 0.122);
    }
    &:hover ${Icon} {
        color: white;
    }

    ${Icon} {
        color: ${({ active }) => (active ? 'white' : '#6f747e')};
    }

    &:hover ${Chevron} {
        color: #f37749;
    }

    ${Chevron} {
        color: ${({ active }) => (active ? '#f37749' : '#6f747e')};
    }
`;

export const SmallCardInput = styled.input`
    margin-left: -19.5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    display: flex;
    align-items: center;

    color: #000000;

    border: 0;
    padding: 0;
    outline: none;
    background: transparent;
    max-width: 240px;
`;

export const SmallCardText = styled.div<{ large?: boolean }>`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: ${({ large }) => (large ? 'bold' : 'normal')};
    font-size: ${({ large }) => (large ? 13 : 16)}px;

    display: flex;
    align-items: center;

    color: ${({ large }) => (large ? '#000000' : '#6f747e')};
`;

export const UserAvatar = styled.img`
    width: 39px;
    height: 39px;
    border-radius: 20px;
`;

export const UserEmail = styled.div`
    margin-top: 2px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;

    color: #6f747e;
`;

export const UserListWrapper = styled.div`
    position: relative;
    padding: 4px 0px 0px;

    height: 100%;
    width: 100%;
    overflow-y: scroll;
    /* scrollbar-color: #3367d6 #e1e1e1; */
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    width: 290px;

    background: #ffffff;
    border: 1px solid #e6e7ec;

    /* Dropdown */
    box-shadow: 0px 10px 20px rgba(83, 83, 119, 0.149);
    border-radius: 4px;
`;

export const UsersList = styled(List)<{ large?: boolean }>`
    height: ${({ large }) => (large ? 300 : 272)}px;
    scrollbar-color: #f37749 white;
`;

export const Footer = styled.div`
    width: 100%;
    height: 73px;
    left: 0px;
    bottom: 0px;

    background: #ffffff;
    border-top: 1px solid #eaecf0;
    box-shadow: inset 0px -1px 0px #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
