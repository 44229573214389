import { Mdi, PayMethodButton, PayMethodContainer } from './styles';

type Props = {
    payByStripe?: () => void;
    payByAch?: () => void;
};

export const PayMethodButtons = ({ payByAch, payByStripe }: Props) => {
    return (
        <PayMethodContainer isSingle={!payByStripe}>
            {!!payByStripe && (
                <PayMethodButton
                    creditCard
                    role="button"
                    tabIndex={0}
                    onClick={payByStripe}
                    onKeyDown={payByStripe}
                >
                    <Mdi className="mdi mdi-credit-card-outline" />
                    Pay by Stripe
                </PayMethodButton>
            )}
            <PayMethodButton
                role="button"
                tabIndex={0}
                onClick={payByAch}
                onKeyDown={payByAch}
            >
                <Mdi className="mdi mdi-square-edit-outline" />
                ACH Payments
            </PayMethodButton>
        </PayMethodContainer>
    );
};
