import { memo } from 'react';
import {
    DeleteAccountButtonContainer,
    DeleteAccountTitle,
    DeleteButton,
    DeleteButtonText,
    DeleteIcon,
} from './styles';
import deleteIcon from './delete.svg';
import { Row } from '../../../components';

type DeleteAccountButtonProps = {
    onPress: () => void;
};

export const DeleteAccountButton = memo(
    ({ onPress }: DeleteAccountButtonProps) => {
        return (
            <DeleteAccountButtonContainer>
                <Row>
                    <DeleteIcon src={deleteIcon} />
                    <DeleteAccountTitle>Delete my account</DeleteAccountTitle>
                </Row>
                <DeleteButton
                    role="button"
                    tabIndex={0}
                    onClick={onPress}
                    onKeyDown={onPress}
                >
                    <DeleteButtonText>Proceed</DeleteButtonText>
                </DeleteButton>
            </DeleteAccountButtonContainer>
        );
    },
);
