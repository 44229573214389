import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import menuIconTransactions from '../../assets/img/menu-icons/menu-icon-transactions.svg';
import caret from '../../assets/img/icons/carret.svg';
import { getSumNotificationsTransaction } from '../../state/statistics';

type Props = {
    isMenuActive?: boolean;
    onPress?: () => void;
};

export const MenuTransactionsLink = ({ isMenuActive, onPress }: Props) => {
    const sumNotificationsTransaction = useSelector(
        getSumNotificationsTransaction,
    );

    const history = useHistory();
    const onClick = useCallback(() => {
        if (onPress) onPress();
        if (!isMenuActive) history.push('/transactions/payables');
    }, [onPress, isMenuActive, history]);

    return (
        <div
            role="button"
            tabIndex={0}
            className="menu-item-link"
            // style={{
            //     borderWidth: 0,
            //     background: 'transparent',
            //     color: 'white',
            //     opacity: location.pathname.includes('transactions') ? 1 : 0.6,
            // }}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <div className="menu-item-icon">
                <img src={menuIconTransactions} alt="transaction icon" />
            </div>
            <div className="menu-item-label">
                Transactions
                {!!sumNotificationsTransaction && (
                    <div className="menu-item-notes">
                        {sumNotificationsTransaction}
                    </div>
                )}
            </div>
            <div className="menu-item-caret">
                <img src={caret} alt="caret" />
            </div>
        </div>
    );
};
