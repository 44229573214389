import { useSelector } from 'react-redux';
import {
    PlanUsage,
    PlanUsageContainer,
    PlanUsagePercentages,
    PlanUsageTitle,
    PlanUsageWrapper,
} from './styles';
import { selectBillingPlanById } from '../../../state/billingPlans';
import { TGroup } from '../../../types/types';

type Props = {
    planId: number;
    usageAmount: TGroup['usage_amount'];
    onClick?: () => void;
};

export const Td2 = ({ planId, usageAmount, onClick }: Props) => {
    const plan = useSelector(selectBillingPlanById(planId));
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <PlanUsageTitle>
                    from ${plan?.limit_from} to ${plan?.limit_to}
                </PlanUsageTitle>
                <PlanUsageWrapper>
                    <PlanUsageContainer>
                        <PlanUsage
                            value={
                                ((usageAmount || 0) / (plan?.limit_to || 1)) *
                                100
                            }
                        />
                    </PlanUsageContainer>
                    <PlanUsagePercentages>
                        {((usageAmount || 0) / (plan?.limit_to || 1)) * 100}%
                    </PlanUsagePercentages>
                </PlanUsageWrapper>
            </button>
        </td>
    );
};
