// import { ButtonWrapper, ConnectButtonContainer, ExternalCard } from './styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { ExternalConnectionCard } from './ExternalConnectionCard';
import { AppHeader, CurrenciesFooter } from '../../components';
import { fetchExternalConnections } from '../../services/ExternalConnectionsService';
import { getExternalConnections } from '../../state/externalConnections';
import { ExternalConnection } from '../../types/types';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { getAbilityEditCurrentOrganizationSettings } from '../../state/myOrganizations';

export const ExternalConnections = () => {
    const history = useHistory();

    const openConnection = useCallback(
        (id: number) => {
            history.push(`/external-connection/${id}`);
        },
        [history],
    );

    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        if (!canChangeOrganizationSettings && !!currentOrganizationId) {
            history.push(`/`);
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history]);

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                setLoading(true);
                await fetchExternalConnections();
                setLoading(false);
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId]);

    const externalConnections = useSelector(getExternalConnections);

    const activeCount = useMemo(
        () =>
            Object.values(externalConnections)?.filter(
                (connection: ExternalConnection) => connection?.active,
            ),
        [externalConnections],
    );

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                title={`${activeCount?.length} active`}
                headerSubtitles={[
                    {
                        subtitle: 'External connections',
                        subtitleLink: '/external-connections',
                    },
                ]}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-title">
                        <div className="block-title-wrapper">
                            Choose your option
                            <div className="block-title-subtitle">
                                We did not find such a user, invite your
                                business collegue to the Troc Circle
                            </div>
                        </div>
                        {isLoading && (
                            <Loader
                                type="ThreeDots"
                                color="#f37749"
                                visible
                                height={20}
                                width={50}
                            />
                        )}
                    </div>

                    <div className="block block-external-connections">
                        {Object.values(externalConnections)?.length
                            ? Object.values(
                                  externalConnections,
                              )?.map(
                                  (externalConnection: ExternalConnection) => (
                                      <ExternalConnectionCard
                                          key={externalConnection?.id?.toString()}
                                          active={!!externalConnection?.active}
                                          connected={
                                              !!externalConnection?.active &&
                                              externalConnection?.connection
                                                  ?.status === 'CONNECTED'
                                          }
                                          pending={
                                              !!externalConnection?.active &&
                                              externalConnection?.connection
                                                  ?.status === 'PENDING'
                                          }
                                          name={externalConnection?.name}
                                          logo={
                                              externalConnection?.logo?.sizes
                                                  ?.original
                                          }
                                          openConnection={() =>
                                              openConnection(
                                                  externalConnection?.id,
                                              )
                                          }
                                      />
                                  ),
                              )
                            : isLoading
                            ? Array.from(Array(3).keys())?.map(item => (
                                  <ExternalConnectionCard
                                      key={item?.toString()}
                                  />
                              ))
                            : null}
                    </div>
                </div>
            </div>
            <CurrenciesFooter />
        </div>
    );
};
