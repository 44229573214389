import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const Status = styled.div`
    margin-left: 7px;
    margin-top: -7px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    position: relative;
`;
