import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FiltersHeader } from './FiltersHeader';
import {
    AppHeader,
    CurrenciesFooter,
    PaginationButtons,
    TableHeader,
} from '../../components';

import {
    amountOptions,
    asc,
    desc,
    getHeaderHelper2,
    getInitials,
} from '../../services/helpers';
import { fetchReceipts } from '../../services/ReceiptsService';
import { getReceipts } from '../../state/receipts';
import { Receipt, ReceiptsState } from '../../types/types';
import { TotalAmounts } from '../Home/TotalAmounts';
import { getCountReceiptsAll } from '../../state/collectionsCount';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { getMyCurrentOrganizationId } from '../../state/statistics';

type SortField =
    | 'created_at'
    | 'company_name'
    | 'netted_amount'
    | 'entry_amount'
    | 'amount_due';

const sort = (
    data: ReceiptsState,
    order: 'ASC' | 'DESC',
    field: SortField,
): any[] => {
    const array: Receipt[] = Object.values(data);
    if (array.length) {
        array.sort((a, b) => {
            const A =
                field === 'created_at'
                    ? DateTime.fromSQL(a.created_at).valueOf()
                    : field === 'company_name'
                    ? a?.user?.company_name
                    : a[field];
            const B =
                field === 'created_at'
                    ? DateTime.fromSQL(b.created_at).valueOf()
                    : field === 'company_name'
                    ? b?.user?.company_name
                    : b[field];
            if (order === 'DESC') {
                return desc(A, B);
            }
            return asc(A, B);
        });
    }
    return array;
};

export const Receipts = () => {
    const receiptsState = useSelector(getReceipts);

    const [sortField, setSortField] = useState<SortField>('created_at');

    const [sortOrder, setSortOrder] = useState<{
        [key: string]: 'ASC' | 'DESC';
    }>({
        created_at: 'DESC',
        company_name: 'DESC',
        netted_amount: 'DESC',
        entry_amount: 'DESC',
        amount_due: 'DESC',
    });

    const setOrderMethod = useCallback(
        (value: SortField) => {
            setSortField(prevState => {
                if (prevState === value) {
                    if (sortOrder[value] === 'ASC') {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'DESC',
                        }));
                    } else {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'ASC',
                        }));
                    }
                }
                return value;
            });
        },
        [sortOrder],
    );
    const receipts: Receipt[] = useMemo(
        () => sort(receiptsState, sortOrder[sortField], sortField),
        [receiptsState, sortField, sortOrder],
    );
    const receiptsCount = useSelector(getCountReceiptsAll);

    const [query, setQuery] = useState<string>('');

    const [delayQuery, setDelayQuery] = useState<string>();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setQuery(delayQuery?.toUpperCase() || '');
        }, 350);

        return () => clearTimeout(delayDebounceFn);
    }, [delayQuery]);

    const [meta, setMeta] = useState({
        current_page: 0,
        last_page: 1,
        total: receiptsCount || 0,
    });

    const location = useLocation();

    const [isLoading, setLoading] = useState(false);

    const fetchUserReceipts = useCallback(
        async (page = 1, update?: boolean) => {
            setLoading(true);
            const receiptsResponse = await fetchReceipts(
                {
                    page,
                    q: query,
                    sortField,
                    sortOrder: sortOrder[sortField],
                },
                update,
            );
            setLoading(false);
            if (receiptsResponse?.data?.meta) {
                setMeta(receiptsResponse?.data?.meta);
            }
        },
        [query, sortField, sortOrder],
    );

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        if (currentOrganizationId) {
            (async () => {
                await fetchUserReceipts();
                await fetchCurrentOrganizationStatistics();
            })();
        }
    }, [currentOrganizationId, fetchUserReceipts]);

    const onPressNext = useCallback(async () => {
        await fetchUserReceipts(meta.current_page + 1);
    }, [fetchUserReceipts, meta.current_page]);

    const onPressPrev = useCallback(async () => {
        await fetchUserReceipts(meta.current_page - 1);
    }, [fetchUserReceipts, meta.current_page]);

    const history = useHistory();

    const openReceipt = useCallback(
        (id: number) => {
            history.push(`/transactions/receipt/${id}`);
        },
        [history],
    );

    const headerTitle = useMemo(() => {
        return getHeaderHelper2(location?.pathname);
    }, [location?.pathname]);

    const onPressMore = useCallback(async () => {
        await fetchUserReceipts(meta.current_page + 1, true);
    }, [fetchUserReceipts, meta.current_page]);

    const onPressFirst = useCallback(async () => {
        await fetchUserReceipts(1);
    }, [fetchUserReceipts]);

    const onPressLast = useCallback(async () => {
        await fetchUserReceipts(meta.last_page);
    }, [fetchUserReceipts, meta.last_page]);

    const onPressPage = useCallback(
        async (page: number) => {
            await fetchUserReceipts(page);
        },
        [fetchUserReceipts],
    );

    const count = useSelector(getCountReceiptsAll);

    return (
        <div className="app-container">
            <AppHeader title={headerTitle} />
            <div className="app-content">
                <div className="app-body">
                    <TotalAmounts />
                    <FiltersHeader
                        isLoading={isLoading && !!count}
                        query={delayQuery}
                        onQueryChange={setDelayQuery}
                    />

                    <div className="table" style={{ position: 'relative' }}>
                        <table>
                            <tbody>
                                {!!count && (
                                    <tr className="tr-overview">
                                        <TableHeader
                                            active={sortField === 'created_at'}
                                            title="Date"
                                            down={
                                                sortOrder?.created_at === 'DESC'
                                            }
                                            onPress={() =>
                                                setOrderMethod('created_at')
                                            }
                                        />
                                        <TableHeader
                                            active={
                                                sortField === 'company_name'
                                            }
                                            title="Recipient"
                                            down={
                                                sortOrder?.company_name ===
                                                'DESC'
                                            }
                                            onPress={() =>
                                                setOrderMethod('company_name')
                                            }
                                        />
                                        <TableHeader
                                            active={
                                                sortField === 'netted_amount'
                                            }
                                            title="Netted amount"
                                            down={
                                                sortOrder?.netted_amount ===
                                                'DESC'
                                            }
                                            onPress={() =>
                                                setOrderMethod('netted_amount')
                                            }
                                        />
                                        <TableHeader
                                            active={
                                                sortField === 'entry_amount'
                                            }
                                            title="Entry amount"
                                            down={
                                                sortOrder?.entry_amount ===
                                                'DESC'
                                            }
                                            onPress={() =>
                                                setOrderMethod('entry_amount')
                                            }
                                        />
                                        <TableHeader
                                            active={sortField === 'amount_due'}
                                            title="Exit amount"
                                            down={
                                                sortOrder?.amount_due === 'DESC'
                                            }
                                            onPress={() =>
                                                setOrderMethod('amount_due')
                                            }
                                        />
                                    </tr>
                                )}
                                {receipts.map(receipt => (
                                    <tr
                                        key={receipt?.id.toString()}
                                        className="tr-compact"
                                        onClick={() => openReceipt(receipt?.id)}
                                    >
                                        <td>
                                            {DateTime.fromSQL(
                                                receipt?.created_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMMM yyyy, HH:mm 'GMT'",
                                                )}
                                        </td>
                                        <td className="nowrap">
                                            <div className="table-user-compact">
                                                <div className="table-user-media">
                                                    <div className="table-user-media-abbr">
                                                        {getInitials(
                                                            receipt?.user
                                                                ?.company_name,
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="table-user-name">
                                                    {
                                                        receipt?.user
                                                            ?.company_name
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        <td className="nowrap">
                                            <div className="table-currency">
                                                <div className="table-currency-media">
                                                    <img
                                                        className="table-currency-media-image"
                                                        src={
                                                            receipt?.currency
                                                                ?.icon?.png
                                                        }
                                                        alt="currency icon"
                                                    />
                                                </div>
                                                <div className="table-currency-value">
                                                    {receipt?.netted_amount
                                                        ? receipt?.netted_amount?.toLocaleString(
                                                              'en',
                                                              amountOptions,
                                                          )
                                                        : '0.00'}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="nowrap">
                                            <div className="table-currency">
                                                <div className="table-currency-media">
                                                    <img
                                                        className="table-currency-media-image"
                                                        src={
                                                            receipt?.currency
                                                                ?.icon?.png
                                                        }
                                                        alt="currency icon"
                                                    />
                                                </div>
                                                <div className="table-currency-value">
                                                    {receipt?.entry_amount
                                                        ? receipt?.entry_amount?.toLocaleString(
                                                              'en',
                                                              amountOptions,
                                                          )
                                                        : '0.00'}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="nowrap">
                                            <div className="table-currency">
                                                <div className="table-currency-media">
                                                    <img
                                                        className="table-currency-media-image"
                                                        src={
                                                            receipt?.currency
                                                                ?.icon?.png
                                                        }
                                                        alt="currency icon"
                                                    />
                                                </div>
                                                <div className="table-currency-value">
                                                    {receipt?.amount_due
                                                        ? receipt?.amount_due?.toLocaleString(
                                                              'en',
                                                              amountOptions,
                                                          )
                                                        : '0.00'}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <PaginationButtons
                        isLoading={isLoading}
                        prevDisabled={meta?.current_page === 1 || isLoading}
                        nextDisabled={
                            meta?.current_page === meta?.last_page || isLoading
                        }
                        currentPage={meta?.current_page}
                        lastPage={meta?.last_page}
                        pagesDisabled={isLoading}
                        total={receiptsCount}
                        onPressPrev={onPressPrev}
                        onPressNext={onPressNext}
                        onPressMore={onPressMore}
                        onPressFirst={onPressFirst}
                        onPressLast={onPressLast}
                        onPressPage={onPressPage}
                    />
                </div>
            </div>
            <CurrenciesFooter />
        </div>
    );
};
