import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CurrentPlan } from './CurrentPlan';
import { CurrentPlanCards } from './CurrentPlanCards';
import {
    AppHeader,
    BlockTitle,
    ChangeBillingPlanModal,
    CurrenciesFooter,
    GroupPaymentHistoryModal,
} from '../../components';

import {
    fetchBillingPlans,
    fetchCurrentOrganizationStatistics,
    fetchMyGroups,
} from '../../services/OrganizationsService';
import {
    getAbilityEditCurrentOrganizationSettings,
    getMyOrganizationById,
} from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import {
    selectAvailableBillingPlans,
    selectCurrentBillingPlan,
} from '../../state/billingPlans';
import {
    selectCurrentGroupForCurrentOrganization,
    selectGroupsMeta,
} from '../../state/groups';

export const BillingDetails = () => {
    const { id } = useParams<{ id: string }>();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (id) {
                await fetchMyGroups();
                const response = await fetchBillingPlans();
                if (response?.status !== 200) {
                    history.push('/');
                }
            }
        })();
    }, [history, id]);

    useEffect(() => {
        (async () => {
            await fetchCurrentOrganizationStatistics();
        })();
    }, []);

    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );

    useEffect(() => {
        if (!canChangeOrganizationSettings && !!currentOrganizationId && !!id) {
            history.push(`/`);
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history, id]);

    useEffect(() => {
        if (
            !!id &&
            !!currentOrganizationId &&
            Number(id) !== currentOrganizationId
        ) {
            history.push(
                `/organization${currentOrganizationId}/billing-details`,
            );
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history, id]);

    const storedOrganization = useSelector(getMyOrganizationById(Number(id)));

    const [
        isButtonDisabled,
        // setButtonDisabled
    ] = useState(false);

    const currentBillingPlan = useSelector(selectCurrentBillingPlan);

    const [
        isChangeBillingPlanModalVisible,
        setChangeBillingPlanModalVisible,
    ] = useState(false);

    const openChangeBillingPlanModal = useCallback(() => {
        setChangeBillingPlanModalVisible(true);
    }, []);

    const availableBillingPlans = useSelector(selectAvailableBillingPlans);

    const groupsMeta = useSelector(selectGroupsMeta);

    const currentGroup = useSelector(selectCurrentGroupForCurrentOrganization);

    const [
        isGroupPaymentHistoryModalVisible,
        setGroupPaymentHistoryModalVisible,
    ] = useState(false);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                isLoading={!!isButtonDisabled}
                tabs={[
                    { name: 'Company details', link: `/organization/${id}` },
                    {
                        name: 'Billing details',
                        link: `/organization${id}/billing-details`,
                    },
                    {
                        name: `My groups (${groupsMeta?.total})`,
                        link: `/organization${id}/my-groups`,
                    },
                ]}
                title={
                    !storedOrganization?.id
                        ? 'New organization'
                        : storedOrganization?.company_name
                }
                headerSubtitles={[
                    {
                        subtitle: 'My organizations',
                        subtitleLink: `/organization/${id}`,
                    },
                ]}
            />

            <div className="app-content">
                <div className="app-body" style={{ gap: 22 }}>
                    <BlockTitle
                        title="Current plan"
                        buttons={[
                            // {
                            //     title: 'Edit billing details',
                            //     className: 'button button-default button-sm',
                            //     iconClassName: 'mdi mdi-pencil start',
                            // },
                            {
                                title: 'Upgrade',
                                className: 'button button-primary button-sm',
                                iconClassName: 'mdi mdi-cog start',
                                onPress: openChangeBillingPlanModal,
                            },
                        ]}
                        style={{ margin: 0 }}
                    />
                    <CurrentPlan
                        plan={currentBillingPlan}
                        usageAmount={currentGroup?.usage_amount}
                        openChangeBillingPlanModal={openChangeBillingPlanModal}
                    />
                    <CurrentPlanCards
                        currentPlanName={currentBillingPlan?.title}
                        nextPaymentAt={currentGroup?.next_payment_at}
                        viewPaymentHistory={currentGroup?.view_payment_history}
                        openChangeBillingPlanModal={openChangeBillingPlanModal}
                        openGroupPaymentHistory={() =>
                            setGroupPaymentHistoryModalVisible(true)
                        }
                    />
                    {/* <div
                        className="block block-title"
                        style={{ marginTop: 20 }}
                    >
                        Favorite payment method
                    </div> */}
                </div>
            </div>
            <CurrenciesFooter />
            {!!isChangeBillingPlanModalVisible && (
                <ChangeBillingPlanModal
                    isOpen={isChangeBillingPlanModalVisible}
                    currentBillingPlan={currentBillingPlan}
                    availableBillingPlans={availableBillingPlans}
                    onClose={() => {
                        setChangeBillingPlanModalVisible(false);
                    }}
                />
            )}
            {!!isGroupPaymentHistoryModalVisible && !!currentGroup?.id && (
                <GroupPaymentHistoryModal
                    groupId={currentGroup?.id}
                    isModalOpen={isGroupPaymentHistoryModalVisible}
                    onRequestClose={() =>
                        setGroupPaymentHistoryModalVisible(false)
                    }
                />
            )}
        </div>
    );
};
