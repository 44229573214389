/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import authLogo from '../../assets/img/auth-logo.svg';
import { FormError } from '../../components';
import { passwordRecovery } from '../../services/AuthService';
import { isEmail } from '../../services/helpers';

export const RestorePassword = () => {
    const [email, setEmail] = useState<string>();
    const history = useHistory();

    const [error, setError] = useState<[string]>();
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const emailChanged = useCallback((value: string) => {
        setError(undefined);
        setEmail(value);
    }, []);

    const [isRestoreEmailSent, setRestoreEmailSent] = useState(false);

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        if (!!email && !isEmail(email)) {
            allFieldsFilled = false;
            setError(['The email must be a valid email address']);
        }
        return allFieldsFilled;
    }, [email]);

    const recovery = useCallback(async () => {
        if (!error?.length && !!email) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await passwordRecovery(email);
                setButtonDisabled(false);
                if (response?.status === 200) {
                    setRestoreEmailSent(true);
                }
                if (response?.response?.data?.errors) {
                    const emailError = response?.response?.data?.errors?.email;
                    const { ...other } = response?.response?.data?.errors;

                    if (emailError) setError(emailError);

                    if (Object.keys(other).length)
                        console.log('other errors:', other);
                }
            }
        }
    }, [checkEmptyValues, email, error?.length]);

    const logIn = useCallback(() => {
        history.push('/sign-in');
    }, [history]);

    return (
        <div className="block block-auth">
            <div className="auth-content" style={{ position: 'relative' }}>
                <form className="auth-wrapper" action="">
                    <div className="auth-icon">
                        <img
                            src={authLogo}
                            alt="auth logo"
                            style={{
                                width: 120,
                                height: 60,
                                marginBottom: 20,
                            }}
                        />
                    </div>
                    <div className="auth-title">Password recovery</div>
                    <div className="auth-description">
                        {isRestoreEmailSent
                            ? 'Please check your email for further instructions'
                            : 'Enter your registration email'}
                    </div>
                    {isRestoreEmailSent ? (
                        <>
                            {/* <FurtherInstructions>
                                <FurtherInstructionsText>
                                    Please check your email for further
                                    instructions &
                                </FurtherInstructionsText>
                            </FurtherInstructions> */}
                            <button
                                type="button"
                                className="button button-lg button-fill button-primary"
                                onClick={logIn}
                            >
                                LOG IN TO YOUR ACCOUNT
                            </button>
                        </>
                    ) : (
                        <div className="form">
                            <div className="form-group">
                                <input
                                    className={`form-control form-control-lg ${
                                        !!error?.length && 'form-error'
                                    }`}
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="YOUR EMAIL"
                                    value={email || ''}
                                    onChange={e => emailChanged(e.target.value)}
                                />
                                <FormError errors={error} />
                            </div>

                            <div className="form-group">
                                <button
                                    type="button"
                                    className={`button button-lg button-fill button-dark ${
                                        !!isButtonDisabled && 'button-disabled'
                                    }`}
                                    onClick={recovery}
                                    disabled={isButtonDisabled}
                                >
                                    Send
                                </button>
                                <div style={{ height: 20 }} />
                                <button
                                    type="button"
                                    className={`button button-lg button-fill button-dark button-dark-outline ${
                                        !!isButtonDisabled && 'button-disabled'
                                    }`}
                                    disabled={isButtonDisabled}
                                    onClick={logIn}
                                >
                                    Have account? Sign in
                                </button>
                            </div>
                        </div>
                    )}
                </form>
                {!!isButtonDisabled && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Loader
                            type="MutatingDots"
                            color="#f37749"
                            visible
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            </div>
            <div className="auth-aside" />
        </div>
    );
};
