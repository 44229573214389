import { useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import {
    FilterContainer,
    InputWrapper,
    SelectComponent,
} from '../../components';
import { Chain } from '../../types/types';

type Props = {
    isLoading?: boolean;
    status?: Chain['status'][];
    onStatusChange: (value: Chain['status'][] | []) => void;
};

export const FiltersHeader = ({ isLoading, status, onStatusChange }: Props) => {
    const statuses: Chain['status'][] = useMemo(
        () => [
            'closing',
            'completed',
            'cancelled',
            'waiting for payment',
            'payment submitted',
            'expired',
        ],
        [],
    );

    const statusOptions = useMemo(
        () =>
            statuses.map(s => ({
                value: s,
                label: s,
            })),
        [statuses],
    );

    const selectedStatusesOptions = useMemo(
        () =>
            status?.map(s => ({
                value: s,
                label: s,
            })),
        [status],
    );

    const onStatusChangeMethod = useCallback(
        (
            objArr:
                | {
                      value: Chain['status'];
                      label: Chain['status'];
                  }[]
                | [],
        ) => {
            onStatusChange(objArr?.map(item => item?.value));
        },
        [onStatusChange],
    );

    return (
        <FilterContainer className="form">
            <InputWrapper>
                <SelectComponent
                    isMulti
                    isClearable
                    placeholder="Status"
                    value={selectedStatusesOptions}
                    onChange={onStatusChangeMethod}
                    options={statusOptions}
                />
            </InputWrapper>
            {!!isLoading && (
                <Loader
                    type="ThreeDots"
                    color="#f37749"
                    visible
                    height={20}
                    width={50}
                />
            )}
        </FilterContainer>
    );
};
