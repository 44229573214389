import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import stripHtml from 'remark-strip-html';
import { HeaderExternalConnection } from './HeaderExternalConnection';

import { CardHeader, CardItem, CardItemText, Icon } from './styles';
import { AppHeader, CurrenciesFooter, FormError, Row } from '../../components';
import { getExternalConnectionById } from '../../state/externalConnections';
import {
    connectExternalConnection,
    disconnectExternalConnection,
    editExternalConnection,
    fetchExternalConnection,
} from '../../services/ExternalConnectionsService';
import { ExternalConnectionField } from '../../types/types';
import { removeSnake } from '../../services/helpers';
import { getMyCurrentOrganization } from '../../state/myOrganizations';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';

export const ExternalConnection = () => {
    const { id } = useParams<{
        id: string;
    }>();
    // const [isTipVisible, setTipVisible] = useState(true);
    const currentOrganization = useSelector(getMyCurrentOrganization);
    const connection = useSelector(getExternalConnectionById(Number(id)));
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [fields, setFields] = useState(() => {
        const savedFields = {};
        connection?.fields.forEach(
            field =>
                (savedFields[field?.input] =
                    (!!connection?.connection &&
                        connection?.connection[field?.input]) ||
                    (!!field?.rules?.split('|')?.length &&
                    field?.rules?.split('|')?.length > 1 &&
                    field?.rules?.split('|')[1] === 'string'
                        ? ''
                        : false)),
        );
        return savedFields;
    });

    const title = useMemo(
        () =>
            connection?.connection?.id
                ? 'Edit external connection'
                : 'Create external connection',
        [connection?.connection?.id],
    );

    const allFields = useMemo(
        () => connection?.fields?.map(field => field?.input),
        [connection?.fields],
    );

    const requiredFields = useMemo(
        () =>
            connection?.fields
                ?.filter(
                    field =>
                        field?.rules?.split('|')?.length &&
                        !!field?.rules?.split('|')?.length &&
                        field?.rules?.split('|')[0] === 'required' &&
                        !!field?.input,
                )
                ?.map(field => field?.input),
        [connection?.fields],
    );

    useEffect(() => {
        if (currentOrganization?.id) {
            (async () => {
                setLoading(true);
                const response = await fetchExternalConnection(Number(id));
                setLoading(false);
                if (response.status !== 200) {
                    history.push('/external-connections');
                } else if (response.data?.data) {
                    setFields(() => {
                        const savedFields = {};
                        if (response.data?.data?.fields?.length) {
                            response.data?.data?.fields?.forEach(
                                (field: ExternalConnectionField) =>
                                    (savedFields[field?.input] =
                                        (!!response.data?.data?.connection &&
                                            response.data?.data?.connection[
                                                field?.input
                                            ]) ||
                                        (!!field?.rules?.split('|')?.length &&
                                        field?.rules?.split('|')?.length > 1 &&
                                        field?.rules?.split('|')[1] === 'string'
                                            ? ''
                                            : false)),
                            );
                        }
                        return savedFields;
                    });
                }
            })();
        }
    }, [currentOrganization?.id, history, id]);

    const [errors, setErrors] = useState<{ [key: string]: any }>({});
    const [otherErrors, setOtherErrors] = useState<{ [key: string]: any }>({});

    const [isFooterButtonsVisible, setFooterButtonsVisible] = useState(false);

    const fieldsChanged = useCallback(
        (key: string, value: string | boolean) => {
            setOtherErrors({});
            setFooterButtonsVisible(true);
            setFields(prevState => ({
                ...prevState,
                [key]: value,
            }));
            if (typeof value !== 'boolean' && !value) {
                const errorMessage = `The ${removeSnake(
                    key,
                )} field is required`;

                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors],
    );

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!fields?.hasOwnProperty(item) || fields[item] === '')
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [errors, fields, requiredFields]);

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const onSubmit = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = connection?.connection?.id
                    ? await editExternalConnection(Number(id), fields)
                    : await connectExternalConnection(Number(id), fields);
                setButtonDisabled(false);
                if (response?.status === 200) {
                    await fetchCurrentOrganizationStatistics();
                    history.push('/external-connections');
                }
                if (
                    response?.response?.status === 422 &&
                    response?.response?.data?.errors
                ) {
                    const allErrors = response?.response?.data?.errors;
                    const filteredErrors = Object.keys(allErrors)
                        .filter(key => allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});

                    const otherFilteredErrors = Object.keys(allErrors)
                        .filter(key => !allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});
                    setErrors(filteredErrors);
                    setOtherErrors(otherFilteredErrors);
                }
            }
        }
    }, [
        errors,
        checkEmptyValues,
        connection?.connection?.id,
        id,
        fields,
        history,
        allFields,
    ]);

    const onCancel = useCallback(() => {
        setErrors({});
        setOtherErrors({});
        history.goBack();
    }, [history]);

    const [isFirstPage, setFirstPage] = useState(true);

    const closeConnection = useCallback(async () => {
        setLoading(true);
        await disconnectExternalConnection(id);
        setLoading(false);
    }, [id]);

    const createConnection = useCallback(async () => {
        if (connection?.key !== 'forwardai' || !!connection?.active) {
            setFirstPage(false);
        } else {
            setLoading(true);
            const response = await connectExternalConnection(
                Number(connection?.id),
            );
            if (
                (response?.status === 200 || response?.status === 201) &&
                response?.data?.data?.connection?.api_url
            ) {
                setFirstPage(false);
                const win = window.open(
                    response?.data?.data?.connection?.api_url,
                    '_blank',
                );
                win?.focus();
            }
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [connection?.active, connection?.id, connection?.key]);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                title={title}
                headerSubtitles={[
                    {
                        subtitle: 'External connections',
                        subtitleLink: '/external-connections',
                    },
                ]}
                isLoading={isLoading}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-window">
                        <HeaderExternalConnection
                            name={connection?.name}
                            logo={connection?.logo?.sizes?.original}
                            organizationName={currentOrganization?.company_name}
                            status={connection?.connection?.status}
                            isHeaderButtonVisible={isFirstPage}
                            onHeaderButtonPress={createConnection}
                            disconnectPress={closeConnection}
                            disabled={isLoading}
                        />
                        {(!isFirstPage || !!connection?.content_markdown) && (
                            <div className="window-body">
                                {!!connection?.content_markdown &&
                                    isFirstPage && (
                                        <ReactMarkdown
                                            remarkPlugins={[
                                                remarkGfm,
                                                stripHtml,
                                            ]}
                                        >
                                            {connection?.content_markdown}
                                        </ReactMarkdown>
                                    )}
                                {!isFirstPage && (
                                    <div className="form">
                                        <div className="form row">
                                            <div className="col col-xs-12 col-lg-8">
                                                {connection?.fields?.map(
                                                    field => (
                                                        <div key={field?.input}>
                                                            {!!field?.rules?.split(
                                                                '|',
                                                            )?.length &&
                                                                field?.rules?.split(
                                                                    '|',
                                                                )?.length > 1 &&
                                                                (field?.rules?.split(
                                                                    '|',
                                                                )[1] ===
                                                                'string' ? (
                                                                    <div className="form-group form-group-inline">
                                                                        <div className="form-label">
                                                                            {
                                                                                field?.name
                                                                            }
                                                                        </div>
                                                                        <input
                                                                            disabled={
                                                                                field?.disabled
                                                                            }
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder={
                                                                                field?.name
                                                                            }
                                                                            value={
                                                                                fields[
                                                                                    field
                                                                                        ?.input
                                                                                ] ||
                                                                                ''
                                                                            }
                                                                            onChange={e =>
                                                                                fieldsChanged(
                                                                                    field?.input,
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group form-group-inline form-group-marginless">
                                                                        <div className="form-label">
                                                                            &nbsp;
                                                                        </div>
                                                                        <div className="form-checkbox checkbox-narrow">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={
                                                                                    field?.input
                                                                                }
                                                                                checked={
                                                                                    fields[
                                                                                        field
                                                                                            ?.input
                                                                                    ] ||
                                                                                    false
                                                                                }
                                                                                onChange={e =>
                                                                                    fieldsChanged(
                                                                                        field?.input,
                                                                                        e
                                                                                            .target
                                                                                            .checked,
                                                                                    )
                                                                                }
                                                                            />
                                                                            <label
                                                                                className="checkbox-label"
                                                                                htmlFor={
                                                                                    field?.input
                                                                                }
                                                                            >
                                                                                <div className="checkbox-label-box">
                                                                                    <div className="mdi mdi-check" />
                                                                                </div>
                                                                                <div className="checkbox-label-text">
                                                                                    {
                                                                                        field?.name
                                                                                    }
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            {!!errors[
                                                                field?.input
                                                            ]?.length && (
                                                                <FormError
                                                                    style={{
                                                                        marginTop:
                                                                            !!field?.rules?.split(
                                                                                '|',
                                                                            )
                                                                                ?.length &&
                                                                            field?.rules?.split(
                                                                                '|',
                                                                            )
                                                                                ?.length >
                                                                                1 &&
                                                                            field?.rules?.split(
                                                                                '|',
                                                                            )[1] ===
                                                                                'boolean'
                                                                                ? -15
                                                                                : -20,
                                                                        marginLeft: 140,
                                                                    }}
                                                                    errors={
                                                                        errors[
                                                                            field
                                                                                ?.input
                                                                        ]
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                        {!!Object.keys(otherErrors)?.length && (
                                            <>
                                                <div className="form-title">
                                                    {`Other error${
                                                        Object.keys(otherErrors)
                                                            ?.length > 1
                                                            ? 's:'
                                                            : ':'
                                                    }`}
                                                </div>
                                                {Object.keys(otherErrors)?.map(
                                                    otherErrorKey => (
                                                        <FormError
                                                            key={otherErrorKey}
                                                            errors={
                                                                otherErrors[
                                                                    otherErrorKey
                                                                ]
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {isFirstPage && (
                        <Row
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'stretch',
                            }}
                        >
                            <div className="block block-window column">
                                <div className="window-card">
                                    <CardHeader>
                                        Import information from the accounting
                                        software
                                    </CardHeader>
                                    <CardItem>
                                        <CardItemText>
                                            Importing customers
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={
                                                connection?.importing_customers
                                            }
                                            className={`mdi mdi-${
                                                connection?.importing_customers
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem>
                                        <CardItemText>
                                            Importing vendors
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={
                                                connection?.importing_vendors
                                            }
                                            className={`mdi mdi-${
                                                connection?.importing_vendors
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem>
                                        <CardItemText>
                                            Importing invoices
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={
                                                connection?.importing_invoices
                                            }
                                            className={`mdi mdi-${
                                                connection?.importing_invoices
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem>
                                        <CardItemText>
                                            Importing bills
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={connection?.importing_bills}
                                            className={`mdi mdi-${
                                                connection?.importing_bills
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem withoutBorder>
                                        <CardItemText>
                                            Importing and syncing payments
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={
                                                connection?.importing_syncing_payments
                                            }
                                            className={`mdi mdi-${
                                                connection?.importing_syncing_payments
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                </div>
                            </div>
                            <div style={{ width: 20 }} />
                            <div className="block block-window column">
                                <div className="window-card">
                                    <CardHeader>
                                        Posting information to the accounting
                                        software
                                    </CardHeader>
                                    <CardItem>
                                        <CardItemText>
                                            Posting new created customers
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={
                                                connection?.created_customers
                                            }
                                            className={`mdi mdi-${
                                                connection?.created_customers
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem>
                                        <CardItemText>
                                            Posting new created vendors
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={connection?.created_vendors}
                                            className={`mdi mdi-${
                                                connection?.created_vendors
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem>
                                        <CardItemText>
                                            Posting new created invoices
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={
                                                connection?.created_invoices
                                            }
                                            className={`mdi mdi-${
                                                connection?.created_invoices
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem>
                                        <CardItemText>
                                            Posting new created bills
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={connection?.created_bills}
                                            className={`mdi mdi-${
                                                connection?.created_bills
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                    <CardItem withoutBorder>
                                        <CardItemText>
                                            Posting payment from netting deals
                                        </CardItemText>
                                        <Icon
                                            type="post"
                                            active={
                                                connection?.payment_netting_deals
                                            }
                                            className={`mdi mdi-${
                                                connection?.payment_netting_deals
                                                    ? 'check'
                                                    : 'close'
                                            }`}
                                        />
                                    </CardItem>
                                </div>
                            </div>
                        </Row>
                    )}

                    {/* {isTipVisible && <Tip setTipVisible={setTipVisible} />} */}
                    {isFooterButtonsVisible && (
                        <div className="form-actions">
                            <div className="button-group">
                                <button
                                    type="button"
                                    className="button button-primary button-sm"
                                    disabled={isButtonDisabled}
                                    onClick={onSubmit}
                                >
                                    <em className="mdi mdi-check start" />
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="button button-default button-primary button-sm"
                                    disabled={isButtonDisabled}
                                    onClick={onCancel}
                                >
                                    <em className="mdi mdi-close start" />
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
        </div>
    );
};
