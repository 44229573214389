import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api from './axiosConfig';
import { countReportsAllSet } from '../state/collectionsCount';
import { reportsSet, reportsUpdate, reportUpdate } from '../state/reports';
import { store } from '../state/store';

export const fetchReports = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api('/reports', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 12,
            ...params,
        },
    });
    if (response?.status === 200 && !!response?.data?.data) {
        store.dispatch(countReportsAllSet(response.data?.meta?.total));
        if (!update) {
            store.dispatch(reportsSet(response.data.data));
        } else {
            store.dispatch(reportsUpdate(response.data?.data));
        }
    }
    return response;
};

export const fetchReport: Promise<AxiosResponse<any>> | any = async (
    id: number,
) => {
    const response = await api(`/reports/${id}`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(reportUpdate(response.data?.data));
    }
    return response;
};

export const acceptReport = async (id: number) => {
    const response = await api.post(`/reports/${id}/accept`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(reportUpdate(response.data?.data));
    }
    return response;
};

export const rejectReport = async (id: number) => {
    const response = await api.post(`/reports/${id}/reject`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(reportUpdate(response.data?.data));
    }
    return response;
};
