import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppHeader, CurrenciesFooter, ReportCard } from '../../components';
import {
    acceptCircle,
    fetchCircle,
    rejectCircle,
} from '../../services/CirclesService';
import { amountOptions, getInitials } from '../../services/helpers';
import { getCircleById } from '../../state/circles';
import { Organization, Report } from '../../types/types';
import buildingsIcon from '../../assets/img/icons/building.svg';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { getMyCurrentOrganizationId } from '../../state/statistics';

export const Circle = () => {
    const { id } = useParams<{ id: string }>();
    const circle = useSelector(getCircleById(Number(id)));
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                setLoading(true);
                const response = await fetchCircle(Number(id));
                setLoading(false);
                if (response.status !== 200) {
                    history.push('/circles/all');
                }
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, history, id]);

    // const [isDetailsVisible, setDetailsVisible] = useState(true);

    // const onClickDetails = useCallback(() => {
    //     setDetailsVisible(prevState => !prevState);
    // }, []);

    const accept = useCallback(async () => {
        if (!isLoading) {
            setLoading(true);
            await acceptCircle(circle?.id);
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [circle?.id, isLoading]);

    const reject = useCallback(async () => {
        if (!isLoading) {
            setLoading(true);
            await rejectCircle(circle?.id);
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [circle?.id, isLoading]);

    const openReport = useCallback(
        (reportId: number) => {
            history.push(`/reports/report/${reportId}`);
        },
        [history],
    );

    const wholeOrganizationsCount = useMemo(
        () =>
            circle?.reports?.length
                ? circle?.reports[0]?.users_count
                : circle?.organizations?.length,
        [circle?.organizations?.length, circle?.reports],
    );

    const hiddenOrganizations = useMemo(() => {
        if (
            wholeOrganizationsCount &&
            circle?.organizations?.length &&
            wholeOrganizationsCount > circle?.organizations?.length
        ) {
            const array = new Array(
                wholeOrganizationsCount - circle?.organizations?.length,
            );
            return array.fill(0);
        }
        return [];
    }, [circle?.organizations?.length, wholeOrganizationsCount]);

    return (
        <div className="app-container">
            <AppHeader
                title={`${
                    circle?.type === 'public' ? 'Public' : 'Private'
                } Circle #${circle?.name}`}
                acceptButton={circle?.can_accept_or_reject}
                rejectButton={circle?.can_accept_or_reject}
                isLoading={isLoading}
                isButtonDisabled={isLoading}
                onAccept={accept}
                onReject={reject}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-payment">
                        <div className="payment-overview">
                            <div className="payment-title">
                                Circle &nbsp;
                                <strong>{circle?.name || ''}</strong>
                                &nbsp;&nbsp;
                                {!!circle?.status && (
                                    <div
                                        className={`label ${
                                            circle?.status === 'completed'
                                                ? 'label-success'
                                                : circle?.status === 'cancelled'
                                                ? 'label-warning'
                                                : circle?.status === 'closing'
                                                ? ''
                                                : circle?.status === 'expired'
                                                ? 'label-danger-light'
                                                : 'label-primary-light'
                                        }`}
                                    >
                                        {circle?.status}
                                    </div>
                                )}
                            </div>
                            <div className="payment-value">
                                {!!circle?.netted_amount_currency?.icon
                                    ?.svg && (
                                    <div className="payment-currency">
                                        <img
                                            src={
                                                circle?.netted_amount_currency
                                                    ?.icon?.svg
                                            }
                                            alt="netted currency"
                                        />
                                    </div>
                                )}
                                {(!!circle?.netted_amount ||
                                    circle?.netted_amount === 0) && (
                                    <div className="payment-amount">
                                        {circle?.netted_amount?.toLocaleString(
                                            'en',
                                            amountOptions,
                                        )}{' '}
                                        <small>netted amount</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="block block-payment">
                        <div className="payment-details">
                            {/* <div className="payment-details-heading">
                                <div className="payment-details-title">
                                    Transaction details
                                </div>
                                <button
                                    type="button"
                                    style={{
                                        borderWidth: 0,
                                        background: 'transparent',
                                    }}
                                    className="payment-details-toggle"
                                    onClick={onClickDetails}
                                >
                                    Hide details
                                    <em className="mdi mdi-chevron-down" />
                                </button>
                            </div> */}
                            {/* {!!isDetailsVisible && ( */}
                            <div className="payment-details-list">
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Created date
                                    </div>
                                    {!!circle?.created_at && (
                                        <div className="payment-details-value">
                                            {DateTime.fromSQL(
                                                circle?.created_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMMM yyyy, HH:mm 'GMT'",
                                                )}
                                        </div>
                                    )}
                                </div>
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Accepted on
                                    </div>
                                    {!!circle?.updated_at && (
                                        <div className="payment-details-value">
                                            {DateTime.fromSQL(
                                                circle?.updated_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMMM yyyy, HH:mm 'GMT'",
                                                )}
                                        </div>
                                    )}
                                </div>
                                {(!!circle?.netted_amount ||
                                    circle?.netted_amount === 0) && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Netted amount
                                        </div>
                                        <div className="payment-details-value">
                                            {`${circle?.netted_amount?.toLocaleString(
                                                'en',
                                                amountOptions,
                                            )} ${
                                                circle?.netted_amount_currency
                                                    ?.code
                                            }`}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                    {circle?.reports?.map((report: Report) => (
                        <ReportCard
                            key={report?.id.toString()}
                            report={report}
                            openReport={() => openReport(report?.id)}
                        />
                    ))}
                    {!!circle?.organizations?.length && (
                        <div className="block block-title">
                            {`${wholeOrganizationsCount} ${
                                wholeOrganizationsCount === 1
                                    ? 'member'
                                    : 'members'
                            }`}
                            {/* <div className="block-title-actions">
                                <button
                                    type="button"
                                    className="button button-default button-sm"
                                >
                                    <em className="mdi mdi-plus start primary" />
                                    Invite new member
                                </button>
                            </div> */}
                        </div>
                    )}
                    {!!circle?.organizations?.length && (
                        <div className="block block-users">
                            {circle?.organizations?.map(
                                (organization: Organization) => (
                                    <div
                                        key={organization?.id}
                                        className="user"
                                    >
                                        <div className="user-media">
                                            {organization?.logo?.sizes
                                                ?.thumbnail ? (
                                                <img
                                                    className="user-media-image"
                                                    src={
                                                        organization?.logo
                                                            ?.sizes?.thumbnail
                                                    }
                                                    alt="user avatar"
                                                />
                                            ) : (
                                                <div className="user-media-abbr">
                                                    {organization?.company_name &&
                                                        getInitials(
                                                            organization?.company_name,
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="user-details">
                                            <div className="user-name">
                                                {organization?.company_name}
                                            </div>
                                        </div>
                                    </div>
                                ),
                            )}
                            {hiddenOrganizations?.map((item, index) => (
                                <div key={index?.toString()} className="user">
                                    <div className="user-media">
                                        <div className="user-media-container">
                                            <img
                                                className="user-media-image-in-container"
                                                src={buildingsIcon}
                                                alt="user avatar"
                                            />
                                        </div>
                                        {/* )} */}
                                    </div>
                                    <div className="user-details">
                                        <div className="user-name italic">
                                            Hidden name
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
        </div>
    );
};
