import { RedLine } from '../../components';

type Props = {
    id?: number;
    disabled?: boolean;
    checked?: boolean;
    redLine?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export const TdCheckBox = ({
    id,
    disabled,
    checked,
    redLine,
    onChange,
}: Props) => {
    return (
        <td style={{ paddingRight: 0 }}>
            <div
                className="form-checkbox"
                style={{
                    position: 'relative',
                }}
            >
                <input
                    type="checkbox"
                    id={id?.toString()}
                    name={id?.toString()}
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                />

                {redLine && <RedLine top={-8} />}
            </div>
        </td>
    );
};
