import api from './axiosConfig';

export const uploadFile = async (
    file: File,
    type?:
        | 'signature'
        | 'current_balance_sheet_file'
        | 'current_income_statement_file'
        | 'current_ar_aging_file'
        | 'current_ap_aging_file'
        | 'guarantors_id_copy_file'
        | 'other_items_file'
        | 'transaction_file'
        | 'organization_file',
) => {
    const formData = new FormData();
    formData.append('file', file);
    if (type) {
        formData.append('type', type);
    }
    return await api.post('/files', formData, {
        timeout: 0,
    });
};

export const deleteDraftContractFile = async (uid: string) => {
    if (uid) {
        return await api.delete(`/files/${uid}`);
    }
    return { status: 404 };
};
