import { useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import iconSearch from '../../../assets/img/icons/icon-search.svg';
import {
    FilterContainer,
    InputWrapper,
    SearchIcon,
    SelectComponent,
} from '../../../components';
import { TGroup } from '../../../types/types';

type Props = {
    isLoading?: boolean;
    query?: string;
    status?: TGroup['status'][];
    onQueryChange?: (value: string) => void;
    onStatusChange: (value: TGroup['status'][] | []) => void;
};

export const FiltersHeader = ({
    isLoading,
    query,
    status,
    onQueryChange,
    onStatusChange,
}: Props) => {
    const statuses: TGroup['status'][] = useMemo(
        () => ['active', 'rejected', 'invited'],
        [],
    );

    const statusOptions = useMemo(
        () =>
            statuses?.map(s => ({
                value: s,
                label: s,
            })),
        [statuses],
    );
    const selectedStatusesOptions = useMemo(
        () =>
            status?.map(s => ({
                value: s,
                label: s,
            })),
        [status],
    );
    const onStatusChangeMethod = useCallback(
        (
            objArr:
                | {
                      value: TGroup['status'];
                      label: TGroup['status'];
                  }[]
                | [],
        ) => {
            onStatusChange(objArr?.map(item => item?.value));
        },
        [onStatusChange],
    );

    return (
        <FilterContainer className="form">
            {!!onQueryChange && (
                <InputWrapper>
                    <div className="form-control-wrapper">
                        <SearchIcon src={iconSearch} />
                        <input
                            id="query"
                            className="form-control"
                            placeholder="Search"
                            value={query || ''}
                            onChange={e => onQueryChange(e.target.value)}
                            style={{
                                paddingLeft: 29,
                                paddingRight: 29,
                                width: 187,
                            }}
                        />
                        <div className="form-control-info">
                            <em className="mdi mdi-information form-control-info-icon" />
                            <div className="form-control-info-container form-control-info-container-dim">
                                Search by organization name, organization email
                            </div>
                        </div>
                    </div>
                </InputWrapper>
            )}
            <InputWrapper>
                <SelectComponent
                    isMulti
                    isClearable
                    placeholder="Group status"
                    value={selectedStatusesOptions}
                    onChange={onStatusChangeMethod}
                    options={statusOptions}
                />
            </InputWrapper>
            {isLoading && (
                <div style={{ marginLeft: 30 }}>
                    <Loader
                        type="ThreeDots"
                        color="#f37749"
                        visible
                        height={20}
                        width={50}
                    />
                </div>
            )}
        </FilterContainer>
    );
};
