import styled from 'styled-components';

export const PayMethodContainer = styled.div<{ isSingle?: boolean }>`
    /* position: absolute;
    z-index: 2;
    left: 100%;
    top: 50%;
    margin-top: 20px;
    margin-left: -200px; */
    width: 180px;
    height: ${({ isSingle }) => (isSingle ? 48 : 96)}px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;

    box-shadow: 0px 10px 20px rgba(83, 83, 119, 0.149);
    border-radius: 5px;
`;

export const Mdi = styled.em`
    display: flex;
    font-size: 18px;
    line-height: inherit;

    margin-right: 5px;
    margin-left: -2.5px;
    float: left;

    color: #9b9b9b;

    margin-bottom: 2px;
`;

export const PayMethodButton = styled.div<{ creditCard?: boolean }>`
    position: static;
    width: 100%;
    height: 48px;
    border-top-left-radius: ${({ creditCard }) => (creditCard ? 5 : 0)}px;
    border-top-right-radius: ${({ creditCard }) => (creditCard ? 5 : 0)}px;
    border-bottom-left-radius: ${({ creditCard }) => (!creditCard ? 5 : 0)}px;
    border-bottom-right-radius: ${({ creditCard }) => (!creditCard ? 5 : 0)}px;
    padding-left: 16px;
    display: flex;
    align-items: center;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;

    color: #000000;

    background: #fbfbfb;
    &:hover {
        cursor: pointer;
        background-color: #ffffff;
        color: #f37749;
    }

    &:hover ${Mdi} {
        color: #f37749;
    }

    box-shadow: inset 0px -1px 0px #e1e1e1;
`;
