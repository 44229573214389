import { ActionMeta } from 'react-select';
import { FormError, SelectComponent } from '../../components';
import { getInitials } from '../../services/helpers';

type Props = {
    firstName?: string;
    onFirstNameChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    lastName?: string;
    onLastNameChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    email?: string;
    onEmailChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    permissionsOptions?: {
        value: string;
        label: string;
    }[];
    selectedPermissions?: {
        value: string;
        label: string;
    }[];
    onDelete?: () => void;
    onPermissionsChange?:
        | (((value: any, actionMeta: ActionMeta<any>) => void) &
              ((value: any, action: ActionMeta<any>) => void))
        | undefined;
    isDeleteButtonHide?: boolean;
    errors?: { [key: string]: any };
    success: boolean;
};

export const InviteItem = ({
    firstName,
    onFirstNameChange,
    lastName,
    onLastNameChange,
    email,
    onEmailChange,
    permissionsOptions,
    onDelete,
    selectedPermissions,
    onPermissionsChange,
    isDeleteButtonHide = false,
    errors,
    success,
}: Props) => {
    return !success ? (
        <div className="invite-item">
            <div className="invite-item-cell">
                <input
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={onFirstNameChange}
                />
                <FormError errors={errors?.first_name} />
            </div>
            <div className="invite-item-cell">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={onLastNameChange}
                />
                <FormError errors={errors?.last_name} />
            </div>
            <div className="invite-item-cell">
                <input
                    className="form-control"
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={onEmailChange}
                />
                <FormError errors={errors?.email} />
            </div>

            <div className="invite-item-cell invite-last">
                <SelectComponent
                    isClearable
                    isMulti
                    placeholder="Select permissions"
                    value={selectedPermissions}
                    onChange={onPermissionsChange}
                    options={permissionsOptions as any}
                />
                <FormError errors={errors?.permissions} />
            </div>
            {!isDeleteButtonHide && (
                <div className="invite-item-action">
                    <button
                        type="button"
                        className="button button-default button-icon"
                        onClick={onDelete}
                    >
                        <em className="mdi mdi-close" />
                    </button>
                </div>
            )}
        </div>
    ) : (
        <div className="invite-item">
            <div className="invite-item-cell">
                <div
                    className="block block-users"
                    style={{
                        padding: 0,
                        border: 0,
                        margin: 0,
                        boxShadow: 'none',
                        background: 'transparent',
                    }}
                >
                    <div className="user" style={{ padding: 0, border: 0 }}>
                        <div className="user-media">
                            {!!firstName && !!lastName && (
                                <div className="user-media-abbr">
                                    {getInitials(
                                        `${firstName} ${lastName}` as string,
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="user-details">
                            <div className="user-name">{`${firstName} ${lastName}`}</div>
                            <div className="user-email">{email}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
