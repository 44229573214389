import { FactoringButton, FactoringButtonsContainer } from './styles';
import { Organization } from '../../../types/types';

type Props = {
    onPress: (id: Organization['id']) => void;
    organizations?: Organization[];
};

export const FactoringButtons = ({ onPress, organizations }: Props) => {
    return (
        <FactoringButtonsContainer>
            {organizations?.map((organization, index) => (
                <FactoringButton
                    key={organization?.id?.toString()}
                    first={index === 0}
                    last={index === organizations?.length - 1}
                    role="button"
                    tabIndex={index}
                    onClick={() => onPress(organization?.id)}
                    onKeyDown={() => onPress(organization?.id)}
                >
                    {organization?.company_name}
                </FactoringButton>
            ))}
        </FactoringButtonsContainer>
    );
};
