import { NotConnected, NotConnectedContainer } from './styles';
import { Column } from '../../components';

type Props = {
    name: string;
    logo: string;
    organizationName?: string;
    status?: 'PENDING' | 'CONNECTED';
    isHeaderButtonVisible?: boolean;
    disabled?: boolean;
    onHeaderButtonPress?: () => void;
    disconnectPress?: () => Promise<void> | undefined;
};

export const HeaderExternalConnection = ({
    name,
    logo,
    organizationName,
    status,
    isHeaderButtonVisible,
    disabled,
    onHeaderButtonPress,
    disconnectPress,
}: Props) => {
    return (
        <div className="window-header">
            <img className="window-header-logo" src={logo} alt={name} />
            <Column>
                {status ? organizationName : name}
                {(status === 'PENDING' || !status) && (
                    <NotConnectedContainer status={status}>
                        <NotConnected>{status || 'Not connected'}</NotConnected>
                    </NotConnectedContainer>
                )}
            </Column>
            {isHeaderButtonVisible && !!status && (
                <button
                    type="button"
                    className="button button-default button-sm"
                    style={{ position: 'absolute', right: 105 }}
                    onClick={disconnectPress}
                    disabled={disabled}
                >
                    <em className="mdi mdi-close start" />
                    Disconnect
                </button>
            )}
            {isHeaderButtonVisible && (
                <button
                    type="button"
                    className="button button-primary button-sm"
                    style={{ position: 'absolute', right: 25 }}
                    onClick={onHeaderButtonPress}
                    disabled={disabled}
                >
                    <em
                        className={`mdi mdi-${
                            status ? 'pencil' : 'plus'
                        } start`}
                    />
                    {status ? 'Edit' : 'Connect'}
                </button>
            )}
        </div>
    );
};
