import api from './axiosConfig';

export const uploadMedia = async (
    file: File,
    type?: 'avatar' | 'logo' | 'logo_external' | 'signature',
) => {
    const formData = new FormData();
    formData.append('file', file);
    if (type) {
        formData.append('type', type);
    }
    return await api.post('/medias', formData, {
        timeout: 0,
    });
};

export const deleteMedia = async (uid?: string) => {
    if (uid) {
        return await api.delete(`/medias/${uid}`);
    }
    return { status: 404 };
};
