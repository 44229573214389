import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { HeaderFactoringConnection } from './HeaderFactoringConnection';
import {
    DefaultCurrencyIcon,
    TotalHeader,
    TotalIcon,
    TotalValue,
} from './styles';
import { ClientForm } from './ClientForm';
import { AppHeader, CurrenciesFooter, Row } from '../../components';
import {
    connectFactoringOrganization,
    disconnectFactoringOrganization,
    fetchCurrentOrganizationStatistics,
    fetchFactoringOrganization,
} from '../../services/OrganizationsService';
import { getFactoringOrganizationById } from '../../state/organizationsFactoring';
import invoiceIcon from '../../assets/img/icons/invoice.svg';
import handShakeIcon from '../../assets/img/icons/icon-handshake.svg';
import { getDefaultCurrency } from '../../state/user';
import { getMyCurrentOrganization } from '../../state/myOrganizations';
import {
    ClientOrganizationOwner,
    ConnectOrganizationProps,
} from '../../types/types';
import { getCountryById } from '../../state/countries';
import { getPhoneString, isEmail, removeSnake } from '../../services/helpers';
import { uploadFile } from '../../services/FilesService';
import { uploadMedia } from '../../services/MediaService';

const ownerFields = [
    'first_name',
    'last_name',
    'phone',
    'email',
    'address',
    'postal_code',
    'country_code',
    'city',
    'state',
    'social_security_number',
    'title',
    'ownership',
];

const allFields = [
    'company_name',
    'trade_name',
    'tax_id',
    'primary_address',
    'primary_postal_code',
    'primary_city',
    'primary_country_code',
    'primary_state',
    'industry',
    'primary_first_name',
    'primary_last_name',
    'primary_phone',
    'primary_email',
    // 'owners',
    'current_balance_sheet_files',
    'current_income_statement_files',
    'current_ar_aging_files',
    'current_ap_aging_files',
    'guarantors_id_copy_files',
    'other_items_files',
];

const requiredFields = [
    'company_name',
    'tax_id',
    'primary_address',
    'primary_postal_code',
    'primary_city',
    'primary_country_code',
    'primary_state',
    'industry',
    'primary_first_name',
    'primary_last_name',
    'primary_phone',
    'primary_email',
    'date',
    // 'owners',
];

export const Factoring = () => {
    const { id } = useParams<{
        id: string;
    }>();
    // const [isTipVisible, setTipVisible] = useState(true);
    const [isFormVisible, setFormVisible] = useState(false);
    const currentOrganization = useSelector(
        getFactoringOrganizationById(Number(id)),
    );
    const myCurrentOrganization = useSelector(getMyCurrentOrganization);

    const [isLoading, setLoading] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const history = useHistory();

    const storedCountry = useSelector(
        getCountryById(myCurrentOrganization?.country_id),
    );

    useEffect(() => {
        if (currentOrganization?.id && myCurrentOrganization?.id) {
            (async () => {
                setButtonDisabled(true);
                const response = await fetchFactoringOrganization(Number(id));
                setButtonDisabled(false);
                if (response.status !== 200) {
                    history.push('/factoring-organizations');
                }
                setOrganization({
                    company_name: myCurrentOrganization?.company_name,
                    primary_address: myCurrentOrganization?.address,
                    primary_city: myCurrentOrganization?.city,
                    primary_postal_code: myCurrentOrganization?.postal_code,
                    primary_country_code: storedCountry?.iso_3166_2,
                    primary_state: myCurrentOrganization?.state,
                    owners: [
                        {
                            first_name: '',
                            last_name: '',
                            phone: '',
                            email: '',
                            postal_code: '',
                            address: '',
                            country_code: '',
                            city: '',
                            state: '',
                            social_security_number: '',
                            title: '',
                            ownership: 0,
                        },
                    ],
                });
            })();
        }
    }, [
        currentOrganization?.id,
        history,
        id,
        myCurrentOrganization?.address,
        myCurrentOrganization?.city,
        myCurrentOrganization?.company_name,
        myCurrentOrganization?.id,
        myCurrentOrganization?.postal_code,
        myCurrentOrganization?.state,
        storedCountry?.iso_3166_2,
    ]);

    const onSubmit = useCallback(async () => {
        if (
            currentOrganization?.organization_request_connection?.status ===
                'Active' ||
            currentOrganization?.organization_request_connection?.status ===
                'Pending'
        ) {
            setButtonDisabled(true);
            setLoading(true);
            await disconnectFactoringOrganization(Number(id));
            setButtonDisabled(false);
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        } else if (
            currentOrganization?.organization_request_connection?.status ===
                'Disconnected by factor' ||
            currentOrganization?.organization_request_connection?.status ===
                'Disconnected by regular' ||
            currentOrganization?.organization_request_connection?.status ===
                'Rejected' ||
            !currentOrganization?.organization_request_connection?.status
        ) {
            // setTipVisible(false);
            setFormVisible(true);
        }
    }, [currentOrganization?.organization_request_connection?.status, id]);

    const [organization, setOrganization] = useState<
        Partial<ConnectOrganizationProps>
    >({
        company_name: myCurrentOrganization?.company_name,
        primary_address: myCurrentOrganization?.address,
        primary_city: myCurrentOrganization?.city,
        primary_postal_code: myCurrentOrganization?.postal_code,
        primary_country_code: storedCountry?.country_code,
        primary_state: myCurrentOrganization?.state,
        owners: [
            {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                postal_code: '',
                address: '',
                country_code: '',
                city: '',
                state: '',
                social_security_number: '',
                title: '',
                ownership: 0,
            },
        ],
    });

    const [errors, setErrors] = useState<{ [key: string]: any }>({});
    const [otherErrors, setOtherErrors] = useState<{ [key: string]: any }>({});

    const organizationChanged = useCallback(
        (
            key: keyof ConnectOrganizationProps,
            value: string | number | ClientOrganizationOwner[],
            key2?: keyof ClientOrganizationOwner,
            index?: number,
        ) => {
            setOtherErrors({});
            setOrganization(prevState => {
                const owners = prevState.owners?.length
                    ? [...prevState?.owners]
                    : [];
                if (!!key2 && (!!index || index === 0)) {
                    owners[index] = {
                        ...owners[index],
                        [key2]: value,
                    };
                }
                return {
                    ...prevState,
                    [key]:
                        key === 'primary_phone'
                            ? getPhoneString(value as string)
                            : key === 'owners'
                            ? owners
                            : value,
                };
            });
            if (
                (!value && value !== 0 && requiredFields?.includes(key)) ||
                (key === 'primary_email' &&
                    !isEmail(value as string) &&
                    !!value) ||
                (key === 'primary_phone' &&
                    !!value &&
                    !parsePhoneNumberFromString(value as string)?.isPossible())
            ) {
                const errorMessage =
                    key === 'primary_email'
                        ? 'The email must be a valid email address'
                        : key === 'primary_phone'
                        ? 'The phone must be a valid and possible phone number'
                        : `The ${removeSnake(key)} field is required`;
                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors],
    );

    const [termsAccepted, setTermsAccepted] = useState(false);

    const [signature, setSignature] = useState<File | undefined>();

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!organization?.hasOwnProperty(item) ||
                    organization[item] === '')
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        ownerFields.forEach(item =>
            organization?.owners?.forEach((owners, ownerIndex) => {
                if (!owners.hasOwnProperty(item) || owners[item] === '') {
                    allFieldsFilled = false;

                    const errorMessage = `The ${removeSnake(
                        item,
                    )} field is required`;
                    setErrors(prevState => {
                        return {
                            [`owners.${ownerIndex}.${item}`]: [errorMessage],
                            ...prevState,
                        };
                    });
                }
            }),
        );
        if (!termsAccepted) {
            allFieldsFilled = false;
            setErrors(prevStateErrors => {
                return {
                    ...prevStateErrors,
                    terms: ['Please accept terms and conditions'],
                };
            });
        }
        if (!signature?.name) {
            allFieldsFilled = false;
            setErrors(prevStateErrors => {
                return {
                    ...prevStateErrors,
                    signature_uid: ['Signature is required'],
                };
            });
        }
        return allFieldsFilled;
    }, [errors, organization, signature, termsAccepted]);

    const [ballanceFiles, setBallanceFiles] = useState<any[]>([]);
    const [incomeFiles, setIncomeFiles] = useState<any[]>([]);
    const [arFiles, setArFiles] = useState<any[]>([]);
    const [apFiles, setApFiles] = useState<any[]>([]);
    const [idFiles, setIdFiles] = useState<any[]>([]);
    const [otherFiles, setOtherFiles] = useState<any[]>([]);

    const onConnect = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                setLoading(true);
                const current_balance_sheet_files: string[] = [];
                const current_income_statement_files: string[] = [];
                const current_ar_aging_files: string[] = [];
                const current_ap_aging_files: string[] = [];
                const guarantors_id_copy_files: string[] = [];
                const other_items_files: string[] = [];
                const signature_uid: string[] = [];
                if (signature?.name) {
                    const uploadNewFile = await uploadMedia(
                        signature,
                        'signature',
                    );
                    if (
                        uploadNewFile?.status === 201 &&
                        uploadNewFile?.data?.data?.uid
                    ) {
                        signature_uid.push(uploadNewFile?.data?.data?.uid);
                    } else {
                        alert(
                            'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                        );
                    }
                }
                if (ballanceFiles?.length) {
                    await Promise.all(
                        ballanceFiles?.map(async item => {
                            const uploadNewFile = await uploadFile(
                                item,
                                'current_balance_sheet_file',
                            );
                            if (
                                uploadNewFile?.status === 201 &&
                                uploadNewFile?.data?.data?.uid
                            ) {
                                current_balance_sheet_files.push(
                                    uploadNewFile?.data?.data?.uid,
                                );
                            } else {
                                alert(
                                    'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                                );
                            }
                        }),
                    );
                }
                if (incomeFiles?.length) {
                    await Promise.all(
                        incomeFiles?.map(async item => {
                            const uploadNewFile = await uploadFile(
                                item,
                                'current_income_statement_file',
                            );
                            if (
                                uploadNewFile?.status === 201 &&
                                uploadNewFile?.data?.data?.uid
                            ) {
                                current_income_statement_files.push(
                                    uploadNewFile?.data?.data?.uid,
                                );
                            } else {
                                alert(
                                    'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                                );
                            }
                        }),
                    );
                }
                if (arFiles?.length) {
                    await Promise.all(
                        arFiles?.map(async item => {
                            const uploadNewFile = await uploadFile(
                                item,
                                'current_ar_aging_file',
                            );
                            if (
                                uploadNewFile?.status === 201 &&
                                uploadNewFile?.data?.data?.uid
                            ) {
                                current_ar_aging_files.push(
                                    uploadNewFile?.data?.data?.uid,
                                );
                            } else {
                                alert(
                                    'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                                );
                            }
                        }),
                    );
                }
                if (apFiles?.length) {
                    await Promise.all(
                        apFiles?.map(async item => {
                            const uploadNewFile = await uploadFile(
                                item,
                                'current_ap_aging_file',
                            );
                            if (
                                uploadNewFile?.status === 201 &&
                                uploadNewFile?.data?.data?.uid
                            ) {
                                current_ap_aging_files.push(
                                    uploadNewFile?.data?.data?.uid,
                                );
                            } else {
                                alert(
                                    'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                                );
                            }
                        }),
                    );
                }
                if (idFiles?.length) {
                    await Promise.all(
                        idFiles?.map(async item => {
                            const formData = new FormData();
                            formData.append('file', item);
                            formData.append('type', 'guarantors_id_copy_file');
                            const uploadNewFile = await uploadFile(
                                item,
                                'guarantors_id_copy_file',
                            );
                            if (
                                uploadNewFile?.status === 201 &&
                                uploadNewFile?.data?.data?.uid
                            ) {
                                guarantors_id_copy_files.push(
                                    uploadNewFile?.data?.data?.uid,
                                );
                            } else {
                                alert(
                                    'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                                );
                            }
                        }),
                    );
                }
                if (otherFiles?.length) {
                    await Promise.all(
                        otherFiles?.map(async item => {
                            const uploadNewFile = await uploadFile(
                                item,
                                'other_items_file',
                            );
                            if (
                                uploadNewFile?.status === 201 &&
                                uploadNewFile?.data?.data?.uid
                            ) {
                                other_items_files.push(
                                    uploadNewFile?.data?.data?.uid,
                                );
                            } else {
                                alert(
                                    'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                                );
                            }
                        }),
                    );
                }
                const response = await connectFactoringOrganization(
                    Number(id),
                    {
                        ...organization,
                        current_balance_sheet_files,
                        current_income_statement_files,
                        current_ar_aging_files,
                        current_ap_aging_files,
                        guarantors_id_copy_files,
                        other_items_files,
                        signature_uid,
                    } as ConnectOrganizationProps,
                );
                setButtonDisabled(false);
                setLoading(false);

                if (response?.status === 201 || response?.status === 200) {
                    setFormVisible(false);
                    await fetchCurrentOrganizationStatistics();
                }
                if (
                    response?.response?.status === 422 &&
                    response?.response?.data?.errors
                ) {
                    const allErrors = response?.response?.data?.errors;
                    const filteredErrors = Object.keys(allErrors)
                        .filter(
                            key =>
                                allFields.includes(key) ||
                                (key.includes('owners.') && key?.length > 7),
                        )
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});

                    const otherFilteredErrors = Object.keys(allErrors)
                        .filter(key => !allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});
                    setErrors(filteredErrors);
                    setOtherErrors(otherFilteredErrors);
                }
            }
        }
    }, [
        apFiles,
        arFiles,
        ballanceFiles,
        checkEmptyValues,
        errors,
        id,
        idFiles,
        incomeFiles,
        organization,
        otherFiles,
        signature,
    ]);

    console.log('otherFilteredErrors', otherErrors);

    const closeForm = useCallback(() => setFormVisible(false), []);

    const defaultCurrency = useSelector(getDefaultCurrency);

    const onTermsClick = useCallback(() => {
        setTermsAccepted(prevState => {
            if (prevState) {
                setErrors(prevStateErrors => {
                    return {
                        ...prevStateErrors,
                        terms: ['Please accept terms and conditions'],
                    };
                });
            } else {
                setErrors(prevStateErrors => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { terms: _, ...res } = prevStateErrors;
                    return res;
                });
            }
            return !prevState;
        });
    }, []);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                isLoading={isLoading}
                title={currentOrganization?.company_name}
                headerSubtitles={[
                    {
                        subtitle: 'Factoring organizations',
                        subtitleLink: '/factoring-organizations',
                    },
                ]}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-window">
                        <HeaderFactoringConnection
                            logo={currentOrganization?.logo?.sizes?.thumbnail}
                            organizationName={currentOrganization?.company_name}
                            isButtonVisible={!isFormVisible}
                            status={
                                currentOrganization
                                    ?.organization_request_connection?.status
                            }
                            onHeaderButtonPress={onSubmit}
                            disabled={isButtonDisabled}
                        />
                        {(!!currentOrganization?.description ||
                            !!currentOrganization?.file?.url_public) &&
                            !isFormVisible && (
                                <div className="window-body">
                                    {currentOrganization?.description}
                                    {!!currentOrganization?.file
                                        ?.url_public && (
                                        <>
                                            <br />
                                            <br />
                                            <a
                                                href={
                                                    currentOrganization?.file
                                                        ?.url_public
                                                }
                                                download
                                                className="button button-default"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Draft contract
                                                <em className="mdi mdi-download end" />
                                            </a>
                                        </>
                                    )}
                                </div>
                            )}
                        {isFormVisible && (
                            <ClientForm
                                organization={organization}
                                setOrganization={setOrganization}
                                errors={errors}
                                setErrors={setErrors}
                                isButtonDisabled={isButtonDisabled}
                                organizationChanged={organizationChanged}
                                onConnect={onConnect}
                                onCancel={closeForm}
                                ballanceFiles={ballanceFiles}
                                incomeFiles={incomeFiles}
                                arFiles={arFiles}
                                apFiles={apFiles}
                                idFiles={idFiles}
                                otherFiles={otherFiles}
                                setBallanceFiles={setBallanceFiles}
                                setIncomeFiles={setIncomeFiles}
                                setArFiles={setArFiles}
                                setApFiles={setApFiles}
                                setIdFiles={setIdFiles}
                                setOtherFiles={setOtherFiles}
                                setSignature={setSignature}
                                termsAccepted={termsAccepted}
                                onTermsClick={onTermsClick}
                            />
                        )}
                    </div>
                    {!isFormVisible && (
                        <Row
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'stretch',
                            }}
                        >
                            <div className="block block-window column">
                                <div className="window-card">
                                    <Row>
                                        <TotalIcon src={invoiceIcon} />
                                        <TotalHeader>
                                            Average interest rate
                                        </TotalHeader>
                                    </Row>
                                    <TotalValue style={{ marginTop: 10 }}>
                                        {currentOrganization?.commission}%
                                    </TotalValue>
                                </div>
                            </div>
                            <div style={{ width: 20 }} />
                            <div className="block block-window column">
                                <div className="window-card">
                                    <Row>
                                        <TotalIcon src={handShakeIcon} />
                                        <TotalHeader>
                                            Minimum amount to lend
                                        </TotalHeader>
                                    </Row>
                                    <Row style={{ marginTop: 10 }}>
                                        <DefaultCurrencyIcon
                                            src={defaultCurrency?.icon?.svg}
                                        />
                                        <TotalValue>
                                            {
                                                currentOrganization?.min_amount_to_factor
                                            }
                                        </TotalValue>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    )}
                    {currentOrganization?.organization_request_connection
                        ?.status === 'Active' && (
                        <Row
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'stretch',
                            }}
                        >
                            <div className="block block-window column">
                                <div className="window-card">
                                    <Row>
                                        <TotalIcon src={invoiceIcon} />
                                        <TotalHeader>
                                            Factored transactions count
                                        </TotalHeader>
                                    </Row>
                                    <TotalValue style={{ marginTop: 10 }}>
                                        {
                                            currentOrganization
                                                ?.organization_request_connection
                                                ?.factored_transactions_count
                                        }
                                    </TotalValue>
                                </div>
                            </div>
                            <div style={{ width: 20 }} />
                            <div className="block block-window column">
                                <div className="window-card">
                                    <Row>
                                        <TotalIcon src={handShakeIcon} />
                                        <TotalHeader>
                                            Factored transactions (total amount)
                                        </TotalHeader>
                                    </Row>
                                    <Row style={{ marginTop: 10 }}>
                                        <DefaultCurrencyIcon
                                            src={defaultCurrency?.icon?.svg}
                                        />
                                        <TotalValue>
                                            {
                                                currentOrganization
                                                    ?.organization_request_connection
                                                    ?.factored_transactions
                                            }
                                        </TotalValue>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    )}

                    {/* {isTipVisible && <Tip setTipVisible={setTipVisible} />} */}
                </div>

                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
        </div>
    );
};
