import { ActionMeta } from 'react-select';
import { Emoji, FormError, SelectComponent } from '.';

type Props = {
    className?: string | undefined;
    value?: string | number;
    type?: 'text' | 'password';
    placeholder?: string;
    selectOption?: {
        value: string;
        label: string;
    } | null;
    selectOptions?: readonly any[] | undefined;
    isSelectorClearable?: boolean;
    tooltip?: string;
    label2?: string;
    emoji?: string;
    errors?: string[];
    onOpenCountrySelector?: () => void;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onSelectChange?:
        | (((value: any, actionMeta: ActionMeta<any>) => void) &
              ((value: any, action: ActionMeta<any>) => void))
        | undefined;
};

export const FormComponent = ({
    className,
    value,
    type = 'text',
    placeholder,
    selectOption,
    selectOptions,
    isSelectorClearable = true,
    tooltip,
    label2,
    emoji,
    errors,
    onOpenCountrySelector,
    onChange,
    onSelectChange,
}: Props) => {
    return (
        <div className={className}>
            {!!placeholder && <div className="form-label">{placeholder}</div>}
            <div
                className="form-control-wrapper"
                style={{
                    alignItems: 'center',
                }}
            >
                {!!selectOptions && !!selectOptions?.length ? (
                    <SelectComponent
                        withInfo={!!tooltip}
                        isClearable={isSelectorClearable}
                        placeholder={placeholder}
                        value={selectOption}
                        onChange={onSelectChange}
                        options={selectOptions}
                    />
                ) : (
                    <input
                        className="form-control"
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        style={{
                            paddingLeft: !!emoji || emoji === '' ? 55 : 12,
                        }}
                    />
                )}

                {!!tooltip && (
                    <div className="form-control-info">
                        <em className="mdi mdi-information form-control-info-icon" />
                        <div className="form-control-info-container form-control-info-container-dim">
                            {tooltip}
                        </div>
                    </div>
                )}

                {(!!emoji || emoji === '') && (
                    <>
                        <Emoji onPress={onOpenCountrySelector} symbol={emoji} />
                    </>
                )}
            </div>
            {!!label2 && <div className="form-label2">{label2}</div>}
            <FormError errors={errors} />
        </div>
    );
};
