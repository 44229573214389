import React from 'react';
import { CurrencyCode, ItemAvatar, ItemContainer, ItemContent } from './styles';

type Props = {
    icon: string;
    code: string;
    checked?: boolean;
    handleClick?: () => void | undefined;
};

export const CurrencyItem = ({ icon, code, checked, handleClick }: Props) => {
    return (
        <ItemContainer checked={checked} onClick={handleClick}>
            <ItemContent>
                <ItemAvatar src={icon} alt="currency icon" />
                <CurrencyCode>{code}</CurrencyCode>
            </ItemContent>
        </ItemContainer>
    );
};
