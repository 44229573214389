import React from 'react';
import {
    ItemAvatar,
    ItemAvatarContainer,
    ItemAvatarTitle,
    ItemContainer,
    ItemContent,
    ItemEmail,
    ItemPerson,
    PersonWrapper,
} from './styles';
import { getInitials, hideEmail } from '../../services/helpers';
import { RadioButton } from '../../components';

type Props = {
    person: string;
    avatar?: string;
    email?: string;
    checked?: boolean;
    handleClick?: () => void | undefined;
};

export const UserItem = ({
    person,
    avatar,
    email,
    checked,
    handleClick,
}: Props) => {
    return (
        <ItemContainer onClick={handleClick}>
            <ItemContent>
                {avatar ? (
                    <ItemAvatar src={avatar} alt="avatar" />
                ) : (
                    <ItemAvatarContainer>
                        <ItemAvatarTitle>
                            {person && getInitials(person)}
                        </ItemAvatarTitle>
                    </ItemAvatarContainer>
                )}
                <PersonWrapper>
                    <ItemPerson>{person}</ItemPerson>
                    {!!email && (
                        <ItemEmail>{email && hideEmail(email)}</ItemEmail>
                    )}
                </PersonWrapper>
            </ItemContent>

            <RadioButton selected={checked} />
        </ItemContainer>
    );
};
