import { DateTime } from 'luxon';
import { memo } from 'react';

type CurrentPlanCardsProps = {
    nextPaymentAt?: string | null;
    currentPlanName?: string;
    viewPaymentHistory?: boolean;
    openChangeBillingPlanModal?: () => void;
    openGroupPaymentHistory?: () => void;
};

export const CurrentPlanCards = memo(
    ({
        nextPaymentAt,
        currentPlanName,
        viewPaymentHistory,
        openChangeBillingPlanModal,
        openGroupPaymentHistory,
    }: CurrentPlanCardsProps) => {
        return (
            <div className="block block-current-plan-cards">
                {!!currentPlanName && (
                    <div className="card">
                        <div className="title">Current plan</div>
                        <div className="subtitle">{currentPlanName}</div>
                        {!!openChangeBillingPlanModal && (
                            <div className="plan-point">
                                <div
                                    className="plan-point-title"
                                    role="button"
                                    tabIndex={0}
                                    onClick={openChangeBillingPlanModal}
                                    onKeyDown={openChangeBillingPlanModal}
                                >
                                    Switch to yearly billing and save
                                </div>
                                <em className="mdi mdi-chevron-right" />
                            </div>
                        )}
                    </div>
                )}
                <div className="card">
                    {!!nextPaymentAt && (
                        <div className="title">Next payment due</div>
                    )}
                    <div
                        className="subtitle"
                        style={!nextPaymentAt ? { marginTop: 0 } : {}}
                    >
                        {nextPaymentAt
                            ? DateTime.fromSQL(nextPaymentAt)
                                  .toUTC()
                                  .toFormat("dd MMM yyyy, HH:mm 'GMT'") ||
                              nextPaymentAt
                            : 'Free plan'}
                    </div>
                    {!!viewPaymentHistory && (
                        <div className="plan-point">
                            <div
                                className="plan-point-title"
                                role="button"
                                tabIndex={-1}
                                onClick={openGroupPaymentHistory}
                                onKeyDown={openGroupPaymentHistory}
                            >
                                View payment history
                            </div>
                            <em className="mdi mdi-chevron-right" />
                        </div>
                    )}
                </div>
                {/* <div className="card">
                    <div className="title">Payment information</div>
                    <div className="plan-point" style={{ marginTop: 18 }}>
                        <div className="plan-point-title">
                            Update payment method
                        </div>
                        <em className="mdi mdi-chevron-right" />
                    </div>
                    <div className="plan-point">
                        <div className="plan-point-title">
                            Manage spending limit
                        </div>
                        <em className="mdi mdi-chevron-right" />
                    </div>
                    <div className="plan-point">
                        <div className="plan-point-title">Something else</div>
                        <em className="mdi mdi-chevron-right" />
                    </div>
                    <em className="mdi mdi-chevron-right" />
                </div> */}
            </div>
        );
    },
);
