type Props = {
    label?: string;
    symbol: string;
    withoutLabel?: boolean;
    onPress?: () => void;
};

export const Emoji = (props: Props) => (
    <div
        className={`form-emoji-button ${props.withoutLabel && 'without-label'}`}
        role="button"
        tabIndex={0}
        onClick={props.onPress}
        onKeyDown={props.onPress}
    >
        <span
            className="emoji"
            role="img"
            aria-label={props.label ? props.label : ''}
            aria-hidden={props.label ? 'false' : 'true'}
        >
            {props.symbol}
        </span>
        <em className="mdi mdi-menu-down" />
    </div>
);
