import styled from 'styled-components';

export const TypeCardContainer = styled.div`
    cursor: pointer;
    padding: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 130px;

    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

export const TypeCardTitle = styled.div<{ active?: boolean }>`
    margin-top: 16px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${({ active }) => (active ? '#000000' : '#555555')};
`;

export const CheckIcon = styled.em`
    position: absolute;
    top: 10px;
    right: 20px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;

    background: #399d0a;
    border-radius: 60px;

    color: #ffffff;
`;

export const Icon = styled.img`
    width: 55px;
    height: 55px;
`;
