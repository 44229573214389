/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react';
import Modal from 'react-modal';
import {
    Center,
    CloseButton,
    CloseButtonText,
    Content,
    Footer,
    Header,
    HeaderSubtitle,
    HeaderTitle,
} from './styles';

import { FooterButtons, FormError, Row, Spinner } from '../../../components';
import { removeSnake } from '../../../services/helpers';
import {
    createGroup,
    fetchMyGroups,
    fetchMyOrganizations,
} from '../../../services/OrganizationsService';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type Props = {
    isModalOpen: boolean;
    onRequestClose: () => void;
};

const allFields = ['name'];
const requiredFields = allFields;

export const NewGroupModal = ({ isModalOpen, onRequestClose }: Props) => {
    const [errors, setErrors] = useState<{ [key: string]: any }>({});
    const [otherErrors, setOtherErrors] = useState<{ [key: string]: any }>({});
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isFooterButtonsVisible, setFooterButtonsVisible] = useState(false);

    const [fields, setFields] = useState<{ name: string }>({ name: '' });

    const fieldsChanged = useCallback(
        (key: string, value: string | boolean) => {
            setOtherErrors({});
            setFooterButtonsVisible(true);
            setFields(prevState => ({
                ...prevState,
                [key]: value,
            }));
            if (typeof value !== 'boolean' && !value) {
                const errorMessage = `The ${removeSnake(
                    key,
                )} field is required`;

                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors],
    );

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!fields?.hasOwnProperty(item) || fields[item] === '')
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [errors, fields]);

    const onSubmit = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await createGroup(fields);
                setButtonDisabled(false);
                if (response?.status === 200 || response?.status === 201) {
                    await fetchMyGroups();
                    await fetchMyOrganizations();
                    onRequestClose();
                }
                if (
                    response?.response?.status === 422 &&
                    response?.response?.data?.errors
                ) {
                    const allErrors = response?.response?.data?.errors;
                    const filteredErrors = Object.keys(allErrors)
                        .filter(key => allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});
                    const otherFilteredErrors = Object.keys(allErrors)
                        .filter(key => !allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});
                    setErrors(filteredErrors);
                    setOtherErrors(otherFilteredErrors);
                }
            }
        }
    }, [checkEmptyValues, errors, fields, onRequestClose]);

    return (
        <Modal
            isOpen={isModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel="New group"
        >
            <Header>
                <Row>
                    {/* <div
                        style={{
                            boxShadow: 'inset -1px 0px 0px #EAECF0',
                            paddingRight: 15,
                        }}
                    >
                        <HeaderSubtitle>Group</HeaderSubtitle>
                        <Row marginTop={6} marginBottom={12}>
                            <HeaderTitle>modal</HeaderTitle>
                        </Row>
                    </div> */}
                    <div style={{ paddingLeft: 15 }}>
                        <HeaderTitle>New Group</HeaderTitle>
                        <HeaderSubtitle>Enter new group name</HeaderSubtitle>
                    </div>
                </Row>
                <CloseButton onClick={onRequestClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>

            <Content>
                <div className="wrapper wrapper-sm form" style={{ margin: 0 }}>
                    <div className="block block-pay-modal">
                        <Center>
                            <div className="wrapper wrapper-sm form">
                                <div className="form-group col-lg-12 col-xs-12">
                                    <div className="form-label">Group Name</div>
                                    <div className="form-control-wrapper">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Group Name"
                                            value={fields?.name || ''}
                                            onChange={e =>
                                                fieldsChanged(
                                                    'name',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <div className="form-control-info">
                                            <em className="mdi mdi-information form-control-info-icon" />
                                            <div className="form-control-info-container form-control-info-container-dim">
                                                Group Name
                                            </div>
                                        </div>
                                    </div>
                                    <FormError errors={errors?.name} />
                                </div>
                            </div>
                        </Center>
                    </div>
                    {!!Object.keys(otherErrors)?.length && (
                        <>
                            <div className="form-title">
                                {`Other error${
                                    Object.keys(otherErrors)?.length > 1
                                        ? 's:'
                                        : ':'
                                }`}
                            </div>
                            {Object.keys(otherErrors)?.map(otherErrorKey => (
                                <FormError
                                    key={otherErrorKey}
                                    errors={otherErrors[otherErrorKey]}
                                />
                            ))}
                        </>
                    )}
                </div>
            </Content>

            <Footer>
                <FooterButtons
                    disabled={isButtonDisabled || !isFooterButtonsVisible}
                    chainPaymentForm
                    onCancel={onRequestClose}
                    onSubmit={onSubmit}
                />
            </Footer>

            {!!isButtonDisabled && <Spinner />}
        </Modal>
    );
};
