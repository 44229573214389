import { Status } from './styles';

import { TGroup } from '../../../types/types';
import { capitalizeFirstLetter } from '../../../services/helpers';

type Props = {
    status: TGroup['status'];

    onClick?: () => void;
};

export const Td4 = ({ status, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                }}
            >
                <Status status={status}>{capitalizeFirstLetter(status)}</Status>
            </button>
        </td>
    );
};
