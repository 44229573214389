import Loader from 'react-loader-spinner';
import IconSearch from '../../assets/img/icons/icon-search.svg';
import { FilterContainer, InputWrapper, SearchIcon } from '../../components';

type Props = {
    isLoading?: boolean;
    query?: string;
    onQueryChange: (value: string) => void;
};

export const FiltersHeader = ({ isLoading, query, onQueryChange }: Props) => {
    return (
        <FilterContainer className="form">
            <InputWrapper>
                <div className="form-control-wrapper">
                    <SearchIcon src={IconSearch} />
                    <input
                        id="query"
                        className="form-control"
                        placeholder="Search"
                        value={query || ''}
                        onChange={e => onQueryChange(e.target.value)}
                        style={{
                            paddingLeft: 29,
                            paddingRight: 29,
                            width: 187,
                        }}
                    />
                    <div className="form-control-info">
                        <em className="mdi mdi-information form-control-info-icon" />
                        <div className="form-control-info-container form-control-info-container-dim">
                            Search by entry amount OR organization name
                        </div>
                    </div>
                </div>
            </InputWrapper>
            {!!isLoading && (
                <Loader
                    type="ThreeDots"
                    color="#f37749"
                    visible
                    height={20}
                    width={50}
                />
            )}
        </FilterContainer>
    );
};
