import styled from 'styled-components';

export const RedLine = styled.div<{ top?: number }>`
    position: absolute;
    top: ${({ top = -7 }) => top}px;
    left: -20px;
    width: 5px;
    height: 32px;
    border-radius: 5px;
    background: #d13b3b;
`;
