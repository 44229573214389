import React, { memo } from 'react';
import { Container, NoMembers, NoMembersSubtitle } from './styles';
import icon from './icon.svg';

type EmptyInvitedOrganizationsProps = {
    onPress?: () => void;
};

export const EmptyInvitedOrganizations = memo(
    ({ onPress }: EmptyInvitedOrganizationsProps) => {
        return (
            <Container>
                <img src={icon} alt="icon" />
                <NoMembers>
                    Right now there is no other organization inside this group.
                </NoMembers>
                <NoMembersSubtitle>
                    You can invite another organization to benefit from your
                    group plan
                </NoMembersSubtitle>
                <button
                    type="button"
                    className="button button-primary"
                    // disabled={isButtonDisabled}
                    onClick={onPress}
                    onKeyDown={onPress}
                >
                    <em className="mdi mdi-plus start" />
                    Add new organization
                </button>
            </Container>
        );
    },
);
