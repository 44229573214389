/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react';
import Modal from 'react-modal';
import {
    Center,
    CloseButton,
    CloseButtonText,
    Content,
    CurrencyIcon,
    Footer,
    Header,
    HeaderSubtitle,
    HeaderTitle,
} from './styles';
import { FooterButtons, FormError, Row, Spinner } from '..';
import { payChainByAch } from '../../../services/ChainsService';
import { getSomeDigitLength, removeSnake } from '../../../services/helpers';
import { PayChainByAchProps } from '../../../types/types';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type Props = {
    id: number;
    isModalOpen: boolean;
    amount?: string;
    currencyIcon?: string;
    onRequestClose: () => void;
    onSuccess: () => void;
    onFail: () => void;
};

const allFields = ['bank_code', 'account_number', 'account_type', 'name'];
const requiredFields = allFields;

export const PayModalAch = ({
    id,
    isModalOpen,
    amount,
    currencyIcon,
    onRequestClose,
    onSuccess,
    onFail,
}: Props) => {
    const onClose = useCallback(() => {
        setErrors({});
        setOtherErrors({});
        setFields({ account_type: 'SAVINGS' });
        onRequestClose();
    }, [onRequestClose]);

    const [errors, setErrors] = useState<{ [key: string]: any }>({});
    const [otherErrors, setOtherErrors] = useState<{ [key: string]: any }>({});
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isFooterButtonsVisible, setFooterButtonsVisible] = useState(false);
    const [fields, setFields] = useState<Partial<PayChainByAchProps>>({
        account_type: 'SAVINGS',
    });

    const fieldsChanged = useCallback(
        (key: string, value: string | boolean) => {
            setOtherErrors({});
            setFooterButtonsVisible(true);
            setFields(prevState => ({
                ...prevState,
                [key]: value,
            }));
            if (typeof value !== 'boolean' && !value) {
                const errorMessage = `The ${removeSnake(
                    key,
                )} field is required`;

                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors],
    );

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!fields?.hasOwnProperty(item) || fields[item] === '')
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [errors, fields]);

    const onSubmit = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await payChainByAch(Number(id), fields);

                setButtonDisabled(false);

                if (response?.status === 200 || response?.status === 201) {
                    onClose();
                    onSuccess();
                } else {
                    onFail();
                }
                if (
                    response?.response?.status === 422 &&
                    response?.response?.data?.errors
                ) {
                    const allErrors = response?.response?.data?.errors;
                    const filteredErrors = Object.keys(allErrors)
                        .filter(key => allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});

                    const otherFilteredErrors = Object.keys(allErrors)
                        .filter(key => !allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});
                    setErrors(filteredErrors);
                    setOtherErrors(otherFilteredErrors);
                }
            }
        }
    }, [errors, checkEmptyValues, id, fields, onClose, onSuccess, onFail]);

    return (
        <Modal
            isOpen={isModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Ach payment"
        >
            <Header>
                <Row>
                    <div
                        style={{
                            boxShadow: 'inset -1px 0px 0px #EAECF0',
                            paddingRight: 15,
                        }}
                    >
                        <HeaderSubtitle>Costs</HeaderSubtitle>
                        <Row marginTop={6} marginBottom={12}>
                            <HeaderTitle>{amount}</HeaderTitle>
                            {!!currencyIcon && (
                                <CurrencyIcon src={currencyIcon} alt="" />
                            )}
                        </Row>
                    </div>
                    <div style={{ paddingLeft: 15 }}>
                        <HeaderTitle>Chain payment</HeaderTitle>
                        <HeaderSubtitle>Enter your payment data</HeaderSubtitle>
                    </div>
                </Row>
                <CloseButton onClick={onClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>

            <Content>
                <div className="wrapper wrapper-sm form" style={{ margin: 0 }}>
                    <div className="block block-pay-modal">
                        <Center>
                            <div className="wrapper wrapper-sm form">
                                <div className="form-group col-lg-12 col-xs-12">
                                    <div className="form-label">Name</div>
                                    <div className="form-control-wrapper">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Name"
                                            value={fields?.name || ''}
                                            onChange={e =>
                                                fieldsChanged(
                                                    'name',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <div className="form-control-info">
                                            <em className="mdi mdi-information form-control-info-icon" />
                                            <div className="form-control-info-container form-control-info-container-dim">
                                                Name
                                            </div>
                                        </div>
                                    </div>
                                    <FormError errors={errors?.name} />
                                </div>
                                <div className="form-group col-lg-12 col-xs-12">
                                    <div className="form-label">
                                        Bank routing number
                                    </div>
                                    <div className="form-control-wrapper">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Bank routing number"
                                            value={fields?.bank_code || ''}
                                            onChange={e =>
                                                fieldsChanged(
                                                    'bank_code',
                                                    getSomeDigitLength(
                                                        e.target.value,
                                                        9,
                                                    ),
                                                )
                                            }
                                        />
                                        <div className="form-control-info">
                                            <em className="mdi mdi-information form-control-info-icon" />
                                            <div className="form-control-info-container">
                                                Bank routing
                                                <br />
                                                number
                                            </div>
                                        </div>
                                    </div>
                                    <FormError errors={errors?.bank_code} />
                                </div>
                                <div className="form-group col-lg-12 col-xs-12">
                                    <div className="form-label">
                                        Bank account
                                    </div>
                                    <div className="form-control-wrapper">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Bank account"
                                            value={fields?.account_number || ''}
                                            onChange={e =>
                                                fieldsChanged(
                                                    'account_number',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <div className="form-control-info">
                                            <em className="mdi mdi-information form-control-info-icon" />
                                            <div className="form-control-info-container">
                                                Bank account
                                                <br />
                                                number
                                            </div>
                                        </div>
                                    </div>
                                    <FormError
                                        errors={errors?.account_number}
                                    />
                                </div>
                                <div className="form-group col-lg-12 col-xs-12">
                                    <div className="flex">
                                        <div className="form-radio radio-narrow radio-inline">
                                            <input
                                                name="SAVINGS"
                                                type="radio"
                                                id="SAVINGS"
                                                value="SAVINGS"
                                                checked={
                                                    fields?.account_type ===
                                                    'SAVINGS'
                                                }
                                                onChange={e =>
                                                    fieldsChanged(
                                                        'account_type',
                                                        e.currentTarget.value,
                                                    )
                                                }
                                            />
                                            <label
                                                className="radio-label"
                                                htmlFor="SAVINGS"
                                            >
                                                <div className="radio-circle" />
                                                Savings
                                            </label>
                                        </div>
                                        <div className="form-radio radio-narrow radio-inline">
                                            <input
                                                name="CHECKING"
                                                type="radio"
                                                id="CHECKING"
                                                value="CHECKING"
                                                checked={
                                                    fields?.account_type ===
                                                    'CHECKING'
                                                }
                                                onChange={e =>
                                                    fieldsChanged(
                                                        'account_type',
                                                        e.currentTarget.value,
                                                    )
                                                }
                                            />
                                            <label
                                                className="radio-label"
                                                htmlFor="CHECKING"
                                            >
                                                <div className="radio-circle" />
                                                Checking
                                            </label>
                                        </div>
                                    </div>
                                    <FormError errors={errors?.account_type} />
                                </div>
                            </div>
                        </Center>
                    </div>
                    {!!Object.keys(otherErrors)?.length && (
                        <>
                            <div className="form-title">
                                {`Other error${
                                    Object.keys(otherErrors)?.length > 1
                                        ? 's:'
                                        : ':'
                                }`}
                            </div>
                            {Object.keys(otherErrors)?.map(otherErrorKey => (
                                <FormError
                                    key={otherErrorKey}
                                    errors={otherErrors[otherErrorKey]}
                                />
                            ))}
                        </>
                    )}
                </div>
            </Content>

            <Footer>
                <FooterButtons
                    disabled={isButtonDisabled || !isFooterButtonsVisible}
                    chainPaymentForm
                    onCancel={onClose}
                    onSubmit={onSubmit}
                />
            </Footer>

            {!!isButtonDisabled && <Spinner />}
        </Modal>
    );
};
