import { memo, useCallback, useState } from 'react';
import Modal from 'react-modal';
import {
    AvailablePlan,
    AvailablePlansWrapper,
    CloseButton,
    CloseButtonText,
    Content,
    Footer,
    Header,
    HeaderTitle,
} from './styles';
import { CurrentPlan } from './CurrentPlan';
import { PlanCard } from './PlanCard';
import { FooterButtons, FormError, Spinner } from '..';
import { TBillingPlan } from '../../types/types';
import { buyBillingPlan } from '../../services/OrganizationsService';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 520 + 40 + 30,
        maxWidth: 1040 + 40 + 30,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type ChangeBillingPlanModalProps = {
    isOpen: boolean;
    currentBillingPlan?: TBillingPlan;
    availableBillingPlans: TBillingPlan[];

    onClose?: () => void;
};

export const ChangeBillingPlanModal = memo(
    ({
        isOpen,
        currentBillingPlan,
        availableBillingPlans,

        onClose,
    }: ChangeBillingPlanModalProps) => {
        const [isButtonDisabled, setButtonDisabled] = useState(false);
        const [selectedPlan, setSelectedPlan] = useState<TBillingPlan>();
        const onPlanSelect = useCallback(
            (planId: number) => {
                setErrors({});
                setSelectedPlan(
                    availableBillingPlans?.find(plan => plan?.id === planId),
                );
            },
            [availableBillingPlans],
        );
        const [errors, setErrors] = useState<{ [key: string]: any }>({});
        const onSubmit = async () => {
            if (selectedPlan?.id) {
                setButtonDisabled(true);
                const response = await buyBillingPlan(selectedPlan?.id);
                if (response?.data?.url) {
                    const win = window.open(response?.data?.url, '_blank');
                    setButtonDisabled(false);
                    onClose?.();
                    win?.focus();
                } else {
                    setButtonDisabled(false);
                    if (
                        response?.response?.status === 412 &&
                        response?.response?.data?.message
                    ) {
                        setErrors({
                            stripe_validation: [
                                response?.response?.data?.message,
                            ],
                        });
                    }
                }
            }
        };

        return (
            <Modal
                isOpen={isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={onClose}
                style={customStyles}
                contentLabel="Change billing plan modal"
            >
                <Header>
                    <HeaderTitle>Change plan</HeaderTitle>

                    <CloseButton onClick={onClose}>
                        <CloseButtonText className="mdi mdi-close start" />
                    </CloseButton>
                </Header>
                <Content>
                    <CurrentPlan
                        name={currentBillingPlan?.title}
                        price={Number(currentBillingPlan?.price || 0)}
                    />
                    <AvailablePlan>Available plan</AvailablePlan>
                    {!!availableBillingPlans?.length && (
                        <AvailablePlansWrapper>
                            {availableBillingPlans?.map(plan => (
                                <PlanCard
                                    key={plan?.id}
                                    plan={plan}
                                    isSelected={plan?.id === selectedPlan?.id}
                                    isLoaded={
                                        plan?.id === selectedPlan?.id &&
                                        isButtonDisabled
                                    }
                                    onSelect={onPlanSelect}
                                />
                            ))}
                        </AvailablePlansWrapper>
                    )}
                    <div className="form form-group">
                        <FormError errors={errors.stripe_validation} />
                    </div>
                </Content>
                <Footer>
                    <FooterButtons
                        disabled={isButtonDisabled || !selectedPlan?.id}
                        submitText="Submit"
                        onCancel={onClose}
                        onSubmit={onSubmit}
                    />
                </Footer>
                {!!isButtonDisabled && <Spinner />}
            </Modal>
        );
    },
);
