import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import menuIconSettings from '../../assets/img/menu-icons/menu-icon-settings.svg';
import caret from '../../assets/img/icons/carret.svg';
import { getMyCurrentOrganizationName } from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';

type Props = {
    isMenuActive?: boolean;
    onPress?: () => void;
};

export const MenuOrganizationLink = ({ isMenuActive, onPress }: Props) => {
    const history = useHistory();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const organizationName = useSelector(getMyCurrentOrganizationName);

    const onClick = useCallback(() => {
        if (onPress) onPress();
        if (!isMenuActive)
            history.push(`/organization/${currentOrganizationId}`);
    }, [onPress, isMenuActive, history, currentOrganizationId]);

    return currentOrganizationId ? (
        <div
            role="button"
            tabIndex={0}
            className="menu-item-link"
            // style={{
            //     borderWidth: 0,
            //     background: 'transparent',
            //     color: 'white',
            //     opacity:
            //         location.pathname.includes(
            //             `/organization/${currentOrganizationId}`,
            //         ) ||
            //         location.pathname.includes(
            //             `/organization${currentOrganizationId}/members`,
            //         ) ||
            //         location.pathname.includes('/external-connections')
            //             ? 1
            //             : 0.6,
            // }}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <div className="menu-item-icon">
                <img src={menuIconSettings} alt="menu icon settings" />
            </div>

            <div className="menu-item-label">{organizationName}</div>
            <div className="menu-item-caret">
                <img src={caret} alt="caret" />
            </div>
        </div>
    ) : null;
};
