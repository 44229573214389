import { CheckIcon, GroupId, Name, Status } from './styles';
import { Column, Row } from '../../components';
import { TGroup } from '../../types/types';
import { capitalizeFirstLetter } from '../../services/helpers';

type Props = {
    name: TGroup['name'];
    status: TGroup['status'];
    id: TGroup['id'];
    isCurrent?: boolean;
    onClick?: () => void;
};

export const Td1 = ({
    name,
    status,
    id,
    isCurrent = false,
    onClick,
}: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Row alignItems="center" justifyContent="center">
                    {isCurrent && (
                        <CheckIcon>
                            <div
                                className="mdi mdi-check"
                                style={{ color: 'white', fontSize: 14 }}
                            />
                        </CheckIcon>
                    )}
                    <Name>{name}</Name>{' '}
                    {!!status && (
                        <Status status={status}>
                            {capitalizeFirstLetter(status)}
                        </Status>
                    )}
                </Row>
                {!!id && (
                    <Column style={{ marginTop: 6 }}>
                        <Row>
                            <GroupId>#{id}</GroupId>
                        </Row>
                    </Column>
                )}
            </button>
        </td>
    );
};
