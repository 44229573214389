import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import menuIconFactoringPartners from '../../assets/img/menu-icons/menu-icon-partners.svg';
import caret from '../../assets/img/icons/carret.svg';

type Props = {
    isMenuActive?: boolean;
    link?: string | undefined;
    onPress?: () => void;
};

export const MenuFactoringLink = ({ isMenuActive, link, onPress }: Props) => {
    const history = useHistory();
    const onClick = useCallback(() => {
        if (onPress) onPress();
        if (!isMenuActive && link) history.push(link);
    }, [onPress, isMenuActive, link, history]);
    return (
        <div
            role="button"
            tabIndex={0}
            className="menu-item-link"
            // style={{
            //     borderWidth: 0,
            //     background: 'transparent',
            //     color: 'white',
            //     opacity: location.pathname.includes('transactions') ? 1 : 0.6,
            // }}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <div className="menu-item-icon">
                <img src={menuIconFactoringPartners} alt="transaction icon" />
            </div>
            <div className="menu-item-label">
                Factoring
                {/* {!!sumNotificationsTransaction && (
                    <div className="menu-item-notes">
                        {sumNotificationsTransaction}
                    </div>
                )} */}
            </div>
            <div className="menu-item-caret">
                <img src={caret} alt="caret" />
            </div>
        </div>
    );
};
