import { AxiosRequestConfig } from 'axios';
import api from './axiosConfig';
import { circlesSet, circlesUpdate, circleUpdate } from '../state/circles';
import { countCirclesAllSet } from '../state/collectionsCount';
import { store } from '../state/store';

// &extra

export const fetchCircles = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api('/circles', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 12,
            ...params,
        },
    });
    if (response?.status === 200 && !!response?.data?.data) {
        store.dispatch(countCirclesAllSet(response.data?.meta?.total));
        if (!update) {
            store.dispatch(circlesSet(response.data.data));
        } else {
            store.dispatch(circlesUpdate(response.data?.data));
        }
    }
    return response;
};

export const fetchCircle = async (id: number) => {
    const response = await api(`/circles/${id}`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(circleUpdate(response.data?.data));
    }
    return response;
};

export const acceptCircle = async (id: number) => {
    const response = await api.patch(`/circles/${id}/accept`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(circleUpdate(response.data?.data));
    }
    return response;
};

export const rejectCircle = async (id: number) => {
    const response = await api.patch(`/circles/${id}/reject`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(circleUpdate(response.data?.data));
    }
    return response;
};
