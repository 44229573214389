import { useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { FactoringButtonWrapper } from './styles';
import {
    FactoringButtons,
    FilterContainer,
    InputWrapper,
    Row,
    SearchIcon,
    SelectComponent,
} from '../../components';
import {
    FactorRequestTransaction,
    Organization,
    Transaction,
} from '../../types/types';
import IconSearch from '../../assets/img/icons/icon-search.svg';

import { useComponentVisible } from '../../hooks';

type Props = {
    archived?: boolean;
    isLoading?: boolean;
    isFactoringRequestStatusAvailable?: boolean;
    isActionButtonDisabled?: boolean;
    query?: string;
    status?: Transaction['status'][];
    paymentStatus?: Transaction['payment_status'][];
    factoringRequestStatus?: FactorRequestTransaction['status'][];
    selectedCount?: number;
    canArchive?: boolean;
    canFactor?: boolean;
    canFactorRequest?: boolean;
    cancelSendToFactor?: boolean;
    acceptOrRejectFactorRequest?: boolean;
    factorOrganizations: Organization[];
    currentConnectedFactoringOrganizations: Organization[];
    onQueryChange: (value: string) => void;
    onStatusChange: (value: Transaction['status'][] | []) => void;
    onPaymentStatusChange: (
        value: Transaction['payment_status'][] | [],
    ) => void;
    onFactoringRequestStatusChange?: (
        value: FactorRequestTransaction['status'][] | [],
    ) => void;
    onArchive?: () => Promise<void>;
    onFactor: (id?: number) => Promise<void>;
    onSendToFactorOrganization: (id?: number) => Promise<void>;
    onCancelFactoringRequest: () => Promise<void>;
    onAcceptFactoringRequest: () => Promise<void>;
    onRejectFactoringRequest: () => Promise<void>;
};

export const FiltersHeader = ({
    archived = false,
    isLoading,
    isFactoringRequestStatusAvailable,
    isActionButtonDisabled,
    query,
    status,
    paymentStatus,
    factoringRequestStatus,
    selectedCount,
    canArchive,
    canFactor,
    canFactorRequest,
    cancelSendToFactor,
    acceptOrRejectFactorRequest,
    factorOrganizations,
    currentConnectedFactoringOrganizations,
    onQueryChange,
    onStatusChange,
    onPaymentStatusChange,
    onFactoringRequestStatusChange,
    onArchive,
    onFactor,
    onSendToFactorOrganization,
    onCancelFactoringRequest,
    onAcceptFactoringRequest,
    onRejectFactoringRequest,
}: Props) => {
    const statuses: Transaction['status'][] = useMemo(() => {
        let array: Transaction['status'][] = [
            'pending',
            'open',
            'archived',
            'rejected',
            'in payment',
            'paid',
            'factored',
        ];
        // canFactor
        //     ? ['open']
        //     :

        if (!archived) {
            array = array?.filter(item => item !== 'archived');
        }
        return array;
    }, [archived]);

    const statusOptions = useMemo(
        () =>
            statuses.map(s => ({
                value: s,
                label: s,
            })),
        [statuses],
    );

    const selectedStatusesOptions = useMemo(
        () =>
            status?.map(s => ({
                value: s,
                label: s,
            })),
        [status],
    );

    const onStatusChangeMethod = useCallback(
        (
            objArr:
                | {
                      value: Transaction['status'];
                      label: Transaction['status'];
                  }[]
                | [],
        ) => {
            onStatusChange(objArr?.map(item => item?.value));
        },
        [onStatusChange],
    );

    const states: Transaction['payment_status'][] = useMemo(
        () => ['not paid', 'partially paid', 'paid'],
        [],
    );

    const statesOptions = useMemo(
        () =>
            states.map(s => ({
                value: s,
                label: s,
            })),
        [states],
    );

    const selectedStatesOptions = useMemo(
        () =>
            paymentStatus?.map(s => ({
                value: s,
                label: s,
            })),
        [paymentStatus],
    );

    const onStateChangeMethod = useCallback(
        (
            objArr:
                | {
                      value: Transaction['payment_status'];
                      label: Transaction['payment_status'];
                  }[]
                | [],
        ) => {
            onPaymentStatusChange(objArr?.map(item => item?.value));
        },
        [onPaymentStatusChange],
    );

    const factoringRequestStatuses: FactorRequestTransaction['status'][] = useMemo(
        () => ['Pending', 'Canceled', 'Accepted', 'Rejected'],
        [],
    );

    const factoringRequestStatusesOptions = useMemo(
        () =>
            factoringRequestStatuses.map(s => ({
                value: s,
                label: s,
            })),
        [factoringRequestStatuses],
    );

    const selectedFactoringRequestStatusesOptions = useMemo(
        () =>
            factoringRequestStatus?.map(s => ({
                value: s,
                label: s,
            })),
        [factoringRequestStatus],
    );

    const onFactoringRequestStatusChangeMethod = useCallback(
        (
            objArr:
                | {
                      value: FactorRequestTransaction['status'];
                      label: FactorRequestTransaction['status'];
                  }[]
                | [],
        ) => {
            if (onFactoringRequestStatusChange) {
                onFactoringRequestStatusChange(
                    objArr?.map(item => item?.value),
                );
            }
        },
        [onFactoringRequestStatusChange],
    );

    const {
        ref,
        buttonRef,
        isComponentVisible,
        setIsComponentVisible,
    } = useComponentVisible(false);

    const setSelectButtonsVisible = useCallback(
        () => setIsComponentVisible(prevState => !prevState),
        [setIsComponentVisible],
    );

    const onFactoring = useCallback(
        async (id: number) => {
            setIsComponentVisible(false);
            await onFactor(id);
        },
        [onFactor, setIsComponentVisible],
    );

    const factoringButtonClick = useCallback(async () => {
        if (factorOrganizations?.length > 1) {
            setSelectButtonsVisible();
        } else {
            await onFactor(factorOrganizations[0]?.id);
        }
    }, [factorOrganizations, onFactor, setSelectButtonsVisible]);

    const sendToFactoringButtonHook = useComponentVisible(false);

    const onSendToFactor = useCallback(
        async (id: number) => {
            sendToFactoringButtonHook?.setIsComponentVisible(false);
            if (onSendToFactorOrganization) {
                await onSendToFactorOrganization(id);
            }
        },
        [onSendToFactorOrganization, sendToFactoringButtonHook],
    );

    const sendToFactoringButtonClick = useCallback(async () => {
        if (currentConnectedFactoringOrganizations?.length) {
            if (currentConnectedFactoringOrganizations?.length > 1) {
                sendToFactoringButtonHook?.setIsComponentVisible(
                    prevState => !prevState,
                );
            } else {
                await onSendToFactor(
                    currentConnectedFactoringOrganizations[0]?.id,
                );
            }
        }
    }, [
        currentConnectedFactoringOrganizations,
        onSendToFactor,
        sendToFactoringButtonHook,
    ]);

    return (
        <FilterContainer className="form">
            {selectedCount &&
            (canArchive ||
                canFactor ||
                canFactorRequest ||
                cancelSendToFactor ||
                acceptOrRejectFactorRequest) ? (
                <>
                    {canArchive && (
                        <>
                            <button
                                ref={buttonRef}
                                type="button"
                                className="button button-primary button-primary-outline button-sm"
                                onClick={onArchive}
                                disabled={isActionButtonDisabled}
                            >
                                <em className="mdi mdi-archive start" />
                                {`${'Archive'} ${selectedCount} selected`}
                            </button>
                            <div style={{ width: 7 }} />
                        </>
                    )}

                    {canFactor && !!factorOrganizations?.length && (
                        <div style={{ position: 'relative', marginRight: 7 }}>
                            <button
                                ref={buttonRef}
                                type="button"
                                className="button button-primary button-primary-outline button-sm"
                                onClick={factoringButtonClick}
                                disabled={isActionButtonDisabled}
                            >
                                {factorOrganizations?.length === 1 && (
                                    <em className="mdi mdi-cash start" />
                                )}
                                {`Factor ${selectedCount} selected by${
                                    factorOrganizations?.length > 1
                                        ? ''
                                        : ` ${factorOrganizations[0]?.company_name}`
                                }`}

                                {factorOrganizations?.length > 1 && (
                                    <em
                                        className={`mdi mdi-menu-${
                                            isComponentVisible ? 'up' : 'down'
                                        } end`}
                                    />
                                )}
                            </button>
                            <FactoringButtonWrapper ref={ref}>
                                {isComponentVisible && (
                                    <FactoringButtons
                                        organizations={factorOrganizations}
                                        onPress={onFactoring}
                                    />
                                )}
                            </FactoringButtonWrapper>
                        </div>
                    )}
                    {cancelSendToFactor && (
                        <>
                            <button
                                ref={buttonRef}
                                type="button"
                                className="button button-primary button-primary-outline button-sm"
                                onClick={onCancelFactoringRequest}
                                disabled={isActionButtonDisabled}
                            >
                                Cancel factoring request
                            </button>
                            <div style={{ width: 7 }} />
                        </>
                    )}
                    {acceptOrRejectFactorRequest && (
                        <Row>
                            <button
                                type="button"
                                className="button button-success button-sm"
                                disabled={isActionButtonDisabled}
                                onClick={onAcceptFactoringRequest}
                            >
                                <em className="mdi mdi-check start" />
                                Accept
                            </button>
                            <div style={{ width: 7 }} />
                            <button
                                type="button"
                                className="button button-danger button-sm"
                                disabled={isActionButtonDisabled}
                                onClick={onRejectFactoringRequest}
                            >
                                <em className="mdi mdi-close start" />
                                Reject
                            </button>
                        </Row>
                    )}
                    {canFactorRequest &&
                        !!currentConnectedFactoringOrganizations?.length && (
                            <div style={{ position: 'relative' }}>
                                <button
                                    ref={sendToFactoringButtonHook?.buttonRef}
                                    type="button"
                                    className="button button-primary button-primary-outline button-sm"
                                    onClick={sendToFactoringButtonClick}
                                    disabled={isActionButtonDisabled}
                                >
                                    {currentConnectedFactoringOrganizations?.length ===
                                        1 && (
                                        <em className="mdi mdi-cash start" />
                                    )}
                                    {`Send ${selectedCount} selected to${
                                        currentConnectedFactoringOrganizations?.length >
                                        1
                                            ? ''
                                            : ` ${currentConnectedFactoringOrganizations[0]?.company_name}`
                                    }`}

                                    {currentConnectedFactoringOrganizations?.length >
                                        1 && (
                                        <em
                                            className={`mdi mdi-menu-${
                                                sendToFactoringButtonHook?.isComponentVisible
                                                    ? 'up'
                                                    : 'down'
                                            } end`}
                                        />
                                    )}
                                </button>
                                <FactoringButtonWrapper
                                    ref={sendToFactoringButtonHook?.ref}
                                >
                                    {sendToFactoringButtonHook?.isComponentVisible && (
                                        <FactoringButtons
                                            organizations={
                                                currentConnectedFactoringOrganizations
                                            }
                                            onPress={onSendToFactor}
                                        />
                                    )}
                                </FactoringButtonWrapper>
                            </div>
                        )}
                </>
            ) : (
                <>
                    <InputWrapper>
                        <div className="form-control-wrapper">
                            <SearchIcon src={IconSearch} />
                            <input
                                id="query"
                                className="form-control"
                                placeholder="Search"
                                value={query || ''}
                                onChange={e => onQueryChange(e.target.value)}
                                style={{
                                    paddingLeft: 29,
                                    paddingRight: 29,
                                    width: 187,
                                }}
                            />
                            <div className="form-control-info">
                                <em className="mdi mdi-information form-control-info-icon" />
                                <div className="form-control-info-container form-control-info-container-dim">
                                    Search by amount due OR organization name
                                    <br />
                                </div>
                            </div>
                        </div>
                    </InputWrapper>
                    {!archived && (
                        <InputWrapper>
                            <SelectComponent
                                isMulti
                                isClearable
                                placeholder="Invoice status"
                                value={selectedStatusesOptions}
                                onChange={onStatusChangeMethod}
                                options={statusOptions}
                            />
                        </InputWrapper>
                    )}
                    <InputWrapper style={{ width: 170 }}>
                        <SelectComponent
                            isMulti
                            isClearable
                            placeholder="Payment status"
                            value={selectedStatesOptions}
                            onChange={onStateChangeMethod}
                            options={statesOptions}
                        />
                    </InputWrapper>
                    {!!isFactoringRequestStatusAvailable && (
                        <InputWrapper style={{ width: 230 }}>
                            <SelectComponent
                                isMulti
                                isClearable
                                placeholder="Factoring request status"
                                value={selectedFactoringRequestStatusesOptions}
                                onChange={onFactoringRequestStatusChangeMethod}
                                options={factoringRequestStatusesOptions}
                            />
                        </InputWrapper>
                    )}
                </>
            )}
            {!!isLoading && (
                <Loader
                    type="ThreeDots"
                    color="#f37749"
                    visible
                    height={20}
                    width={50}
                />
            )}
        </FilterContainer>
    );
};
