type Props = {
    downloadPath?: string;
    onClick?: () => void;
};

export const Td5 = ({ downloadPath, onClick }: Props) => {
    return (
        <td>
            {downloadPath ? (
                <a
                    href={downloadPath}
                    download
                    // className="button button-default"
                    target="_blank"
                    rel="noreferrer"
                >
                    <em className="mdi mdi-download start" />
                </a>
            ) : (
                <button type="button" onClick={onClick}>
                    -
                </button>
            )}
        </td>
    );
};
