import styled from 'styled-components';
import { BASE_FONT } from '../../../theme/fonts';
import { TGroup } from '../../../types/types';

export const CompanyAvatarContainer = styled.div<{ index: number }>`
    width: 18px;
    height: 18px;

    background: ${({ index }) =>
        index % 4 === 0
            ? '#9B51E0'
            : index % 3 === 0
            ? '#27AE60'
            : index % 2 === 0
            ? '#EB5757'
            : '#0d71aa'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    margin-right: 6px;
`;

export const CompanyName = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: #000000;
`;

export const CompanyInitials = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */

    color: #ffffff;
`;

export const MemberFrom = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    /* identical to box height, or 133% */
    display: flex;
    align-items: center;

    color: #000000;
`;

export const Email = styled(BASE_FONT)`
    margin-left: 6.75px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    /* identical to box height, or 133% */
    display: flex;
    align-items: center;

    /* Dirty Blue */
    color: #0c161d;
`;

export const TableRow = styled.tr<{ last?: boolean }>`
    position: relative;
    &:hover ${CompanyName} {
        color: #f37749;
    }
    &:hover td {
        background-color: #f2f2f2;
    }
    td {
        background-color: #fff;
        padding-top: 18px;
        padding-bottom: 18px;
        box-shadow: ${({ last }) =>
            last ? 'none' : 'inset 0px -1px 0px #e1e1e1'};
        cell {
            vertical-align: middle;
        }
    }
`;

export const Status = styled(BASE_FONT)<{
    active?: boolean;
    status?: TGroup['status'];
}>`
    background: ${({ status }) =>
        status?.toLowerCase() === 'owner'
            ? '#1B9477'
            : status === 'active'
            ? '#CFFACF'
            : status === 'invited'
            ? '#f5f79b'
            : status === 'rejected'
            ? '#ffa500'
            : '#ffffff'};

    border: 1px solid
        ${({ status }) =>
            status?.toLowerCase() === 'owner'
                ? '#1B9477'
                : status === 'default'
                ? 'gray'
                : status === 'active'
                ? '#CFFACF'
                : status === 'invited'
                ? '#f5f79b'
                : status === 'rejected'
                ? '#ffa500'
                : '#080808'};

    border-radius: 3px;
    margin: 0px 0px;
    padding: 3px 5px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: ${({ status }) => (status === 'rejected' ? 'bold' : 'normal')};
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;

    color: ${({ status }) =>
        status?.toLowerCase() === 'owner'
            ? '#fff'
            : status === 'active'
            ? '#17AA51'
            : status === 'rejected'
            ? '#fff'
            : '#080808'};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const PlanUsageTitle = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;

    display: flex;
    align-items: center;

    color: #202531;
`;

export const PlanUsageWrapper = styled.div`
    margin-top: 13px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const PlanUsageContainer = styled.div`
    width: 100%;
    height: 3px;

    /* #F4F4F1 */
    background: #f4f4f1;
    border-radius: 40px;
`;

export const PlanUsage = styled.div<{ value?: number }>`
    width: ${({ value = 0 }) => value}%;
    height: 3px;
    border-radius: 40px 0px 0px 40px;

    background: #f37749;
`;

export const PlanUsagePercentages = styled(BASE_FONT)`
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    text-align: right;

    color: #202531;
`;
