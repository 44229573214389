import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableRow } from './styles';
import { Td1 } from './Td1';
import { Td2 } from './Td2';
import { Td4 } from './Td4';
import { FiltersHeader } from './FiltersHeader';
import { TdActions } from './TdActions';
import { Td11 } from './Td11';
import {
    AppHeader,
    CurrenciesFooter,
    PaginationButtons,
    Th,
} from '../../components';
import { asc, desc } from '../../services/helpers';
import {
    activateClientOrganization,
    fetchCurrentOrganizationStatistics,
    fetchFactoringClients,
    rejectClientOrganization,
} from '../../services/OrganizationsService';
import { getCountFactoringClients } from '../../state/collectionsCount';
import { Organization, OrganizationsState } from '../../types/types';
import { TotalAmounts } from '../Home/TotalAmounts';
import { getFactoringClients } from '../../state/organizationsFactoringClients';
import { getMyCurrentOrganization } from '../../state/myOrganizations';

type SortField =
    | 'status'
    | 'date_in_service'
    | 'volume_transactions'
    | 'company_name'
    | 'created_at'
    | 'request_status';

const sort = (
    data: OrganizationsState,
    order: 'ASC' | 'DESC',
    field: SortField = 'request_status',
): any[] => {
    const array: Organization[] = Object.values(data);
    if (array.length) {
        array.sort((a, b) => {
            const A =
                field === 'created_at'
                    ? DateTime.fromSQL(a?.created_at).valueOf()
                    : field === 'date_in_service'
                    ? a?.date_in_service
                        ? DateTime.fromSQL(a?.date_in_service).valueOf()
                        : 0
                    : field === 'request_status'
                    ? a?.organization_request_connection?.status || 'undefined'
                    : a[field];
            const B =
                field === 'created_at'
                    ? DateTime.fromSQL(b?.created_at).valueOf()
                    : field === 'date_in_service'
                    ? b?.date_in_service
                        ? DateTime.fromSQL(b?.date_in_service).valueOf()
                        : 0
                    : field === 'request_status'
                    ? b?.organization_request_connection?.status || 'undefined'
                    : b[field];
            if (order === 'DESC') {
                return desc(A, B);
            }
            return asc(A, B);
        });
    }
    return array;
};

export const FactoringClients = () => {
    const organizationsState = useSelector(getFactoringClients);
    const count = useSelector(getCountFactoringClients);

    const [sortField, setSortField] = useState<SortField>('request_status');
    const [sortOrder, setSortOrder] = useState<{
        [key: string]: 'ASC' | 'DESC';
    }>({
        commission: 'DESC',
        date_in_service: 'DESC',
        min_amount_to_factor: 'DESC',
        company_name: 'DESC',
        created_at: 'DESC',
        request_status: 'ASC',
    });

    const setOrderMethod = useCallback(
        (value: SortField) => {
            setSortField(prevState => {
                if (prevState === value) {
                    if (sortOrder[value] === 'ASC') {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'DESC',
                        }));
                    } else {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'ASC',
                        }));
                    }
                }
                return value;
            });
        },
        [sortOrder],
    );

    const organizations: Organization[] = useMemo(
        () => sort(organizationsState, sortOrder[sortField], sortField),
        [organizationsState, sortField, sortOrder],
    );
    const [meta, setMeta] = useState({
        current_page: 0,
        last_page: 1,
        total: count || 0,
    });

    const [isLoading, setLoading] = useState(false);

    const [query, setQuery] = useState<string>('');

    const [delayQuery, setDelayQuery] = useState<string>();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setQuery(delayQuery?.toUpperCase() || '');
        }, 350);

        return () => clearTimeout(delayDebounceFn);
    }, [delayQuery]);

    const fetchSomeOrganizations = useCallback(
        async (page = 1, update?: boolean) => {
            setLoading(true);
            const response = await fetchFactoringClients(
                {
                    page,
                    q: query,
                    sortField,
                    sortOrder: sortOrder[sortField],
                },
                update,
            );
            setLoading(false);
            if (response?.data?.meta) {
                setMeta(response?.data?.meta);
            }
        },
        [query, sortField, sortOrder],
    );

    const currentOrganization = useSelector(getMyCurrentOrganization);

    useEffect(() => {
        if (currentOrganization?.id) {
            (async () => {
                await fetchSomeOrganizations();
                await fetchCurrentOrganizationStatistics();
            })();
        }
    }, [currentOrganization?.id, fetchSomeOrganizations]);

    const onPressNext = useCallback(async () => {
        await fetchSomeOrganizations(meta.current_page + 1);
    }, [fetchSomeOrganizations, meta.current_page]);

    const onPressPrev = useCallback(async () => {
        await fetchSomeOrganizations(meta.current_page - 1);
    }, [fetchSomeOrganizations, meta.current_page]);

    const history = useHistory();

    const onClick = useCallback(
        (id: number) => {
            history.push(`/factoring-client/${id}`);
        },
        [history],
    );

    const onPressMore = useCallback(async () => {
        await fetchSomeOrganizations(meta.current_page + 1, true);
    }, [fetchSomeOrganizations, meta.current_page]);

    const onPressFirst = useCallback(async () => {
        await fetchSomeOrganizations(1);
    }, [fetchSomeOrganizations]);

    const onPressLast = useCallback(async () => {
        await fetchSomeOrganizations(meta.last_page);
    }, [fetchSomeOrganizations, meta.last_page]);

    const onPressPage = useCallback(
        async (page: number) => {
            await fetchSomeOrganizations(page);
        },
        [fetchSomeOrganizations],
    );

    // const [isTipVisible, setTipVisible] = useState(false);

    const [isActionButtonsDisabled, setActionButtonsDisabled] = useState(false);

    const activateClientOrganizationMethod = useCallback(
        async (id?: number) => {
            if (id) {
                setLoading(true);
                setActionButtonsDisabled(true);
                await activateClientOrganization(id);
                setLoading(false);
                setActionButtonsDisabled(false);
            }
        },
        [],
    );

    const rejectClientOrganizationMethod = useCallback(async (id?: number) => {
        if (id) {
            setLoading(true);
            setActionButtonsDisabled(true);
            await rejectClientOrganization(id);
            setLoading(false);
            setActionButtonsDisabled(false);
        }
    }, []);

    return (
        <div className="app-container">
            <AppHeader title="Factoring clients" />
            <div className="app-content" style={{ width: '100%' }}>
                <div className="app-body">
                    <TotalAmounts />
                    <FiltersHeader
                        isLoading={isLoading && !!count}
                        query={delayQuery}
                        onQueryChange={setDelayQuery}
                    />

                    <div className="table" style={{ position: 'relative' }}>
                        <table>
                            <tbody>
                                {!!count && (
                                    <tr className="tr-overview">
                                        <Th
                                            title="Factoring client"
                                            field="company_name"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.company_name}
                                            onPress={() =>
                                                setOrderMethod('company_name')
                                            }
                                        />
                                        <Th
                                            title="Request status"
                                            field="request_status"
                                            sortField={sortField}
                                            sortOrder={
                                                sortOrder?.request_status
                                            }
                                            onPress={() =>
                                                setOrderMethod('request_status')
                                            }
                                        />
                                        <Th
                                            title="Date of joining Troc Circle"
                                            field="date_in_service"
                                            sortField={sortField}
                                            sortOrder={
                                                sortOrder?.date_in_service
                                            }
                                            onPress={() =>
                                                setOrderMethod(
                                                    'date_in_service',
                                                )
                                            }
                                        />
                                        <Th
                                            title="Volume of transactions"
                                            field="volume_transactions"
                                            sortField={sortField}
                                            sortOrder={
                                                sortOrder?.volume_transactions
                                            }
                                            onPress={() =>
                                                setOrderMethod(
                                                    'volume_transactions',
                                                )
                                            }
                                        />

                                        <Th
                                            title="Actions"
                                            field="status"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.status}
                                            onPress={() =>
                                                setOrderMethod('status')
                                            }
                                        />
                                    </tr>
                                )}
                                {organizations.map((organization, index) => (
                                    <TableRow
                                        key={organization?.id.toString()}
                                        last={
                                            index === organizations?.length - 1
                                        }
                                    >
                                        <Td1
                                            name={organization?.company_name}
                                            email={organization?.email}
                                            logo={
                                                organization?.logo?.sizes
                                                    ?.thumbnail
                                            }
                                            onClick={() =>
                                                onClick(organization?.id)
                                            }
                                        />
                                        <Td11
                                            status={
                                                organization
                                                    ?.organization_request_connection
                                                    ?.status
                                            }
                                            onClick={() =>
                                                onClick(organization?.id)
                                            }
                                        />
                                        <Td2
                                            date={organization?.created_at}
                                            onClick={() =>
                                                onClick(organization?.id)
                                            }
                                        />

                                        <Td4
                                            amount={
                                                organization
                                                    ?.organization_request_connection
                                                    ?.volume_transactions
                                            }
                                            onClick={() =>
                                                onClick(organization?.id)
                                            }
                                        />
                                        <TdActions
                                            isButtonDisabled={
                                                isActionButtonsDisabled
                                            }
                                            status={
                                                organization
                                                    ?.organization_request_connection
                                                    ?.status
                                            }
                                            onActivate={() =>
                                                activateClientOrganizationMethod(
                                                    organization
                                                        ?.organization_request_connection
                                                        ?.id,
                                                )
                                            }
                                            onReject={() =>
                                                rejectClientOrganizationMethod(
                                                    organization
                                                        ?.organization_request_connection
                                                        ?.id,
                                                )
                                            }
                                            onClick={() =>
                                                onClick(organization?.id)
                                            }
                                        />
                                    </TableRow>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationButtons
                        // withoutBlock
                        isLoading={isLoading}
                        prevDisabled={meta?.current_page === 1 || isLoading}
                        nextDisabled={
                            meta?.current_page === meta?.last_page || isLoading
                        }
                        currentPage={meta?.current_page}
                        lastPage={meta?.last_page}
                        pagesDisabled={isLoading}
                        total={count}
                        onPressPrev={onPressPrev}
                        onPressNext={onPressNext}
                        onPressMore={onPressMore}
                        onPressFirst={onPressFirst}
                        onPressLast={onPressLast}
                        onPressPage={onPressPage}
                    />
                    {/* {isTipVisible && <Tip setTipVisible={setTipVisible} />} */}
                </div>
            </div>
            <CurrenciesFooter />
        </div>
    );
};
