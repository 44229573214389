import { AttachButton } from '../Transactions/AttachButton';
import {
    Attachment,
    AttachmentContainer,
    AttachmentName,
    AttachmentText,
    DeleteAttachmentButton,
} from '../Transactions/styles';

type Props = {
    name?: string;
    openFile: (e: any) => void;
    deleteAttachment: () => void;
};

export const AttachmentBlock = ({
    name,
    openFile,
    deleteAttachment,
}: Props) => {
    return (
        <>
            {!name && (
                <AttachButton
                    style={{
                        marginBottom: 20,
                    }}
                    title="Attach draft contract"
                    onPress={openFile}
                />
            )}
            {!!name && (
                <div
                    className="block block-overview-list"
                    style={{
                        paddingBottom: 20,
                    }}
                >
                    <div className="col-lg-12">
                        {!!name && (
                            <AttachmentContainer>
                                <Attachment>
                                    <em
                                        className="mdi mdi-attachment start"
                                        style={{
                                            transform: 'rotate(-45deg)',
                                            fontSize: 18,
                                            color: '#f37749',
                                        }}
                                    />
                                    <AttachmentName>{name}</AttachmentName>
                                </Attachment>
                                <DeleteAttachmentButton
                                    onClick={deleteAttachment}
                                >
                                    <em className="mdi mdi-delete-outline start" />
                                    <AttachmentText>Delete</AttachmentText>
                                </DeleteAttachmentButton>
                            </AttachmentContainer>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
