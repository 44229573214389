import React from 'react';

type Props = {
    id?: string;
    title?: string;
    style?: React.CSSProperties | undefined;
    className?: string;
    onPress?: (e: any) => void;
    multiple?: boolean;
};

export const AttachButton = ({
    id,
    title,
    style,
    className,
    multiple = false,
    onPress,
}: Props) => {
    return (
        <div
            className={
                className || 'button button-primary button-primary-outline'
            }
            style={style}
        >
            <label htmlFor={id || 'files-input'}>
                <input
                    id={id || 'files-input'}
                    name="files"
                    type="file"
                    accept="image/*, .doc, .docx, application/pdf"
                    onChange={onPress}
                    className="hidden"
                    multiple={multiple}
                />
                <em
                    className="mdi mdi-attachment start"
                    style={{ transform: 'rotate(-45deg)' }}
                />
                {title || 'Attach document'}
            </label>
        </div>
    );
};
