import { useSelector } from 'react-redux';
import { CurrencyIcon, Amount } from './styles';
import { getDefaultCurrency } from '../../state/user';
import { Row } from '../../components';
import { amountOptions } from '../../services/helpers';

type Props = {
    amount?: number;
    onClick?: () => void;
};

export const Td4 = ({ amount, onClick }: Props) => {
    const defaultCurrency = useSelector(getDefaultCurrency);
    return (
        <td>
            <button type="button" onClick={onClick}>
                <Row>
                    {!!defaultCurrency?.icon?.svg && (
                        <CurrencyIcon src={defaultCurrency?.icon?.svg} />
                    )}
                    <Amount>
                        {amount?.toLocaleString('en', amountOptions) || '0.00'}
                    </Amount>
                </Row>
            </button>
        </td>
    );
};
