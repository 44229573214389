import { AttachmentBlock } from './AttachmentBlock';
import { FormError } from '../../components';
import { getFloat, setPercentageLimit } from '../../services/helpers';
import { CreateOrganizationProps } from '../../types/types';

type Props = {
    visible?: boolean;
    commission?: number;
    min_amount_to_factor?: number;
    description?: string;
    errors: { [key: string]: any };

    attachmentName?: string;

    organizationChanged: (
        key: keyof CreateOrganizationProps,
        value: string | number,
    ) => void;

    openFile: (e: any) => void;
    deleteAttachment: () => void;
};

export const FactoringEditableBlock = ({
    visible,
    commission,
    min_amount_to_factor,
    description,
    errors,
    attachmentName,
    organizationChanged,
    openFile,
    deleteAttachment,
}: Props) => {
    return visible ? (
        <>
            <div className="form-title">
                Factoring organization specific settings
            </div>
            <div className="row">
                <div className="form-group col-lg-6 col-xs-12">
                    <div className="form-label">
                        Minimum amount to factor, USD
                    </div>
                    <div className="form-control-wrapper">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Minimum amount to factor, USD"
                            value={
                                !!min_amount_to_factor ||
                                min_amount_to_factor === 0
                                    ? min_amount_to_factor
                                    : ''
                            }
                            onChange={e =>
                                organizationChanged(
                                    'min_amount_to_factor',
                                    getFloat(e.target.value),
                                )
                            }
                        />
                    </div>
                    <FormError errors={errors?.min_amount_to_factor} />
                </div>
                <div className="form-group col-lg-6 col-xs-12">
                    <div className="form-label">Average interest rate, %</div>
                    <div className="form-control-wrapper">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Average interest rate, %"
                            value={
                                !!commission || commission === 0
                                    ? commission
                                    : ''
                            }
                            onChange={e =>
                                organizationChanged(
                                    'commission',
                                    setPercentageLimit(e.target.value),
                                )
                            }
                        />
                    </div>
                    <FormError errors={errors?.commission} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-lg-12 col-xs-12">
                    <div className="form-label">Description</div>
                    <div className="form-control-wrapper">
                        <textarea
                            className="form-control"
                            placeholder="Description"
                            value={description || ''}
                            onChange={e =>
                                organizationChanged(
                                    'description',
                                    e.target.value,
                                )
                            }
                        />
                    </div>
                    <FormError errors={errors?.description} />
                </div>
            </div>
            <AttachmentBlock
                name={attachmentName}
                openFile={openFile}
                deleteAttachment={deleteAttachment}
            />
        </>
    ) : null;
};
