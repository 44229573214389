import Modal from 'react-modal';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    CloseButton,
    CloseButtonText,
    Content,
    Footer,
    Header,
    HeaderSubtitle,
    HeaderTitle,
} from './styles';
import { InviteItem } from './InviteItem';
import { getPermissions } from '../../state/permissions';
import { fetchPermissions } from '../../services/PermissionsService';
import { isEmail, removeSnake } from '../../services/helpers';

import { FooterButtons, Spinner } from '../../components';
import { inviteMember } from '../../services/MembersService';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 1060,
        padding: 0,
        overflow: 'visible',
    },
};

Modal.setAppElement('body');

type Props = {
    isModalOpen: boolean;
    onRequestClose: () => void;
};

type Invite = {
    first_name: string;
    last_name: string;
    email: string;
    permissions: any;
};

export const InviteModal = ({ isModalOpen, onRequestClose }: Props) => {
    const storedPermissions = useSelector(getPermissions);

    useEffect(() => {
        (async () => {
            await fetchPermissions();
        })();
    }, []);

    const permissionsOptions = useMemo(
        () =>
            storedPermissions.map(item => ({
                value: item.id?.toString(),
                label: item.name,
            })),
        [storedPermissions],
    );

    const [errors, setErrors] = useState<{ [key: string]: any }[]>([{}]);

    const presetPermissions = useMemo(
        () =>
            storedPermissions
                ?.filter(
                    storedPermission =>
                        storedPermission?.slug === 'transactions' ||
                        storedPermission?.slug === 'invoices' ||
                        storedPermission?.slug === 'circles_and_chains' ||
                        storedPermission?.slug === 'payments',
                )
                .map(filteredPermision => ({
                    value: filteredPermision?.id,
                    label: filteredPermision?.name,
                })),
        [storedPermissions],
    );

    const [invites, setInvites] = useState<Invite[]>([
        {
            first_name: '',
            last_name: '',
            email: '',
            permissions: presetPermissions,
        },
    ]);

    const requiredFields = useMemo(
        () => invites?.map(() => ['first_name', 'last_name', 'email']),
        [invites],
    );

    const onClose = useCallback(() => {
        setInvites([
            {
                first_name: '',
                last_name: '',
                email: '',
                permissions: presetPermissions,
            },
        ]);
        setErrors([{}]);
        onRequestClose();
    }, [onRequestClose, presetPermissions]);

    const changeFirstName = useCallback((value: string, index: number) => {
        if (!value) {
            setErrors(prevState => {
                const newState = [...prevState];
                newState[index] = {
                    ...prevState[index],
                    first_name: ['The first name is required'],
                };
                return newState;
            });
        } else {
            setErrors(prevState => {
                const newState = [...prevState];
                if (newState[index]) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { first_name: _, ...res } = newState[index];
                    newState[index] = res;
                }
                return newState;
            });
        }
        setInvites(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...prevState[index],
                first_name: value,
            };
            return newState;
        });
    }, []);

    const changeLastName = useCallback((value: string, index: number) => {
        if (!value) {
            setErrors(prevState => {
                const newState = [...prevState];
                newState[index] = {
                    ...prevState[index],
                    last_name: ['The last name is required'],
                };
                return newState;
            });
        } else {
            setErrors(prevState => {
                const newState = [...prevState];
                if (newState[index]) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { last_name: _, ...res } = newState[index];
                    newState[index] = res;
                }
                return newState;
            });
        }
        setInvites(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...prevState[index],
                last_name: value,
            };
            return newState;
        });
    }, []);

    const changeEmail = useCallback((value: string, index: number) => {
        if (!isEmail(value as string)) {
            setErrors(prevState => {
                const newState = [...prevState];
                newState[index] = {
                    ...prevState[index],
                    email: ['The email must be a valid email address'],
                };
                return newState;
            });
        } else {
            setErrors(prevState => {
                const newState = [...prevState];
                if (newState[index]) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { email: _, ...res } = newState[index];
                    newState[index] = res;
                }
                return newState;
            });
        }
        setInvites(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...prevState[index],
                email: value,
            };
            return newState;
        });
    }, []);

    const changePermissions = useCallback((value: any, index: number) => {
        setErrors(prevState => {
            const newState = [...prevState];
            if (newState[index]) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { permissions: _, ...res } = newState[index];
                newState[index] = res;
            }
            return newState;
        });
        setInvites(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...prevState[index],
                permissions: value,
            };
            return newState;
        });
    }, []);

    const addAnother = useCallback(() => {
        if (invites.length < 3) {
            setInvites(prevState => [
                ...prevState,
                {
                    first_name: '',
                    last_name: '',
                    email: '',
                    permissions: presetPermissions,
                },
            ]);
        }
    }, [invites.length, presetPermissions]);

    const deleteSome = useCallback((index: number) => {
        setInvites(prevState => {
            const newState = prevState.filter((_, i) => i !== index);
            return newState;
        });
        setErrors(prevState => {
            const newState = prevState.filter((_, i) => i !== index);
            return newState;
        });
    }, []);

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        if (
            !errors?.some(
                item =>
                    typeof item === 'object' && !!Object?.values(item).length,
            )
        ) {
            requiredFields.forEach((item, index) => {
                item?.forEach(itm => {
                    if (
                        (!errors[index]?.hasOwnProperty(itm) &&
                            (!invites[index]?.hasOwnProperty(itm) ||
                                !invites[index][itm])) ||
                        (itm === 'permissions' &&
                            !invites[index]?.permissions?.length)
                    ) {
                        allFieldsFilled = false;
                        const errorMessage = `The ${removeSnake(
                            itm,
                        )}  field is required`;
                        setErrors(prevState => {
                            const array = [...prevState];
                            array[index] = {
                                [itm]: [errorMessage],
                                ...array[index],
                            };
                            return array;
                        });
                    }
                });
            });
        }
        return allFieldsFilled;
    }, [errors, invites, requiredFields]);

    const [isSuccess, setSuccess] = useState<boolean[]>([]);

    const [shouldClear, setShouldClear] = useState(false);

    const clearSuccess = useCallback(() => {
        setTimeout(() => {
            setShouldClear(false);
            isSuccess.forEach((item, index) => {
                if (item) {
                    deleteSome(index);
                }
            });
            setSuccess([]);

            if (
                !errors?.some(
                    item =>
                        typeof item === 'object' &&
                        !!Object?.values(item).length,
                )
            ) {
                onClose();
            }
        }, 1500);
    }, [deleteSome, errors, isSuccess, onClose]);

    const onSubmit = useCallback(async () => {
        if (
            !errors?.some(
                item =>
                    typeof item === 'object' && !!Object?.values(item).length,
            )
        ) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                await Promise.all(
                    invites?.map(async (invitation, index) => {
                        const response = await inviteMember({
                            first_name: invitation?.first_name,
                            last_name: invitation?.last_name,
                            email: invitation?.email,
                            permissions: invitation?.permissions?.map(
                                (item: { value: string; label: string }) =>
                                    Number(item?.value),
                            ),
                        });
                        if (
                            response?.status === 201 ||
                            response?.status === 200
                        ) {
                            setSuccess(prevState => {
                                const nextState = [...prevState];
                                nextState[index] = true;
                                return nextState;
                            });
                        } else {
                            setSuccess(prevState => {
                                const nextState = [...prevState];
                                nextState[index] = false;
                                return nextState;
                            });
                        }

                        if (response?.response?.data?.errors) {
                            const {
                                first_name,
                                last_name,
                                email,
                                permissions,
                                ...other
                            } = response?.response?.data?.errors;
                            if (first_name)
                                setErrors(prevState => {
                                    const array = [...prevState];
                                    array[index] = {
                                        first_name,
                                        ...array[index],
                                    };
                                    return array;
                                });
                            if (last_name)
                                setErrors(prevState => {
                                    const array = [...prevState];
                                    array[index] = {
                                        last_name,
                                        ...array[index],
                                    };
                                    return array;
                                });
                            if (email)
                                setErrors(prevState => {
                                    const array = [...prevState];
                                    array[index] = {
                                        email,
                                        ...array[index],
                                    };
                                    return array;
                                });
                            if (permissions)
                                setErrors(prevState => {
                                    const array = [...prevState];
                                    array[index] = {
                                        permissions,
                                        ...array[index],
                                    };
                                    return array;
                                });

                            if (Object.keys(other).length) {
                                console.log('other errors:', other);
                            }
                        }
                    }),
                );
                setButtonDisabled(false);
                setShouldClear(true);
            }
        }
    }, [checkEmptyValues, errors, invites]);

    useEffect(() => {
        if (shouldClear) {
            clearSuccess();
        }
    }, [clearSuccess, shouldClear]);

    return (
        <Modal
            isOpen={isModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Invite member"
        >
            <Header>
                <div>
                    <HeaderTitle>Invite New Members</HeaderTitle>
                    <HeaderSubtitle>
                        Invite new members by email to join organization.
                    </HeaderSubtitle>
                </div>
                <CloseButton onClick={onClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>

            <Content>
                <div className="block block-invite-modal">
                    <div className="form">
                        <div className="invite-list">
                            <div className="invite-item">
                                <div className="invite-item-cell">
                                    <div className="form-label">First name</div>
                                </div>
                                <div className="invite-item-cell">
                                    <div className="form-label">Last name</div>
                                </div>
                                <div className="invite-item-cell">
                                    <div className="form-label">
                                        Email address
                                    </div>
                                </div>
                                <div className="invite-item-cell">
                                    <div className="form-label">
                                        Permissions
                                    </div>
                                </div>
                                <div className="invite-item-action" />
                            </div>

                            {invites?.map((invite, index) => (
                                <InviteItem
                                    key={index.toString()}
                                    success={isSuccess[index] || false}
                                    firstName={invite.first_name}
                                    onFirstNameChange={e =>
                                        changeFirstName(e?.target?.value, index)
                                    }
                                    lastName={invite.last_name}
                                    onLastNameChange={e =>
                                        changeLastName(e?.target?.value, index)
                                    }
                                    email={invite.email}
                                    onEmailChange={e =>
                                        changeEmail(e?.target?.value, index)
                                    }
                                    permissionsOptions={permissionsOptions}
                                    onDelete={() => deleteSome(index)}
                                    selectedPermissions={invite.permissions}
                                    onPermissionsChange={(value: any) =>
                                        changePermissions(value, index)
                                    }
                                    isDeleteButtonHide={invites?.length === 1}
                                    errors={errors[index]}
                                />
                            ))}
                        </div>
                        {invites.length < 3 && (
                            <div
                                role="button"
                                tabIndex={0}
                                className={`button button-text button-text-primary ${
                                    invites.length > 2 && 'button-disabled'
                                }`}
                                onClick={addAnother}
                                onKeyDown={addAnother}
                            >
                                {' '}
                                <em className="mdi mdi-plus start" />
                                Add another
                            </div>
                        )}
                    </div>
                </div>
            </Content>
            <Footer>
                <FooterButtons
                    invitationDisabled={
                        isButtonDisabled ||
                        errors?.some(
                            item =>
                                typeof item === 'object' &&
                                !!Object?.values(item).length,
                        )
                    }
                    inviteForm
                    onCancel={onClose}
                    onSubmit={onSubmit}
                />
            </Footer>
            {!!isButtonDisabled && <Spinner />}
        </Modal>
    );
};
