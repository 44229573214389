import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;

    box-shadow: 0px 10px 20px rgba(83, 83, 119, 0.149);
    border-radius: 5px;
`;

export const Button = styled.div<{ first?: boolean; last?: boolean }>`
    position: static;
    min-width: 140px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    border-top-left-radius: ${({ first }) => (first ? 5 : 0)}px;
    border-top-right-radius: ${({ first }) => (first ? 5 : 0)}px;
    border-bottom-left-radius: ${({ last }) => (last ? 5 : 0)}px;
    border-bottom-right-radius: ${({ last }) => (last ? 5 : 0)}px;

    display: flex;
    align-items: center;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;

    color: #000000;

    background: #fbfbfb;
    &:hover {
        cursor: pointer;
        background-color: #ffffff;
        color: #f37749;
    }
    box-shadow: ${({ last }) =>
        !last ? 'inset 0px -1px 0px #e1e1e1' : 'unset'};
`;

export const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 10px;
`;
