import { CheckIcon, Icon, TypeCardContainer, TypeCardTitle } from './styles';

import iconRegular from '../../assets/img/pane-icons/icon-regular.svg';
import iconFactoring from '../../assets/img/pane-icons/icon-factoring.svg';

type Props = {
    type: 'regular' | 'factoring';
    active?: boolean;
    onPress?: () => void;
};

export const TypeCard = ({ type, active, onPress }: Props) => {
    return (
        <div className="form-group col-lg-6 col-xs-12">
            <TypeCardContainer
                role="button"
                tabIndex={0}
                onClick={onPress}
                onKeyDown={onPress}
            >
                <Icon src={type === 'regular' ? iconRegular : iconFactoring} />
                <TypeCardTitle active={active}>
                    {type === 'regular'
                        ? 'Regular organization'
                        : 'Factoring organization'}
                </TypeCardTitle>
                {active && <CheckIcon className="mdi mdi-check" />}
            </TypeCardContainer>
        </div>
    );
};
