import { memo, useState } from 'react';
import Icon from '@mdi/react';
import { mdiCheckDecagramOutline } from '@mdi/js';
import {
    AmountLimit,
    CheckedView,
    Footer,
    Header,
    HeaderLeft,
    IconContainer,
    Period,
    PlanCardContainer,
    PlanName,
    Price,
} from './styles';

import { TBillingPlan } from '../../../types/types';
import {
    amountOptions,
    getAmountDecimal,
    getAmountInteger,
} from '../../../services/helpers';

type PlanCardProps = {
    plan: TBillingPlan;
    isSelected?: boolean;
    isLoaded?: boolean;
    onSelect?: (planId: number) => void;
};

export const PlanCard = memo(
    ({ plan, isSelected, isLoaded, onSelect }: PlanCardProps) => {
        const [isRotated, setRotated] = useState(false);
        return (
            <PlanCardContainer planId={plan?.id}>
                <Header>
                    <HeaderLeft>
                        <IconContainer
                            planId={plan.id}
                            onMouseEnter={() => setRotated(true)}
                            onMouseLeave={() => setRotated(false)}
                        >
                            <Icon
                                path={mdiCheckDecagramOutline}
                                size={1}
                                spin={isLoaded || isRotated}
                                color="white"
                            />
                        </IconContainer>
                        <PlanName>{plan?.title}</PlanName>
                    </HeaderLeft>
                    {(!!plan?.price || plan?.price === 0) && (
                        <div>
                            <Price>
                                ${getAmountInteger(Number(plan?.price || 0))}
                                <sup>
                                    {getAmountDecimal(Number(plan?.price || 0))}
                                </sup>
                            </Price>
                            <Period>month</Period>
                        </div>
                    )}
                </Header>
                <AmountLimit>
                    netted amount $
                    {plan?.limit_from?.toLocaleString('en', amountOptions)}-$
                    {plan.limit_to?.toLocaleString('en', amountOptions)}
                </AmountLimit>

                <Footer>
                    <button
                        type="button"
                        className="button button-default button-sm"
                        onClick={() => onSelect?.(plan?.id)}
                    >
                        <em
                            className={`mdi mdi-${
                                isSelected ? 'check' : 'plus'
                            } start`}
                        />
                        {isSelected ? 'Selected' : 'Connect'}
                    </button>

                    {!!isSelected && (
                        <CheckedView planId={plan?.id}>
                            <em
                                className="mdi mdi-check start"
                                style={{ color: 'white' }}
                            />
                        </CheckedView>
                    )}
                </Footer>
            </PlanCardContainer>
        );
    },
);
