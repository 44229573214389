import { Status } from './styles';
import { OrganizationRequestConnection } from '../../types/types';

type Props = {
    status?: OrganizationRequestConnection['status'];
    onClick?: () => void;
};

export const Td11 = ({ status, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                // style={{ alignItems: 'flex-start' }}
            >
                <Status active={status === 'Active'} status={status}>
                    {status || 'Not connected'}
                </Status>
            </button>
        </td>
    );
};
