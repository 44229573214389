import { useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import iconSearch from '../../assets/img/icons/icon-search.svg';
import { FilterContainer, InputWrapper, SearchIcon, SelectComponent } from '..';
import { TPaymentHistory } from '../../types/types';

type Props = {
    isLoading?: boolean;
    query?: string;
    state?: TPaymentHistory['state'][];
    onQueryChange?: (value: string) => void;
    onStateChange: (value: TPaymentHistory['state'][] | []) => void;
};

export const FiltersHeader = ({
    isLoading,
    query,
    state,
    onQueryChange,
    onStateChange,
}: Props) => {
    const states: TPaymentHistory['state'][] = useMemo(
        () => ['active', 'incomplete'],
        [],
    );

    const statesOptions = useMemo(
        () =>
            states?.map(s => ({
                value: s,
                label: s,
            })),
        [states],
    );
    const selectedStatesOptions = useMemo(
        () =>
            state?.map(s => ({
                value: s,
                label: s,
            })),
        [state],
    );
    const onStateChangeMethod = useCallback(
        (
            objArr:
                | {
                      value: TPaymentHistory['state'];
                      label: TPaymentHistory['state'];
                  }[]
                | [],
        ) => {
            onStateChange(objArr?.map(item => item?.value));
        },
        [onStateChange],
    );

    return (
        <FilterContainer className="form">
            {!!onQueryChange && (
                <InputWrapper>
                    <div className="form-control-wrapper">
                        <SearchIcon src={iconSearch} />
                        <input
                            id="query"
                            className="form-control"
                            placeholder="Search"
                            value={query || ''}
                            onChange={e => onQueryChange(e.target.value)}
                            style={{
                                paddingLeft: 29,
                                paddingRight: 29,
                                width: 187,
                            }}
                        />
                        <div className="form-control-info">
                            <em className="mdi mdi-information form-control-info-icon" />
                            <div className="form-control-info-container form-control-info-container-dim">
                                Search by plan name
                            </div>
                        </div>
                    </div>
                </InputWrapper>
            )}
            <InputWrapper>
                <SelectComponent
                    isMulti
                    isClearable
                    placeholder="Payment state"
                    value={selectedStatesOptions}
                    onChange={onStateChangeMethod}
                    options={statesOptions}
                />
            </InputWrapper>
            {isLoading && (
                <div style={{ marginLeft: 30 }}>
                    <Loader
                        type="ThreeDots"
                        color="#f37749"
                        visible
                        height={20}
                        width={50}
                    />
                </div>
            )}
        </FilterContainer>
    );
};
