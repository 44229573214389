import { useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { Container, RelativeContainer } from './styles';

type Props = {
    relative?: boolean;
    marginTop?: number;
};

export const Spinner = ({ relative, marginTop }: Props) => {
    const renderSpinner = useMemo(
        () => (
            <Loader
                type="Oval"
                color="#f37749"
                visible
                height={35}
                width={35}
            />
        ),
        [],
    );
    return relative ? (
        <RelativeContainer>{renderSpinner}</RelativeContainer>
    ) : (
        <Container marginTop={marginTop}>{renderSpinner}</Container>
    );
};
