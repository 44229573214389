import { AxiosResponse } from 'axios';
import api from './axiosConfig';
import { store } from '../state/store';

import { memberDelete, membersSet, memberUpdate } from '../state/members';

export const inviteMember: Promise<AxiosResponse<any>> | any = async (props: {
    first_name: string;
    last_name: string;
    email: string;
    permissions: number[];
}) => {
    const response = await api.post('/account/invite-user-new', props);
    if (
        (response.status === 200 || response.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(memberUpdate(response?.data?.data));
    }
    return response;
};

export const fetchMembers = async () => {
    const response = await api(`/members`);
    if (
        (response.status === 200 || response.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(membersSet(response.data.data));
    }
    return response;
};

export const fetchMember = async (id: number) => {
    const response = await api(`/members/${id}`);
    if (
        (response.status === 200 || response.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(memberUpdate(response?.data?.data));
    }
    return response;
};

export const deleteMember = async (id: number) => {
    const response = await api.delete(`/members/${id}`);
    if (response.status === 200 || response.status === 201) {
        store.dispatch(memberDelete(id));
    }
    return response;
};

export const deleteAllMembers = async () => {
    const response = await api.delete('/members/all');
    if (response.status === 200 || response.status === 201) {
        store.dispatch(membersSet());
    }
    return response;
};

export const updateMemberPermissions:
    | Promise<AxiosResponse<any>>
    | any = async (id: number, permissions: number[]) => {
    const response = await api.put(`/members/${id}`, {
        permissions,
    });
    if (
        response.status === 200 ||
        (response.status === 201 && response?.data?.data)
    ) {
        store.dispatch(memberUpdate(response?.data?.data));
    }
    return response;
};
