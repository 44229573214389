import { DateTime } from 'luxon';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

import {
    Chevron,
    CloseButton,
    CloseButtonText,
    Content,
    Footer,
    Header,
    HeaderTitle,
    Icon,
    IconWrapper,
    ItemAvatarContainer,
    ItemAvatarTitle,
    Left,
    LeftRef,
    ListWrapper,
    PersonWrapper,
    RowElement,
    SentTo,
    SmallCard,
    SmallCardInput,
    SmallCardText,
    UserAvatar,
    UserEmail,
    UserListWrapper,
    UsersList,
} from './styles';
import { FooterButtons, FormError, Spinner } from '..';
import { useComponentVisible } from '../../hooks';
import { UserItem } from '../../screens/Transactions/UserItem';
import {
    desc,
    getInitials,
    hideEmail,
    removeSnake,
} from '../../services/helpers';
import {
    fetchCurrentOrganizationStatistics,
    fetchOrganizations,
    inviteOrganizationsIntoGroup,
} from '../../services/OrganizationsService';
import { getOrganizations } from '../../state/organizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { Organization, OrganizationsState } from '../../types/types';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // width: 750,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type Props = {
    groupId: number | undefined;
    isModalOpen: boolean;
    onRequestClose: () => void;
};

const sort = (organizations: OrganizationsState): Organization[] => {
    const organizationsArray: Organization[] = Object.values(organizations);
    organizationsArray.sort((a, b) =>
        desc(
            DateTime.fromSQL(a.created_at).valueOf(),
            DateTime.fromSQL(b.created_at).valueOf(),
        ),
    );
    return organizationsArray;
};

export const InviteGroupsMembersModal = ({
    groupId,
    isModalOpen,
    onRequestClose,
}: Props) => {
    const organizationsState = useSelector(getOrganizations);

    const myCurrentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const organizations = useMemo(() => sort(organizationsState), [
        organizationsState,
    ]);

    const [checkedMember, setCheckedMember] = useState<Organization>();

    const [query, setQuery] = useState<string>();

    const [meta, setMeta] = useState({ current_page: 0, last_page: 1 });

    const [isOrganizationsLoading, setOrganizationsLoading] = useState(false);

    const fetchSomeOrganizations = useCallback(
        async (currentPage = 0, lastPage = 1) => {
            const page = currentPage + 1;
            if (currentPage < lastPage) {
                setOrganizationsLoading(true);
                const organizationsResponse = await fetchOrganizations({
                    page,
                    per_page: 20,
                    q: query,
                });
                setOrganizationsLoading(false);
                if (organizationsResponse?.data?.meta) {
                    setMeta(organizationsResponse?.data?.meta);
                }
            }
        },
        [query],
    );

    useEffect(() => {
        if (myCurrentOrganizationId && isModalOpen) {
            setCheckedMember(undefined);
            (async () => {
                await fetchSomeOrganizations();
            })();
        }
    }, [fetchSomeOrganizations, isModalOpen, myCurrentOrganizationId]);

    const loadMoreUsers = useCallback(async () => {
        await fetchSomeOrganizations(meta.current_page, meta.last_page);
    }, [fetchSomeOrganizations, meta.current_page, meta.last_page]);

    const userListHook = useComponentVisible(false);

    const [searchValue, setSearchValue] = useState('');

    const search = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e?.target?.value;
            setSearchValue(value);
            const delayDebounceFn = setTimeout(() => {
                setQuery(value);
            }, 350);
            if (value && value?.length > 0 && !checkedMember) {
                userListHook?.setIsComponentVisible(true);
            }
            return () => clearTimeout(delayDebounceFn);
        },
        [checkedMember, userListHook],
    );

    const onFocusSearchInput = useCallback(() => {
        userListHook?.setIsComponentVisible(true);
    }, [userListHook]);

    const onOrganizationSelect = useCallback(
        (organization: Organization) => {
            setCheckedMember(organization);
            setUserInputShown(false);
            userListHook?.setIsComponentVisible(false);
        },
        [userListHook],
    );

    const inputRefUser = useRef<HTMLInputElement>(null);

    const [isUserInputShown, setUserInputShown] = useState(false);

    const showUserInput = useCallback(() => {
        setCheckedMember(undefined);
        setUserInputShown(true);
    }, []);

    useEffect(() => {
        if (isUserInputShown) {
            if (inputRefUser?.current) {
                setCheckedMember(undefined);
                inputRefUser?.current?.focus();
            }
        }
    }, [isUserInputShown]);

    const handleUsersScroll = useCallback(
        async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            const bottom =
                e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 2 <
                e.currentTarget.clientHeight;

            if (bottom && !isOrganizationsLoading) {
                await loadMoreUsers();
            }
        },
        [isOrganizationsLoading, loadMoreUsers],
    );

    const displayUsersListMethod = useCallback(() => {
        userListHook?.setIsComponentVisible(prevState => !prevState);
    }, [userListHook]);

    const [isLoading, setLoading] = useState(false);

    const onClose = useCallback(() => {
        setCheckedMember(undefined);
        onRequestClose();
        setQuery('');
        setErrors({});
    }, [onRequestClose]);

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const onSubmit = useCallback(async () => {
        setErrors({});
        if (checkedMember?.id && groupId) {
            setLoading(true);
            const response = await inviteOrganizationsIntoGroup(groupId, {
                id: checkedMember?.id,
            });
            setLoading(false);
            if (response.status === 201 || response.status === 200) {
                await fetchCurrentOrganizationStatistics();
                onClose();
            } else if (response?.response?.data?.errors) {
                setErrors(response?.response?.data?.errors);
            }
        }
    }, [checkedMember?.id, groupId, onClose]);

    return (
        <Modal
            isOpen={isModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Invite group member"
        >
            <Header>
                <HeaderTitle>Add organization into the group</HeaderTitle>
                <CloseButton onClick={onClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>

            <Content>
                <>
                    <RowElement>
                        <SmallCard
                            active={
                                userListHook?.isComponentVisible ||
                                !!checkedMember?.id
                            }
                        >
                            <Left>
                                <SentTo>
                                    {checkedMember?.id ? 'Invite:' : ''}
                                </SentTo>
                                <LeftRef ref={userListHook?.buttonRef}>
                                    <IconWrapper
                                        onClick={displayUsersListMethod}
                                    >
                                        {checkedMember?.id ? (
                                            checkedMember?.logo?.sizes
                                                ?.thumbnail ? (
                                                <UserAvatar
                                                    src={
                                                        checkedMember?.logo
                                                            ?.sizes?.thumbnail
                                                    }
                                                    alt="company logo"
                                                />
                                            ) : (
                                                <ItemAvatarContainer large>
                                                    <ItemAvatarTitle large>
                                                        {checkedMember?.company_name &&
                                                            getInitials(
                                                                checkedMember?.company_name,
                                                            )}
                                                    </ItemAvatarTitle>
                                                </ItemAvatarContainer>
                                            )
                                        ) : (
                                            <Icon className="mdi mdi-help" />
                                        )}
                                    </IconWrapper>
                                    <Chevron
                                        className={`mdi mdi-chevron-${
                                            userListHook?.isComponentVisible
                                                ? 'up'
                                                : 'down'
                                        }`}
                                        onClick={displayUsersListMethod}
                                    />
                                </LeftRef>
                            </Left>

                            {!isUserInputShown ? (
                                <PersonWrapper margin onClick={showUserInput}>
                                    <SmallCardText large={!!checkedMember?.id}>
                                        {checkedMember?.id
                                            ? checkedMember?.company_name
                                            : 'Select organization to invite'}
                                    </SmallCardText>
                                    <UserEmail>
                                        {!!checkedMember?.email &&
                                            hideEmail(checkedMember?.email)}
                                    </UserEmail>
                                </PersonWrapper>
                            ) : (
                                <SmallCardInput
                                    ref={inputRefUser}
                                    placeholder="Search or select "
                                    value={searchValue}
                                    onChange={search}
                                    onFocus={onFocusSearchInput}
                                />
                            )}
                            <ListWrapper ref={userListHook?.ref}>
                                {userListHook?.isComponentVisible && (
                                    <UsersList large={false}>
                                        <>
                                            <UserListWrapper
                                                onScroll={handleUsersScroll}
                                            >
                                                {organizations?.map(
                                                    organization => (
                                                        <UserItem
                                                            key={organization?.id?.toString()}
                                                            person={
                                                                organization?.company_name
                                                            }
                                                            avatar={
                                                                organization
                                                                    ?.logo
                                                                    ?.sizes
                                                                    ?.thumbnail
                                                            }
                                                            email={
                                                                organization?.email
                                                            }
                                                            checked={
                                                                checkedMember?.id ===
                                                                organization?.id
                                                            }
                                                            handleClick={() =>
                                                                onOrganizationSelect(
                                                                    organization,
                                                                )
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </UserListWrapper>
                                        </>
                                    </UsersList>
                                )}
                            </ListWrapper>
                        </SmallCard>
                    </RowElement>

                    {!!Object.entries(errors)?.length && (
                        <div
                            className="form"
                            style={{
                                marginLeft: 10,
                                marginTop: 10,
                            }}
                        >
                            {Object.entries(errors).map((err, index) => (
                                <div
                                    key={index.toString()}
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                    }}
                                >
                                    <div className="form-error">
                                        {removeSnake(err[0])?.toUpperCase()}:{' '}
                                    </div>
                                    <FormError errors={err[1]} />
                                </div>
                            ))}
                        </div>
                    )}
                </>
            </Content>
            <Footer>
                <FooterButtons
                    submitText="Invite"
                    disabled={!checkedMember?.id}
                    onCancel={onRequestClose}
                    onSubmit={onSubmit}
                />
            </Footer>
            {!!isLoading && <Spinner />}
        </Modal>
    );
};
