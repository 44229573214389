import { DateTime } from 'luxon';
import React from 'react';
import iconPayment from '../../../assets/img/pane-icons/icon-payment.svg';
import { getAmountDecimal, getAmountInteger } from '../../../services/helpers';
import { Payment } from '../../../types/types';

interface Props {
    payment: Payment;
}

export const PaymentCard = ({ payment }: Props) => {
    return (
        <div className="block block-receipt-pane">
            <div className="receipt-overview">
                <div className="receipt-media">
                    <img src={iconPayment} alt="icon receipt" />
                </div>
                <div className="receipt-info">
                    <div className="receipt-title">
                        <small>Payment via&nbsp;</small>
                        {payment?.source}
                    </div>
                    <div className="receipt-info-list">
                        <div className="receipt-info-item text-primary">
                            {DateTime.fromSQL(payment?.created_at)
                                .toUTC()
                                .toFormat("dd MMM yyyy, HH:mm 'GMT'")}
                        </div>
                    </div>
                </div>
                <div className="receipt-value" style={{ alignSelf: 'center' }}>
                    <div className="receipt-amount">
                        {getAmountInteger(payment?.amount)}
                        <sup>{getAmountDecimal(payment?.amount)}</sup>
                        <img
                            src={payment?.currency?.icon?.png}
                            alt="icon currency"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
