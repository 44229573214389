import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppHeader, CurrenciesFooter, ReceiptCard } from '../../components';
import { amountOptions, getInitials } from '../../services/helpers';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import {
    fetchReceipt,
    fetchReceiptAsPDF,
} from '../../services/ReceiptsService';
import { getMyCurrentOrganizationName } from '../../state/myOrganizations';
import { getReceiptById } from '../../state/receipts';
import { getMyCurrentOrganizationId } from '../../state/statistics';

export const Receipt = () => {
    const { id } = useParams<{ id: string }>();
    const receipt = useSelector(getReceiptById(Number(id)));
    const myCurrentOrganizationName = useSelector(getMyCurrentOrganizationName);

    const history = useHistory();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                setLoading(true);
                const receiptResponse = await fetchReceipt(Number(id));
                setLoading(false);
                if (receiptResponse.status !== 200) {
                    history.push('/transactions/receipts');
                }
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, history, id]);

    // const [isDetailsVisible, setDetailsVisible] = useState(true);

    // const onClickDetails = useCallback(() => {
    //     setDetailsVisible(prevState => !prevState);
    // }, []);

    const [isLoading, setLoading] = useState(false);

    const downloadPDF = useCallback(async () => {
        if (!isLoading) {
            setLoading(true);
            await fetchReceiptAsPDF(receipt?.id);
            setLoading(false);
        }
    }, [isLoading, receipt?.id]);

    const openTransaction = useCallback(() => {
        if (receipt?.transaction_id) {
            history.push(
                `/transactions/transaction/${receipt?.transaction_id}`,
            );
        }
    }, [history, receipt?.transaction_id]);

    return (
        <div className="app-container">
            <AppHeader
                title={`Receipt #${id}`}
                headerSubtitles={[{ subtitle: 'Receipts' }]}
                pdfButton
                isLoading={isLoading}
                isButtonDisabled={isLoading}
                onPdfDownloadPress={downloadPDF}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-title">
                        {`Generated ${
                            receipt?.created_at
                                ? DateTime.fromSQL(receipt?.created_at)
                                      .toUTC()
                                      .toFormat("dd MMMM yyyy, HH:mm 'GMT'")
                                : ''
                        }`}
                    </div>

                    <ReceiptCard style={{ cursor: 'auto' }} receipt={receipt} />
                    <div className="block block-payment block-payment-in">
                        <div className="payment-overview">
                            <div className="payment-title">Netted amount</div>
                            <div className="payment-value">
                                <div className="payment-currency">
                                    {!!receipt?.currency?.icon?.png && (
                                        <img
                                            src={receipt?.currency?.icon?.png}
                                            alt="receipt currency icon"
                                        />
                                    )}
                                </div>
                                <div className="payment-amount">
                                    {receipt?.netted_amount ||
                                    receipt?.netted_amount === 0
                                        ? receipt?.netted_amount?.toLocaleString(
                                              'en',
                                              amountOptions,
                                          )
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block block-payment">
                        <div className="payment-details">
                            {/* <div className="payment-details-heading">
                                <div className="payment-details-title">
                                    Receipt details
                                </div>
                                <button
                                    type="button"
                                    style={{
                                        borderWidth: 0,
                                        background: 'transparent',
                                    }}
                                    className="payment-details-toggle"
                                    onClick={onClickDetails}
                                >
                                    Hide details
                                    <em className="mdi mdi-chevron-down" />
                                </button>
                            </div> */}
                            {/* {!!isDetailsVisible && ( */}
                            <div className="payment-details-list">
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Generated on
                                    </div>
                                    <div className="payment-details-value">
                                        {!!receipt?.created_at &&
                                            DateTime.fromSQL(
                                                receipt?.created_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMM yyyy, HH:mm 'GMT'",
                                                )}
                                    </div>
                                </div>
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Netted amount
                                    </div>
                                    <div className="payment-details-value">
                                        {`${
                                            receipt?.netted_amount ||
                                            receipt?.netted_amount === 0
                                                ? receipt?.netted_amount?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : ''
                                        } ${receipt?.currency?.code || ''}`}
                                    </div>
                                </div>
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Entry amount
                                    </div>
                                    <div className="payment-details-value">
                                        {`${
                                            receipt?.entry_amount ||
                                            receipt?.entry_amount === 0
                                                ? receipt?.entry_amount?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : ''
                                        } ${receipt?.currency?.code || 0}`}
                                    </div>
                                </div>
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Exit amount
                                    </div>
                                    <div className="payment-details-value">
                                        {`${
                                            !!receipt?.amount_due ||
                                            receipt?.amount_due === 0
                                                ? receipt?.amount_due?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : ''
                                        } ${receipt?.currency?.code || ''}`}
                                    </div>
                                </div>
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                    <div
                        className="block block-payment block-payment-out"
                        role="button"
                        tabIndex={0}
                        onClick={openTransaction}
                        onKeyDown={openTransaction}
                    >
                        <div className="payment-overview">
                            <div className="payment-title">
                                {`Transaction ID#${
                                    receipt?.transaction_id || ''
                                } between ${myCurrentOrganizationName} and ${
                                    receipt?.user?.company_name || ''
                                }  `}
                            </div>
                            <div className="payment-value">
                                <div className="payment-currency">
                                    {!!receipt?.currency?.icon?.svg && (
                                        <img
                                            src={receipt?.currency?.icon?.svg}
                                            alt="currency icon"
                                        />
                                    )}
                                </div>
                                {!!receipt?.entry_amount && (
                                    <div className="payment-amount">
                                        {receipt?.entry_amount ||
                                        receipt?.entry_amount === 0
                                            ? receipt?.entry_amount?.toLocaleString(
                                                  'en',
                                                  amountOptions,
                                              )
                                            : ''}{' '}
                                        <small>
                                            {receipt?.amount_due ||
                                            receipt?.amount_due === 0
                                                ? receipt?.amount_due?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : ''}
                                        </small>
                                    </div>
                                )}
                            </div>
                            <div className="payment-overview-icon">
                                <div className="mdi mdi-arrow-top-right" />
                            </div>
                        </div>
                    </div>

                    {/* Pay now block */}
                    {/* <div className="block block-receipt-pane">
                        <div className="receipt-payment">
                            <div className="payment-title">
                                In order to close this chain - you need to pay
                                this amount to Rocket Minds via Troc Circle.
                                Save on the bank fees by paying online via Troc
                                Circle.
                            </div>
                            <div className="payment-overview">
                                <div className="payment-value">
                                    <div className="payment-currency">
                                        <img
                                            src="./assets/img/currency-icons/eur.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="payment-amount">
                                        -6.645,34
                                        <div className="payment-label label label-success-light">
                                            Approved
                                        </div>
                                    </div>
                                </div>
                                <div className="payment-actions">
                                    <div className="button button-primary">
                                        Pay now
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="block block-payment-graph block-payment-graph-connect">
                        <div className="graph-col">
                            <div className="graph-header">Entry amount</div>
                            <div className="graph-item graph-item-in">
                                <div className="graph-item-details">
                                    <div className="graph-item-title">
                                        Entry amount
                                    </div>
                                    <div className="graph-value">
                                        <div className="graph-currency">
                                            {!!receipt?.currency?.icon?.svg && (
                                                <img
                                                    src={
                                                        receipt?.currency?.icon
                                                            ?.svg
                                                    }
                                                    alt="currency icon"
                                                />
                                            )}
                                        </div>
                                        <div className="graph-amount">
                                            {receipt?.entry_amount ||
                                            receipt?.entry_amount === 0
                                                ? receipt?.entry_amount?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="graph-arrow">
                            <div className="mdi mdi-arrow-right" />
                        </div>
                        <div className="graph-col">
                            <div className="graph-header">Exit amount</div>
                            <div className="graph-item graph-item-in">
                                <div className="graph-item-details">
                                    <div className="graph-item-title">
                                        Exit amount
                                    </div>
                                    <div className="graph-value">
                                        <div className="graph-currency">
                                            {!!receipt?.currency?.icon?.svg && (
                                                <img
                                                    src={
                                                        receipt?.currency?.icon
                                                            ?.svg
                                                    }
                                                    alt="currency icon"
                                                />
                                            )}
                                        </div>
                                        <div className="graph-amount">
                                            {receipt?.amount_due ||
                                            receipt?.amount_due === 0
                                                ? receipt?.amount_due?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block block-title">Recipient</div>
                    {!!receipt?.user && (
                        <div className="block block-users">
                            <div className="user">
                                <div className="user-media">
                                    {receipt.user?.logo?.sizes?.thumbnail ? (
                                        <img
                                            className="user-media-image"
                                            src={
                                                receipt.user?.logo?.sizes
                                                    ?.thumbnail
                                            }
                                            alt="user avatar"
                                        />
                                    ) : (
                                        <div className="user-media-abbr">
                                            {receipt.user?.company_name
                                                ? getInitials(
                                                      receipt.user
                                                          ?.company_name,
                                                  )
                                                : ''}
                                        </div>
                                    )}
                                </div>
                                <div className="user-details">
                                    <div className="user-name">
                                        {receipt.user?.company_name || ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
        </div>
    );
};
