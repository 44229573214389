import { CountryRegionData } from 'react-country-region-selector';
import { useCallback, useMemo, useRef, useState } from 'react';
import Switch from 'react-switch';
import parsePhoneNumberFromString, {
    parsePhoneNumber,
} from 'libphonenumber-js';
import * as countries from 'countries-list';
import SignatureCanvas from 'react-signature-canvas';
import { DateTime } from 'luxon';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { Name, SignatureWrapper } from './styles';
import { Owner } from './Owner';
import { FileAttachments } from './FileAttchments';
import { ClearButton } from './ClearButton';
import {
    CountryPhoneSelector,
    FormComponent,
    FormError,
    SelectComponent,
} from '../../components';
import { dataURLtoFile, getRegions } from '../../services/helpers';
import {
    ClientOrganizationOwner,
    ConnectOrganizationProps,
} from '../../types/types';
import calendarIcon from '../../assets/img/icons/calendar.svg';

const countriesOptions = CountryRegionData.map(item => ({
    value: item[1],
    label: item[0],
    regions: item[2],
}));

type Props = {
    isButtonDisabled?: boolean;
    organization?: Partial<ConnectOrganizationProps>;
    setOrganization: React.Dispatch<
        React.SetStateAction<Partial<ConnectOrganizationProps>>
    >;
    errors: {
        [key: string]: any;
    };
    setErrors: React.Dispatch<
        React.SetStateAction<{
            [key: string]: any;
        }>
    >;
    organizationChanged: (
        key: keyof ConnectOrganizationProps,
        value: string | number | ClientOrganizationOwner[],
        key2?: keyof ClientOrganizationOwner,
        index?: number,
    ) => void;
    onConnect: () => void;
    onCancel: () => void;
    ballanceFiles: any[];
    incomeFiles: any[];
    arFiles: any[];
    apFiles: any[];
    idFiles: any[];
    otherFiles: any[];
    setBallanceFiles: React.Dispatch<React.SetStateAction<any[]>>;
    setIncomeFiles: React.Dispatch<React.SetStateAction<any[]>>;
    setArFiles: React.Dispatch<React.SetStateAction<any[]>>;
    setApFiles: React.Dispatch<React.SetStateAction<any[]>>;
    setIdFiles: React.Dispatch<React.SetStateAction<any[]>>;
    setOtherFiles: React.Dispatch<React.SetStateAction<any[]>>;
    setSignature: React.Dispatch<React.SetStateAction<File | undefined>>;
    termsAccepted: boolean;
    onTermsClick: () => void;
};

export const ClientForm = ({
    isButtonDisabled,
    organization,
    setOrganization,
    errors,
    setErrors,
    organizationChanged,
    onConnect,
    onCancel,
    ballanceFiles,
    incomeFiles,
    arFiles,
    apFiles,
    idFiles,
    otherFiles,
    setBallanceFiles,
    setIncomeFiles,
    setArFiles,
    setApFiles,
    setIdFiles,
    setOtherFiles,
    setSignature,
    termsAccepted,
    onTermsClick,
}: Props) => {
    const ownerChanged = useCallback(
        (
            key: keyof ClientOrganizationOwner,
            value: string | number,
            index: number,
        ) => {
            organizationChanged('owners', value, key, index);
        },
        [organizationChanged],
    );

    const [countryOption, setCountryOption] = useState<{
        value: string;
        label: string;
        regions: string;
    } | null>(
        countriesOptions.filter(
            item => item.value === organization?.primary_country_code,
        )[0] || null,
    );

    const regionsOptions = useMemo(
        () =>
            countryOption?.regions
                ? getRegions(countryOption?.regions)?.map(
                      (item: string, index: number) => ({
                          value: index.toString(),
                          label: item,
                      }),
                  )
                : undefined,
        [countryOption?.regions],
    );
    const [regionOption, setRegionOption] = useState<{
        value: string;
        label: string;
    } | null>(
        regionsOptions?.filter(
            item => item?.label === organization?.primary_state,
        )[0] || null,
    );
    const onCountryChange = useCallback(
        item => {
            setCountryOption(item);
            organizationChanged('primary_country_code', item?.value);
            setRegionOption(null);
            organizationChanged('primary_state', '');
        },
        [organizationChanged],
    );
    const onRegionChange = useCallback(
        item => {
            setRegionOption(item);
            organizationChanged('primary_state', item?.label);
        },
        [organizationChanged],
    );

    const [isSecondOwnerFormVisible, setSecondOwnerFormVisible] = useState(
        false,
    );

    const changeSecondOwnerFormVisibility = useCallback(
        (checked: boolean) => {
            if (!checked) {
                setOrganization(prevState => {
                    const owners = prevState?.owners?.length
                        ? [...prevState?.owners]
                        : [];
                    owners.splice(1);
                    return {
                        ...prevState,
                        owners,
                    };
                });
            }
            setSecondOwnerFormVisible(checked);
        },
        [setOrganization],
    );

    const [isThirdOwnerFormVisible, setThirdOwnerFormVisible] = useState(false);

    const changeThirdOwnerFormVisibility = useCallback(
        (checked: boolean) => {
            if (!checked) {
                setOrganization(prevState => {
                    const owners = prevState?.owners?.length
                        ? [...prevState?.owners]
                        : [];
                    owners.splice(2);
                    return {
                        ...prevState,
                        owners,
                    };
                });
            }
            setThirdOwnerFormVisible(checked);
        },
        [setOrganization],
    );

    const [phoneCountryCode, setPhoneCountryCode] = useState(
        !!organization?.primary_phone &&
            organization?.primary_phone?.length > 6 &&
            !!parsePhoneNumberFromString(organization?.primary_phone)
            ? parsePhoneNumber(organization?.primary_phone)?.country
            : '',
    );

    const emoji = useMemo(
        () =>
            phoneCountryCode ? countries?.getEmojiFlag(phoneCountryCode) : '',
        [phoneCountryCode],
    );

    const [isCountrySelectorOpen, setCountrySelectorOpen] = useState(false);

    const onOpenCountrySelector = useCallback(() => {
        setCountrySelectorOpen(true);
    }, []);

    const onCloseCountrySelector = useCallback(() => {
        setCountrySelectorOpen(false);
    }, []);

    const updatePhonePrefix = useCallback(
        (countryCode: string, phonePrefix: string) => {
            setPhoneCountryCode(countryCode);
            organizationChanged('primary_phone', phonePrefix);
        },
        [organizationChanged],
    );

    const primaryPhoneChanged = useCallback(
        (value: string) => {
            if (
                !!value &&
                value?.length > 6 &&
                !!parsePhoneNumberFromString(value)
            ) {
                if (parsePhoneNumber(value)?.country) {
                    setPhoneCountryCode(parsePhoneNumber(value)?.country);
                }
            }
            organizationChanged('primary_phone', value);
        },
        [organizationChanged],
    );

    const onDateChange = useCallback(
        (date: Date | Date[] | null | undefined) => {
            console.log('date', date);
            if (
                !!date &&
                !(date instanceof Array) &&
                typeof date === 'object'
            ) {
                organizationChanged(
                    'date',
                    DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
                );
            } else {
                organizationChanged('date', '');
            }
        },
        [organizationChanged],
    );

    const sigRef = useRef<any>(null);

    const readSignature = useCallback(() => {
        const isSignatureExist = !sigRef?.current?.isEmpty();
        if (isSignatureExist) {
            const signature = dataURLtoFile(
                sigRef?.current?.toDataURL(),
                'signature',
            );
            setSignature(signature);
            setErrors(prevState => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { signature_uid: _, ...res } = prevState;
                return res;
            });
        } else {
            setSignature(undefined);
            setErrors(prevStateErrors => {
                return {
                    ...prevStateErrors,
                    signature_uid: ['Signature is required'],
                };
            });
        }
    }, [setErrors, setSignature]);

    const removeSignature = useCallback(() => {
        sigRef?.current?.clear();
        setSignature(undefined);
        setErrors(prevStateErrors => {
            return {
                ...prevStateErrors,
                signature_uid: ['Signature is required'],
            };
        });
    }, [setErrors, setSignature]);

    return (
        <div
            className="col-lg-12 form"
            style={{
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div className="form form-actions" />
            <div
                className="col-lg-12 form"
                style={{
                    background: 'white',
                    paddingBottom: 20,
                }}
            >
                <Name marginTop={-20}>{`${
                    organization?.company_name || ''
                } Application`}</Name>
                <div style={{ height: 20 }} />
                <div className="form-title">Business information</div>
                <div className="row">
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Legal name of the business"
                        value={organization?.company_name || ''}
                        onChange={e =>
                            organizationChanged('company_name', e.target.value)
                        }
                        errors={errors?.company_name}
                    />
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Trade Name (DBA)"
                        value={organization?.trade_name || ''}
                        onChange={e =>
                            organizationChanged('trade_name', e.target.value)
                        }
                        errors={errors?.trade_name}
                    />
                </div>
                <div className="row">
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Tax ID"
                        value={organization?.tax_id || ''}
                        onChange={e =>
                            organizationChanged('tax_id', e.target.value)
                        }
                        errors={errors?.tax_id}
                    />
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Industry"
                        value={organization?.industry || ''}
                        onChange={e =>
                            organizationChanged('industry', e.target.value)
                        }
                        errors={errors?.industry}
                    />
                </div>
                <div className="row">
                    <FormComponent
                        className="form-group col-lg-12"
                        placeholder="Address (street, house number and addition)"
                        value={organization?.primary_address || ''}
                        onChange={e =>
                            organizationChanged(
                                'primary_address',
                                e.target.value,
                            )
                        }
                        errors={errors?.primary_address}
                    />
                </div>
                <div className="row">
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="City"
                        value={organization?.primary_city || ''}
                        onChange={e =>
                            organizationChanged('primary_city', e.target.value)
                        }
                        errors={errors?.primary_city}
                    />
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Zip or Postal code"
                        value={organization?.primary_postal_code || ''}
                        onChange={e =>
                            organizationChanged(
                                'primary_postal_code',
                                e.target.value,
                            )
                        }
                        errors={errors?.primary_postal_code}
                    />
                </div>
                <div className="row">
                    <div className="form-group col-lg-6">
                        <div className="form-label">Country</div>
                        <SelectComponent
                            isClearable
                            placeholder="Country"
                            value={countryOption}
                            onChange={onCountryChange}
                            options={countriesOptions as any}
                        />
                        <FormError errors={errors?.primary_country_id} />
                    </div>
                    <div className="form-group col-lg-6">
                        <div className="form-label">State (region)</div>
                        <SelectComponent
                            isClearable
                            placeholder="State (region)"
                            value={regionOption}
                            onChange={onRegionChange}
                            options={regionsOptions as any}
                        />
                        <FormError errors={errors?.state} />
                    </div>
                </div>
                <div className="form-title">Primary contact</div>
                <div className="row">
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="First name"
                        value={organization?.primary_first_name || ''}
                        onChange={e =>
                            organizationChanged(
                                'primary_first_name',
                                e.target.value,
                            )
                        }
                        errors={errors?.primary_first_name}
                    />
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Last name"
                        value={organization?.primary_last_name || ''}
                        onChange={e =>
                            organizationChanged(
                                'primary_last_name',
                                e.target.value,
                            )
                        }
                        errors={errors?.primary_last_name}
                    />
                </div>
                <div className="row">
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Phone"
                        value={organization?.primary_phone || ''}
                        emoji={emoji}
                        onOpenCountrySelector={onOpenCountrySelector}
                        onChange={e => primaryPhoneChanged(e.target.value)}
                        errors={errors?.primary_phone}
                    />
                    <FormComponent
                        className="form-group col-lg-6"
                        placeholder="Email"
                        value={organization?.primary_email || ''}
                        onChange={e =>
                            organizationChanged('primary_email', e.target.value)
                        }
                        errors={errors?.primary_email}
                    />
                </div>

                <div className="form-title">Owner / Officer information</div>
                <Owner
                    ownerIndex={0}
                    organization={organization}
                    countriesOptions={countriesOptions}
                    errors={errors}
                    setErrors={setErrors}
                    ownerChanged={ownerChanged}
                />
                <div style={{ marginBottom: 15 }}>
                    <div className="form-label">Add another owner?</div>
                    <Switch
                        checked={isSecondOwnerFormVisible}
                        disabled={isThirdOwnerFormVisible}
                        onChange={changeSecondOwnerFormVisibility}
                        onColor="#f37749"
                    />
                </div>
                {isSecondOwnerFormVisible && (
                    <Owner
                        ownerIndex={1}
                        organization={organization}
                        countriesOptions={countriesOptions}
                        errors={errors}
                        setErrors={setErrors}
                        ownerChanged={ownerChanged}
                    />
                )}
                {isSecondOwnerFormVisible && (
                    <div style={{ marginBottom: 15 }}>
                        <div className="form-label">Add another owner?</div>
                        <Switch
                            checked={isThirdOwnerFormVisible}
                            onChange={changeThirdOwnerFormVisibility}
                            onColor="#f37749"
                        />
                    </div>
                )}
                {isThirdOwnerFormVisible && (
                    <Owner
                        ownerIndex={2}
                        organization={organization}
                        countriesOptions={countriesOptions}
                        errors={errors}
                        setErrors={setErrors}
                        ownerChanged={ownerChanged}
                    />
                )}
                <div className="form-title">Attachments</div>
                <FileAttachments
                    title="Current Balance Sheet"
                    files={ballanceFiles}
                    setFiles={setBallanceFiles}
                />
                <FileAttachments
                    title="Current income statement"
                    files={incomeFiles}
                    setFiles={setIncomeFiles}
                />
                <FileAttachments
                    title="Current AR Aging"
                    files={arFiles}
                    setFiles={setArFiles}
                />
                <FileAttachments
                    title="Current AP Aging"
                    files={apFiles}
                    setFiles={setApFiles}
                />
                <FileAttachments
                    title="Guarantor’s ID Copy"
                    files={idFiles}
                    setFiles={setIdFiles}
                />
                <FileAttachments
                    title="Other Items"
                    files={otherFiles}
                    setFiles={setOtherFiles}
                />
                <div className="form-title">Terms</div>

                <div className="form-label">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborumy. At vero eos et
                    accusamus et iusto odio dignissimos ducimus qui blanditiis
                    praesentium voluptatum deleniti atque corrupti quos dolores
                    et quas molestias excepturi sint occaecati cupiditate non
                    provident, similique sunt in culpa qui officia deserunt
                    mollitia animi, id est laborum et dolorum fuga. Et harum
                    quidem rerum facilis est et expedita distinctio. Nam libero
                    tempore, cum soluta nobis est eligendi optio cumque nihil
                    impedit quo minus id quod maxime placeat facere possimus,
                    omnis voluptas assumenda est, omnis dolor repellendus.
                    Temporibus autem quibusdam et aut officiis debitis aut rerum
                    necessitatibus saepe eveniet ut et voluptates repudiandae
                    sint et molestiae non recusandae. Itaque earum rerum hic
                    tenetur a sapiente delectus, ut aut reiciendis voluptatibus
                    maiores alias consequatur aut perferendis doloribus
                    asperiores repellat.
                </div>

                <div className="form-checkbox checkbox-narrow">
                    <input
                        type="checkbox"
                        id="remember"
                        checked={termsAccepted}
                        onChange={onTermsClick}
                    />
                    <label className="checkbox-label" htmlFor="remember">
                        <div className="checkbox-label-box">
                            <div className="mdi mdi-check" />
                        </div>
                        <div className="checkbox-label-text">
                            I ACCEPT THE TERMS AND CONDITIONS
                        </div>
                    </label>
                </div>
                <FormError errors={errors?.terms} />
                <div className="row">
                    <div
                        className="form-group col-lg-4"
                        style={{
                            display: 'unset',
                            width: 320,
                            alignItems: 'center',
                        }}
                    >
                        <div className="form-label">Signature</div>
                        <SignatureWrapper>
                            <SignatureCanvas
                                ref={sigRef}
                                onEnd={readSignature}
                                penColor="#f37749"
                                canvasProps={{
                                    width: 300,
                                    height: 200,
                                    className: 'sigCanvas',
                                }}
                            />
                            <ClearButton onPress={removeSignature} />
                        </SignatureWrapper>
                        <FormError errors={errors?.signature_uid} />
                    </div>
                    <div className="form-group col-lg-3">
                        <div className="form-label">Date</div>
                        <DatePicker
                            format="yyyy MM dd"
                            dayPlaceholder=""
                            monthPlaceholder=""
                            monthAriaLabel=""
                            yearPlaceholder="date"
                            className="form-control form-control-small-padding-with-info"
                            onChange={onDateChange}
                            maxDate={new Date()}
                            value={
                                organization?.date
                                    ? DateTime.fromSQL(
                                          organization?.date,
                                      ).toJSDate()
                                    : undefined
                            }
                            calendarIcon={
                                <img src={calendarIcon} alt="calendar icon" />
                            }
                        />
                        <FormError errors={errors?.date} />
                    </div>
                </div>
            </div>

            <div
                className="col-lg-12 form-actions"
                style={{ marginBottom: 20 }}
            >
                <div className="button-group">
                    <button
                        type="button"
                        className={`button button-primary button-sm ${
                            isButtonDisabled ? 'button-disabled' : ''
                        }`}
                        disabled={isButtonDisabled}
                        onClick={onConnect}
                    >
                        {' '}
                        <em className="mdi mdi-check start" />
                        Submit
                    </button>
                    <button
                        type="button"
                        className="button button-default button-primary button-sm"
                        disabled={isButtonDisabled}
                        onClick={onCancel}
                    >
                        {' '}
                        <em className="mdi mdi-close start" />
                        Cancel
                    </button>
                </div>
            </div>
            <CountryPhoneSelector
                isCountrySelectorOpen={isCountrySelectorOpen}
                onCloseCountrySelector={onCloseCountrySelector}
                countryCode={phoneCountryCode}
                updatePhonePrefix={updatePhonePrefix}
            />
        </div>
    );
};
