import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import menuIconChains from '../../assets/img/menu-icons/menu-icon-chains.svg';
import { getSumNotificationsChain } from '../../state/statistics';

export const MenuChainsLink = () => {
    const history = useHistory();

    const sumNotificationsChain = useSelector(getSumNotificationsChain);
    const onClick = useCallback(() => {
        history.push('/chains/all');
    }, [history]);
    return (
        <div
            role="button"
            tabIndex={0}
            className="menu-item-link"
            // style={{
            //     borderWidth: 0,
            //     background: 'transparent',
            //     color: 'white',
            //     opacity: location.pathname.includes('chains') ? 1 : 0.6,
            // }}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <div className="menu-item-icon">
                <img src={menuIconChains} alt="menu icon chains" />
            </div>

            <div className="menu-item-label">
                Chains
                {!!sumNotificationsChain && (
                    <div className="menu-item-notes">
                        {sumNotificationsChain}
                    </div>
                )}
            </div>
        </div>
    );
};
