import React, { useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { CountResult, PaginationButtonsEmpty, WithoutBlock } from './styles';

type Props = {
    isLoading?: boolean;
    withoutBlock?: boolean;
    total: number;
    currentPage?: number;
    lastPage?: number;
    prevDisabled?: boolean;
    nextDisabled?: boolean;
    pagesDisabled?: boolean;
    onPressPrev?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onPressNext?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onPressMore?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onPressFirst?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onPressLast?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onPressPage?: (page: number) => void;
};

export const PaginationButtons = ({
    isLoading,
    withoutBlock,
    total,
    currentPage,
    lastPage,
    prevDisabled,
    nextDisabled,
    pagesDisabled,
    onPressPrev,
    onPressNext,
    onPressMore,
    onPressFirst,
    onPressLast,
    onPressPage,
}: Props) => {
    const resultText = useMemo(
        () => `${total} ${total === 1 ? 'result' : 'results'}`,
        [total],
    );
    const onPressPageMethod = useCallback(
        (page: number) => {
            if (onPressPage) {
                onPressPage(page);
            }
        },
        [onPressPage],
    );
    const renderLoadMore = useMemo(
        () =>
            total !== 0 && (
                <button
                    type="button"
                    className="button button-primary button-sm"
                    disabled={nextDisabled}
                    onClick={onPressMore}
                >
                    Load more
                </button>
            ),
        [nextDisabled, onPressMore, total],
    );

    const renderPaginationButtons = useMemo(
        () =>
            total > 0 && !!currentPage && !!lastPage && lastPage > 1 ? (
                <>
                    <button
                        type="button"
                        className="pagination-item"
                        disabled={currentPage === 1 || pagesDisabled}
                        onClick={onPressFirst}
                    >
                        1
                    </button>
                    {currentPage > 2 && lastPage > 3 && (
                        <div className="dots-pagination-item">...</div>
                    )}
                    {(currentPage <= lastPage - 3 || lastPage === 3) && (
                        <>
                            {currentPage > 2 && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled}
                                    onClick={() =>
                                        onPressPageMethod(currentPage - 1)
                                    }
                                >
                                    {currentPage - 1}
                                </button>
                            )}
                            {currentPage > 1 && currentPage !== lastPage && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled || !!currentPage}
                                    onClick={() =>
                                        onPressPageMethod(currentPage)
                                    }
                                >
                                    {currentPage}
                                </button>
                            )}
                            {lastPage > currentPage + 1 && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled}
                                    onClick={() =>
                                        onPressPageMethod(currentPage + 1)
                                    }
                                >
                                    {currentPage + 1}
                                </button>
                            )}
                            {lastPage > currentPage + 2 && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled}
                                    onClick={() =>
                                        onPressPageMethod(currentPage + 2)
                                    }
                                >
                                    {currentPage + 2}
                                </button>
                            )}
                        </>
                    )}
                    {currentPage <= lastPage - 3 && (
                        <div className="dots-pagination-item">...</div>
                    )}
                    {currentPage > lastPage - 3 && lastPage > 3 && (
                        <>
                            {/* {currentPage !== lastPage - 3 && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled}
                                    onClick={() =>
                                        onPressPageMethod(lastPage - 3)
                                    }
                                >
                                    {lastPage - 3}
                                </button>
                            )} */}
                            {currentPage !== lastPage - 2 && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled}
                                    onClick={() =>
                                        onPressPageMethod(lastPage - 2)
                                    }
                                >
                                    {lastPage - 2}
                                </button>
                            )}
                            {currentPage < lastPage - 1 && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled || !!currentPage}
                                    onClick={() =>
                                        onPressPageMethod(currentPage)
                                    }
                                >
                                    {currentPage}
                                </button>
                            )}

                            {currentPage !== lastPage - 1 && (
                                <button
                                    type="button"
                                    className="pagination-item"
                                    disabled={pagesDisabled}
                                    onClick={() =>
                                        onPressPageMethod(lastPage - 1)
                                    }
                                >
                                    {lastPage - 1}
                                </button>
                            )}
                            {currentPage < lastPage &&
                                currentPage === lastPage - 1 && (
                                    <button
                                        type="button"
                                        className="pagination-item"
                                        disabled={
                                            pagesDisabled || !!currentPage
                                        }
                                        onClick={() =>
                                            onPressPageMethod(currentPage)
                                        }
                                    >
                                        {currentPage}
                                    </button>
                                )}
                        </>
                    )}
                    <button
                        type="button"
                        className="pagination-item"
                        disabled={currentPage === lastPage || pagesDisabled}
                        onClick={onPressLast}
                    >
                        {lastPage}
                    </button>
                </>
            ) : (
                withoutBlock && <PaginationButtonsEmpty />
            ),
        [
            currentPage,
            lastPage,
            onPressFirst,
            onPressLast,
            onPressPageMethod,
            pagesDisabled,
            total,
            withoutBlock,
        ],
    );

    const renderPrevNextButtons = useMemo(
        () =>
            total > 0 && (
                <>
                    <button
                        type="button"
                        className="pagination-item"
                        disabled={prevDisabled}
                        onClick={onPressPrev}
                    >
                        <em className="mdi mdi-chevron-left start" />
                        Prev
                    </button>
                    <button
                        type="button"
                        className="pagination-item"
                        disabled={nextDisabled}
                        onClick={onPressNext}
                    >
                        Next
                        <em className="mdi mdi-chevron-right end" />
                    </button>
                </>
            ),
        [nextDisabled, onPressNext, onPressPrev, prevDisabled, total],
    );

    return withoutBlock ? (
        <WithoutBlock className="pagination">
            {renderPrevNextButtons}
            <div className="pagination-overview pagination-overview-center">
                {renderLoadMore}
                <CountResult withoutMargin={total === 0}>
                    {resultText}
                </CountResult>
            </div>
            {total > 0 && renderPaginationButtons}
        </WithoutBlock>
    ) : (
        <div className="block block-pagination">
            <div className="pagination">
                {renderLoadMore}
                <div
                    className={`pagination-overview ${
                        total === 0 && 'pagination-overview-center'
                    }`}
                >
                    {isLoading && total === 0 ? (
                        <div style={{ marginBottom: -10 }}>
                            <Loader
                                type="Bars"
                                color="#f37749"
                                visible
                                height={30}
                                width={60}
                            />
                        </div>
                    ) : (
                        resultText
                    )}
                </div>
                {total > 0 && (
                    <div className="pagination-overview">
                        {renderPaginationButtons}
                    </div>
                )}
                <div className="pagination-list">{renderPrevNextButtons}</div>
            </div>
        </div>
    );
};
