import Loader from 'react-loader-spinner';
import iconSearch from '../../assets/img/icons/icon-search.svg';
import { FilterContainer, SearchIcon } from '../../components';

type Props = {
    isLoading?: boolean;
    query?: string;
    onQueryChange?: (value: string) => void;
};

export const FiltersHeader = ({ isLoading, query, onQueryChange }: Props) => {
    return (
        <FilterContainer className="form">
            {!!onQueryChange && (
                <div className="form-control-wrapper">
                    <SearchIcon src={iconSearch} />
                    <input
                        id="query"
                        className="form-control"
                        placeholder="Search"
                        value={query || ''}
                        onChange={e => onQueryChange(e.target.value)}
                        style={{
                            paddingLeft: 29,
                            paddingRight: 29,
                            width: 187,
                        }}
                    />
                    <div className="form-control-info">
                        <em className="mdi mdi-information form-control-info-icon" />
                        <div className="form-control-info-container form-control-info-container-dim">
                            Search by company name <br />
                            OR registration number / EIN
                            <br />
                        </div>
                    </div>
                </div>
            )}
            {isLoading && (
                <div style={{ marginLeft: 30 }}>
                    <Loader
                        type="ThreeDots"
                        color="#f37749"
                        visible
                        height={20}
                        width={50}
                    />
                </div>
            )}
        </FilterContainer>
    );
};
