import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { FiltersHeader } from './FiltersHeader';
import { NewGroupModal } from './NewGroupModal';
import { TableRow } from './styles';
import { Td1 } from './Td1';
import { Td2 } from './Td2';
import { Td3 } from './Td3';
import { Td4 } from './Td4';
import { Td5 } from './Td5';
import { Td6 } from './Td6';

import { EditGroupModal } from './EditGroupModal';
import {
    AppHeader,
    CurrenciesFooter,
    PaginationButtons,
    Th,
} from '../../components';

import { useDelayDebounceQuery } from '../../hooks';
import {
    changeGroup,
    fetchCurrentOrganizationStatistics,
    fetchMyGroups,
} from '../../services/OrganizationsService';
import { selectGroups, selectGroupsMeta } from '../../state/groups';
import {
    getAbilityEditCurrentOrganizationSettings,
    getMyOrganizationById,
} from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { TGroup } from '../../types/types';

type SortField = 'name' | 'created_at';

export const MyGroups = () => {
    const { id } = useParams<{ id: string }>();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const history = useHistory();

    const groups = useSelector(selectGroups);
    const meta = useSelector(selectGroupsMeta);

    const [sortField, setSortField] = useState<SortField>('created_at');
    const [sortOrder, setSortOrder] = useState<{
        [key: string]: 'ASC' | 'DESC';
    }>({
        name: 'DESC',
        created_at: 'DESC',
        plan_id: 'DESC',
        usage_amount: 'DESC',
    });

    const setOrderMethod = useCallback(
        (value: SortField) => {
            setSortField(prevState => {
                if (prevState === value) {
                    if (sortOrder[value] === 'ASC') {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'DESC',
                        }));
                    } else {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'ASC',
                        }));
                    }
                }
                return value;
            });
        },
        [sortOrder],
    );

    const [isLoading, setLoading] = useState(false);

    const { query, delayQuery, setDelayQuery } = useDelayDebounceQuery();

    const [status, setStatus] = useState<TGroup['status'][]>([]);

    const fetchGroups = useCallback(
        async (page = 1, update?: boolean) => {
            setLoading(true);
            await fetchMyGroups(
                {
                    page,
                    q: query,
                    status: status?.length ? status?.toString() : '',
                    // sortField,
                    // sortOrder: sortOrder[sortField],
                },
                update,
            );
            setLoading(false);
        },
        [query, status],
    );

    useEffect(() => {
        (async () => {
            if (id) {
                await fetchGroups();
            }
        })();
    }, [fetchGroups, id]);

    useEffect(() => {
        (async () => {
            await fetchCurrentOrganizationStatistics();
        })();
    }, []);

    const onPressNext = useCallback(async () => {
        await fetchGroups(meta.current_page + 1);
    }, [fetchGroups, meta.current_page]);

    const onPressPrev = useCallback(async () => {
        await fetchGroups(meta.current_page - 1);
    }, [fetchGroups, meta.current_page]);

    const onPressMore = useCallback(async () => {
        await fetchGroups(meta.current_page + 1, true);
    }, [fetchGroups, meta.current_page]);

    const onPressFirst = useCallback(async () => {
        await fetchGroups(1);
    }, [fetchGroups]);

    const onPressLast = useCallback(async () => {
        if (meta.last_page) await fetchGroups(meta.last_page);
    }, [fetchGroups, meta.last_page]);

    const onPressPage = useCallback(
        async (page: number) => {
            await fetchGroups(page);
        },
        [fetchGroups],
    );

    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );

    useEffect(() => {
        if (!canChangeOrganizationSettings && !!currentOrganizationId && !!id) {
            history.push(`/`);
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history, id]);

    useEffect(() => {
        if (
            !!id &&
            !!currentOrganizationId &&
            Number(id) !== currentOrganizationId
        ) {
            history.push(`/organization${currentOrganizationId}/my-groups`);
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history, id]);

    const storedOrganization = useSelector(getMyOrganizationById(Number(id)));

    const [isNewGroupModalVisible, setNewGroupModalVisible] = useState(false);

    const showNewGroupModal = useCallback(() => {
        setNewGroupModalVisible(true);
    }, []);

    const closeNewGroupModal = useCallback(() => {
        setNewGroupModalVisible(false);
    }, []);

    const [editGroupModalId, setEditGroupModalId] = useState<
        number | undefined
    >();

    const showEditGroupModal = useCallback((groupId: number) => {
        setEditGroupModalId(groupId);
    }, []);

    const closeEditGroupModal = useCallback(() => {
        setEditGroupModalId(undefined);
    }, []);

    const onClick = useCallback(
        (groupId: number) => {
            history.push(`/my-group/${groupId}`);
        },
        [history],
    );

    const onGroupChange = useCallback(
        async (groupId: number) => {
            const response = await changeGroup(groupId);
            if (response?.status === 200) {
                await fetchGroups(meta?.current_page);
            }
        },
        [fetchGroups, meta?.current_page],
    );

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                tabs={[
                    { name: 'Company details', link: `/organization/${id}` },
                    {
                        name: 'Billing details',
                        link: `/organization${id}/billing-details`,
                    },
                    {
                        name: `My groups (${meta?.total})`,
                        link: `/organization${id}/my-groups`,
                    },
                ]}
                title={
                    !storedOrganization?.id
                        ? 'New organization'
                        : storedOrganization?.company_name
                }
                headerSubtitles={[
                    {
                        subtitle: 'My organizations',
                        subtitleLink: `/organization/${id}`,
                    },
                ]}
                singleButtonText="Add new group"
                singleButtonMdi="plus"
                onSingleButtonPress={showNewGroupModal}
            />

            <div className="app-content" style={{ width: '100%' }}>
                <div className="app-body">
                    <FiltersHeader
                        isLoading={isLoading && !!meta?.total}
                        query={delayQuery}
                        status={status}
                        onQueryChange={setDelayQuery}
                        onStatusChange={setStatus}
                    />
                    <div className="table" style={{ position: 'relative' }}>
                        <table>
                            <tbody>
                                {!!meta?.total && (
                                    <tr className="tr-overview">
                                        <Th
                                            title="Group name"
                                            field="name"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.name}
                                            onPress={() =>
                                                setOrderMethod('name')
                                            }
                                        />
                                        <Th
                                            title="Created on"
                                            style={{ minWidth: 110 }}
                                            field="created_at"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.created_at}
                                            onPress={() =>
                                                setOrderMethod('created_at')
                                            }
                                        />
                                        <Th title="Plan type" />
                                        <Th title="Plan usage" />
                                        <Th title="Members" />

                                        <Th title="Actions" />
                                    </tr>
                                )}
                                {groups.map((group, index) => (
                                    <TableRow
                                        key={group?.id?.toString()}
                                        last={index === groups?.length - 1}
                                    >
                                        <Td1
                                            name={group?.name}
                                            status={group?.status}
                                            id={group?.id}
                                            isCurrent={group?.is_current}
                                            onClick={() => onClick(group?.id)}
                                        />
                                        <Td2
                                            createdAt={group?.created_at}
                                            onClick={() => onClick(group?.id)}
                                        />
                                        <Td3
                                            planId={group?.plan_id}
                                            onClick={() => onClick(group?.id)}
                                        />
                                        <Td4
                                            planId={group?.plan_id}
                                            usageAmount={group?.usage_amount}
                                            onClick={() => onClick(group?.id)}
                                        />
                                        <Td5
                                            members={group?.members}
                                            onClick={() => onClick(group?.id)}
                                        />
                                        <Td6
                                            group={group}
                                            onEdit={showEditGroupModal}
                                            onChange={onGroupChange}
                                        />
                                    </TableRow>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationButtons
                        // withoutBlock
                        isLoading={isLoading}
                        prevDisabled={meta?.current_page === 1 || isLoading}
                        nextDisabled={
                            meta?.current_page === meta?.last_page || isLoading
                        }
                        currentPage={meta?.current_page}
                        lastPage={meta?.last_page}
                        pagesDisabled={isLoading}
                        total={meta?.total}
                        onPressPrev={onPressPrev}
                        onPressNext={onPressNext}
                        onPressMore={onPressMore}
                        onPressFirst={onPressFirst}
                        onPressLast={onPressLast}
                        onPressPage={onPressPage}
                    />
                </div>
            </div>
            {isNewGroupModalVisible && (
                <NewGroupModal
                    isModalOpen={isNewGroupModalVisible}
                    onRequestClose={closeNewGroupModal}
                />
            )}
            {!!editGroupModalId && (
                <EditGroupModal
                    id={editGroupModalId}
                    onRequestClose={closeEditGroupModal}
                />
            )}
            <CurrenciesFooter />
        </div>
    );
};
