import styled from 'styled-components';
import { BASE_FONT } from '../../../theme/fonts';

const backgroundColors = ['#FFF8E7', '#F2EDFC', '#FFEEEF', '#E9FAF0'];

const getTagColor = (id: number, tagColors: string[]) => {
    if (Number.isInteger(id / 7)) {
        return tagColors[3];
    }
    if (Number.isInteger(id / 6)) {
        return tagColors[2];
    }
    if (Number.isInteger(id / 3)) {
        return tagColors[1];
    }
    if (Number.isInteger(id / 2)) {
        return tagColors[0];
    }
    return tagColors[0];
};

export const PlanCardContainer = styled.div<{ planId?: number }>`
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;

    width: 250px;
    max-width: 250px;

    background: ${({ planId }) => getTagColor(planId || 1, backgroundColors)};
    border-radius: 5px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderLeft = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 155px;
`;

const iconColors = ['#FDA611', '#6933D8', '#FC3B44', '#66CD77'];

export const IconContainer = styled.div<{ planId?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 36px;
    height: 36px;

    background: ${({ planId }) => getTagColor(planId || 1, iconColors)};
    border-radius: 5px;
`;

export const PlanName = styled(BASE_FONT)`
    margin-top: 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #202531;
`;

export const Price = styled(BASE_FONT)`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    color: #080808;
`;

export const Period = styled(BASE_FONT)`
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    /* identical to box height, or 100% */
    text-align: right;

    /* Heading 1 */
    color: #080808;
`;

export const AmountLimit = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: #272727;
`;

export const Footer = styled(Header)`
    width: 100%;
    justify-content: space-between;
`;

export const CheckedView = styled.div<{ planId?: number }>`
    width: 18px;
    height: 18px;

    background: ${({ planId }) => getTagColor(planId || 1, iconColors)};
    border-radius: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
`;
