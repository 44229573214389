import { CompanyAvatarContainer, CompanyInitials, CompanyName } from './styles';
import { Row } from '../../../components';
import { TGroupMember } from '../../../types/types';
import { getInitial } from '../../../services/helpers';

type Props = {
    companyName: TGroupMember['company_name'];
    index: number;
    onClick?: () => void;
};

export const Td1 = ({ companyName, index, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Row alignItems="center" justifyContent="center">
                    <CompanyAvatarContainer index={index}>
                        <CompanyInitials>
                            {getInitial(companyName)}
                        </CompanyInitials>
                    </CompanyAvatarContainer>
                    <CompanyName>{companyName}</CompanyName>
                </Row>
            </button>
        </td>
    );
};
