import { DateTime } from 'luxon';
import { MemberFrom } from './styles';

type Props = {
    sqlDate: string;
    onClick?: () => void;
};

export const Td3 = ({ sqlDate, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                }}
            >
                {!!sqlDate && (
                    <MemberFrom>
                        {DateTime?.fromSQL(sqlDate)
                            ?.toUTC()
                            .toFormat("dd MMM yyyy, HH:mm 'GMT'")}
                    </MemberFrom>
                )}
            </button>
        </td>
    );
};
