import styled from 'styled-components';

export const RadioContainer = styled.div<{ selected?: boolean }>`
    width: ${({ selected }) => (selected ? 18 : 16)}px;
    height: ${({ selected }) => (selected ? 18 : 16)}px;

    background: #f4f4f1;
    border: 1px solid #e1edff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* align-self: center; */
`;

export const Selected = styled.div`
    width: 12px;
    height: 12px;

    background: #ffa048;
    border-radius: 12px;
`;
