import { amountOptions } from '../../services/helpers';

type Props = {
    income?: boolean;
    currencyIcon?: string;
    amount: number;
};

export const TrocTransaction = ({ income, currencyIcon, amount }: Props) => {
    return (
        <div
            className={`graph-item ${
                !income ? 'graph-item-out' : 'graph-item-in'
            }`}
        >
            <div className="graph-item-details">
                <div className="graph-item-title">
                    {income ? 'Receive from' : 'Send to'}
                    <strong> Troc Circle</strong>
                </div>
                <div className="graph-value">
                    <div className="graph-currency">
                        <img src={currencyIcon} alt="currency icon" />
                    </div>
                    <div className="graph-amount">
                        {amount?.toLocaleString('en', amountOptions)}
                    </div>
                </div>
            </div>
            <div className="graph-item-icon">
                <em
                    className={`mdi ${
                        !income
                            ? 'mdi-arrow-top-right'
                            : 'mdi-arrow-bottom-left'
                    } `}
                />
            </div>
        </div>
    );
};
