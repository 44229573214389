import { AxiosRequestConfig } from 'axios';
import api from './axiosConfig';
import { countReceiptsAllSet } from '../state/collectionsCount';
import { receiptsSet, receiptsUpdate, receiptUpdate } from '../state/receipts';
import { store } from '../state/store';

export const fetchReceipts = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api('/receipts', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 10,
            ...params,
        },
    });
    if (response?.status === 200 && !!response?.data?.data) {
        store.dispatch(countReceiptsAllSet(response.data?.meta?.total));
        if (!update) {
            store.dispatch(receiptsSet(response.data.data));
        } else {
            store.dispatch(receiptsUpdate(response.data?.data));
        }
    }
    return response;
};

export const fetchReceipt = async (id: number) => {
    const response = await api(`/receipts/${id}`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(receiptUpdate(response.data?.data));
    }
    return response;
};

export const fetchReceiptAsPDF = async (id: number) => {
    const response = await api(`/receipts/${id}/pdf-base64`);
    if (response.status === 200 && response.data?.data) {
        const linkSource = `data:application/pdf;base64,${response.data.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `receipt-${id}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    return response;
};
