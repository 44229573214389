import React, { memo } from 'react';
import { TableRow } from './styles';
import { Td1 } from './Td1';
import { Td2 } from './Td2';
import { Td3 } from './Td3';
import { Td4 } from './Td4';
import { Td5 } from './Td5';
import { FiltersHeader } from './FiltersHeader';
import { SortField } from '..';
import { PaginationButtons, Th } from '../../../components';
import { TGroup, TGroupMember, TMeta } from '../../../types/types';

type GroupMembersTableProps = {
    planId: number;
    members: TGroupMember[];
    meta: TMeta;
    isLoading: boolean;
    sortField: SortField;
    sortOrder: {
        [key: string]: 'ASC' | 'DESC';
    };
    setOrderMethod: (value: SortField) => void;
    delayQuery?: string | undefined;
    status?: TGroup['status'][];
    onPressPrev: () => Promise<void>;
    onPressNext: () => Promise<void>;
    onPressMore: () => Promise<void>;
    onPressFirst: () => Promise<void>;
    onPressLast: () => Promise<void>;
    onPressPage: (page: number) => Promise<void>;
    onQueryChange?: (value: string) => void;
    onStatusChange: (value: TGroup['status'][] | []) => void;
};

export const GroupMembersTable = memo(
    ({
        planId,
        members,
        meta,
        isLoading,
        sortField,
        sortOrder,
        setOrderMethod,
        delayQuery,
        status,
        onPressPrev,
        onPressNext,
        onPressMore,
        onPressFirst,
        onPressLast,
        onPressPage,
        onQueryChange,
        onStatusChange,
    }: GroupMembersTableProps) => {
        return (
            <>
                <FiltersHeader
                    isLoading={isLoading && !!meta?.total}
                    query={delayQuery}
                    status={status}
                    onQueryChange={onQueryChange}
                    onStatusChange={onStatusChange}
                />
                <div className="table" style={{ position: 'relative' }}>
                    <table>
                        <tbody>
                            {!!meta?.total && (
                                <tr className="tr-overview">
                                    <Th
                                        title="Company name"
                                        field="company_name"
                                        sortField={sortField}
                                        sortOrder={sortOrder?.company_name}
                                        onPress={() =>
                                            setOrderMethod('company_name')
                                        }
                                    />
                                    <Th title="Used" />
                                    <Th
                                        title="Member from"
                                        field="created_at"
                                        sortField={sortField}
                                        sortOrder={sortOrder?.created_at}
                                        onPress={() =>
                                            setOrderMethod('created_at')
                                        }
                                    />
                                    <Th
                                        title="Status"
                                        field="status"
                                        sortField={sortField}
                                        sortOrder={sortOrder.status}
                                        onPress={() => setOrderMethod('status')}
                                    />
                                    <Th
                                        title="Email"
                                        field="email"
                                        sortField={sortField}
                                        sortOrder={sortOrder.email}
                                        onPress={() => setOrderMethod('email')}
                                    />
                                </tr>
                            )}
                            {members.map((member, index) => (
                                <TableRow
                                    key={member?.id?.toString()}
                                    last={index === members?.length - 1}
                                >
                                    <Td1
                                        companyName={member?.company_name}
                                        index={index + 1}
                                    />
                                    <Td2
                                        planId={planId}
                                        usageAmount={member?.usage_amount}
                                        // onClick={() => onClick(group?.id)}
                                    />
                                    <Td3 sqlDate={member?.created_at} />
                                    <Td4 status={member?.status} />
                                    <Td5 email={member?.email} />
                                </TableRow>
                            ))}
                        </tbody>
                    </table>
                </div>
                <PaginationButtons
                    // withoutBlock
                    isLoading={isLoading}
                    prevDisabled={meta?.current_page === 1 || isLoading}
                    nextDisabled={
                        meta?.current_page === meta?.last_page || isLoading
                    }
                    currentPage={meta?.current_page}
                    lastPage={meta?.last_page}
                    pagesDisabled={isLoading}
                    total={meta?.total}
                    onPressPrev={onPressPrev}
                    onPressNext={onPressNext}
                    onPressMore={onPressMore}
                    onPressFirst={onPressFirst}
                    onPressLast={onPressLast}
                    onPressPage={onPressPage}
                />
            </>
        );
    },
);
