import { AxiosResponse } from 'axios';
import api from './axiosConfig';
import {
    externalConnectionAdd,
    externalConnectionUpdate,
} from '../state/externalConnections';
import { store } from '../state/store';

export const fetchExternalConnections = async () =>
    await api('/external-sources');

export const fetchExternalConnection = async (id: number) => {
    const response = await api(`/external-sources/${id}`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(externalConnectionUpdate(response.data?.data));
    }
    return response;
};

export const connectExternalConnection:
    | Promise<AxiosResponse<any>>
    | any = async (id: number, fields?: { [key: string]: string }) => {
    const response = await api.post(`/external-sources/${id}/connect`, fields);
    if (
        (response.status === 200 || response.status === 201) &&
        response.data?.data
    ) {
        store.dispatch(externalConnectionAdd(response.data?.data));
    }
    return response;
};

export const editExternalConnection: Promise<AxiosResponse<any>> | any = async (
    id: number,
    fields: { [key: string]: string },
) => {
    const response = await api.patch(`/external-sources/${id}/update`, fields);
    if (
        (response.status === 200 || response.status === 201) &&
        response.data?.data
    ) {
        store.dispatch(externalConnectionUpdate(response.data?.data));
    }
    return response;
};

export const disconnectExternalConnection:
    | Promise<AxiosResponse<any>>
    | any = async (id: number) => {
    const response = await api.post(`/external-sources/${id}/disconnect`);
    if (
        (response.status === 200 || response.status === 201) &&
        response.data?.data
    ) {
        store.dispatch(externalConnectionUpdate(response.data?.data));
    }
    return response;
};
