import { State } from './styles';
import { TPaymentHistory } from '../../types/types';

type Props = {
    state: TPaymentHistory['state'];
    onClick?: () => void;
};

export const TdState = ({ state, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <State state={state}>{state}</State>
            </button>
        </td>
    );
};
