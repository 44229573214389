import { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { InviteModal } from './InviteModal';

import { getMyUser } from '../../state/user';
import { AppHeader, CurrenciesFooter } from '../../components';
import { getInitials } from '../../services/helpers';
import { deleteMember, fetchMembers } from '../../services/MembersService';
import {
    getAbilityEditCurrentOrganizationSettings,
    getAbilityToManageMembers,
    getMyCurrentOrganizationName,
} from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { getMembers } from '../../state/members';

export const OrganizationMembers = () => {
    const { id } = useParams<{ id: string }>();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const history = useHistory();
    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );
    const membersState = useSelector(getMembers);

    const members = useMemo(
        () =>
            Object.values(membersState).sort((member1, member2) => {
                if (member1.is_owner) {
                    return -1;
                }
                if (
                    !member1?.is_owner &&
                    !member2?.is_owner &&
                    member1.id > member2.id
                ) {
                    return -1;
                }
                return 1;
            }),
        [membersState],
    );

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                setLoading(true);
                const response = await fetchMembers();
                setLoading(false);
                if (response.status !== 200) {
                    history.push('/');
                }
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, history]);

    useEffect(() => {
        if (!canChangeOrganizationSettings && !!currentOrganizationId) {
            history.push(`/`);
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history]);

    useEffect(() => {
        if (
            !!id &&
            !!currentOrganizationId &&
            Number(id) !== currentOrganizationId
        ) {
            history.push(`/organization${currentOrganizationId}/members`);
        }
    }, [currentOrganizationId, history, id]);

    const currentOrganizationName = useSelector(getMyCurrentOrganizationName);
    const myUser = useSelector(getMyUser);

    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const openMember = useCallback(
        (memberId: number) => {
            history.push(
                `/organization${currentOrganizationId}/member/${memberId}`,
            );
        },
        [currentOrganizationId, history],
    );

    const [isLoading, setLoading] = useState(false);

    const removeMember = useCallback(async (memberId: number) => {
        setLoading(true);
        await deleteMember(memberId);
        setLoading(false);
    }, []);

    const canManageMembers = useSelector(getAbilityToManageMembers);

    // const [isTipVisible, setTipVisible] = useState(true);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                isLoading={isLoading}
                title={`Members(${members?.length})`}
                headerSubtitles={[
                    {
                        subtitle: currentOrganizationName || '',
                        subtitleLink: `/organization/${id}`,
                    },
                ]}
            />

            <div className="app-content">
                <div className="app-body">
                    <div className="block block-title">
                        <div className="block-title-wrapper">
                            {`Members (${members?.length})`}
                            {canManageMembers && (
                                <div className="block-title-subtitle">
                                    Manage who has access to{' '}
                                    {currentOrganizationName || ''} or add new
                                    members to
                                </div>
                            )}
                        </div>
                        {canManageMembers && (
                            <div className="block-title-actions">
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className="button button-primary button-primary-outline button-sm"
                                    onClick={showModal}
                                    onKeyDown={showModal}
                                >
                                    <em className="mdi mdi-account-multiple-outline start" />
                                    Add people
                                </div>
                            </div>
                        )}
                    </div>
                    {!!members?.length && (
                        <div className="block block-users">
                            {members?.map((member, index) => (
                                <div key={index.toString()} className="user">
                                    <div className="user-media">
                                        {member?.avatar?.sizes?.thumbnail ? (
                                            <img
                                                className="user-media-image"
                                                src={
                                                    member?.avatar?.sizes
                                                        ?.thumbnail
                                                }
                                                alt=""
                                            />
                                        ) : (
                                            !!member?.first_name &&
                                            !!member?.last_name && (
                                                <div className="user-media-abbr">
                                                    {getInitials(
                                                        `${member?.first_name} ${member?.last_name}`,
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="user-details">
                                        <div className="user-name">
                                            {/* {' '} */}
                                            {`${member?.first_name} ${member?.last_name}`}
                                            <div
                                                className={`label ${
                                                    !!member?.invintation_accepted ||
                                                    member?.is_owner
                                                        ? 'label-primary-outline'
                                                        : 'label-default-outline'
                                                }`}
                                            >
                                                {member?.is_owner
                                                    ? 'Owner'
                                                    : member?.invintation_accepted
                                                    ? 'User'
                                                    : 'Invitation sent'}
                                            </div>
                                        </div>
                                        <div className="user-email">
                                            {member?.email}
                                        </div>
                                    </div>
                                    {!member?.is_owner &&
                                        myUser?.email !== member?.email &&
                                        canManageMembers && (
                                            <div className="user-actions">
                                                <div className="button-group">
                                                    <div
                                                        className="button button-default"
                                                        role="button"
                                                        tabIndex={-1}
                                                        onClick={() =>
                                                            openMember(
                                                                member?.id,
                                                            )
                                                        }
                                                        onKeyDown={() =>
                                                            openMember(
                                                                member?.id,
                                                            )
                                                        }
                                                    >
                                                        <div className="mdi mdi-pencil start primary" />
                                                        Edit permissions
                                                    </div>
                                                    <div
                                                        className="button button-default"
                                                        role="button"
                                                        tabIndex={-2}
                                                        onClick={() =>
                                                            removeMember(
                                                                member?.id,
                                                            )
                                                        }
                                                        onKeyDown={() =>
                                                            removeMember(
                                                                member?.id,
                                                            )
                                                        }
                                                    >
                                                        <div className="mdi mdi-trash-can-outline start" />
                                                        Delete
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* {isTipVisible && <Tip setTipVisible={setTipVisible} />} */}
                </div>
                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
            <InviteModal
                isModalOpen={isModalOpen}
                onRequestClose={closeModal}
            />
        </div>
    );
};
