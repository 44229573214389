const privacyPolicy =
    'https://troccircle.com/api/files/c3307dd59e15a2d918b7449340ec04a035e8f5b0d2462ef62935bb1a00b3bb97/download';
const termsAndConditionsUrl =
    'https://troccircle.com/api/files/4e1be9cf8f6cb9f39d793c786d173cd4e4ef7b663dc23e2cef9e5fe75e03c764/download';
const payneticsConsumer =
    'https://troccircle.com/api/files/6f0231d606b94371109cac3f09741bca231fe13cf53a94fa7aec7448250bc6dd/download';
const payneticsCorporate =
    'https://troccircle.com/api/files/f424cb37fb2c78289108b72525dff0c928bb80a3d77a57047dcf950f3da440ee/download';
export const config = {
    apiUrl: process.env.REACT_APP_BASE_URL,
    privacyPolicy,
    termsAndConditionsUrl,
    payneticsConsumer,
    payneticsCorporate,
    isDev:
        process?.env?.REACT_APP_BASE_URL ===
        'https://api.dev.troccircle.com/v1',
};
