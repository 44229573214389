import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import menuIconCircles from '../../assets/img/menu-icons/menu-icon-circles.svg';
import { getSumNotificationsCircle } from '../../state/statistics';

export const MenuCirclesLink = () => {
    const history = useHistory();

    const sumNotificationsCircle = useSelector(getSumNotificationsCircle);
    const onClick = useCallback(() => {
        history.push('/circles/all');
    }, [history]);
    return (
        <div
            role="button"
            tabIndex={0}
            className="menu-item-link"
            // style={{
            //     borderWidth: 0,
            //     background: 'transparent',
            //     color: 'white',
            //     opacity: location.pathname.includes('circles') ? 1 : 0.6,
            // }}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <div className="menu-item-icon">
                <img src={menuIconCircles} alt="menu icon circles" />
            </div>

            <div className="menu-item-label">
                Circles
                {!!sumNotificationsCircle && (
                    <div className="menu-item-notes">
                        {sumNotificationsCircle}
                    </div>
                )}
            </div>
        </div>
    );
};
