import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { HeaderFactoringConnection } from './HeaderFactoringConnection';

import {
    DefaultCurrencyIcon,
    TotalHeader,
    TotalIcon,
    TotalValue,
} from './styles';
import { ClientBlocks } from './ClientBlocks';
import { AppHeader, CurrenciesFooter, Row } from '../../components';
import {
    activateClientOrganization,
    fetchCurrentOrganizationStatistics,
    fetchFactoringClient,
    rejectClientOrganization,
} from '../../services/OrganizationsService';

import invoiceIcon from '../../assets/img/icons/invoice.svg';
import handShakeIcon from '../../assets/img/icons/icon-handshake.svg';
import { getDefaultCurrency } from '../../state/user';
import { getFactoringClientById } from '../../state/organizationsFactoringClients';
import { amountOptions } from '../../services/helpers';
import { getMyCurrentOrganization } from '../../state/myOrganizations';

export const FactoringClient = () => {
    const { id } = useParams<{
        id: string;
    }>();

    // const [isTipVisible, setTipVisible] = useState(true);
    const currentOrganization = useSelector(getFactoringClientById(Number(id)));
    const connection_id = useMemo(
        () => currentOrganization?.organization_request_connection?.id,
        [currentOrganization?.organization_request_connection?.id],
    );
    const myCurrentOrganization = useSelector(getMyCurrentOrganization);

    const [isLoading, setLoading] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (myCurrentOrganization?.id && connection_id) {
            (async () => {
                setButtonDisabled(true);
                const response = await fetchFactoringClient(connection_id);
                setButtonDisabled(false);
                if (response.status !== 200) {
                    history.push('/factoring-clients');
                }
            })();
        }
    }, [connection_id, history, id, myCurrentOrganization?.id]);

    const onSubmit = useCallback(async () => {
        if (connection_id) {
            setButtonDisabled(true);
            setLoading(true);
            if (
                currentOrganization?.organization_request_connection?.status ===
                'Active'
            ) {
                await rejectClientOrganization(connection_id);
            } else if (
                currentOrganization?.organization_request_connection?.status ===
                    'Disconnected by factor' ||
                currentOrganization?.organization_request_connection?.status ===
                    'Rejected'
            ) {
                await activateClientOrganization(connection_id);
            }
            setButtonDisabled(false);
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [
        connection_id,
        currentOrganization?.organization_request_connection?.status,
    ]);

    const activateClientOrganizationMethod = useCallback(async () => {
        if (connection_id) {
            setLoading(true);
            setButtonDisabled(true);
            await activateClientOrganization(connection_id);
            setLoading(false);
            setButtonDisabled(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [connection_id]);

    const rejectClientOrganizationMethod = useCallback(async () => {
        if (connection_id) {
            setLoading(true);
            setButtonDisabled(true);
            await rejectClientOrganization(connection_id);
            setLoading(false);
            setButtonDisabled(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [connection_id]);

    const defaultCurrency = useSelector(getDefaultCurrency);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                isLoading={isLoading}
                title={currentOrganization?.company_name}
                headerSubtitles={[
                    {
                        subtitle: 'Factoring clients',
                        subtitleLink: '/factoring-clients',
                    },
                ]}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-window">
                        <HeaderFactoringConnection
                            logo={currentOrganization?.logo?.sizes?.thumbnail}
                            organizationName={currentOrganization?.company_name}
                            status={
                                currentOrganization
                                    ?.organization_request_connection?.status
                            }
                            isFactor
                            disabled={isButtonDisabled}
                            onHeaderButtonPress={onSubmit}
                            onActivate={activateClientOrganizationMethod}
                            onReject={rejectClientOrganizationMethod}
                        />
                    </div>

                    {currentOrganization?.organization_request_connection
                        ?.status === 'Active' && (
                        <Row
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'stretch',
                            }}
                        >
                            <div className="block block-window column">
                                <div className="window-card">
                                    <Row>
                                        <TotalIcon src={invoiceIcon} />
                                        <TotalHeader>
                                            Factored transactions (total amount)
                                        </TotalHeader>
                                    </Row>
                                    <Row style={{ marginTop: 10 }}>
                                        <DefaultCurrencyIcon
                                            src={defaultCurrency?.icon?.svg}
                                        />
                                        <TotalValue>
                                            {currentOrganization?.organization_request_connection
                                                ? currentOrganization?.organization_request_connection?.factored_transactions?.toLocaleString(
                                                      'en',
                                                      amountOptions,
                                                  )
                                                : '0.00'}
                                        </TotalValue>
                                    </Row>
                                </div>
                            </div>
                            <div style={{ width: 20 }} />
                            <div className="block block-window column">
                                <div className="window-card">
                                    <Row>
                                        <TotalIcon src={handShakeIcon} />
                                        <TotalHeader>
                                            In connection since
                                        </TotalHeader>
                                    </Row>
                                    <Row style={{ marginTop: 10 }}>
                                        <TotalValue>
                                            {currentOrganization
                                                ?.organization_request_connection
                                                ?.created_at
                                                ? DateTime.fromSQL(
                                                      currentOrganization
                                                          ?.organization_request_connection
                                                          ?.created_at,
                                                  )
                                                      .toUTC()
                                                      .toFormat(
                                                          "dd MMMM yyyy, HH:mm 'GMT'",
                                                      )
                                                : '-'}
                                        </TotalValue>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    )}
                    {!!currentOrganization?.organization_request_connection
                        ?.owners?.length && (
                        <ClientBlocks
                            connection={
                                currentOrganization?.organization_request_connection
                            }
                        />
                    )}

                    {/* {isTipVisible && <Tip setTipVisible={setTipVisible} />} */}
                </div>

                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
        </div>
    );
};
