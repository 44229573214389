import Select, { ActionMeta } from 'react-select';
import makeAnimated from 'react-select/animated';

type Props = {
    isClearable?: boolean | undefined;
    isMulti?: boolean | undefined;
    placeholder?: React.ReactNode;
    value?: any;
    options?: readonly any[] | undefined;
    disabled?: boolean;
    menuIsOpen?: boolean;
    withInfo?: boolean;
    onChange?:
        | (((value: any, actionMeta: ActionMeta<any>) => void) &
              ((value: any, action: ActionMeta<any>) => void))
        | undefined;
};

const animatedComponents = makeAnimated();

export const SelectComponent = ({
    isClearable = true,
    isMulti = false,
    placeholder,
    value,
    options,
    disabled,
    menuIsOpen,
    withInfo,
    onChange,
}: Props) => {
    return (
        <Select
            className={`form-control ${
                withInfo
                    ? 'form-control-without-padding-with-info'
                    : 'form-control-without-padding'
            }`}
            isClearable={isClearable}
            isMulti={isMulti}
            placeholder={placeholder}
            value={value}
            isDisabled={disabled}
            onChange={onChange}
            options={options}
            components={animatedComponents}
            menuIsOpen={menuIsOpen}
            styles={{
                control: base => ({
                    ...base,
                    borderWidth: 0,
                    borderColor: undefined,
                    boxShadow: 'none',
                    minHeight: undefined,
                    borderRadius: 5,
                }),
                placeholder: base => ({
                    ...base,
                    color: '#919191',
                    font: 'inherit',
                    lineHeight: 20,
                }),
            }}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary: '#3367d6',
                },
            })}
        />
    );
};
