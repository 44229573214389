import { memo } from 'react';
import {
    CreateOrganizationProps,
    EditOrganizationBlock,
} from '../../types/types';
import { FormError, SelectComponent } from '../../components';

type OrganizationDetailsBlockProps = {
    isDetailsEditable: boolean;
    id?: string | null;
    canChangeOrganizationSettings: boolean;
    organization: Partial<CreateOrganizationProps>;
    legalOption: {
        value: string;
        label: string;
    } | null;
    errors: {
        [key: string]: any;
    };
    countryOption: {
        value: string;
        label: string;
        regions: string;
    } | null;
    countriesOptions: {
        value: string;
        label: string;
        regions: string;
    }[];
    businessOptions: {
        value:
            | 'INDIVIDUAL_SOLE_PROPRIETORSHIP'
            | 'CORPORATION'
            | 'LIMITED_LIABILITY_COMPANY'
            | 'PARTNERSHIP'
            | 'ASSOCIATION_ESTATE_TRUST'
            | 'TAX_EXEMPT_ORGANIZATION'
            | 'INTERNATIONAL_ORGANIZATION'
            | 'GOVERNMENT_AGENCY';
        label: string;
    }[];
    editMethodModal: (block: EditOrganizationBlock) => void;
    organizationChanged: (
        key: keyof CreateOrganizationProps,
        value: string | number,
    ) => void;
    onCountryChange: (item: any) => void;
    onLegalFormChange: (item: any) => void;
};

export const OrganizationDetailsBlock = memo(
    ({
        isDetailsEditable,
        id,
        canChangeOrganizationSettings,
        organization,
        legalOption,
        errors,
        countryOption,
        countriesOptions,
        businessOptions,
        editMethodModal,
        organizationChanged,
        onCountryChange,
        onLegalFormChange,
    }: OrganizationDetailsBlockProps) => {
        return !isDetailsEditable && !!id ? (
            <>
                <div className="block block-title">
                    Organization details
                    {canChangeOrganizationSettings && (
                        <div className="block-title-actions">
                            <button
                                type="button"
                                className="button button-default button-sm"
                                onClick={() => editMethodModal('details')}
                            >
                                <em className="mdi mdi-pencil start primary" />
                                Edit
                            </button>
                        </div>
                    )}
                </div>
                <div className="block block-overview-list">
                    <div className="row">
                        <div className="col-lg-6 col-xs-12 overview-item">
                            <div className="overview-item-label">
                                Legal name (business name)
                            </div>
                            <div className="overview-item-value">
                                {organization?.company_name || '-'}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-xs-12 overview-item">
                      <div className="overview-item-label">
                          Alternative names
                      </div>
                      <div className="overview-item-value">
                          Weget Enterprise, Fine Art
                          Room
                      </div>
                  </div> */}
                        <div className="col-lg-6 col-xs-12 overview-item">
                            <div className="overview-item-label">
                                Business type
                            </div>
                            <div className="overview-item-value">
                                {legalOption?.label || '-'}
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12 overview-item">
                            <div className="overview-item-label">
                                Registration number / EIN
                            </div>
                            <div className="overview-item-value">
                                {organization?.registration_number || '-'}
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12 overview-item">
                            <div className="overview-item-label">
                                VAT number (Optional)
                            </div>
                            <div className="overview-item-value">
                                {organization?.vat_number || '-'}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-xs-12 overview-item">
              <div className="overview-item-label">
                  Incorporation date
              </div>
              <div className="overview-item-value">
                  14 January 2014
              </div>
          </div> */}
                    </div>
                </div>
            </>
        ) : (
            <>
                <div className="form-title">Organization details</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-xs-12">
                        <div className="form-label">
                            Legal name (business name)
                        </div>
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Legal name (business name)"
                                value={organization?.company_name || ''}
                                onChange={e =>
                                    organizationChanged(
                                        'company_name',
                                        e.target.value,
                                    )
                                }
                            />
                            <div className="form-control-info">
                                <em className="mdi mdi-information form-control-info-icon" />
                                <div className="form-control-info-container form-control-info-container-dim">
                                    Legal name
                                    <br />
                                    (business name)
                                </div>
                            </div>
                        </div>
                        <FormError errors={errors?.company_name} />
                    </div>
                    {!id ? (
                        <div className="form-group col-lg-6 col-xs-12">
                            <div className="form-label">Country</div>
                            <div className="form-control-wrapper">
                                <SelectComponent
                                    withInfo
                                    isClearable
                                    placeholder="Country"
                                    value={countryOption}
                                    onChange={onCountryChange}
                                    options={countriesOptions as any}
                                />
                                <div className="form-control-info">
                                    <em className="mdi mdi-information form-control-info-icon" />
                                    <div className="form-control-info-container">
                                        Country
                                    </div>
                                </div>
                            </div>
                            <FormError errors={errors?.country_code} />
                        </div>
                    ) : (
                        <div className="form-group col-lg-6 col-xs-12">
                            <div className="form-label">Business type</div>
                            <div className="form-control-wrapper">
                                <SelectComponent
                                    withInfo
                                    isClearable
                                    placeholder="Business type"
                                    value={legalOption}
                                    onChange={onLegalFormChange}
                                    options={businessOptions as any}
                                />
                                <div className="form-control-info">
                                    <em className="mdi mdi-information form-control-info-icon" />
                                    <div className="form-control-info-container form-control-info-container-dim">
                                        Business
                                        <br />
                                        type
                                    </div>
                                </div>
                            </div>
                            <FormError errors={errors?.business_type} />
                        </div>
                    )}
                    {/* <div className="form-group col-lg-6 col-xs-12">
  <input
      className="form-control"
      type="text"
      placeholder="Alternative names (optional)"
  />
</div> */}
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-xs-12">
                        <div className="form-label">
                            Registration number / EIN
                        </div>
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Registration number / EIN"
                                value={organization?.registration_number || ''}
                                onChange={e =>
                                    organizationChanged(
                                        'registration_number',
                                        e.target.value,
                                    )
                                }
                            />
                            <div className="form-control-info">
                                <em className="mdi mdi-information form-control-info-icon" />
                                <div className="form-control-info-container form-control-info-container-dim">
                                    Registration
                                    <br />
                                    number
                                </div>
                            </div>
                        </div>
                        <FormError errors={errors?.registration_number} />
                    </div>
                    <div className="form-group col-lg-6 col-xs-12">
                        <div className="form-label">VAT number (Optional)</div>
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="VAT number (Optional)"
                                value={organization?.vat_number || ''}
                                onChange={e =>
                                    organizationChanged(
                                        'vat_number',
                                        e.target.value.toUpperCase(),
                                    )
                                }
                            />
                            <div className="form-control-info">
                                <em className="mdi mdi-information form-control-info-icon" />
                                <div className="form-control-info-container">
                                    VAT number (Optional)
                                </div>
                            </div>
                        </div>
                        <FormError errors={errors?.vat_number} />
                    </div>
                </div>
                {/* <div className="row">
<div className="form-group col-lg-6 col-xs-12">
  <DatePicker
      format={
          organization?.date
              ? 'yyyy-MM-dd'
              : 'dd'
      }
      dayPlaceholder="Incorporation date"
      className="form-control form-control-small-padding"
      onChange={onDateChange}
      value={
          organization?.date
              ? DateTime.fromSQL(
                    organization?.date,
                ).toJSDate()
              : undefined
      }
      calendarIcon={
          <img
              src={calendarIcon}
              alt="calendar icon"
          />
      }
  />
  <FormError errors={errors?.date} />
</div>
</div> */}
            </>
        );
    },
);
