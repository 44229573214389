import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import '../../scss/components/DatePicker.css';
import '../../scss/components/Calendar.css';
import parsePhoneNumberFromString, {
    parsePhoneNumber,
} from 'libphonenumber-js';
import { useHistory, useLocation } from 'react-router-dom';
import * as countries from 'countries-list';
import Loader from 'react-loader-spinner';
import { DeleteAccountButton } from './DeleteAccountButton';
import { DeleteAccountForm } from './DeleteAccountForm';
import calendarIcon from '../../assets/img/icons/calendar.svg';

import { getMyUser } from '../../state/user';
import {
    checkPassword,
    getInitials,
    getPhoneString,
    hideEmail,
    isEmail,
    removeSnake,
} from '../../services/helpers';
import { UpdateUserProps } from '../../types/types';
import {
    AppHeader,
    CountryPhoneSelector,
    CurrenciesFooter,
    Emoji,
    FormError,
} from '../../components';
import { updateMyUser } from '../../services/UsersService';
import { deleteMedia, uploadMedia } from '../../services/MediaService';
import { signOut } from '../../services/SignOutService';

export const Profile = () => {
    const storedUser = useSelector(getMyUser);

    const storedUserData = useMemo(() => {
        return {
            first_name: storedUser?.first_name,
            last_name: storedUser?.last_name,
            phone: storedUser?.phone ? getPhoneString(storedUser?.phone) : '',
            email: storedUser?.email,
            birthday: storedUser?.birthday,
            country_code: storedUser?.country_code,
            media_uid: storedUser?.avatar?.uid,
        };
    }, [
        storedUser?.avatar?.uid,
        storedUser?.birthday,
        storedUser?.country_code,
        storedUser?.email,
        storedUser?.first_name,
        storedUser?.last_name,
        storedUser?.phone,
    ]);

    const [user, setUser] = useState<Partial<UpdateUserProps>>(storedUserData);

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const [isFooterButtonsVisible, setFooterButtonsVisible] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location?.pathname) {
            setUser(storedUserData);
            setErrors({});
            setDeleteAccountFormVisible(false);
        }
    }, [location?.pathname, storedUserData]);

    const [phoneCountryCode, setPhoneCountryCode] = useState(
        !!user?.phone &&
            user?.phone?.length > 6 &&
            !!parsePhoneNumberFromString(user?.phone)
            ? parsePhoneNumber(user?.phone)?.country
            : '',
    );

    const emoji = useMemo(
        () =>
            phoneCountryCode ? countries?.getEmojiFlag(phoneCountryCode) : '',
        [phoneCountryCode],
    );

    const userChanged = useCallback(
        (key: string, value: string) => {
            setFooterButtonsVisible(true);
            if (
                key === 'phone' &&
                !!value &&
                value?.length > 6 &&
                !!parsePhoneNumberFromString(value)
            ) {
                if (parsePhoneNumber(value)?.country) {
                    setPhoneCountryCode(parsePhoneNumber(value)?.country);
                }
            }
            setUser(prevState => ({
                ...prevState,
                [key]: key === 'phone' ? getPhoneString(value) : value,
            }));
            if (
                (key === 'email' && !isEmail(value)) ||
                (key === 'first_name' && !value) ||
                (key === 'last_name' && !value) ||
                (key === 'password' && !checkPassword(value)) ||
                (key === 'password_confirmation' && value !== user?.password) ||
                (key === 'phone' &&
                    ((!!value &&
                        !parsePhoneNumberFromString(value)?.isPossible()) ||
                        !value))
            ) {
                const errorMessage =
                    key === 'email'
                        ? 'The email must be a valid email address'
                        : key === 'password'
                        ? 'The password must be 6–30 characters, and include a number and a letter'
                        : key === 'password_confirmation'
                        ? 'The password confirmation does not match'
                        : key === 'phone'
                        ? 'The phone must be a valid phone number'
                        : `The ${removeSnake(key)} field is required`;

                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors, user.password],
    );

    const updatePhonePrefix = useCallback(
        (countryCode: string, phonePrefix: string) => {
            setPhoneCountryCode(countryCode);
            userChanged('phone', phonePrefix);
        },
        [userChanged],
    );

    const handleBirthday = useCallback(
        (date: Date | Date[] | null | undefined) => {
            if (!!date && !(date instanceof Array)) {
                userChanged(
                    'birthday',
                    DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
                );
            } else {
                userChanged('birthday', '');
            }
        },
        [userChanged],
    );

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const requiredFields = useMemo(() => {
        return location?.pathname === '/change-password'
            ? ['old_password', 'password', 'password_confirmation']
            : ['first_name', 'last_name', 'phone', 'email'];
    }, [location?.pathname]);

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!user?.hasOwnProperty(item) || user[item] === undefined)
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [errors, requiredFields, user]);

    const history = useHistory();

    const onCancel = useCallback(() => {
        setUser(storedUserData);
        setErrors({});
        setFooterButtonsVisible(false);
    }, [storedUserData]);

    const [avatar, setAvatar] = useState<{
        data: string | ArrayBuffer | null;
        path: string;
        file: string;
    }>();

    const signUp = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                let mediaUid = user?.media_uid;
                if (avatar?.file) {
                    const addNewAvatar = await uploadMedia(
                        avatar?.file as any,
                        'avatar',
                    );

                    if (
                        addNewAvatar.status === 200 ||
                        addNewAvatar.status === 201
                    ) {
                        if (mediaUid) {
                            await deleteMedia(mediaUid);
                        }
                        mediaUid = addNewAvatar?.data?.data?.uid;
                    }
                }
                const userProps = { ...user };
                if (mediaUid) {
                    userProps.media_uid = mediaUid;
                }

                const response = await updateMyUser(
                    userProps as UpdateUserProps,
                );

                setButtonDisabled(false);
                if (response?.status === 200) {
                    history.goBack();
                }
                if (response?.response?.data?.errors) {
                    const {
                        first_name,
                        last_name,
                        email,
                        phone,
                        password,
                        password_confirmation,
                        old_password,
                        ...other
                    } = response?.response?.data?.errors;
                    if (first_name)
                        setErrors(prevState => ({
                            ...prevState,
                            first_name,
                        }));
                    if (last_name)
                        setErrors(prevState => ({
                            ...prevState,
                            last_name,
                        }));
                    if (email)
                        setErrors(prevState => ({ ...prevState, email }));
                    if (phone)
                        setErrors(prevState => ({ ...prevState, phone }));
                    if (password)
                        setErrors(prevState => ({ ...prevState, password }));
                    if (password_confirmation)
                        setErrors(prevState => ({
                            ...prevState,
                            password_confirmation,
                        }));
                    if (old_password)
                        setErrors(prevState => ({
                            ...prevState,
                            old_password,
                        }));
                    if (Object.keys(other).length)
                        console.log('other errors:', other);
                }
            }
        }
    }, [errors, checkEmptyValues, avatar?.file, user, history]);

    const readFile = useCallback(e => {
        if (FileReader && e.target?.files && e.target?.files.length) {
            setFooterButtonsVisible(true);
            const avatars = [...e.target?.files];
            const fr = new FileReader();
            fr.readAsDataURL(avatars[0]);
            let path = avatars[0]?.name;
            path = path.replace(/\s/g, '/');
            fr.onload = () => {
                setAvatar({
                    data: fr.result as string,
                    path: `/files/${path}`,
                    file: avatars[0],
                });
            };
        }
        e.target.value = null;
    }, []);

    const [isCountrySelectorOpen, setCountrySelectorOpen] = useState(false);

    const onOpenCountrySelector = useCallback(() => {
        setCountrySelectorOpen(true);
    }, []);

    const onCloseCountrySelector = useCallback(() => {
        setCountrySelectorOpen(false);
    }, []);

    const [isDeleteAccountFormVisible, setDeleteAccountFormVisible] = useState(
        false,
    );

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                tabs={[
                    { name: 'Personal details', link: '/profile' },
                    {
                        name: 'Password settings',
                        link: '/change-password',
                    },
                ]}
                title="Profile"
                // type="headerMenu"
                logoutButton
                isButtonDisabled={isButtonDisabled}
                logout={signOut}
                // headerMenuItems={[
                //     { title: 'Personal details', link: '/profile' },
                //     { title: 'Password settings', link: '/change-password' },
                // ]}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="flex flex-direction-row">
                        <div className="wrapper wrapper-sm form">
                            {location?.pathname === '/profile' &&
                                !isDeleteAccountFormVisible && (
                                    <>
                                        <div className="block block-media-picker">
                                            <label
                                                htmlFor="file-input"
                                                className="picker-media"
                                            >
                                                <input
                                                    id="file-input"
                                                    name="myFile"
                                                    type="file"
                                                    accept="image/*"
                                                    className="hidden"
                                                    onChange={readFile}
                                                />
                                                {storedUser.avatar?.sizes
                                                    ?.thumbnail || avatar ? (
                                                    <img
                                                        src={
                                                            (avatar?.data as string) ||
                                                            storedUser.avatar
                                                                ?.sizes
                                                                ?.thumbnail
                                                        }
                                                        alt="user avatar"
                                                        className="picker-media-image"
                                                    />
                                                ) : (
                                                    <div className="picker-media-abbr">
                                                        {!!storedUser?.first_name &&
                                                            !!storedUser?.last_name &&
                                                            getInitials(
                                                                `${storedUser?.first_name} ${storedUser?.last_name}`,
                                                            )}
                                                    </div>
                                                )}
                                            </label>
                                            <div className="picker-details">
                                                <div className="picker-details-title">
                                                    {!!storedUser?.first_name &&
                                                        !!storedUser?.last_name &&
                                                        `${storedUser?.first_name} ${storedUser?.last_name}`}
                                                </div>
                                                <div className="picker-details-title">
                                                    {user?.email &&
                                                        hideEmail(user?.email)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-title">
                                            Personal details
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6 col-xs-12">
                                                <div className="form-label">
                                                    First name
                                                </div>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="First name"
                                                    value={user.first_name}
                                                    onChange={e =>
                                                        userChanged(
                                                            'first_name',
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                                <FormError
                                                    errors={errors?.first_name}
                                                />
                                            </div>
                                            <div className="form-group col-lg-6 col-xs-12">
                                                <div className="form-label">
                                                    Last name
                                                </div>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Last name"
                                                    value={user.last_name}
                                                    onChange={e =>
                                                        userChanged(
                                                            'last_name',
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                                <FormError
                                                    errors={errors?.last_name}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6 col-xs-12">
                                                <div className="form-label">
                                                    Phone
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <input
                                                        className="form-control"
                                                        type="phone"
                                                        placeholder="Phone"
                                                        value={
                                                            user?.phone || ''
                                                        }
                                                        onChange={e =>
                                                            userChanged(
                                                                'phone',
                                                                e.target.value,
                                                            )
                                                        }
                                                        style={{
                                                            paddingLeft: 55,
                                                        }}
                                                    />

                                                    <Emoji
                                                        onPress={
                                                            onOpenCountrySelector
                                                        }
                                                        symbol={emoji}
                                                    />
                                                </div>

                                                <FormError
                                                    errors={errors?.phone}
                                                />
                                            </div>
                                            <div className="form-group col-lg-6 col-xs-12">
                                                <div className="form-label">
                                                    E-mail
                                                </div>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    placeholder="E-mail"
                                                    value={user?.email || ''}
                                                    onChange={e =>
                                                        userChanged(
                                                            'email',
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                                <FormError
                                                    errors={errors?.email}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6 col-xs-12">
                                                <div className="form-label">
                                                    Date of birth
                                                </div>
                                                <DatePicker
                                                    format="yyyy MM dd"
                                                    dayPlaceholder=""
                                                    monthPlaceholder=""
                                                    monthAriaLabel=""
                                                    yearPlaceholder="Date of birth"
                                                    maxDate={new Date()}
                                                    className="form-control form-control-small-padding"
                                                    onChange={handleBirthday}
                                                    value={
                                                        user?.birthday
                                                            ? DateTime.fromSQL(
                                                                  user?.birthday,
                                                              ).toJSDate()
                                                            : undefined
                                                    }
                                                    calendarIcon={
                                                        <img
                                                            src={calendarIcon}
                                                            alt="calendar icon"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            {location?.pathname === '/change-password' && (
                                <>
                                    <div className="form-title">
                                        Change password
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6 col-xs-12">
                                            <div className="form-label">
                                                Password
                                            </div>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter old password"
                                                value={user?.old_password || ''}
                                                onChange={e =>
                                                    userChanged(
                                                        'old_password',
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <FormError
                                                errors={errors?.old_password}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6 col-xs-12">
                                            <div className="form-label">
                                                New password
                                            </div>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter new password"
                                                value={user?.password || ''}
                                                onChange={e =>
                                                    userChanged(
                                                        'password',
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <FormError
                                                errors={errors?.password}
                                            />
                                        </div>
                                        <div className="form-group col-lg-6 col-xs-12">
                                            <div className="form-label">
                                                Password confirmation
                                            </div>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Confirm new password"
                                                value={
                                                    user?.password_confirmation ||
                                                    ''
                                                }
                                                onChange={e =>
                                                    userChanged(
                                                        'password_confirmation',
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <FormError
                                                errors={
                                                    errors?.password_confirmation
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {isFooterButtonsVisible &&
                                !isDeleteAccountFormVisible && (
                                    <div className="form-actions">
                                        <div className="button-group">
                                            <button
                                                type="button"
                                                className="button button-primary button-sm"
                                                disabled={isButtonDisabled}
                                                onClick={signUp}
                                            >
                                                <em className="mdi mdi-check start" />
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="button button-default button-primary button-sm"
                                                disabled={isButtonDisabled}
                                                onClick={onCancel}
                                            >
                                                <em className="mdi mdi-close start" />
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            {!!isButtonDisabled && (
                                <div
                                    className="form-group col-lg-12"
                                    style={{
                                        alignItems: 'center',
                                        marginTop: 20,
                                    }}
                                >
                                    <Loader
                                        type="Bars"
                                        color="#f37749"
                                        visible
                                        height={30}
                                        width={60}
                                    />
                                </div>
                            )}
                            {location?.pathname === '/profile' &&
                                !isDeleteAccountFormVisible &&
                                !isFooterButtonsVisible && (
                                    <DeleteAccountButton
                                        onPress={() =>
                                            setDeleteAccountFormVisible(true)
                                        }
                                    />
                                )}
                        </div>
                        <div className="flex flex-grow" />
                    </div>
                    {isDeleteAccountFormVisible && (
                        <DeleteAccountForm
                            setDeleteAccountFormVisible={
                                setDeleteAccountFormVisible
                            }
                        />
                    )}
                </div>
            </div>
            <CurrenciesFooter />
            <CountryPhoneSelector
                isCountrySelectorOpen={isCountrySelectorOpen}
                onCloseCountrySelector={onCloseCountrySelector}
                countryCode={phoneCountryCode}
                updatePhonePrefix={updatePhonePrefix}
            />
        </div>
    );
};
