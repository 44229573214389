import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonWrapper, Container, Status } from './styles';
import {
    AppHeader,
    CurrenciesFooter,
    PayMethodButtons,
    PayModalAch,
    ReportCard,
} from '../../components';
import {
    acceptChain,
    fetchChain,
    payChain,
    rejectChain,
} from '../../services/ChainsService';
import { amountOptions, getInitials } from '../../services/helpers';
import { fetchReport } from '../../services/ReportsService';
import { getChainById } from '../../state/chains';
import { Organization, Report } from '../../types/types';
import { getReportById } from '../../state/reports';
import { PayMethodContainerWrapper, SalePaymentDate } from '../Report/styles';
import { useComponentVisible } from '../../hooks';
import buildingsIcon from '../../assets/img/icons/building.svg';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { getAbilityToPerformPayments } from '../../state/myOrganizations';

export const Chain = () => {
    const { id } = useParams<{ id: string }>();
    const chain = useSelector(getChainById(Number(id)));
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const abilityToPerformPayments = useSelector(getAbilityToPerformPayments);

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                setLoading(true);
                const response = await fetchChain(Number(id));
                setLoading(false);
                if (response.status !== 200) {
                    history.push('/chains/all');
                }
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [history, id, currentOrganizationId]);

    useEffect(() => {
        if (!!chain?.reports && chain?.reports[0]?.id) {
            (async () => {
                setLoading(true);
                const response = chain?.reports
                    ? await fetchReport(chain?.reports[0]?.id)
                    : { status: 404 };

                setLoading(false);
                if (response.status !== 200) {
                    history.push('/chains/all');
                }
            })();
        }
    }, [chain?.reports, history]);

    const report = useSelector(
        getReportById(chain?.reports ? chain?.reports[0]?.id : undefined),
    );

    // const [isDetailsVisible, setDetailsVisible] = useState(true);

    // const onClickDetails = useCallback(() => {
    //     setDetailsVisible(prevState => !prevState);
    // }, []);

    const accept = useCallback(async () => {
        if (!isLoading) {
            setLoading(true);
            await acceptChain(chain?.id);
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [chain?.id, isLoading]);

    const reject = useCallback(async () => {
        if (!isLoading) {
            setLoading(true);
            await rejectChain(chain?.id);
            setLoading(false);
            await fetchCurrentOrganizationStatistics();
        }
    }, [chain?.id, isLoading]);

    const openReport = useCallback(
        (reportId: number) => {
            history.push(`/reports/report/${reportId}`);
        },
        [history],
    );

    const {
        ref,
        buttonRef,
        isComponentVisible,
        setIsComponentVisible,
    } = useComponentVisible(false);

    const [isAchModalOpen, setAchModalOpen] = useState(false);

    const showAchModal = useCallback(() => {
        setAchModalOpen(true);
        setIsComponentVisible(false);
    }, [setIsComponentVisible]);

    const closeAchModal = useCallback(() => {
        setAchModalOpen(false);
    }, []);

    const wholeOrganizationsCount = useMemo(
        () =>
            chain?.reports?.length
                ? chain?.reports[0]?.users_count
                : chain?.organizations?.length,
        [chain?.organizations?.length, chain?.reports],
    );

    const hiddenOrganizations = useMemo(() => {
        if (
            wholeOrganizationsCount &&
            chain?.organizations?.length &&
            wholeOrganizationsCount > chain?.organizations?.length
        ) {
            const array = new Array(
                wholeOrganizationsCount - chain?.organizations?.length,
            );
            return array.fill(0);
        }
        return [];
    }, [chain?.organizations?.length, wholeOrganizationsCount]);

    const [paymentStatus, setPaymentStatus] = useState<
        'success' | 'fail' | undefined
    >();

    const onSuccess = useCallback(() => {
        setPaymentStatus('success');
        (async () => {
            await fetchChain(Number(id));
            await fetchCurrentOrganizationStatistics();
        })();
    }, [id]);

    const onFail = useCallback(() => setPaymentStatus('fail'), []);
    const resetStatus = useCallback(() => setPaymentStatus(undefined), []);

    const paymentAmount = report?.sale?.amount
        ? Number(report?.sale?.amount)?.toLocaleString('en', amountOptions)
        : report?.netted_amount?.toLocaleString('en', amountOptions);

    const isCanPayChain = abilityToPerformPayments && !!report?.can_pay;

    // const onPayNowPress = () => {
    //     setIsComponentVisible(prevState => !prevState);
    // };

    const payByStripe = async () => {
        if (!isLoading) {
            setIsComponentVisible(false);
            setLoading(true);
            const response = await payChain(report?.reportable_id);
            setLoading(false);
            if (response?.data?.url) {
                const win = window.open(response?.data?.url, '_blank');
                win?.focus();
            }
        }
    };

    return (
        <div className="app-container">
            <AppHeader
                title={`Chain #${chain?.name || ''}`}
                acceptButton={chain?.can_accept_or_reject}
                rejectButton={chain?.can_accept_or_reject}
                isLoading={isLoading}
                isButtonDisabled={isLoading}
                onAccept={accept}
                onReject={reject}
                message={paymentStatus}
                resetStatus={resetStatus}
            />
            <div className="app-content">
                <div className="app-body">
                    <div className="block block-payment">
                        <div className="payment-overview">
                            <div className="payment-title">
                                Chain&nbsp;<strong>{chain?.name || ''}</strong>
                                &nbsp;&nbsp;
                                {!!chain?.status && (
                                    <div
                                        className={`label ${
                                            chain?.status === 'completed'
                                                ? 'label-success'
                                                : chain?.status === 'cancelled'
                                                ? 'label-warning'
                                                : chain?.status === 'closing'
                                                ? ''
                                                : chain?.status === 'expired'
                                                ? 'label-danger-light'
                                                : chain?.status ===
                                                  'waiting for payment'
                                                ? 'label-warning-light'
                                                : 'label-primary-light'
                                        }`}
                                    >
                                        {chain?.status}
                                    </div>
                                )}
                            </div>
                            <div className="payment-value">
                                <div className="payment-currency">
                                    {!!chain?.netted_amount_currency?.icon
                                        ?.svg && (
                                        <img
                                            src={
                                                chain?.netted_amount_currency
                                                    ?.icon?.svg
                                            }
                                            alt="netted currency"
                                        />
                                    )}
                                </div>
                                <div className="payment-amount">
                                    {chain?.netted_amount ||
                                    chain?.netted_amount === 0
                                        ? chain?.netted_amount?.toLocaleString(
                                              'en',
                                              amountOptions,
                                          )
                                        : ''}{' '}
                                    <small>netted amount</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block block-payment">
                        <div className="payment-details">
                            {/* <div className="payment-details-heading">
                                <div className="payment-details-title">
                                    Transaction details
                                </div>
                                <button
                                    type="button"
                                    style={{
                                        borderWidth: 0,
                                        background: 'transparent',
                                    }}
                                    className="payment-details-toggle"
                                    onClick={onClickDetails}
                                >
                                    Hide details
                                    <em className="mdi mdi-chevron-down" />
                                </button>
                            </div> */}
                            {/* {!!isDetailsVisible && ( */}
                            <div className="payment-details-list">
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Created date
                                    </div>
                                    {!!chain?.created_at && (
                                        <div className="payment-details-value">
                                            {DateTime.fromSQL(chain?.created_at)
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMMM yyyy, HH:mm 'GMT'",
                                                )}
                                        </div>
                                    )}
                                </div>
                                <div className="payment-details-item">
                                    <div className="payment-details-key">
                                        Accepted on
                                    </div>
                                    {!!chain?.updated_at && (
                                        <div className="payment-details-value">
                                            {DateTime.fromSQL(chain?.updated_at)
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMM yyyy, HH:mm 'GMT'",
                                                )}
                                        </div>
                                    )}
                                </div>
                                {(!!chain?.netted_amount ||
                                    chain?.netted_amount === 0) && (
                                    <div className="payment-details-item">
                                        <div className="payment-details-key">
                                            Netted amount
                                        </div>
                                        <div className="payment-details-value">
                                            {`${chain?.netted_amount?.toLocaleString(
                                                'en',
                                                amountOptions,
                                            )} ${
                                                chain?.netted_amount_currency
                                                    ?.code
                                            }`}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                    {(!!report?.can_pay || !!report?.sale?.id) && (
                        <div className="block block-payment block-payment-in">
                            <div
                                className="payment-overview"
                                style={{
                                    background:
                                        report?.sale?.state === 'SUCCEEDED'
                                            ? '#cffacf'
                                            : '#FFFFFF',
                                }}
                            >
                                {!!report?.can_pay && (
                                    <div className="payment-title">
                                        In order to close this chain - you need
                                        to pay this amount. Save on the bank
                                        fees by paying online via Troc Circle.
                                    </div>
                                )}
                                <div className="payment-value payment-value-justify">
                                    <Container>
                                        <div className="payment-currency">
                                            <img
                                                src={
                                                    report
                                                        ?.netted_amount_currency
                                                        ?.icon?.png
                                                }
                                                alt="netted currency"
                                            />
                                        </div>
                                        <div className="payment-amount">
                                            {paymentAmount}
                                        </div>
                                        <Status>
                                            <div className="circle-title">
                                                <div
                                                    className={`label ${
                                                        report?.sale?.state ===
                                                        'SUCCEEDED'
                                                            ? 'label-success-payment'
                                                            : report?.sale
                                                                  ?.state ===
                                                              'PENDING'
                                                            ? 'label-warning-light'
                                                            : report?.sale
                                                                  ?.state ===
                                                                  'CANCELED' ||
                                                              report?.sale
                                                                  ?.state ===
                                                                  'FAILED'
                                                            ? 'label-warning'
                                                            : 'label-primary-light'
                                                    } label-md`}
                                                >
                                                    {!report?.sale?.state
                                                        ? 'not paid'
                                                        : report?.sale
                                                              ?.state ===
                                                          'PENDING'
                                                        ? 'processing payment'
                                                        : report?.sale
                                                              ?.state ===
                                                          'FAILED'
                                                        ? 'paid failed'
                                                        : report?.sale
                                                              ?.state ===
                                                          'CANCELED'
                                                        ? 'paid canceled'
                                                        : 'paid'}
                                                </div>
                                            </div>
                                        </Status>
                                    </Container>
                                    {isCanPayChain && (
                                        <ButtonWrapper>
                                            <button
                                                ref={buttonRef}
                                                type="button"
                                                className="button button-primary button-sm"
                                                onClick={payByStripe}
                                                // onClick={onPayNowPress}
                                            >
                                                Pay now
                                                {/* <em
                                                    className={`mdi mdi-menu-${
                                                        isComponentVisible
                                                            ? 'up'
                                                            : 'down'
                                                    } end`}
                                                /> */}
                                            </button>
                                            <PayMethodContainerWrapper
                                                ref={ref}
                                            >
                                                {isComponentVisible && (
                                                    <PayMethodButtons
                                                        payByAch={showAchModal}
                                                        payByStripe={
                                                            payByStripe
                                                        }
                                                    />
                                                )}
                                            </PayMethodContainerWrapper>
                                        </ButtonWrapper>
                                    )}
                                    {report?.sale?.state === 'SUCCEEDED' && (
                                        <SalePaymentDate>
                                            Payment received on{' '}
                                            {DateTime.fromSQL(
                                                report?.sale?.created_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMM yyyy 'at' HH:mm 'GMT'",
                                                )}
                                        </SalePaymentDate>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {chain?.reports?.map((r: Report) => (
                        <ReportCard
                            key={r?.id.toString()}
                            report={r}
                            openReport={() => openReport(r?.id)}
                        />
                    ))}
                    {!!chain?.organizations?.length && (
                        <div className="block block-title">
                            {`${wholeOrganizationsCount} ${
                                wholeOrganizationsCount === 1
                                    ? 'member'
                                    : 'members'
                            }`}
                            {/* <div className="block-title-actions">
                                <button
                                    type="button"
                                    className="button button-default button-sm"
                                >
                                    <em className="mdi mdi-plus start" />
                                    Invite new member
                                </button>
                            </div> */}
                        </div>
                    )}
                    {!!chain?.organizations?.length && (
                        <div className="block block-users">
                            {chain?.organizations?.map(
                                (organization: Organization) => (
                                    <div
                                        key={organization?.id}
                                        className="user"
                                    >
                                        <div className="user-media">
                                            {organization?.logo?.sizes
                                                ?.thumbnail ? (
                                                <img
                                                    className="user-media-image"
                                                    src={
                                                        organization?.logo
                                                            ?.sizes?.thumbnail
                                                    }
                                                    alt="user avatar"
                                                />
                                            ) : (
                                                <div className="user-media-abbr">
                                                    {organization?.company_name &&
                                                        getInitials(
                                                            organization?.company_name,
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="user-details">
                                            <div className="user-name">
                                                {organization?.company_name}
                                            </div>
                                        </div>
                                    </div>
                                ),
                            )}
                            {hiddenOrganizations?.map((item, index) => (
                                <div key={index?.toString()} className="user">
                                    <div className="user-media">
                                        <div className="user-media-container">
                                            <img
                                                className="user-media-image-in-container"
                                                src={buildingsIcon}
                                                alt="user avatar"
                                            />
                                        </div>
                                        {/* )} */}
                                    </div>
                                    <div className="user-details">
                                        <div className="user-name italic">
                                            Hidden name
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="app-aside" />
            </div>
            <CurrenciesFooter />
            {isAchModalOpen && (
                <PayModalAch
                    id={Number(report?.reportable_id)}
                    isModalOpen={isAchModalOpen}
                    amount={paymentAmount}
                    currencyIcon={report?.netted_amount_currency?.icon?.png}
                    onRequestClose={closeAchModal}
                    onSuccess={onSuccess}
                    onFail={onFail}
                />
            )}
        </div>
    );
};
