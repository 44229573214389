import { OrganizationRequestConnection } from '../../types/types';

type Props = {
    status?: OrganizationRequestConnection['status'];
    isButtonDisabled?: boolean;
    onActivate?: () => void;
    onReject?: () => void;
    onClick?: () => void;
};

export const TdActions = ({
    status,
    isButtonDisabled,
    onActivate,
    onReject,
    onClick,
}: Props) => {
    return (
        <td>
            {status === 'Pending' ? (
                <div style={{ display: 'inline-block' }}>
                    <div className="button-group">
                        <button
                            type="button"
                            className="button button-success button-xs"
                            // style={{ width: 100 }}
                            disabled={isButtonDisabled}
                            onClick={onActivate}
                        >
                            <em className="mdi mdi-check start" />
                            Activate
                        </button>

                        <button
                            type="button"
                            className="button button-danger button-xs"
                            // style={{ width: 100 }}
                            disabled={isButtonDisabled}
                            onClick={onReject}
                        >
                            <em className="mdi mdi-close start" />
                            Reject
                        </button>
                    </div>
                </div>
            ) : status === 'Rejected' || status === 'Disconnected by factor' ? (
                <div style={{ display: 'inline-block' }}>
                    <button
                        type="button"
                        className="button button-primary button-xs"
                        disabled={isButtonDisabled}
                        onClick={onActivate}
                    >
                        <em className="mdi mdi-check start" />
                        Connect
                    </button>
                </div>
            ) : status === 'Active' ? (
                <div style={{ display: 'inline-block' }}>
                    <button
                        type="button"
                        className="button button-default button-primary button-xs"
                        style={{ display: 'inline-block' }}
                        disabled={isButtonDisabled}
                        onClick={onReject}
                    >
                        <em className="mdi mdi-close start" />
                        Disconnect
                    </button>
                </div>
            ) : (
                <button type="button" onClick={onClick}>
                    -
                </button>
            )}
        </td>
    );
};
