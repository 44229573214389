import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiltersHeader } from './FiltersHeader';
import {
    AppHeader,
    CurrenciesFooter,
    PaginationButtons,
    RedLine,
} from '../../components';
import { fetchCircles } from '../../services/CirclesService';
import { amountOptions, desc } from '../../services/helpers';
import { getCircles } from '../../state/circles';
import { getCountCirclesAll } from '../../state/collectionsCount';
import { Circle, CirclesState } from '../../types/types';
import { TotalAmounts } from '../Home/TotalAmounts';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { getMyCurrentOrganizationId } from '../../state/statistics';

const sort = (data: CirclesState): any[] => {
    const array: any[] = Object.values(data);
    if (array.length) {
        array.sort((a, b) =>
            desc(
                DateTime.fromSQL(a.created_at).valueOf(),
                DateTime.fromSQL(b.created_at).valueOf(),
            ),
        );
    }
    return array;
};

export const Circles = () => {
    const circlesState = useSelector(getCircles);
    const circles: Circle[] = useMemo(() => sort(circlesState), [circlesState]);
    const circlesCount = useSelector(getCountCirclesAll);

    const [meta, setMeta] = useState({
        current_page: 0,
        last_page: 1,
        total: circlesCount || 0,
    });

    const [isLoading, setLoading] = useState(false);
    const [status, setStatus] = useState<Circle['status'][]>([]);

    const fetchUserCircles = useCallback(
        async (page = 1, update?: boolean) => {
            setLoading(true);
            const circlesResponse = await fetchCircles(
                {
                    page,
                    status: status?.length ? status?.toString() : '',
                },
                update,
            );
            setLoading(false);
            if (circlesResponse?.data?.meta) {
                setMeta(circlesResponse?.data?.meta);
            }
        },
        [status],
    );

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                await fetchUserCircles();
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, fetchUserCircles]);

    const onPressNext = useCallback(async () => {
        await fetchUserCircles(meta.current_page + 1);
    }, [fetchUserCircles, meta.current_page]);

    const onPressPrev = useCallback(async () => {
        await fetchUserCircles(meta.current_page - 1);
    }, [fetchUserCircles, meta.current_page]);

    const history = useHistory();

    const onClick = useCallback(
        (id: number) => {
            history.push(`/circles/circle/${id}`);
        },
        [history],
    );

    const onPressMore = useCallback(async () => {
        await fetchUserCircles(meta.current_page + 1, true);
    }, [fetchUserCircles, meta.current_page]);

    const onPressFirst = useCallback(async () => {
        await fetchUserCircles(1);
    }, [fetchUserCircles]);

    const onPressLast = useCallback(async () => {
        await fetchUserCircles(meta.last_page);
    }, [fetchUserCircles, meta.last_page]);

    const onPressPage = useCallback(
        async (page: number) => {
            await fetchUserCircles(page);
        },
        [fetchUserCircles],
    );

    return (
        <div className="app-container">
            <AppHeader title="Circles" />
            <div className="app-content">
                <div className="app-body">
                    <TotalAmounts />
                    <FiltersHeader
                        isLoading={isLoading && !!circlesCount}
                        status={status}
                        onStatusChange={setStatus}
                    />
                    <div className="table" style={{ position: 'relative' }}>
                        <table>
                            <tbody>
                                {!!circlesCount && (
                                    <tr className="tr-overview">
                                        <th>Circle</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Members count</th>
                                        <th>Netted</th>
                                    </tr>
                                )}
                                {circles.map(circle => (
                                    <tr
                                        key={circle?.id.toString()}
                                        className="tr-compact"
                                        onClick={() => onClick(circle?.id)}
                                    >
                                        <td className="nowrap">
                                            <div
                                                className="table-user-compact"
                                                style={{
                                                    position: 'relative',
                                                }}
                                            >
                                                {circle.can_accept_or_reject && (
                                                    <RedLine />
                                                )}
                                                <div className="table-user-name">
                                                    {circle?.name}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                className={`label ${
                                                    circle?.status ===
                                                    'completed'
                                                        ? 'label-success'
                                                        : circle?.status ===
                                                          'cancelled'
                                                        ? 'label-warning'
                                                        : circle?.status ===
                                                          'closing'
                                                        ? ''
                                                        : circle?.status ===
                                                          'expired'
                                                        ? 'label-danger-light'
                                                        : 'label-primary-light'
                                                }`}
                                            >
                                                {circle?.status}
                                            </div>
                                        </td>
                                        <td>
                                            {DateTime.fromSQL(
                                                circle?.created_at,
                                            )
                                                .toUTC()
                                                .toFormat(
                                                    "dd MMMM yyyy, HH:mm 'GMT'",
                                                )}
                                        </td>
                                        <td className="nowrap">
                                            <div
                                                className="circle-members-label"
                                                style={{
                                                    color: '#3357a7',
                                                }}
                                            >
                                                <em className="mdi mdi-account-outline" />
                                                &nbsp;
                                                {`${circle?.users_count} ${
                                                    circle?.users_count === 1
                                                        ? 'member'
                                                        : 'members'
                                                }`}
                                            </div>
                                            {/* </div> */}
                                        </td>

                                        <td className="nowrap">
                                            <div className="table-currency">
                                                <div className="table-currency-media">
                                                    <img
                                                        className="table-currency-media-image"
                                                        src={
                                                            circle
                                                                ?.netted_amount_currency
                                                                ?.icon?.png
                                                        }
                                                        alt="currency icon"
                                                    />
                                                </div>
                                                <div className="table-currency-value">
                                                    {`${circle?.netted_amount?.toLocaleString(
                                                        'en',
                                                        amountOptions,
                                                    )}`}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationButtons
                        isLoading={isLoading}
                        prevDisabled={meta?.current_page === 1 || isLoading}
                        nextDisabled={
                            meta?.current_page === meta?.last_page || isLoading
                        }
                        currentPage={meta?.current_page}
                        lastPage={meta?.last_page}
                        pagesDisabled={isLoading}
                        total={circlesCount}
                        onPressPrev={onPressPrev}
                        onPressNext={onPressNext}
                        onPressMore={onPressMore}
                        onPressFirst={onPressFirst}
                        onPressLast={onPressLast}
                        onPressPage={onPressPage}
                    />
                </div>
            </div>
            <CurrenciesFooter />
        </div>
    );
};
