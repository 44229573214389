import styled from 'styled-components';

export const Triangle = styled.div<{ right?: number }>`
    z-index: 999;
    position: fixed;
    right: ${({ right }) => right || 107}px;

    top: 57px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #ffffff transparent transparent;

    transform: rotate(90deg);
`;

export const BlankNotificationsText = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    letter-spacing: -0.408px;
`;
