import { useCallback } from 'react';
import { AttachButton } from '../Transactions/AttachButton';
import {
    Attachment,
    AttachmentContainer,
    AttachmentName,
    AttachmentText,
    DeleteAttachmentButton,
} from '../Transactions/styles';

type Props = {
    files?: any[];
    title: string;
    setFiles: React.Dispatch<React.SetStateAction<any[]>>;
};

export const FileAttachments = ({ files, title, setFiles }: Props) => {
    const fileToDataURL = useCallback(
        (file: any) => {
            const fr = new FileReader();
            return new Promise(resolve => {
                fr.onload = event => {
                    setFiles(prevState => [...prevState, file]);

                    resolve(event?.target?.result);
                };
                fr.readAsDataURL(file);
            });
        },
        [setFiles],
    );
    const openFiles = useCallback(
        e => {
            if (FileReader && e.target?.files && e.target?.files.length) {
                const targetFiles = [...e.target?.files];
                Promise.all(targetFiles.map(fileToDataURL));
            }
            e.target.value = null;
        },
        [fileToDataURL],
    );
    const deleteAttachment = useCallback(
        (index: number) => {
            setFiles(prevState => {
                const nextState = [...prevState];
                return nextState?.filter((item, ind) => ind !== index);
            });
        },
        [setFiles],
    );

    return (
        <div style={{ paddingBottom: 20 }}>
            <div className="form-label">{title}</div>
            {!!files?.length &&
                files?.map((file, index) => (
                    <AttachmentContainer key={index?.toString()}>
                        <Attachment>
                            <em
                                className="mdi mdi-attachment start"
                                style={{
                                    transform: 'rotate(-45deg)',
                                    fontSize: 18,
                                    color: '#f37749',
                                }}
                            />
                            <AttachmentName>{file?.name || ''}</AttachmentName>
                        </Attachment>
                        <DeleteAttachmentButton
                            onClick={() => deleteAttachment(index)}
                        >
                            <em className="mdi mdi-delete-outline start" />
                            <AttachmentText>Delete</AttachmentText>
                        </DeleteAttachmentButton>
                    </AttachmentContainer>
                ))}
            {!files?.length && (
                <AttachButton
                    id={title}
                    title="Upload"
                    multiple
                    className="button button-primary button-primary-outline button-esm"
                    onPress={openFiles}
                />
            )}
        </div>
    );
};
