import styled from 'styled-components';
import { BASE_FONT } from '../../../theme/fonts';

export const Container = styled.div`
    width: 100%;

    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NoMembers = styled(BASE_FONT)`
    margin-top: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
`;

export const NoMembersSubtitle = styled(BASE_FONT)`
    margin-top: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    text-align: center;

    /* Logo Gray */
    color: #555555;
    margin-bottom: 20px;
`;
