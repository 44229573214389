import React from 'react';
import { Link, useLocation } from 'react-router-dom';

type Props = {
    path: string;
    name: string;
    count?: number;
    tooltip?: boolean;
};

export const MenuSubItem = ({ path, name, count, tooltip }: Props) => {
    const location = useLocation();
    return (
        <Link
            className={`menu-item-dropdown-item white ${
                location.pathname === path && 'active'
            }`}
            to={path}
            style={{
                borderWidth: 0,
                fontWeight: location.pathname === path ? 'bold' : 'normal',
                // alignItems: 'center',
                // alignContent: 'center',
                // justifyContent: 'center',
                position: 'relative',
            }}
        >
            {name}
            {!!count && <div className="menu-item-notes">{count}</div>}

            {tooltip && (
                <div
                    className="form"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: 6,
                        display: 'flex',
                        alignSelf: 'center',
                    }}
                >
                    <div className="form-control-wrapper">
                        <div className="form-control-info">
                            <em className="mdi mdi-information form-control-info-icon white" />
                            <div
                                className="form-control-info-container"
                                style={{
                                    color: 'black',
                                    textAlign: 'start',
                                }}
                            >
                                A factoring company is a specialized financial
                                <br />
                                services organization that provides businesses
                                with
                                <br />
                                working capital by purchasing their accounts
                                <br />
                                receivable
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Link>
    );
};
