import { DateTime } from 'luxon';
import { PaymentDate } from './styles';

type Props = {
    paymentDate: string;
    onClick?: () => void;
};

export const TdPaymentDate = ({ paymentDate, onClick }: Props) => {
    return (
        <td>
            <button
                type="button"
                onClick={onClick}
                style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PaymentDate>
                    {paymentDate
                        ? DateTime.fromSQL(paymentDate)
                              .toUTC()
                              .toFormat("dd MMM yyyy, HH:mm 'GMT'")
                        : ''}
                </PaymentDate>
            </button>
        </td>
    );
};
