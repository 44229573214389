import { Info, Initials, Logo, LogoWrapper, Name, Status } from './styles';
import { getInitials } from '../../services/helpers';
import { OrganizationRequestConnection } from '../../types/types';

type Props = {
    logo?: string;
    organizationName?: string;
    status?: OrganizationRequestConnection['status'];
    isButtonVisible?: boolean;
    isFactor?: boolean;
    disabled?: boolean;
    onHeaderButtonPress?: () => void;
    onActivate?: () => void;
    onReject?: () => void;
};

export const HeaderFactoringConnection = ({
    logo,
    organizationName,
    status,
    isButtonVisible = true,
    isFactor,
    disabled,
    onHeaderButtonPress,
    onActivate,
    onReject,
}: Props) => {
    return (
        <div className="window-header">
            <LogoWrapper>
                {logo ? (
                    <Logo src={logo} />
                ) : (
                    <Initials>{getInitials(organizationName)}</Initials>
                )}
            </LogoWrapper>
            <Info>
                <Name>{organizationName}</Name>
                <Status status={status} active={status === 'Active'}>
                    {status || 'Not connected'}
                </Status>
            </Info>

            {(status === 'Active' ||
                (status === 'Disconnected by regular' && !isFactor) ||
                (status === 'Pending' && !isFactor) ||
                (status === 'Disconnected by factor' && isFactor) ||
                status === 'Rejected' ||
                (!isFactor && !status)) &&
                isButtonVisible && (
                    <button
                        type="button"
                        className={`button ${
                            status === 'Active' || status === 'Pending'
                                ? 'button-default button-primary'
                                : 'button-primary'
                        } button-sm`}
                        style={{ position: 'absolute', right: 25 }}
                        onClick={onHeaderButtonPress}
                        disabled={disabled}
                    >
                        <em
                            className={`mdi mdi-${
                                status === 'Active' || status === 'Pending'
                                    ? 'close'
                                    : 'plus'
                            } start`}
                        />
                        {status === 'Active' || status === 'Pending'
                            ? 'Disconnect'
                            : 'Connect'}
                    </button>
                )}
            {status === 'Pending' && isFactor && (
                <div
                    className="button-group"
                    style={{ position: 'absolute', right: 25 }}
                >
                    <button
                        type="button"
                        className="button button-success button-xs"
                        style={{ width: 100 }}
                        disabled={disabled}
                        onClick={onActivate}
                    >
                        <em className="mdi mdi-check start" />
                        Activate
                    </button>

                    <button
                        type="button"
                        className="button button-danger button-xs"
                        style={{ width: 100 }}
                        disabled={disabled}
                        onClick={onReject}
                    >
                        <em className="mdi mdi-close start" />
                        Reject
                    </button>
                </div>
            )}
        </div>
    );
};
