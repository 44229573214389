import styled from 'styled-components';

export const Mdi = styled.em<{ active?: boolean }>`
    font-size: 24px;

    color: ${({ active }) => (active ? '#f37749' : 'gray')};
`;

export const SortOrderButtonContainer = styled.div<{ border?: boolean }>`
    background: transparent;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
        opacity: 0.85;
    }
    &:hover ${Mdi} {
        color: #f37749;
    }
    border-right: ${({ border }) => (border ? 1 : 0)}px solid #eaecf0;
`;
