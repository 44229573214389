import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MenuChainsLink } from './MenuChainsLink';
import { MenuCirclesLink } from './MenuCirclesLink';
import { MenuFactoringLink } from './MenuFactoringLink';
import { MenuOrganizationLink } from './MenuOrganizationLink';
import { MenuReportsLink } from './MenuReportsLink';
import { MenuSubItem } from './MenuSubItem';
import { MenuTransactionsLink } from './MenuTransactionsLink';
import { MenuUser } from './MenuUser';

import {
    getAbilityEditCurrentOrganizationSettings,
    getAbilityToManageCirclesAndChains,
    getAbilityToManageTransactions,
    getMyCurrentOrganizationType,
} from '../../state/myOrganizations';
import {
    getMyCurrentOrganizationId,
    getSumNotificationsFactorRequestConnections,
    getSumNotificationsFactorRequestTransactions,
} from '../../state/statistics';

export const LeftMenu = () => {
    const location = useLocation();
    const [
        isTransactionMenuItemsVisible,
        setTransactionMenuItemsVisible,
    ] = useState(true);

    const isTransactionsMenuActive = useMemo(
        () =>
            !location.pathname.includes('factoring-requests') &&
            location?.pathname?.includes('transactions'),
        [location.pathname],
    );

    const selectTransactions = useCallback(() => {
        if (!isTransactionsMenuActive) {
            setTransactionMenuItemsVisible(true);
        } else {
            setTransactionMenuItemsVisible(prevState => !prevState);
        }
    }, [isTransactionsMenuActive]);

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    const [
        isFactoringMenuItemsVisible,
        setFactoringMenuItemsVisible,
    ] = useState(true);

    const isFactoringMenuActive = useMemo(
        () =>
            location.pathname.includes('factoring-organization') ||
            location.pathname.includes('factoring-client') ||
            location.pathname.includes('factoring-requests'),
        [location.pathname],
    );

    const selectFactoring = useCallback(() => {
        if (!isFactoringMenuActive) {
            setFactoringMenuItemsVisible(true);
        } else {
            setFactoringMenuItemsVisible(prevState => !prevState);
        }
    }, [isFactoringMenuActive]);

    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );

    const myCurrentOrganizationType = useSelector(getMyCurrentOrganizationType);

    const isFactoringPartnersMenuVisible = useMemo(
        () =>
            !!myCurrentOrganizationType &&
            myCurrentOrganizationType === 'regular' &&
            canChangeOrganizationSettings,
        [canChangeOrganizationSettings, myCurrentOrganizationType],
    );

    const isFactoringClientsMenuVisible = useMemo(
        () =>
            !!myCurrentOrganizationType &&
            myCurrentOrganizationType === 'factoring' &&
            canChangeOrganizationSettings,
        [canChangeOrganizationSettings, myCurrentOrganizationType],
    );

    const [isSettingsMenuItemsVisible, setSettingsMenuItemsVisible] = useState(
        true,
    );

    const isSettingsMenuActive = useMemo(
        () =>
            location.pathname.includes(
                `/organization/${currentOrganizationId}`,
            ) ||
            location.pathname.includes(
                `/organization${currentOrganizationId}/members`,
            ) ||
            location.pathname.includes('/external-connections'),
        [currentOrganizationId, location.pathname],
    );

    const selectSettings = useCallback(() => {
        if (!isSettingsMenuActive) {
            setSettingsMenuItemsVisible(true);
        } else {
            setSettingsMenuItemsVisible(prevState => !prevState);
        }
    }, [isSettingsMenuActive]);

    const abilityToManageTransactions = useSelector(
        getAbilityToManageTransactions,
    );
    const abilityToManageCirclesAndChains = useSelector(
        getAbilityToManageCirclesAndChains,
    );

    const sumNotificationsFactorRequestConnections = useSelector(
        getSumNotificationsFactorRequestConnections,
    );

    const sumNotificationsFactorRequestTransactions = useSelector(
        getSumNotificationsFactorRequestTransactions,
    );

    return (
        <div className="app-menu">
            <MenuUser />
            <div className="menu-items">
                <div
                    className={`menu-item ${
                        isTransactionsMenuActive && 'active'
                    }`}
                >
                    <MenuTransactionsLink
                        isMenuActive={isTransactionsMenuActive}
                        onPress={selectTransactions}
                    />
                    {!!isTransactionMenuItemsVisible && (
                        <div className="menu-item-dropdown">
                            <MenuSubItem
                                path="/transactions/payables"
                                name="Payables"
                            />
                            <MenuSubItem
                                path="/transactions/receivables"
                                name="Receivables"
                            />
                            {abilityToManageTransactions && (
                                <>
                                    <MenuSubItem
                                        path="/transactions/receipts"
                                        name="Receipts"
                                    />
                                    <MenuSubItem
                                        path="/transactions/archived"
                                        name="Archived"
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>
                {abilityToManageCirclesAndChains && (
                    <>
                        <div
                            className={`menu-item ${
                                location.pathname.includes('circles') &&
                                'active'
                            }`}
                        >
                            <MenuCirclesLink />
                        </div>
                        <div
                            className={`menu-item ${
                                location.pathname.includes('chains') && 'active'
                            }`}
                        >
                            <MenuChainsLink />
                        </div>
                        <div
                            className={`menu-item ${
                                location.pathname.includes('reports') &&
                                'active'
                            }`}
                        >
                            <MenuReportsLink />
                        </div>
                    </>
                )}
                {!!myCurrentOrganizationType && !!currentOrganizationId && (
                    <div
                        className={`menu-item ${
                            isFactoringMenuActive && 'active'
                        }`}
                    >
                        <MenuFactoringLink
                            isMenuActive={isFactoringMenuActive}
                            link={
                                isFactoringPartnersMenuVisible
                                    ? '/factoring-organizations'
                                    : isFactoringClientsMenuVisible
                                    ? '/factoring-clients'
                                    : abilityToManageTransactions
                                    ? '/factoring-requests/transactions'
                                    : undefined
                            }
                            onPress={selectFactoring}
                        />
                        {!!isFactoringMenuItemsVisible && (
                            <div className="menu-item-dropdown">
                                {isFactoringPartnersMenuVisible && (
                                    <MenuSubItem
                                        tooltip
                                        path="/factoring-organizations"
                                        name="Factoring partners"
                                    />
                                )}
                                {isFactoringClientsMenuVisible && (
                                    <MenuSubItem
                                        path="/factoring-clients"
                                        name="Factoring clients"
                                        count={
                                            sumNotificationsFactorRequestConnections
                                        }
                                    />
                                )}
                                {abilityToManageTransactions && (
                                    <MenuSubItem
                                        path="/factoring-requests/transactions"
                                        name="Factoring requests"
                                        count={
                                            sumNotificationsFactorRequestTransactions
                                        }
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div className="flex-grow" />

                {canChangeOrganizationSettings && (
                    <div
                        className={`menu-item ${
                            (location.pathname.includes(
                                `/organization/${currentOrganizationId}`,
                            ) ||
                                location.pathname.includes(
                                    `/organization${currentOrganizationId}/billing-details`,
                                ) ||
                                location.pathname.includes(
                                    `/organization${currentOrganizationId}/members`,
                                ) ||
                                location.pathname.includes(
                                    '/external-connections',
                                )) &&
                            'active'
                        }`}
                    >
                        <MenuOrganizationLink
                            isMenuActive={isSettingsMenuActive}
                            onPress={selectSettings}
                        />
                        {!!isSettingsMenuItemsVisible && (
                            <div className="menu-item-dropdown">
                                <MenuSubItem
                                    path={`/organization/${currentOrganizationId}`}
                                    name="Company information"
                                />
                                <MenuSubItem
                                    path={`/organization${currentOrganizationId}/billing-details`}
                                    name="Billing details"
                                />
                                <MenuSubItem
                                    path={`/organization${currentOrganizationId}/my-groups`}
                                    name="My groups"
                                />
                                <MenuSubItem
                                    path={`/organization${currentOrganizationId}/members`}
                                    name="Members"
                                />

                                <MenuSubItem
                                    path="/external-connections"
                                    name="Integrations"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
