import styled from 'styled-components';

export const NotConnectedContainer = styled.div<{
    status?: 'PENDING' | 'CONNECTED';
}>`
    background: ${({ status }) =>
        status === 'PENDING'
            ? '#f5f79b'
            : status === 'CONNECTED'
            ? '#cffacf'
            : '#f4f4f1'};
    border-radius: 3px;

    margin-top: 8px;

    padding: 3px 5px;
`;

export const NotConnected = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;

    color: #080808;
`;

export const CardHeader = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: 14px;
`;

export const CardItem = styled.div<{ withoutBorder?: boolean }>`
    width: 100%;
    height: 44px;

    background: #ffffff;
    box-shadow: inset 0px ${({ withoutBorder }) => (withoutBorder ? 0 : -1)}px
        0px #e1e1e1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const CardItemText = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;

    /* identical to box height, or 123% */
    display: flex;
    align-items: center;

    color: #000000;
`;

export const Icon = styled.em<{
    type: 'import' | 'post';
    active?: boolean;
}>`
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;

    background: ${({ type, active }) =>
        type === 'import'
            ? active
                ? '#399d0a'
                : '#EB5757'
            : active
            ? '#CFFACF'
            : '#FFE6E6'};
    border-radius: 60px;

    color: ${({ type, active }) =>
        type === 'import'
            ? active
                ? '#FFFFFF'
                : '#FFFFFF'
            : active
            ? '#19956B'
            : '#D13B3B'};
`;
