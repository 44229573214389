import { TdValue } from './styles';

type Props = {
    commission?: number;
    onClick?: () => void;
};

export const Td3 = ({ commission, onClick }: Props) => {
    return (
        <td>
            <button type="button" onClick={onClick}>
                <TdValue>{`${commission || 0}%`}</TdValue>
            </button>
        </td>
    );
};
