import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const Status = styled.div`
    margin-left: 7px;
    margin-top: -7px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    position: relative;
`;

export const SalePaymentDate = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #1b9477;
`;

export const PayMethodContainerWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 35px;
    right: 0px;
`;

export const FlexOne = styled.div`
    flex: 1;
`;
