import styled from 'styled-components';
import { BASE_FONT } from '../../../theme/fonts';

export const DeleteAccountCardContainer = styled.div`
    display: flex;
    padding: 25px 101px 25px 25px;
    align-items: center;

    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px -1px 0px 0px #e1e1e1 inset;
    max-width: 1024px;
    position: relative;
`;

export const SpinnerWrapper = styled.div`
    position: absolute;
    right: 30px;
`;

export const DeleteIcon = styled.img`
    width: 60px;
    height: 60px;
    margin-right: 15px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DeleteCardTitle = styled.span<{ danger?: boolean }>`
    color: ${({ danger }) => (danger ? '#D13B3B' : '#202531')};
    font-family: 'Azo Sans', 'Roboto', arial, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 91.667% */
    letter-spacing: -0.408px;
`;

export const DeleteCardSubtitle = styled(BASE_FONT)`
    margin-top: 12px;
    max-width: 633px;
    color: #080808;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
`;

export const DeleteFormTitle = styled(BASE_FONT)`
    margin-top: 49px;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
`;
