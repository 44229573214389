import React from 'react';

import { RadioContainer, Selected } from './styles';

interface Props {
    selected?: boolean;
}

export const RadioButton = ({ selected }: Props) => {
    return (
        <RadioContainer selected={selected}>
            {!!selected && <Selected />}
        </RadioContainer>
    );
};
