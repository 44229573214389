import React, { memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
    CloseButton,
    CloseButtonText,
    Content,
    Header,
    HeaderTitle,
    TableRow,
} from './styles';
import { FiltersHeader } from './FiltersHeader';
import { TdPlanName } from './TdPlanName';
import { TdState } from './TdState';
import { TdPaymentDate } from './TdPaymentDate';
import { TdAmount } from './TdAmount';
import { fetchGroupHistory } from '../../services/OrganizationsService';
import { TMeta, TPaymentHistory } from '../../types/types';
import { PaginationButtons, Th } from '../common';
import { useDelayDebounceQuery } from '../../hooks';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type GroupPaymentHistoryModalProps = {
    groupId: number;
    isModalOpen: boolean;
    onRequestClose: () => void;
};

type SortField = 'created_at' | 'state' | 'title';

export const GroupPaymentHistoryModal = memo(
    ({
        groupId,
        isModalOpen,
        onRequestClose,
    }: GroupPaymentHistoryModalProps) => {
        const onClose = useCallback(() => {
            onRequestClose();
        }, [onRequestClose]);

        const [paymentHistory, setPaymentHistory] = useState<TPaymentHistory[]>(
            [],
        );

        const [meta, setMeta] = useState<TMeta>({
            current_page: 1,
            from: null,
            last_page: 1,
            links: null,
            path: '',
            per_page: 10,
            to: null,
            total: 0,
        });

        const [isLoading, setIsLoading] = useState(false);

        const { query, delayQuery, setDelayQuery } = useDelayDebounceQuery();

        const [state, setState] = useState<TPaymentHistory['state'][]>([]);

        const [sortField, setSortField] = useState<SortField>('created_at');

        const [sortOrder, setSortOrder] = useState<{
            [key: string]: 'ASC' | 'DESC';
        }>({
            created_at: 'DESC',
            state: 'ASC',
            title: 'DESC',
        });

        const setOrderMethod = useCallback(
            (value: SortField) => {
                setSortField(prevState => {
                    if (prevState === value) {
                        if (sortOrder[value] === 'ASC') {
                            setSortOrder(prev => ({
                                ...prev,
                                [value]: 'DESC',
                            }));
                        } else {
                            setSortOrder(prev => ({
                                ...prev,
                                [value]: 'ASC',
                            }));
                        }
                    }
                    return value;
                });
            },
            [sortOrder],
        );

        const fetchGroupHistoryMethod = useCallback(
            async (page = 1, update?: boolean) => {
                setIsLoading(true);
                const response = await fetchGroupHistory(groupId, {
                    page,
                    q: query,
                    state: state?.length ? state?.toString() : '',
                    sortField,
                    sortOrder: sortOrder[sortField],
                });
                setIsLoading(false);
                if (response?.status !== 200) {
                    onClose();
                }
                if (response?.data?.data) {
                    if (update) {
                        setPaymentHistory(prevState => [
                            ...prevState,
                            ...response?.data?.data,
                        ]);
                    } else {
                        setPaymentHistory(response?.data?.data);
                    }
                }
                if (response?.data?.meta) {
                    setMeta(response?.data?.meta);
                }
            },
            [groupId, onClose, query, sortField, sortOrder, state],
        );

        useEffect(() => {
            (async () => {
                await fetchGroupHistoryMethod();
            })();
        }, [fetchGroupHistoryMethod]);

        const onPressNext = useCallback(async () => {
            await fetchGroupHistoryMethod(meta.current_page + 1);
        }, [fetchGroupHistoryMethod, meta.current_page]);

        const onPressPrev = useCallback(async () => {
            await fetchGroupHistoryMethod(meta.current_page - 1);
        }, [fetchGroupHistoryMethod, meta.current_page]);

        const onPressMore = useCallback(async () => {
            await fetchGroupHistoryMethod(meta.current_page + 1, true);
        }, [fetchGroupHistoryMethod, meta.current_page]);

        const onPressFirst = useCallback(async () => {
            await fetchGroupHistoryMethod(1);
        }, [fetchGroupHistoryMethod]);

        const onPressLast = useCallback(async () => {
            if (meta.last_page) await fetchGroupHistoryMethod(meta.last_page);
        }, [fetchGroupHistoryMethod, meta.last_page]);

        const onPressPage = useCallback(
            async (page: number) => {
                await fetchGroupHistoryMethod(page);
            },
            [fetchGroupHistoryMethod],
        );

        return (
            <Modal
                isOpen={isModalOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={onClose}
                style={customStyles}
                contentLabel="Payment history"
            >
                <Header>
                    <HeaderTitle>Group payment history</HeaderTitle>
                    <CloseButton onClick={onClose}>
                        <CloseButtonText className="mdi mdi-close start" />
                    </CloseButton>
                </Header>

                <Content>
                    <FiltersHeader
                        isLoading={isLoading && !!meta?.total}
                        query={delayQuery}
                        state={state}
                        onQueryChange={setDelayQuery}
                        onStateChange={setState}
                    />
                    <div className="table" style={{ position: 'relative' }}>
                        <table>
                            <tbody>
                                {!!meta?.total && (
                                    <tr className="tr-overview">
                                        <Th
                                            title="Payment date"
                                            field="created_at"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.created_at}
                                            onPress={() =>
                                                setOrderMethod('created_at')
                                            }
                                        />
                                        <Th title="Payment amount" />
                                        <Th
                                            title="Payment state"
                                            field="state"
                                            sortField={sortField}
                                            sortOrder={sortOrder.state}
                                            onPress={() =>
                                                setOrderMethod('state')
                                            }
                                        />
                                        <Th
                                            title="Plan name"
                                            field="title"
                                            sortField={sortField}
                                            sortOrder={sortOrder.title}
                                            onPress={() =>
                                                setOrderMethod('title')
                                            }
                                        />
                                    </tr>
                                )}
                                {paymentHistory.map(
                                    (paymentHistoryItem, index) => (
                                        <TableRow
                                            key={paymentHistoryItem?.id?.toString()}
                                            last={
                                                index ===
                                                paymentHistory?.length - 1
                                            }
                                        >
                                            <TdPaymentDate
                                                paymentDate={
                                                    paymentHistoryItem?.created_at
                                                }
                                            />
                                            <TdAmount
                                                amount={Number(
                                                    paymentHistoryItem?.plan
                                                        ?.price || 0,
                                                )}
                                            />

                                            <TdState
                                                state={
                                                    paymentHistoryItem?.state
                                                }
                                            />
                                            <TdPlanName
                                                planName={
                                                    paymentHistoryItem?.plan
                                                        ?.title
                                                }
                                            />
                                        </TableRow>
                                    ),
                                )}
                            </tbody>
                        </table>
                    </div>
                    <PaginationButtons
                        // withoutBlock
                        isLoading={isLoading}
                        prevDisabled={meta?.current_page === 1 || isLoading}
                        nextDisabled={
                            meta?.current_page === meta?.last_page || isLoading
                        }
                        currentPage={meta?.current_page}
                        lastPage={meta?.last_page}
                        pagesDisabled={isLoading}
                        total={meta?.total}
                        onPressPrev={onPressPrev}
                        onPressNext={onPressNext}
                        onPressMore={onPressMore}
                        onPressFirst={onPressFirst}
                        onPressLast={onPressLast}
                        onPressPage={onPressPage}
                    />
                </Content>
            </Modal>
        );
    },
);
